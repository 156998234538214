import React, { useState, Fragment, useEffect } from 'react'

import BasePage from '../../components/BasePage/BasePage'
import * as Styled from './Search.styled'
import { useQuery } from '../../Routes/RouteAux'
import { searchPodcasts } from '../../services/api'
import { PodcastSummary } from 'types'
import Button from '../../components/Button/Button'
import PodcastCard from '../../components/PodcastCard/PodcastCard'
import SearchBar from '../../components/SearchBar/SearchBar'

interface SearchProps {
  signOut: () => Promise<void>
}

const Search: React.FC<SearchProps> = ({ signOut }) => {
  const query = useQuery()
  const searchTerm = query.get('searchTerm')
  const [isLoadingPodcasts, setIsLoadingPodcasts] = useState<boolean>(false)
  const [errorLoadingPodcasts, setErrorLoadingPodcasts] =
    useState<boolean>(false)
  const [podcasts, setPodcasts] = useState<PodcastSummary[]>([])

  const fetchData = async (queryString: string) => {
    setIsLoadingPodcasts(true)
    setErrorLoadingPodcasts(false)

    const req = await searchPodcasts(queryString)

    if (req.hasFailed()) {
      setErrorLoadingPodcasts(true)
    } else {
      setPodcasts(req.data)
    }

    setIsLoadingPodcasts(false)
  }

  useEffect(() => {
    if (searchTerm) {
      fetchData(searchTerm)
    }
  }, [searchTerm])

  const podcastsList = () => {
    if (isLoadingPodcasts) {
      return (
        <Styled.MessageWrapper>
          Procurando em milhares e milhares de creators...
        </Styled.MessageWrapper>
      )
    }

    if (errorLoadingPodcasts) {
      return (
        <Fragment>
          <Styled.MessageWrapper>
            Ops, parece que tivemos um erro aqui.
          </Styled.MessageWrapper>
          <Button
            variant="contained"
            fontColor="black"
            onClick={() => {
              if (searchTerm) fetchData(searchTerm)
            }}
          >
            Tentar novamente
          </Button>
        </Fragment>
      )
    }

    if (podcasts.length === 0) {
      return (
        <Styled.MessageWrapper>
          Ops, parece que não encontramos nenhum podcast para essa busca
        </Styled.MessageWrapper>
      )
    }


    return (
      podcasts.map((podcast) => {
        return (
          <PodcastCard
            key={podcast.podcastId}
            podcastId={podcast.podcastId}
            author={podcast.author}
            title={podcast.title}
            image={podcast.image}
          />
        )
      })
    )
  }

  return (
    <BasePage signOut={signOut} hideSearchBar>
      <Styled.PageWrapper>
        <SearchBar initialSearchTerm={searchTerm} />
        {searchTerm ? (
          podcastsList()
        ) : (
          <Styled.MessageWrapper>
            Procure em milhares e milhares de creators!
          </Styled.MessageWrapper>
        )}
      </Styled.PageWrapper>
    </BasePage>
  )
}

export default Search
