import { Colors } from '../../styles'
import styled from 'styled-components'
import { Form } from 'formik'
import { CircularProgress } from '@material-ui/core'
import TextFieldField from '../Fields/TextFieldField/TextFieldField'

export const FlexForm = styled(Form)`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  color: ${Colors.TEXT_LIGHT};

  @media (max-width: 600px) {
    width: 100%;
  }

  .MuiFormControl-root {
    border: 2px solid ${Colors.TEXT_LIGHT};
    border-radius: 12px;
    input,
    textarea,
    select {
      color: ${Colors.TEXT_LIGHT};
    }
  }
  .MuiSelect-root {
    border: 2px solid ${Colors.TEXT_LIGHT};
    color: ${Colors.TEXT_LIGHTEN};
  }
  .MuiFormLabel-root {
    color: ${Colors.TEXT_LIGHT};
    .fieldset {
      display: none;
    }
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${Colors.TEXT_LIGHTEN};
  }
  .llHdmD {
    color: white;
  }
`

export const LoadingCircle = styled(CircularProgress)`
  margin-left: 12px;
`

export const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  .MuiButton-root {
    color: ${Colors.TEXT_DARK};
  }
`

export const SubtitleInput = styled(TextFieldField)`
  font-style: italic;
  font-family: PT-Serif;
  color: #fff;
`

export const InputWrapper = styled.div`
  width: 100%;
`

export const TextLabel = styled.div`
  margin: 8px 0;
  color: #efefef;
`

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const InfoLabel = styled.div`
  margin-right: 12px;
  color: #efefef;
`
