import { useCallback, useEffect, useState } from 'react'
import { ApiFetch } from 'services/ApiFetch'

type Runner<T> = (args?) => Promise<T>

interface ErrorWithStatus extends Error {
  status: number
}

interface State<T> {
  isLoading: boolean
  error: null | ErrorWithStatus
  data: T | null
}

const INITIAL_STATE = {
  isLoading: true,
  error: null,
  data: null,
}

type HelperFunctions = {
  fetchData: (args?) => Promise<void>
  reset: () => void
}

const useFetch = <T>(
  runner: Runner<ApiFetch>,
  fireWhenIsMounted = true,
): State<T> & HelperFunctions => {
  const [state, setState] = useState<State<T>>(
    fireWhenIsMounted ? INITIAL_STATE : { ...INITIAL_STATE, isLoading: false },
  )

  const onFetchData = useCallback(async (args?): Promise<void> => {
    setState({
      ...INITIAL_STATE,
    })

    const res = await runner(args)
    if (!res.hasFailed()) {
      setState({
        ...INITIAL_STATE,
        isLoading: false,
        data: res.data,
      })
    } else {
      setState({
        ...INITIAL_STATE,
        isLoading: false,
        error: res.error,
      })
    }
  }, [])

  useEffect(() => {
    if (fireWhenIsMounted) {
      onFetchData()
    }
  }, [runner])

  const resetFetchData = (): void => {
    setState(INITIAL_STATE)
  }

  return {
    isLoading: state.isLoading,
    fetchData: onFetchData,
    reset: resetFetchData,
    error: state.error,
    data: state.data,
  }
}

export default useFetch
