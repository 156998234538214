import React, { Fragment } from 'react'
import * as Styled from './Podcast.styled'
import EpisodeCardWrapper from '../../components/EpisodeCard/EpisodeCardWrapper'
import { PlayerCurrentEpisode } from './PodcastContent'
import { Playlist } from '../../types'
import { logAnalyticsEvent } from '../../services/firebase'
import { getEpisodeDetails } from '../../services/api'
import usePlayer from '../../hooks/usePlayer'

interface PlaylistsTabProps {
  playerCurrentEpisode: PlayerCurrentEpisode
  playerIsPlaying: boolean
  setIsPlaying: (isPlaying: boolean) => void
  skipTime: (timeToSkip: number) => void
  podcastId: string
  playlists: Playlist[]
}

const PlaylistsTab: React.FC<PlaylistsTabProps> = ({
  playerCurrentEpisode,
  skipTime,
  setIsPlaying,
  playerIsPlaying,
  playlists,
  podcastId,
}) => {
  const playerController = usePlayer()

  return (
    <Fragment>
      {playlists.map((playlist) => (
        <Fragment key={playlist.playlistId}>
          <Styled.ListHeader>
            <Styled.PlaylistTitle>{playlist.playlistTitle}</Styled.PlaylistTitle>
          </Styled.ListHeader>
          {playlist.episodes.map((episode) => (
            <Fragment key={episode.episodeId}>
              <EpisodeCardWrapper
                key={episode.episodeId}
                episode={episode}
                episodeId={episode.episodeId}
                isPlaying={
                  playerCurrentEpisode?.currentEpisodeId ===
                  episode.episodeId && playerIsPlaying
                }
                onPlay={async () => {
                  logAnalyticsEvent('play', {
                    podcastId: episode.podcast.podcastId,
                    episodeId: episode.episodeId,
                    audioLength: episode.duration,
                    episodeName: episode.title,
                    podcastName: episode.podcast.title,
                  })

                  try {
                    const response = await getEpisodeDetails(episode.episodeId)
                    if (response?.error) {
                      playerController.playNewAudio({
                        currentAudioUrl: episode.audioUrl,
                        title: episode.title,
                        link: episode.link,
                        author: episode.author,
                        podcastTitle: episode.podcast.title,
                        currentTime: episode.userProfileData.currentPlayerTime,
                        thumbnail: episode.thumbnail,
                        currentEpisodeId: episode.episodeId,
                      })
                    } else {
                      const episodeUpdated = response.data
                      playerController.playNewAudio({
                        currentAudioUrl: episodeUpdated.audioUrl,
                        title: episodeUpdated.title,
                        link: episodeUpdated.link,
                        author: episodeUpdated.author,
                        podcastTitle: episodeUpdated.podcast.title,
                        currentTime:
                          episodeUpdated.userProfileData.currentPlayerTime,
                        thumbnail: episodeUpdated.thumbnail,
                        currentEpisodeId: episodeUpdated.episodeId,
                      })
                    }
                  } catch (e) {
                    playerController.playNewAudio({
                      currentAudioUrl: episode.audioUrl,
                      title: episode.title,
                      link: episode.link,
                      author: episode.author,
                      podcastTitle: episode.podcast.title,
                      currentTime: episode.userProfileData.currentPlayerTime,
                      thumbnail: episode.thumbnail,
                      currentEpisodeId: episode.episodeId,
                    })
                  }
                }}
                onStop={() => setIsPlaying(false)}
                onRewind={() => skipTime(-30)}
                onSkip={() => skipTime(30)}
                currentTime={
                  playerCurrentEpisode?.currentEpisodeId ===
                  episode.episodeId && playerCurrentEpisode?.currentTime
                }
                podcastId={podcastId}
              />
            </Fragment>
          ))}
          <Styled.PlaylistWhitespace />
        </Fragment>
      ))}
    </Fragment>
  )
}

export default PlaylistsTab
