import MicIcon from '@material-ui/icons/Mic'
import FastForwardIcon from '@material-ui/icons/FastForward'
import styled from 'styled-components'
import { CircularProgress } from '@material-ui/core'
import Button from '../../components/Button/Button'
import { Form } from 'formik'
import { ReactComponent as QuoteCircledIconSvg } from '../../assets/icons/quote-circled.svg'
import { ReactComponent as PrivateIconSvg } from '../../assets/icons/private.svg'
import { ReactComponent as QuoteIconSvg } from '../../assets/icons/quote.svg'
import { ReactComponent as PadlockFillIconSvg } from '../../assets/icons/padlock_fill.svg'
import { ReactComponent as EditIconSvg } from '../../assets/icons/edit.svg'
import { ReactComponent as CopyIconSvg } from '../../assets/icons/copy.svg'
import { ReactComponent as ArchiveFolderIconSvg } from '../../assets/icons/archive_folder.svg'
import { Colors, Typography } from 'styles'

export const PageWrapper = styled.div`
  display: flex;
  align-items: start;
  padding: 20px 64px;
  gap: 64px;
  color: ${Colors.GREY[400]};

  @media (max-width: 900px) {
    padding: 16px;
    flex-wrap: wrap;
  }
`

export const TitleAndLinkWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 42px;
  width: 100%;

  @media (max-width: 900px) {
    align-items: center;
    width: 100%;
  }
`

export const MenuWrapper = styled.div`
  display: flex;
  align-items: end;
  flex-direction: column;
  margin-bottom: 42px;
  width: 30%;

  background: #000000;
  border-radius: 24px;
  padding: 42px 16px 84px 16px;
  margin-top: 4rem;
  gap: 14px;

  @media (max-width: 900px) {
    align-items: center;
    width: 100%;
  }
`

export const MenuHeader = styled.div`
  font-weight: 600;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;

  text-align: right;
  text-transform: uppercase;
`

export const MenuButton = styled.div``

export const ItemGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 100%;
  width: 70%;

  @media (max-width: 900px) {
    width: 100%;
  }
`

export const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
`

interface ItemStylesInterface {
  isDark?: boolean
  textCenter?: boolean
}

export const EmptyStateMesage = styled.div`
  font-family: SpaceGrotesk;
  font-weight: 500;
  font-size: 1rem;
  line-height: 40px;

  text-align: center;

  color: #000000;

  display: flex;
  align-items: stretch;
  flex-direction: column;
  margin-top: 3rem;

  flex: 1;

  u {
    cursor: pointer;
  }

  @media (max-width: 900px) {
    order: 1;
    margin-top: 0;
  }
`

export const ButtonWrapper = styled.div`
  justify-content: end;
  width: 100%;
  display: flex;

  @media (max-width: 900px) {
    justify-content: center;
  }
`

export const MenuButtonWrapper = styled.div`
  margin-bottom: 16px;
`

export const PageTitle = styled.div`
  font-family: PP-Neue-Machina;
  font-weight: 800;
  font-size: 2.5rem;
  color: ${Colors.GREY[400]};
  margin-top: 16px;

  @media (max-width: 900px) {
    text-align: center;
    font-size: 2rem;
    line-height: normal;
    margin-bottom: 0;
    margin-left: 0;
  }
`

export const MessageWrapper = styled.div`
  margin-top: 50px;
  margin-bottom: 20px;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`

export const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const LoadingCircle = styled(CircularProgress)`
  margin-left: 12px;
`

export const SupportTierCardWrapper = styled.div`
  width: 40%;
  margin-bottom: 16px;

  &.MuiCard-root {
    background-color: #1f1f1f;
    color: white;
  }

  @media (max-width: 900px) {
    width: 90%;
  }
`

export const TiersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`

export const AddTiersButton = styled(Button)`
  align-self: center;

  &.MuiButtonBase-root {
    margin-bottom: 24px;
  }
`

export const ErrorText = styled.div`
  color: red;
  margin-bottom: 12px;
`

export const FlexForm = styled(Form)`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`

export const SearchBarWrapper = styled.div`
  margin: 32px 0 16px 0;
  width: 100%;
`

export const ListHeader = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  margin: 3rem 0 2rem 0;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 900px) {
    flex-direction: column;
    text-align: left;
  }
`

export const ListHeaderTitle = styled.span`
  font-family: SpaceGrotesk;
  color: ${Colors.GREY[400]};
  font-weight: 700;
  font-size: 32px;
  line-height: 25px;
  letter-spacing: -${Typography.LETTER_SPACING_1}px;

  @media (max-width: 900px) {
    width: 100%;
    text-align: left;
    padding-left: 12px;
  }
`

export const QuoteCircledIcon = styled(QuoteCircledIconSvg)`
  width: 2rem;
  height: 2rem;
  margin: 6px;

  @media (max-width: 900px) {
    margin-top: 0;
    margin-left: 0;
  }
`

export const PrivateIcon = styled(PrivateIconSvg)`
  width: 2rem;
  height: 2rem;
  margin-top: 6px;
  margin-bottom: 6px;

  @media (max-width: 900px) {
    margin-top: 0;
  }
`

export const QuoteIcon = styled(QuoteIconSvg)`
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 12px;
`

export const ClickableIcon = styled.div<{ disabled?: boolean }>`
  margin-right: 12px;
  ${(props) => !props.disabled && 'cursor: pointer;'};

  @media (max-width: 900px) {
    margin-right: 4px;
  }
`

export const HeaderTableTitle = styled.div`
  flex-grow: 4;
  width: 35%;
  padding-left: 12px;
  font-size: 22px;
  letter-spacing: -${Typography.LETTER_SPACING_1}px;
`

export const HeaderTableLabel = styled.div<{ grow?: number }>`
  flex-grow: ${({ grow }) => (grow ? grow : 1)};
  font-size: 22px;
  letter-spacing: -${Typography.LETTER_SPACING_1}px;
`

export const HeaderTable = styled.div`
  display: flex;
  color: ${Colors.GREY[400]};
  width: 100%;
  flex: 0 0 100%;
  border-bottom: 1px solid ${Colors.GREY[200]};
  font-family: SpaceGrotesk;
  font-weight: 600;
`

export const MainTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`

export const ExclusiveIcon = styled(PadlockFillIconSvg)``
export const EditIcon = styled(EditIconSvg)``
export const CopyIcon = styled(CopyIconSvg)``
export const ArchiveFolderIcon = styled(ArchiveFolderIconSvg)``

export const MenuButtonWithoutIcon = styled(Button)`
  &.MuiButtonBase-root {
    padding: 4px 24px;

    .MuiButton-label {
      svg {
        margin-right: 24px;
      }
    }
  }
`

interface ItemStylesInterface {
  isDark?: boolean
  textCenter?: boolean
}

export const MicrophoneCircledIcon = styled(MicIcon)`
  background: black;
  color: #fff;
  border-radius: 100%;
  width: 1.6rem;
  height: 1.6rem;
  padding: 4px;
  margin-top: 6px;
  margin-bottom: 6px;

  @media (max-width: 900px) {
    margin-top: 0;
    margin-left: 0;
  }
`

export const MicrophoneIcon = styled(MicIcon)`
  width: 1.4rem;
  height: 1.4rem;
`

export const ForwardIcon = styled(FastForwardIcon)`
  background: black;
  color: #fff;
  border-radius: 100%;
  width: 1.3rem;
  height: 1.3rem;
  padding: 2px;
`

export const headerAndNavigation = styled.div``

export const Item = styled.div<{ isOdd?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 12px 0px 12px 16px;
  width: 100%;
  color: ${Colors.GREY[400]};
  background-color: ${({ isOdd }) => (isOdd ? Colors.GREY[50] : Colors.WHITE)};

  @media (max-width: 600px) {
    flex: 0 0 100%;
    order: 2;
  }
`

export const ItemHeader = styled.div.attrs(
  (props: ItemStylesInterface) => props,
)`
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 40px;
  border-bottom: 1px solid #000000;
  padding: 12px 32px;
  border-color: ${(props) => (props.isDark ? 'white' : 'black')} !important;
  text-align: ${(props) => (props.textCenter ? 'center' : 'left')};
`

export const ItemSubHeader = styled.div.attrs(
  (props: ItemStylesInterface) => props,
)`
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 40px;
  border-bottom: 1px solid #000000;
  padding: 12px 32px;
  border-color: ${(props) => (props.isDark ? 'white' : 'black')} !important;
  text-align: ${(props) => (props.textCenter ? 'center' : 'left')};
`

export const ItemWrapper = styled.div.attrs(
  (props: ItemStylesInterface) => props,
)`
  color: black;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
`

export const ItemIcons = styled.div`
  margin-right: 8px;
  flex: 0 0 22%;
  display: flex;
  align-items: center;
  justify-content: end;

  @media (max-width: 600px) {
    flex-wrap: nowrap;
    display: flex;
  }
`

export const ItemDescription = styled.div.attrs(
  (props: { disabled: boolean }) => props,
)`
  display: flex;
  align-items: center;
  width: 100%;
`

export const ItemInformation = styled.div.attrs(
  (props: { disabled: boolean }) => props,
)`
  opacity: ${(props) => (props.disabled ? '0.21' : '1')};
  display: flex;
  align-items: center;
  width: 100%;

  @media (max-width: 600px) {
    flex: 0 0 100%;
  }
`

export const ItemTitle = styled.div`
  display: flex;
  align-items: center;
  font-family: SpaceGrotesk;
  font-weight: 600;
  font-size: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  width: 50%;
  gap: 8px;
  margin-right: 16px;
`

export const ItemReleaseDate = styled.div`
  font-weight: 400;
  font-size: 1rem;
  flex-grow: 1;
  font-family: Inter;
  letter-spacing: -${Typography.LETTER_SPACING_1}px;
`

export const ItemPlayCount = styled.div`
  font-weight: 400;
  font-size: 1rem;
  flex-grow: 1;
  font-family: Inter;
  letter-spacing: -${Typography.LETTER_SPACING_1}px;
`

export const ItemActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  flex-grow: 1;

  @media (max-width: 600px) {
    width: 50%;
  }
`

export const ItemBody = styled.div`
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  padding: 32px 32px 48px 32px;
  word-break: break-word;
`
