import { widthPercentageToDP } from './metrics'

// FONT FAMILY
export const FAMILY_DEFAULT = 'Source-Sans-Pro - fontBrandPrimary'

// FONT SIZE
export const XXX_LARGE = widthPercentageToDP('6.5%')
export const XX_LARGE = widthPercentageToDP('6%')
export const X_LARGE = widthPercentageToDP('5.5%')
export const MEDIUM = widthPercentageToDP('5%')
export const X_SMALL = widthPercentageToDP('4%')
export const XX_SMALL = widthPercentageToDP('3.5%')
export const XXX_SMALL = widthPercentageToDP('3%')

// FONT WEIGHT
export const LIGHT = '300'
export const REGULAR = '400'
export const SEMI_BOLD = '500'
export const BOLD = '600'

// FONT LETTER SPACING
export const LETTER_SPACING_DEFAULT = 0
export const LETTER_SPACING_1 = 0.5
export const LETTER_SPACING_2 = 1.5

// LINE HEIGHT
export const LINE_HEIGHT_1 = widthPercentageToDP('5%')
export const LINE_HEIGHT_2 = widthPercentageToDP('4%')
