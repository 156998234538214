import React, { useState, useRef, MouseEvent, useEffect, Fragment } from 'react'
import {
  ClickAwayListener,
  Popper,
  Paper,
  Button as MuiButton,
} from '@material-ui/core'
import * as Styled from './Header.styled'
import StyledNavLink from '../StyledNavLink'
import SearchBar from '../SearchBar/SearchBar'
import {
  getEditProfilePath,
  getRegisterCreatorIntroPath,
  getRootPath,
} from '../../Routes/RouteNames'
import isMobile from '../../utils/isMobile'
import { useGoToRoute } from '../../Routes/RouteAux'

type ProfileInfo = {
  name: string
  image: string
  action?: () => void
  type: 'listener' | 'podcast' | 'brand'
  id: string
  isDark?: boolean
}

interface HeaderProps {
  currentProfile?: ProfileInfo
  otherProfiles?: ProfileInfo[]
  signOut: () => Promise<void>
  loginRoute: string
  hideSearchBar?: boolean
  isDark?: boolean
}

const Header: React.FC<HeaderProps> = ({
  currentProfile,
  otherProfiles,
  signOut,
  loginRoute,
  hideSearchBar,
  isDark,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [isPodcastProfile, setIsPodcastProfile] = useState(
    currentProfile && currentProfile.type === 'podcast',
  )
  const [hasAnyPodcastProfile, setHasAnyPodcastProfile] = useState(
    currentProfile && currentProfile.type === 'podcast',
  )
  const anchorRef = useRef<HTMLButtonElement>(null)
  const goToRoute = useGoToRoute()

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen)
  }

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const handleMenuClose = (event: MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setIsMenuOpen(false)
  }

  const prevOpen = useRef(isMenuOpen)

  useEffect(() => {
    if (currentProfile) {
      setIsPodcastProfile(currentProfile.type === 'podcast')

      const hasPodcastProfile = otherProfiles.find(
        (profile) => profile.type === 'podcast',
      )
      setHasAnyPodcastProfile(!!hasPodcastProfile)
    } else {
      setIsPodcastProfile(false)
    }
  }, [currentProfile, otherProfiles])

  useEffect(() => {
    if (
      prevOpen.current === true &&
      isMenuOpen === false &&
      anchorRef.current
    ) {
      anchorRef.current!.focus()
    }

    prevOpen.current = isMenuOpen
  }, [isMenuOpen, prevOpen, anchorRef])

  const goToScreenButton = (path: string, label: string) => {
    return (
      <Styled.NavLinkWithMargin to={path}>
        <Styled.LinkButton isDark={isMobile()}>
          <b>{label}</b>
        </Styled.LinkButton>
      </Styled.NavLinkWithMargin>
    )
  }

  const goToPodcastScreen = () => {
    if (currentProfile && isPodcastProfile) {
      return goToScreenButton(`/podcast/${currentProfile.id}`, 'Minha página')
    }
  }

  const goToDashBoardButton = () => {
    if (currentProfile && isPodcastProfile) {
      return goToScreenButton(
        `/podcast/${currentProfile.id}/dashboard`,
        'Dashboard',
      )
    }
  }

  const goToSearchButton = () => {
    return goToScreenButton(`/search`, 'Busca')
  }

  const CreateWithUsButton = (
    <StyledNavLink to={getRegisterCreatorIntroPath()}>
      <Styled.StartProjectButton
        variant={"primary"}
        type="black"
        label={'Comece seu projeto'}
      />
    </StyledNavLink>
  )

  const HelpButton = (
    <Styled.HeaderLink
      href={'https://orelohelp.zendesk.com/hc/pt-br'}
      target="_blank"
    >
      <Styled.LinkButton isDark={isMobile()}>
        <b>Ajuda</b>
      </Styled.LinkButton>
    </Styled.HeaderLink>
  )

  const renderUserPodcasts = () => {
    if (otherProfiles && otherProfiles.length > 0) {
      return (
        <>
          {otherProfiles.map((profile) => {
            return (
              <Styled.HeaderMenuItem onClick={profile.action}>
                <Styled.MenuAvatar src={profile.image} />
                <Styled.UserName>{profile.name}</Styled.UserName>
              </Styled.HeaderMenuItem>
            )
          })}
        </>
      )
    }
  }

  return (
    <Styled.Header>
      <Styled.Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        <Styled.DrawerContent>
          {HelpButton}
          {goToSearchButton()}
          {isPodcastProfile ? (
            <>
              {goToDashBoardButton()}
              {goToPodcastScreen()}
            </>
          ) : (
            <>
              {!isMobile() && <Styled.Whitespace />}
              {CreateWithUsButton}
            </>
          )}
        </Styled.DrawerContent>
      </Styled.Drawer>
      <Styled.HeaderWrapper isDark={isDark} isLogged={currentProfile}>
        {isMobile() ? (
          <Styled.MobileMenuWrapper>
            <MuiButton onClick={toggleDrawer}>
              <Styled.MenuIcon />
            </MuiButton>
            <StyledNavLink to={getRootPath()}>
              <Styled.MobileLogo />
            </StyledNavLink>
          </Styled.MobileMenuWrapper>
        ) : (
          <Styled.LogoAndSearchBarWrapper>
            <StyledNavLink to={getRootPath()}>
              <Styled.Logo />
            </StyledNavLink>
          </Styled.LogoAndSearchBarWrapper>
        )}
        <Styled.ButtonsWrapper>
          {!isMobile() && (
            <Fragment>
              {isPodcastProfile ? (
                <>
                  {goToDashBoardButton()}
                  {goToPodcastScreen()}
                </>
              ) : (
                !hasAnyPodcastProfile && CreateWithUsButton
              )}
              {!hideSearchBar && <SearchBar />}

              {HelpButton}
            </Fragment>
          )}
          {currentProfile ? (
            <>
              <Styled.ProfileButton
                disableElevation
                onClick={toggleMenu}
                ref={anchorRef}
                startIcon={<Styled.HeaderAvatar src={currentProfile.image} />}
              >
                <Styled.UserName>{currentProfile.name}</Styled.UserName>
              </Styled.ProfileButton>
              <Popper
                open={isMenuOpen}
                anchorEl={anchorRef.current}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Styled.HeaderGrow {...TransitionProps} placement={placement}>
                    <Paper>
                      <ClickAwayListener onClickAway={handleMenuClose}>
                        <Styled.HeaderMenuList
                          autoFocusItem={isMenuOpen}
                          id="menu-list-grow"
                        >
                          {renderUserPodcasts()}

                          {currentProfile.type === 'listener' && (
                            <Styled.HeaderMenuItem
                              onClick={(event) => {
                                handleMenuClose(event)
                                goToRoute(getEditProfilePath())
                              }}
                            >
                              Meu Perfil
                            </Styled.HeaderMenuItem>
                          )}

                          <Styled.HeaderMenuItem
                            onClick={(event) => {
                              handleMenuClose(event)
                              signOut()
                            }}
                          >
                            Logout
                          </Styled.HeaderMenuItem>
                        </Styled.HeaderMenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Styled.HeaderGrow>
                )}
              </Popper>
            </>
          ) : (
            <StyledNavLink to={loginRoute}>
              <Styled.LinkButton isDark={isMobile()}>Login</Styled.LinkButton>
            </StyledNavLink>
          )}
        </Styled.ButtonsWrapper>
      </Styled.HeaderWrapper>
    </Styled.Header>
  )
}

export default Header
