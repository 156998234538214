import React from 'react'
import { Image, View } from 'react-native'
import * as R from 'ramda'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'
import { AuthConsumer } from '../authentication/context'
import { useTypedSelector } from 'reducers'
import RouteAuth from './RouteAuth'
import {
  articlePath,
  confirmSecondaryEmailPath,
  createArticlePath,
  createTopicPath,
  createTopicWebViewPath,
  creatorDashBoardPath,
  creatorProfilePath,
  deactivateEmailsPath,
  deleteAccountPath,
  editArticlePath,
  editPodcastInfoPath,
  editProfilePath,
  editSupportPath,
  embedPath,
  episodeCreatePath,
  episodeEditPath,
  episodeListingPath,
  episodePath,
  errorPath,
  forumTopicPath,
  getHomePath,
  homePath,
  listArticlesPath,
  loginPath,
  metricsPath,
  paymentsPath,
  paymentsPathViaEmail,
  playlistListingPath,
  podcastContentPath,
  podcastPath,
  podcastUrlPathName,
  registerCreatorIntroPath,
  registerCreatorOutroPath,
  registerCreatorPath,
  rootPath,
  searchPath,
  supportPodcastPath,
  supportPodcastPathName,
  topicPath,
} from './RouteNames'
import styled from 'styled-components/native'
import ErrorPage from '../pages/error/Error'
import Support from '../pages/payment/Support/Support'
import Podcast from '../pages/podcast/Podcast'
import EditPodcastSupportPage from '../pages/editPodcastSupportPage/EditPodcastSupportPage'
import Article from '../pages/article/Article'
import PodcastEpisode from '../pages/podcastEpisode/PodcastEpisode'
import RoutePodcast from './RoutePodcast'
import { useFirebaseAnalyticsScreenViewLogger } from 'services/firebase'
import DeactivateAllEmails from '../pages/deactivateAllEmails/DeactivateAllEmails'
import Search from '../pages/search/Search'
import Home from '../pages/home/Home'
import RegisterCreatorForm from '../pages/registerCreator/RegisterCreatorForm'
import RegisterCreatorIntro from '../pages/registerCreator/RegisterCreatorIntro'
import RegisterCreatorOutro from '../pages/registerCreator/RegisterCreatorOutro'
import EditPodcastInfo from '../pages/editPodcastInfo/EditPodcastInfo'
import Login from '../pages/login/Login'
import EmbedPlayer from '../pages/embedPlayer/EmbedPlayer'
import CreateTopic from '../pages/forum/pages/Topic/CreateTopic'
import Topic from '../pages/topic/Topic'
import PodcastContentPage from '../pages/podcast/PodcastContentPage'
import ConfirmSecondaryEmail from '../pages/confirmSecondaryEmail/ConfirmSecondaryEmail'
import EditProfile from '../pages/editProfile/EditProfile'
import EpisodeListingPage from '../pages/episodeListingPage/EpisodeListingPage'
import EpisodeCreatePage from '../pages/episodeCreatePage/EpisodeCreatePage'
import PlaylistListingPage from '../pages/playlistListingPage/PlaylistListingPage'
import EpisodeEditPage from 'pages/episodeEditPage/EpisodeEditPage'
import ArticleListingPage from '../pages/articleListingPage/ArticleListingPage'
import ArticleCreatePage from '../pages/articleCreatePage/ArticleCreatePage'
import ArticleEditPage from '../pages/articleEditPage/ArticleEditPage'
import CreatorDashboardPage from '../pages/creatorDashboardPage/CreatorDashboardPage'
import CreatorProfilePage from '../pages/creatorProfilePage/CreatorProfilePage'
import CreatorMetricsPage from '../pages/creatorMetricsPage/CreatorMetricsPage'
import DeleteUserAccount from "../pages/deleteUserAccount/DeleteUserAccount";

const RNSplashWrapper = styled(View)`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`

const RNSplash = styled(Image)`
  width: 200px;
  height: 200px;
  resize-mode: contain;
`

interface SwitchProps {
  context: any
}

const SwitchWithScreenTracker = ({ context }: SwitchProps): JSX.Element => {
  useFirebaseAnalyticsScreenViewLogger()
  return (
    <Switch>
      <RoutePodcast exact isAuth={context.user} path={creatorDashBoardPath}>
        <CreatorDashboardPage signOut={context.signOut} />
      </RoutePodcast>
      <Route exact path={loginPath}>
        <Login />
      </Route>
      <RouteAuth exact isAuth={context.user} path={paymentsPath}>
        <Support signOut={context.signOut} />
      </RouteAuth>
      <RouteAuth exact isAuth={context.user} path={paymentsPathViaEmail}>
        <Support signOut={context.signOut} />
      </RouteAuth>
      <RouteAuth exact isAuth={context.user} path={deactivateEmailsPath}>
        <DeactivateAllEmails />
      </RouteAuth>
      <RouteAuth exact isAuth={context.user} path={deleteAccountPath}>
        <DeleteUserAccount signOut={context.signOut} />
      </RouteAuth>
      <RouteAuth exact isAuth={context.user} path={registerCreatorPath}>
        <RegisterCreatorForm signOut={context.signOut} />
      </RouteAuth>
      <RouteAuth exact isAuth={context.user} path={registerCreatorIntroPath}>
        <RegisterCreatorIntro signOut={context.signOut} />
      </RouteAuth>
      <RouteAuth exact isAuth={context.user} path={registerCreatorOutroPath}>
        <RegisterCreatorOutro signOut={context.signOut} />
      </RouteAuth>
      <RouteAuth exact isAuth={context.user} path={editProfilePath}>
        <EditProfile signOut={context.signOut} />
      </RouteAuth>
      <RoutePodcast exact isAuth={context.user} path={metricsPath}>
        <CreatorMetricsPage signOut={context.signOut} />
      </RoutePodcast>
      <RoutePodcast exact isAuth={context.user} path={editSupportPath}>
        <EditPodcastSupportPage signOut={context.signOut} />
      </RoutePodcast>
      <RoutePodcast exact isAuth={context.user} path={episodeListingPath}>
        <EpisodeListingPage signOut={context.signOut} />
      </RoutePodcast>
      <RoutePodcast exact isAuth={context.user} path={playlistListingPath}>
        <PlaylistListingPage signOut={context.signOut} />
      </RoutePodcast>
      <RoutePodcast exact isAuth={context.user} path={episodeCreatePath}>
        <EpisodeCreatePage signOut={context.signOut} />
      </RoutePodcast>
      <RoutePodcast exact isAuth={context.user} path={episodeEditPath}>
        <EpisodeEditPage signOut={context.signOut} />
      </RoutePodcast>
      <RoutePodcast exact isAuth={context.user} path={listArticlesPath}>
        <ArticleListingPage signOut={context.signOut} />
      </RoutePodcast>
      <RoutePodcast exact isAuth={context.user} path={createArticlePath}>
        <ArticleCreatePage signOut={context.signOut} />
      </RoutePodcast>
      <RoutePodcast exact isAuth={context.user} path={editArticlePath}>
        <ArticleEditPage signOut={context.signOut} />
      </RoutePodcast>
      <RoutePodcast exact isAuth={context.user} path={editPodcastInfoPath}>
        <EditPodcastInfo signOut={context.signOut} />
      </RoutePodcast>
      <Route exact path={articlePath}>
        <Article signOut={context.signOut} />
      </Route>
      <Route exact path={episodePath}>
        <PodcastEpisode signOut={context.signOut} />
      </Route>
      <Route exact path={errorPath}>
        <ErrorPage signOut={context.signOut} />
      </Route>
      <Route exact path={searchPath}>
        <Search signOut={context.signOut} />
      </Route>
      <Route exact path={rootPath}>
        <Redirect to={getHomePath()} />
      </Route>
      <Route exact path={homePath}>
        <Home signOut={context.signOut} />
      </Route>
      <Route exact path={podcastUrlPathName}>
        <Podcast signOut={context.signOut} />
      </Route>
      <Route exact path={podcastPath}>
        <Podcast signOut={context.signOut} />
      </Route>
      <Route exact path={podcastContentPath}>
        <PodcastContentPage signOut={context.signOut} />
      </Route>
      <Route exact path={supportPodcastPathName}>
        <Podcast signOut={context.signOut} />
      </Route>
      <Route exact path={supportPodcastPath}>
        <Podcast signOut={context.signOut} />
      </Route>
      <Route exact path={embedPath}>
        <EmbedPlayer signOut={context.signOut} />
      </Route>
      <Route exact path={creatorProfilePath}>
        <CreatorProfilePage signOut={context.signOut} />
      </Route>
      <Route exact path={creatorProfilePath}>
        <CreatorProfilePage signOut={context.signOut} />
      </Route>
      <Route exact path={forumTopicPath}>
        <Topic signOut={context.signOut} />
      </Route>
      <Route exact path={createTopicWebViewPath}>
        <CreateTopic signOut={context.signOut} appMode={true} />
      </Route>
      <Route exact path={createTopicPath}>
        <CreateTopic signOut={context.signOut} />
      </Route>
      <Route exact path={topicPath}>
        <Topic signOut={context.signOut} />
      </Route>
      <Route exact path={confirmSecondaryEmailPath}>
        <ConfirmSecondaryEmail signOut={context.signOut} />
      </Route>
    </Switch>
  )
}

interface RoutesProps {}

const Routes: React.FC<RoutesProps> = () => {
  const currentProfileType = useTypedSelector<string | undefined>((state) =>
    R.path(['profiles', 'currentProfile', 'profile'], state),
  )

  return (
    <AuthConsumer>
      {(context: any) => {
        if (context.isLoading || (context.user && !currentProfileType)) {
          return (
            <RNSplashWrapper>
              <RNSplash source={require('../assets/images/logo-white.png')} />
            </RNSplashWrapper>
          )
        }
        return (
          <Router>
            <SwitchWithScreenTracker context={context} />
          </Router>
        )
      }}
    </AuthConsumer>
  )
}

export default Routes
