import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import BasePage from '../../components/BasePage/BasePage'
import * as Styled from './EpisodeListingPage.styled'
import useFetch from '../../hooks/useFetch'
import { Episode, FullSupportInformation } from '../../types'
import {
  archiveArticle,
  archiveEpisode,
  getPodcastEpisodes,
  getShareLink,
  getSupportInformationByPodcastId,
} from '../../services/api'
import Button from '../../components/Button/Button'
import {
  MessageWrapper,
  PageWrapper,
} from '../podcastSupport/PodcastSupport.styled'
import { CircularProgress, Snackbar, Tooltip } from '@material-ui/core'
import { useGoToRoute } from '../../Routes/RouteAux'
import {
  getEpisodeCreatePath,
  getEpisodeEditPath,
  getPlaylistListingPath,
} from '../../Routes/RouteNames'
import { DateClass } from '../../utils/domain/DateClass'
import handleCopyToClipboard from '../../services/copyToClipboard'
import SearchBar from '../../components/SearchBar/SearchBar'
import Dialog from '../../components/Dialog/Dialog'
import { Colors } from '../../styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import ButtonShadow from 'components/ButtonShadow/ButtonShadow'
import isMobile from 'utils/isMobile'

interface EditPodcastSupportPageProps {
  signOut: () => Promise<void>
}

const EpisodeListingPage: React.FC<EditPodcastSupportPageProps> = ({
  signOut,
}) => {
  const { podcastId } = useParams<{ podcastId: string }>()

  const [isLoadingEpisodes, setIsLoadingEpisodes] = useState(false)
  const [errorEpisodes, setErrorEpisodes] = useState(false)
  const [episodesPage, setEpisodesPage] = useState(0)
  const [hasFetchedAllEpisodes, setHasFetchedAllEpisodes] = useState(false)
  const [isLoadingMoreEpisodes, setIsLoadingMoreEpisodes] = useState(false)
  const [isShowingAllEpisodes, setIsShowingAllEpisodes] = useState(false)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [episodes, setEpisodes] = useState<Episode[]>([])
  const [archivingEpisode, setArchivingEpisode] = useState<Episode>()
  const [errorOnDelete, setErrorOnDelete] = useState<string>()

  const goToRoute = useGoToRoute()

  const { isLoading, data, error, fetchData } =
    useFetch<FullSupportInformation>(
      useCallback(() => {
        return getSupportInformationByPodcastId(podcastId)
      }, [podcastId]),
    )

  const initialFetchEpisodes = async () => {
    setIsLoadingEpisodes(true)
    const res = await getPodcastEpisodes(
      podcastId,
      undefined,
      true,
      'desc',
      true,
    )

    if (res.hasFailed()) {
      setErrorEpisodes(true)
    } else {
      if (res.data?.length < 50) {
        setHasFetchedAllEpisodes(true)
      } else {
        setHasFetchedAllEpisodes(false)
      }

      setEpisodes(res.data)
    }

    setIsLoadingEpisodes(false)
  }

  const fetchEpisodesWithPage = async () => {
    const res = await getPodcastEpisodes(
      podcastId,
      episodesPage,
      true,
      'desc',
      true,
    )

    if (!res.hasFailed()) {
      const currentEpisodes = episodes.slice()

      if (res.data?.length < 50) {
        setHasFetchedAllEpisodes(true)
      } else {
        setHasFetchedAllEpisodes(false)
      }

      currentEpisodes.push(...res.data)

      setEpisodes(currentEpisodes)
    } else {
      alert(
        'Ops! Não conseguimos carregar mais episódios. Por favor, tente novamente.',
      )
    }

    setIsLoadingMoreEpisodes(false)
  }

  const fetchEpisodesBySearchTerm = async () => {
    const res = await getPodcastEpisodes(
      podcastId,
      undefined,
      true,
      'desc',
      true,
      searchTerm,
    )

    if (!res.hasFailed()) {
      if (res.data?.length < 50) {
        setHasFetchedAllEpisodes(true)
      } else {
        setHasFetchedAllEpisodes(false)
      }

      setEpisodes(res.data)
    } else {
      alert(
        'Ops! Não conseguimos carregar mais episódios. Por favor, tente novamente.',
      )
    }

    setIsLoadingMoreEpisodes(false)
  }

  useEffect(() => {
    initialFetchEpisodes()
  }, [])

  useEffect(() => {
    if (episodesPage !== null && episodesPage !== 0) {
      fetchEpisodesWithPage()
    }
  }, [episodesPage])

  useEffect(() => {
    if (searchTerm) {
      fetchEpisodesBySearchTerm()
    } else {
      initialFetchEpisodes()
    }
  }, [searchTerm])

  if (isLoading || isLoadingEpisodes) {
    return (
      <BasePage signOut={signOut} showNavigationBar>
        <Styled.PageWrapper>
          <Styled.MessageWrapper>Carregando...</Styled.MessageWrapper>
        </Styled.PageWrapper>
      </BasePage>
    )
  }

  if (error || errorEpisodes) {
    return (
      <BasePage signOut={signOut} isDark showNavigationBar>
        <PageWrapper>
          <MessageWrapper>Ops, parece que tivemos um erro aqui.</MessageWrapper>
          <Button variant="contained" fontColor="black" onClick={fetchData}>
            Tentar novamente
          </Button>
        </PageWrapper>
      </BasePage>
    )
  }

  const onAddNewEpisode = () => {
    goToRoute(getEpisodeCreatePath(podcastId))
  }

  const onEditEpisode = (episode: Episode) => {
    goToRoute(getEpisodeEditPath(podcastId, episode.episodeId))
  }

  const onCopyEpisodeUrl = async (episode: Episode) => {
    const res = await getShareLink({
      shareType: 'episode',
      shareId: episode.episodeId,
      linkTitle: 'Compartilhar Episódio',
      linkImage: episode.image ?? '',
      linkDescription: 'Compartilhar Episódio',
      redirectToMobileApp: false,
    })

    if (!res.hasFailed()) {
      await handleCopyToClipboard(res.data)
      alert(`Link copiado! (${res.data})`)
    }
  }

  const renderSeeMoreButtonOrLoading = () => {
    if (!isShowingAllEpisodes) {
      return (
        <ButtonShadow
          variant="secondary"
          onPress={() => {
            setIsShowingAllEpisodes(true)
          }}
          label="Ver mais"
        />
      )
    }

    if (hasFetchedAllEpisodes) return

    if (isLoadingMoreEpisodes) {
      return <CircularProgress />
    } else {
      return (
        <ButtonShadow
          variant="secondary"
          onPress={() => {
            setIsLoadingMoreEpisodes(true)
            setEpisodesPage(episodesPage + 1)
          }}
          label="Ver mais"
        />
      )
    }
  }

  const isEpisodeReleased = (episode: Episode) =>
    DateClass.fromLongDTO(episode.longReleaseDate)
      .getValue()
      .isBefore(DateClass.now())

  const onArchiveEpisode = async (episodeId: string) => {
    const res = await archiveEpisode(podcastId, episodeId)
    if (res.error) {
      setErrorOnDelete(res.error.message)
      return false
    }

    return true
  }

  return (
    <BasePage signOut={signOut} showNavigationBar>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={!!errorOnDelete}
        autoHideDuration={10000}
        onClose={() => setErrorOnDelete(undefined)}
        message={`Ocorreu um erro inesperado. Tente novamente ou entre em contato com o suporte`}
      />

      <Dialog
        isDialogOpen={!!archivingEpisode}
        handleCloseDialog={() => setArchivingEpisode(null)}
        dialogText={`tem certeza que deseja arquivar o episódio: ${archivingEpisode?.title}?`}
        secondaryDialogText="essa ação não pode ser desfeita"
        dialogActionButtonText="arquivar"
        onClickDialogActionButton={async () => {
          if (await onArchiveEpisode(archivingEpisode.episodeId)) {
            initialFetchEpisodes()
          }
          setArchivingEpisode(null)
        }}
        noActionText="cancelar"
      />

      <Styled.PageWrapper>
        <Styled.TitleAndLinkWrapper>
          <Styled.MainTitleWrapper>
            <Styled.PageTitle>Meus Episódios</Styled.PageTitle>
            <div>
              <Styled.MenuButtonWrapper>
                <ButtonShadow
                  onPress={() => onAddNewEpisode()}
                  label={'Criar novo episódio'}
                  leftIcon={
                    <FontAwesomeIcon
                      icon={faPlus}
                      color={Colors.BRAND_SECONDARY}
                      name="plus-icon"
                    />
                  }
                />
              </Styled.MenuButtonWrapper>
              <Styled.MenuButtonWrapper>
                <ButtonShadow
                  onPress={() => goToRoute(getPlaylistListingPath(podcastId))}
                  label={'Ver listas de episódios'}
                  variant="secondary"
                />
              </Styled.MenuButtonWrapper>
              <ButtonShadow
                onPress={() =>
                  window.open(
                    'https://orelohelp.zendesk.com/hc/pt-br/articles/360051747574-Registro-de-Autoria',
                    '_blank',
                  )
                }
                label={'Vincular podcast'}
                variant="secondary"
              />
            </div>
          </Styled.MainTitleWrapper>
          <Styled.ListHeader>
            <Styled.ListHeaderTitle>Podcasts</Styled.ListHeaderTitle>
          </Styled.ListHeader>
          <Styled.ItemGrid>
            {(!episodes || episodes?.length <= 0) && !isShowingAllEpisodes ? (
              <Styled.EmptyStateMesage>
                Você ainda não possui nenhum episódio registrado.
                <br />
                <u onClick={() => onAddNewEpisode()}>
                  Clique aqui para criar um.
                </u>
              </Styled.EmptyStateMesage>
            ) : null}

            {isShowingAllEpisodes ? (
              <Styled.SearchBarWrapper>
                <SearchBar
                  initialSearchTerm={searchTerm}
                  placeholder="Buscar por episódios"
                  onChange={(term) => setSearchTerm(term)}
                  fullWidth={true}
                />
              </Styled.SearchBarWrapper>
            ) : null}

            {(!episodes || episodes?.length <= 0) && isShowingAllEpisodes ? (
              <Styled.EmptyStateMesage>
                Nenhum episódio encontrado com os termos informados.
              </Styled.EmptyStateMesage>
            ) : null}

            {episodes?.length > 0 && !isMobile() && (
              <Styled.HeaderTable>
                <Styled.HeaderTableTitle>Nome</Styled.HeaderTableTitle>
                <Styled.HeaderTableLabel>Data</Styled.HeaderTableLabel>
                <Styled.HeaderTableLabel>Plays</Styled.HeaderTableLabel>
                <Styled.HeaderTableLabel>Ações</Styled.HeaderTableLabel>
              </Styled.HeaderTable>
            )}
            {episodes?.length > 0 &&
              episodes.map((episode, index) => (
                <Styled.Item isOdd={index % 2 === 1}>
                  <Styled.ItemWrapper>
                    <Styled.ItemInformation>
                      <Styled.ItemDescription
                        disabled={!isEpisodeReleased(episode)}
                      >
                        <Styled.ItemTitle title={episode.title}>
                          {episode.title}{' '}
                          {episode.exclusiveToSupporters && (
                            <Styled.IconWrapper>
                              <Styled.ExclusiveIcon />
                            </Styled.IconWrapper>
                          )}
                        </Styled.ItemTitle>
                        <Styled.ItemReleaseDate>
                          {DateClass.fromLongDTO(episode.longReleaseDate)
                            .getValue()
                            .toDTO()}
                        </Styled.ItemReleaseDate>
                        {!isMobile() && (
                          <Styled.ItemPlayCount>
                            {episode.playsCount ?? 0}
                          </Styled.ItemPlayCount>
                        )}
                      </Styled.ItemDescription>
                      <Styled.ItemActions>
                        <Tooltip
                          title="Editar"
                          disableHoverListener={!!episode.guid}
                        >
                          <Styled.ClickableIcon
                            onClick={() =>
                              !episode.guid ? onEditEpisode(episode) : null
                            }
                            disabled={!!episode.guid}
                          >
                            <Styled.EditIcon
                              fill={
                                episode.guid
                                  ? Colors.GREY[200]
                                  : Colors.PURPLE[400]
                              }
                            />
                          </Styled.ClickableIcon>
                        </Tooltip>
                        <Tooltip title="Copiar url">
                          <Styled.ClickableIcon
                            onClick={() =>
                              !!isEpisodeReleased(episode)
                                ? onCopyEpisodeUrl(episode)
                                : null
                            }
                          >
                            <Styled.CopyIcon />
                          </Styled.ClickableIcon>
                        </Tooltip>
                        <Tooltip title="Arquivar">
                          <Styled.ClickableIcon
                            onClick={() =>
                              !episode.isArchived
                                ? setArchivingEpisode(episode)
                                : null
                            }
                          >
                            <Styled.ArchiveFolderIcon />
                          </Styled.ClickableIcon>
                        </Tooltip>
                      </Styled.ItemActions>
                    </Styled.ItemInformation>
                  </Styled.ItemWrapper>
                </Styled.Item>
              ))}
          </Styled.ItemGrid>

          {episodes.length > 0 && (
            <div style={{ width: '70%', marginTop: 24 }}>
              <div style={{ float: 'right' }}>
                {renderSeeMoreButtonOrLoading()}
              </div>
            </div>
          )}
        </Styled.TitleAndLinkWrapper>
      </Styled.PageWrapper>
    </BasePage>
  )
}

export default EpisodeListingPage
