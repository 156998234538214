import React, { Fragment, useState } from 'react'
import * as Styled from './TopicCard.styled'
import { Icon, TopicIcon } from './TopicCard.styled'
import { TopicSummaryDTO } from 'types'
import { useGoToRoute } from '../../Routes/RouteAux'
import PigIcon from '../../assets/icons/PIG.png'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import Launch from '@material-ui/icons/Launch'
import { Tooltip } from '@material-ui/core'
import Dialog from '../Dialog/Dialog'

import { getForumTopicPath, getPodcastPath, getSupportPodcastPath } from '../../Routes/RouteNames'
import OptionsButton from 'components/OptionsButton/OptionsButton'
import RichTextEditor from 'components/RichTextEditor'
import FollowerImages from 'pages/podcast/components/PodcastSummary/components/FollowerImages'
import isMobile from 'utils/isMobile'
import ShareButton from 'components/ShareButton/ShareButton'

export interface TopicCardProps extends TopicSummaryDTO {
  podcastId: string
  editable?: boolean
  handleDelete?: () => void
  handleEdit?: () => void,
  forumVisibility: string
}

const TopicCard: React.FC<TopicCardProps> = ({
  id,
  image,
  author,
  body,
  createdAt,
  lastUpdatedAt,
  commentAvatars,
  commentCount,
  userHasAccessToTopic,
  podcast,
  title,
  editable,
  handleDelete,
  handleEdit,
  podcastId,
  forumVisibility
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const goToRoute = useGoToRoute()

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const goToTopicPage = () => {
    if (userHasAccessToTopic) {
      goToRoute(getForumTopicPath(podcastId, id))
    } else {
      setIsModalOpen(true)
    }
  }

  return (
    <Fragment>
      <Dialog
        isDialogOpen={isModalOpen}
        handleCloseDialog={handleCloseModal}
        dialogText={'Você não tem acesso para visualizar este tópico'}
        dialogActionButtonText={forumVisibility === 'supporters' ? 'apoiar podcast' : 'seguir podcast'}
        onClickDialogActionButton={() =>
          goToRoute(forumVisibility === 'supporters' ? getSupportPodcastPath(podcastId) : getPodcastPath(podcastId))
        }
        noActionText="ok"
      />
      <Styled.TopicCard
        onClick={() => {
          if (userHasAccessToTopic) {
            goToTopicPage()
          }
        }}
        clickable={userHasAccessToTopic}
        key={id}
      >
        <Styled.CoverAndIconWrapper>
          <Styled.ArticleCover
            image={image}
          />
        </Styled.CoverAndIconWrapper>
        <Styled.InfosTitleAndDescriptionWrapper>
          <Styled.InfoAndTitleWrapper>
            {editable && (
              <Styled.IconButtonsWrapper>
                <Styled.IconButtonWrapper>
                  <Tooltip disableFocusListener title="Ir para tópico">
                    <IconButton
                      color="primary"
                      onClick={goToTopicPage}
                      size="small"
                    >
                      <Launch />
                    </IconButton>
                  </Tooltip>
                </Styled.IconButtonWrapper>
                {handleDelete && <Styled.IconButtonWrapper>
                  <Tooltip disableFocusListener title="Apagar tópico">
                    <IconButton
                      color="secondary"
                      onClick={handleDelete}
                      size="small"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Styled.IconButtonWrapper>}
              </Styled.IconButtonsWrapper>
            )}
          </Styled.InfoAndTitleWrapper>
          <Styled.AdditionalInfo>{createdAt}</Styled.AdditionalInfo>
          <Styled.Row>
            <Styled.ArticleTitle>{title}</Styled.ArticleTitle>
            <ShareButton
              showAlert
              shareType="topic"
              linkTitle="Compartilhar"
              shareId={id}
              linkImage=""
              linkDescription="Compartilhar"
              iconButton
              redirectToMobileApp={false}
            />
          </Styled.Row>

          <Styled.Description>
            <RichTextEditor readonly initialValue={body} />
          </Styled.Description>
          {!isMobile() &&
            <Styled.AdditionalInfoWrapper>
              <Styled.AdditionalInfo>
                <Styled.AuthorAvatar src={author.avatar} />
                <Styled.PaddedText>{author.name}</Styled.PaddedText>
              </Styled.AdditionalInfo>
              {commentCount > 0 && (
                <Styled.AdditionalInfo>
                  <FollowerImages images={commentAvatars} isFollower={false} />
                  <Styled.PaddedText>
                    {commentCount} comentário{commentCount > 1 && 's'}
                  </Styled.PaddedText>
                </Styled.AdditionalInfo>
              )}
            </Styled.AdditionalInfoWrapper>}
        </Styled.InfosTitleAndDescriptionWrapper>

        {isMobile() &&
          <Styled.AdditionalInfoWrapper>
            <Styled.AdditionalInfo>
              <Styled.AuthorAvatar src={author.avatar} />
              <Styled.PaddedText>{author.name}</Styled.PaddedText>
            </Styled.AdditionalInfo>

            <Styled.AdditionalInfo>{createdAt}</Styled.AdditionalInfo>

            {commentCount > 0 && (
              <Styled.AdditionalInfo>
                <FollowerImages images={commentAvatars} isFollower={false} />
                <Styled.PaddedText>
                  {commentCount} comentário{commentCount > 1 && 's'}
                </Styled.PaddedText>
              </Styled.AdditionalInfo>
            )}
          </Styled.AdditionalInfoWrapper>}
      </Styled.TopicCard>
    </Fragment>
  )
}

export default TopicCard
