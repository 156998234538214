import styled from 'styled-components'

export const Container = styled.div`
  .ShareButton-root {
    button {
      background-color: transparent !important;
      border-radius: 0 !important;
    }
  }

  .MuiIconButton-root {
    color: white;
  }
`
