import React from 'react'
import * as Styled from './Showcase.styled'
import nao_inviabilize from '../../../../assets/images/home/nao_inviabilize.png'
import petit_journal from '../../../../assets/images/home/petit_journal.png'
import modus_operandi from '../../../../assets/images/home/modus_operandi.png'
import ladoB from '../../../../assets/images/home/ladoB.png'
import StyledButton from '../Button/Button'
import { getPodcastPath, getSearchPath } from '../../../../Routes/RouteNames'
import StyledNavLink from '../../../../components/StyledNavLink'

const data = [
  {
    img: nao_inviabilize,
    title: 'NÃO INVIABILIZE',
    podcastId: '5e8a485d83d35d7aae91c591',
  },
  {
    img: petit_journal,
    title: 'PETIT JOURNAL',
    podcastId: '5e7f972bc36ede0ed50d2337',
  },
  {
    img: modus_operandi,
    title: 'MODUS OPERANDI',
    podcastId: '5e7f6ef361d8770ee8e4ac49',
  },
  { img: ladoB, title: 'LADO B DO RIO', podcastId: '5e7f580fc36ede0ed5032f1c' },
]

const Showcase: React.FC<{}> = () => {
  return (
    <Styled.SectionWrapper>
      <Styled.SectionShowcase>
        <Styled.TextWrapper>
          <Styled.Title>Descubra</Styled.Title>
          <Styled.Description>
            você não está sozinho nessa. muita gente já está criando, reunindo e
            ganhando.
          </Styled.Description>
        </Styled.TextWrapper>
        <Styled.CardWrapper>
          {data.map((item) => (
            <StyledNavLink to={getPodcastPath(item.podcastId)}>
              <Styled.Card>
                <Styled.CardImg src={item.img} />
                <Styled.CardTitle>{item.title}</Styled.CardTitle>
              </Styled.Card>
            </StyledNavLink>
          ))}
        </Styled.CardWrapper>
        <Styled.ButtonWrapper>
          <StyledNavLink to={getSearchPath()}>
            <StyledButton
              fontSize="small"
              labelColor="black"
              buttonColor="white"
              searchIcon
            >
              E muito mais
            </StyledButton>
          </StyledNavLink>
        </Styled.ButtonWrapper>
      </Styled.SectionShowcase>
    </Styled.SectionWrapper>
  )
}

export default Showcase
