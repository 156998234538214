import styled from 'styled-components'
import { ReactComponent as Sticker4svg } from '../../../../assets/images/home/sticker_bulb.svg'

export const SectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1.5px solid #212427;

  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: center;
    padding: auto;
  }
`
export const TextWrapper = styled.div`
  align-self: center;
  width: 50%;
  padding: 0 5%;

  @media (max-width: 1023px) {
    width: 85%;
    justify-content: center;
    padding-bottom: 5%;
  }
`

export const Title = styled.h2`
  text-transform: uppercase;
  margin-bottom: 0px;
  font-size: 2.5rem;
  color: #212427;
  line-height: 47px;
  width: 75%;

  @media (max-width: 767px) {
    font-size: 32px;
    line-height: 35px;
    width: 85%;
  }
`

export const Description = styled.p`
  text-transform: uppercase;
  font-size: 1.25rem;
  line-height: 100%;
  color: #212427;
  width: 80%;
  @media (max-width: 767px) {
    font-size: 14px;
  }
`

export const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  align-self: center;
  justify-content: center;
  background: #eae6e0;
  flex: 1;

  @media (max-width: 1023px) {
    display: flex;
    align-self: center;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`

export const Image = styled.img`
  display: flex;
  align-self: center;
  margin: 110px 110px 60px 110px;
  justify-content: center;
  max-width: 60%;
  object-fit: contain;

  @media (max-width: 1023px) {
    margin: 2% auto;
    max-width: 40%;
  }

  @media (max-width: 767px) {
    margin: 2% auto;
    max-width: 70%;
  }
`

export const StickerSeparator = styled.img`
  position: absolute;
  max-width: 145px;
  height: auto;
  left: 0;
  transform: translate(-50%, 50%);

  @media (max-width: 1023px) {
    display: none;
  }
`

export const StickerSeparatorSvg = styled(Sticker4svg)`
  position: absolute;
  max-width: 145px;
  height: auto;
  left: 0;
  transform: translate(-50%, 50%);

  @media (max-width: 1023px) {
    display: none;
  }
`

export const StickerSeparatorLogoDark = styled.img`
  position: absolute;
  max-width: 145px;
  height: auto;
  left: 5%;
  top: 40%;

  @media (max-width: 1023px) {
    display: none;
  }
`
