import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import BasePage from '../../components/BasePage/BasePage'
import * as Styled from './EpisodeCreatePage.styled'
import { useGoToRoute } from '../../Routes/RouteAux'
import EpisodeForm from '../../components/EpisodeForm/EpisodeForm'
import { Snackbar } from '@material-ui/core'
import { getEpisodeListingPath } from '../../Routes/RouteNames'
import {
  createEpisode,
  uploadEpisodeAudio,
  uploadEpisodeImage,
} from '../../services/api'
import { DateClass } from '../../utils/domain/DateClass'
import ReturnButton from '../../components/ReturnButton/ReturnButton'

interface EditPodcastSupportPageProps {
  signOut: () => Promise<void>
}

interface FormValues {
  title: string
  description: string
  playlistIds?: string[]
  visibility: string
  releaseDate?: Date
  audio?: {
    url?: string
    size?: string
    audioType?: string
    duration?: number
  }
  image?: string
}

const EpisodeCreatePage: React.FC<EditPodcastSupportPageProps> = ({
  signOut,
}) => {
  const { podcastId } = useParams<{ podcastId: string }>()

  const [errorOnSave, setErrorOnSave] = useState<string>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const goToRoute = useGoToRoute()

  const initialValues: FormValues = {
    title: '',
    description: '',
    playlistIds: [],
    visibility: '',
    releaseDate: DateClass.now().addMinute(10).toValue(),
    audio: undefined,
    image: undefined,
  }

  const onCreateEpisode = async (formValues: FormValues): Promise<void> => {
    setIsLoading(true)

    const res = await createEpisode(podcastId, {
      ...formValues,
      ...{
        exclusiveToSupporters:
          formValues.visibility === 'exclusiveToSupporters',
        releaseDate: DateClass.fromPersistence(formValues.releaseDate)
          .getValue()
          .toLongDTO(),
      },
    })

    if (res.error) {
      setErrorOnSave(res.error.message)
    } else {
      goToRoute(getEpisodeListingPath(podcastId))
    }

    setIsLoading(false)
  }

  const onUploadImage = (file: File): Promise<string> => {
    return new Promise((resolve) =>
      uploadEpisodeImage(podcastId, undefined, file).then((value) =>
        resolve(value.data),
      ),
    )
  }

  const onUploadAudio = (file: File): Promise<string> => {
    return new Promise((resolve) =>
      uploadEpisodeAudio(podcastId, undefined, file).then((value) =>
        resolve(value.data),
      ),
    )
  }

  return (
    <BasePage signOut={signOut}>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={!!errorOnSave}
        autoHideDuration={10000}
        onClose={() => setErrorOnSave(undefined)}
        message={`Ocorreu um erro inesperado. Tente novamente ou entre em contato com o suporte`}
      />

      <Styled.PageWrapper>
        <ReturnButton route={getEpisodeListingPath(podcastId)} />
        <Styled.TitleAndLinkWrapper>

          <Styled.PageTitle>Criar novo episódio</Styled.PageTitle>

          <EpisodeForm
            initialValues={initialValues}
            onSubmit={(values) => onCreateEpisode(values)}
            podcastId={podcastId}
            uploadImage={onUploadImage}
            uploadAudio={onUploadAudio}
          />

        </Styled.TitleAndLinkWrapper>
      </Styled.PageWrapper>
    </BasePage>
  )
}

export default EpisodeCreatePage
