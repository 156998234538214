import styled from 'styled-components'
import { MenuItem as MuiMenuItem } from '@material-ui/core'
import { MoreHoriz } from '@material-ui/icons'
import { Colors } from '../../styles'

export const MenuItem = styled(MuiMenuItem)`
  &.MuiMenuItem-root {
    font-family: Inter;
  }
`

export const MenuIcon = styled(MoreHoriz) <{ iconColor?: string }>`
transform: rotate(90deg);

  &.MuiSvgIcon-root {
    font-size: 1.6em;
  }
  color: ${(props) => props.iconColor || Colors.BRAND_PRIMARY};
`
