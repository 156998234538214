import React, { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import useFetch from '../../hooks/useFetch'
import { FullSupportInformation } from '../../types'
import {
  getPodcastId,
  getSupportInformationByPodcastId,
  getSupportInformationByUrlPathName,
} from '../../services/api'
import BasePage from '../../components/BasePage/BasePage'
import * as Styled from './Podcast.styled'
import Button from '../../components/Button/Button'
import { getHomePath } from '../../Routes/RouteNames'
import { useGoToRoute } from '../../Routes/RouteAux'
import PodcastContent from './PodcastContent'

interface PodcastProps {
  signOut: () => Promise<void>
}

const Podcast: React.FC<PodcastProps> = ({ signOut }) => {
  const { podcastId: podcastIdParam } = useParams<{ podcastId: string }>()
  const { podcastUrlPathName } = useParams<{ podcastUrlPathName: string }>()

  const goToRoute = useGoToRoute()

  const {
    isLoading: isLoadingPodcastId,
    data: podcastId,
    error: errorPodcastId,
    fetchData: fetchPodcastId,
  } = useFetch<string>(
    useCallback(() => {
      if (podcastUrlPathName) {
        return getPodcastId(podcastUrlPathName)
      } else {
        return getSupportInformationByPodcastId(podcastIdParam)
      }
    }, [podcastUrlPathName, podcastIdParam]),
  )

  if (podcastIdParam) {
    return <PodcastContent signOut={signOut} podcastId={podcastIdParam} />
  }

  if (isLoadingPodcastId) {
    return (
      <BasePage signOut={signOut} isDark>
        <Styled.PageWrapper>
          <Styled.MessageWrapper>
            <Styled.LoadingSpinner />
            Carregando...
          </Styled.MessageWrapper>
        </Styled.PageWrapper>
      </BasePage>
    )
  }

  if (errorPodcastId) {
    return (
      <BasePage signOut={signOut} isDark>
        <Styled.PageWrapper>
          <Styled.MessageWrapper>
            Ops, parece que não conseguimos encontrar o podcast ou tivemos algum
            erro
          </Styled.MessageWrapper>
          <Button
            variant="contained"
            fontColor="black"
            onClick={() => {
              fetchPodcastId()
            }}
          >
            Tentar novamente
          </Button>
          <Styled.ButtonWithMarginWrapper>
            <Button
              variant="contained"
              fontColor="black"
              onClick={() => goToRoute(getHomePath())}
            >
              Voltar para página inicial
            </Button>
          </Styled.ButtonWithMarginWrapper>
        </Styled.PageWrapper>
      </BasePage>
    )
  }

  return <PodcastContent signOut={signOut} podcastId={podcastId} />
}

export default Podcast
