import styled from 'styled-components'

export const FooterWrapper = styled.footer`
  display: flex;
  background-color: #000;
  position: absolute;
  bottom: 0;
  height: 80px;
  width: 100%;
  justify-content: space-between;
`

export const Copyright = styled.span`
  font-size: 8px;
  line-height: 12px;
  color: #fff;
  margin: auto 0;

  @media (max-width: 768px) {
    margin-left: 16px;
  }
`

export const IconsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 122px;
`
export const Icons = styled.img`
  width: auto;
  padding-right: 16px;
`

export const Link = styled.a`
  align-self: center;
`
