import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import BasePage from '../../components/BasePage/BasePage'
import useFetch from '../../hooks/useFetch'
import { Comment as CommentType, FullSupportInformation, Topic as TopicType } from '../../types'
import { deleteComment, getSupportInformationByPodcastId, getTopic, getTopicComments, } from '../../services/api'
import * as Styled from './Topic.styled'
import { useGoToRoute } from '../../Routes/RouteAux'
import Button from '../../components/Button/Button'
import RichTextEditor from '../../components/RichTextEditor'
import ReportContentDialog from '../../components/ReportContentDialog/ReportContentDialog'
import { useTypedSelector } from '../../reducers'
import { getPodcastPath, getSupportPodcastPath } from '../../Routes/RouteNames'
import { Colors, Metrics } from '../../styles'
import FollowerImages from "../podcast/components/PodcastSummary/components/FollowerImages";
import ProgressiveImage from "../../components/ProgressiveImage";
import { Modal } from "@material-ui/core";
import CommentModal from "./CommentModal";
import DropdownMenu from "../../components/DropdownMenu/DropdownMenu";
import ShareButton from "../../components/ShareButton/ShareButton";
import { format } from 'date-fns'
import Dialog from "../../components/Dialog/Dialog";
import ButtonShadow from 'components/ButtonShadow/ButtonShadow'

interface TopicProps {
  signOut: () => Promise<void>
}

const Topic: React.FC<TopicProps> = ({ signOut }) => {
  const { podcastId, topicId } = useParams<{ podcastId: string, topicId: string }>()
  const [isLoadingPodcast, setIsLoadingPodcast] = useState(true)
  const [podcastInformation, setPodcastInformation] =
    useState<FullSupportInformation>()
  const [errorPodcast, setErrorPodcast] = useState(false)
  const [reportDialogOpen, setReportDialogOpen] = useState<boolean>(false)
  const [commentModalOpen, setCommentModalOpen] = useState<boolean>(false)
  const [commentReport, setCommentReport] = useState<CommentType>(undefined)
  const [commentReportDialogOpen, setCommentReportDialogOpen] = useState<boolean>(false)
  const [commentDelete, setCommentDelete] = useState<CommentType>(undefined)
  const [commentDeleteDialogOpen, setCommentDeleteDialogOpen] = useState<boolean>(false)
  const [errorOnDelete, setErrorOnDelete] = useState<string>()

  const goToRoute = useGoToRoute()

  const currentProfile = useTypedSelector(
    (state) => state && state.profiles && state.profiles.currentProfile,
  )

  const {
    isLoading: isLoadingTopic,
    data: topicInformation,
    error: errorTopic,
    fetchData: fetchTopic,
  } = useFetch<TopicType>(
    useCallback(() => {
      return getTopic(topicId)
    }, [topicId]),
  )

  const {
    isLoading: isLoadingComments,
    data: comments,
    error: commentsError,
    fetchData: reloadComments,
  } = useFetch<CommentType[]>(
    useCallback(() => getTopicComments(topicId), [topicId]),
    true,
  )

  const fetchPodcast = async (podcastId: string): Promise<void> => {
    setIsLoadingPodcast(true)
    setErrorPodcast(false)

    const res = await getSupportInformationByPodcastId(podcastId)

    if (res.hasFailed()) {
      setErrorPodcast(true)
    } else {
      setPodcastInformation(res.data)
    }

    setIsLoadingPodcast(false)
  }

  useEffect(() => {
    if (podcastId) {
      fetchPodcast(podcastId)
    }
  }, [podcastId])

  const errorMessage = (errorType: 'payment' | 'follow' | undefined): string => {
    switch (errorType) {
      case 'payment': {
        return `Ops, parece que esse topico é exclusivo para apoiadores de ${podcastInformation.supportSummary?.title}.`;
      }

      case 'follow': {
        return `Ops, parece que esse topico é exclusivo para seguidores de ${podcastInformation.supportSummary?.title}.`;
      }
    }

    return `Ops, parece que tivemos um erro aqui.`;
  }

  const errorButtonLabel = (errorType: 'payment' | 'follow' | undefined): string => {
    switch (errorType) {
      case 'payment': {
        return `Apoiar`;
      }

      case 'follow': {
        return `Seguir`;
      }
    }

    return 'Tentar novamente';
  }

  const onComment = () => {
    setCommentModalOpen(true);
  }

  if (errorPodcast || errorTopic || commentsError) {
    let errorType = undefined;
    if (!!errorTopic && errorTopic.status === 402 && !errorPodcast && podcastInformation) {
      errorType = podcastInformation.forumVisibility === 'supporters' ? 'payment' : 'follow'
    }

    return (
      <BasePage signOut={signOut}>
        <Styled.PageWrapper>
          <Styled.ErrorWrapper>
            <Styled.MessageWrapper>
              {errorMessage(errorType)}
            </Styled.MessageWrapper>
            <Button
              variant="contained"
              buttonColorOnHover="#940a00"
              buttonColor="#cc0033"
              onClick={() => {
                if (errorType === 'payment') {
                  goToRoute(
                    getSupportPodcastPath(
                      podcastInformation?.supportSummary?.podcastId,
                    ),
                  )
                } else if (errorType === 'follow') {
                  goToRoute(
                    getPodcastPath(
                      podcastInformation?.supportSummary?.podcastId,
                    ),
                  )
                } else {
                  fetchTopic()
                }
              }}
            >
              <b>{errorButtonLabel(errorType)}</b>
            </Button>
          </Styled.ErrorWrapper>
        </Styled.PageWrapper>
      </BasePage>
    )
  }

  if (isLoadingPodcast || isLoadingTopic || isLoadingComments) {
    return (
      <BasePage signOut={signOut}>
        <Styled.PageWrapper>
          <Styled.MessageWrapper>Carregando...</Styled.MessageWrapper>
        </Styled.PageWrapper>
      </BasePage>
    )
  }

  const doDeleteComment = async (commentId: string): Promise<void> => {
    const res = await deleteComment(podcastId, commentId)
    if (res.error) {
      setErrorOnDelete(res.error.message)
    } else {
      await reloadComments()
    }
  }

  return (
    <BasePage signOut={signOut}>
      <Dialog
        isDialogOpen={commentDeleteDialogOpen}
        handleCloseDialog={() => setCommentDeleteDialogOpen(false)}
        dialogText="Tem certeza que deseja apagar o comentário?"
        secondaryDialogText="Essa ação não pode ser desfeita  "
        dialogActionButtonText="Apagar"
        onClickDialogActionButton={async () => {
          await doDeleteComment(commentDelete.id)
          setCommentDeleteDialogOpen(false)
        }}
        noActionText="cancelar"
      />
      <ReportContentDialog
        contentType="topic"
        contentId={topicInformation.id}
        isDialogOpen={reportDialogOpen}
        handleCloseDialog={() => setReportDialogOpen(false)}
      />
      <ReportContentDialog
        contentType="comment"
        contentId={commentReport?.id}
        isDialogOpen={commentReportDialogOpen}
        handleCloseDialog={() => setCommentReportDialogOpen(false)}
      />
      <Styled.PageWrapper>
        <Styled.ColoredBackground>
          <Styled.PodcastInfoAndFollowButton>
            <Styled.TitleAndImageWrapper
              onClick={() =>
                goToRoute(getPodcastPath(topicInformation.podcastId))
              }
            >
              <Styled.PodcastProfileImage
                src={
                  podcastInformation.supportSummary.thumbnail ||
                  podcastInformation.supportSummary.image
                }
              />
              <Styled.PodcastTitle>
                Conversa em <br />
                <b>{podcastInformation.supportSummary.title}</b>
              </Styled.PodcastTitle>
            </Styled.TitleAndImageWrapper>

            <ButtonShadow
              onPress={onComment}
              label={'adicionar comentário'}
              size="regular" />

          </Styled.PodcastInfoAndFollowButton>
          <Styled.TopicWrapper>
            <Styled.TopicTitle>
              {topicInformation.title}
            </Styled.TopicTitle>

            <Styled.AdditionalInfoWrapper>
              <Styled.AdditionalInfo>
                <Styled.AuthorAvatar src={topicInformation.author.avatar} />
                <Styled.PaddedText>{topicInformation.author.name}</Styled.PaddedText>
              </Styled.AdditionalInfo>
              {topicInformation.commentCount > 0 && (
                <Styled.AdditionalInfo>
                  <FollowerImages images={topicInformation.commentAvatars} isFollower={false} />
                  <Styled.PaddedText>
                    {topicInformation.commentCount} comentário{topicInformation.commentCount > 1 && 's'}
                  </Styled.PaddedText>
                </Styled.AdditionalInfo>
              )}
            </Styled.AdditionalInfoWrapper>

            <Styled.ImageWrapper>
              <ProgressiveImage
                image={topicInformation.image || topicInformation.podcast.image}
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: Metrics.mediumSize,
                  backgroundColor: Colors.BRAND_PRIMARY,
                }}
                resizeMode={'contain'}
              />
            </Styled.ImageWrapper>

            <Styled.InfoAndTopicMenuWrapper>
              <Styled.AdditionalInfoWrapper>
                <Styled.AdditionalInfo>
                  {topicInformation.createdAt}
                </Styled.AdditionalInfo>
                <ShareButton
                  shareType="topic"
                  shareId={topicInformation.id}
                  linkTitle={topicInformation.title}
                  linkImage={
                    topicInformation.image ||
                    podcastInformation.supportSummary.image
                  }
                  linkDescription={topicInformation.body}
                  redirectToMobileApp={false}
                  iconButton
                  color={'rgba(255,255,255, 0.5)'}
                />
              </Styled.AdditionalInfoWrapper>
              <DropdownMenu
                items={[
                  {
                    title: 'Denunciar tópico',
                    onClick: () => {
                      setReportDialogOpen(true)
                    },
                  },
                ]}
                color={Colors.BRAND_SECONDARY}
              />
            </Styled.InfoAndTopicMenuWrapper>
            <RichTextEditor
              readonly
              initialValue={topicInformation.body}
              fontFamily="PT-Serif"
            />
          </Styled.TopicWrapper>

          {comments.map(comment => <Styled.CommentWrapper>
            <Styled.CommentHeadWrapper>
              <Styled.AuthorWrapper>
                <Styled.StyledAvatar src={comment.author.avatar} />

                <Styled.AuthorName>
                  {comment.author.name}
                </Styled.AuthorName>
              </Styled.AuthorWrapper>

              <Styled.TopicDate>{format(comment.createdAt, 'dd/MM/yy HH:mm')}</Styled.TopicDate>

              <DropdownMenu
                items={
                  podcastInformation.userCanManageForum ? [
                    {
                      title: 'Denunciar comentário',
                      onClick: () => {
                        setCommentReport(comment)
                        setCommentReportDialogOpen(true)
                      },
                    },
                    {
                      title: 'Deletar comentário',
                      onClick: () => {
                        setCommentDelete(comment)
                        setCommentDeleteDialogOpen(true)
                      },
                    },
                  ] : [
                    {
                      title: 'Denunciar comentário',
                      onClick: () => {
                        setCommentReport(comment)
                        setCommentReportDialogOpen(true)
                      },
                    },
                  ]}
                color={Colors.BRAND_SECONDARY}
              />
            </Styled.CommentHeadWrapper>

            <Styled.CommentBodyWrapper>
              <Styled.CommentText>{comment.body}</Styled.CommentText>
            </Styled.CommentBodyWrapper>
          </Styled.CommentWrapper>)}

          {/*<Styled.FullWidthDivider />*/}

          {/*<Styled.CreatorResume>*/}
          {/*  <Styled.CreatorResumeImageWithFollowButton>*/}
          {/*    <Styled.CreatorResumeImage*/}
          {/*      src={*/}
          {/*        podcastInformation.supportSummary.thumbnail ||*/}
          {/*        podcastInformation.supportSummary.image*/}
          {/*      }*/}
          {/*      onClick={() =>*/}
          {/*        goToRoute(getPodcastPath(topicInformation.podcastId))*/}
          {/*      }*/}
          {/*    />*/}
          {/*  </Styled.CreatorResumeImageWithFollowButton>*/}
          {/*  <Styled.CreatorResumeContent>*/}
          {/*    <Styled.CreatorResumeTitleWithFollowButton>*/}
          {/*      <Styled.CreatorResumeTitle*/}
          {/*        onClick={() =>*/}
          {/*          goToRoute(getPodcastPath(topicInformation.podcastId))*/}
          {/*        }*/}
          {/*      >*/}
          {/*        {podcastInformation.supportSummary.title}*/}
          {/*      </Styled.CreatorResumeTitle>*/}
          {/*    </Styled.CreatorResumeTitleWithFollowButton>*/}
          {/*    <Styled.CreatorResumeDescription>*/}
          {/*      {podcastInformation.supportSummary.description}*/}
          {/*    </Styled.CreatorResumeDescription>*/}
          {/*  </Styled.CreatorResumeContent>*/}
          {/*</Styled.CreatorResume>*/}

        </Styled.ColoredBackground>
      </Styled.PageWrapper>

      <Modal
        open={commentModalOpen}
        onClose={() => setCommentModalOpen(false)}
      >
        <CommentModal closeModal={() => {
          setCommentModalOpen(false);
          reloadComments();

        }} topic={topicInformation} />
      </Modal>
    </BasePage>
  )
}

export default Topic
