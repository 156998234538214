import styled from 'styled-components'

import Tab from '@material-ui/core/Tab'
import { Colors } from 'styles'

export const NavigationBarWrapper = styled.nav`
  position: sticky;
  top: 0;
  margin-top: 30px;
  margin-left: 60px;
  width: calc(100% - 120px);

  @media (max-width: 900px) {
    margin-left: 0px;
    margin-top: 50px;
    width: 100%;
  }
`

export const MenuWrapper = styled.div`
  margin-top: 20px;
  gap: 14px;
  border-bottom: 2px solid ${Colors.GREY[200]};

  @media (max-width: 1200px) {
    display: block;
    margin: auto;
  }
`

export const StyledTab = styled(Tab)`
  &.MuiTab-root {
    font-weight: bold;
    font-family: PP-Neue-Machina;
    text-transform: uppercase;
    position: relative;
    height: 10%;
    font-size: 14px;
    margin-top: 30px;

    &.MuiTabs-scroller {
      border-bottom: 2px solid ${Colors.GREY[200]};
    }

    &.MuiTabs-flexContainer {
      display: block;
    }

    @media (max-width: 900px) {
      font-size: 12px;
    }
  }

  &.Mui-selected {
    color: ${Colors.PURPLE[400]};
  }

  &.MuiTab-textColorInherit {
    opacity: 1;
    color: ${Colors.GREY[200]};
  }

  &.MuiTab-textColorInherit.Mui-selected {
    color: ${Colors.PURPLE[400]};
  }
`
