import styled from 'styled-components'
import { Card } from '@material-ui/core'
import { Colors, Typography } from 'styles'
import StyledNavLink from '../StyledNavLink'

export const CardTitleAndValue = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  height: 80px;
  justify-content: space-between;
  width: 18rem;
`

export const CardTitle = styled.div`
  font-size: 24px;
  font-weight: 700;
  font-family: SpaceGrotesk;
  letter-spacing: -${Typography.LETTER_SPACING_1}px;
  word-break: break-word;
  width: 14rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @media (max-width: 600px) {
    font-size: 18px;
  }
`

export const CardDescription = styled.div`
  font-size: 14px;
  text-justify: inter-word;
  white-space: break-spaces;
  font-family: Inter;
  padding: 16px 0 0;
  line-height: 24px;
  letter-spacing: -${Typography.LETTER_SPACING_1}px;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`

export const DescriptionTitle = styled.div`
  font-family: SpaceGrotesk;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 12px;
  letter-spacing: -${Typography.LETTER_SPACING_1}px;
`

export const StyledCard = styled(Card)<{ horizontal?: boolean }>`
  box-shadow: 2px 2px 8px rgba(33, 36, 39, 0.25)
  border-radius: 16px;
  
  &.MuiPaper-rounded {
    border-radius: 12px;
    background-color:${Colors.GREY[400]};
    color: ${Colors.WHITE};
  }
`

export const CardValue = styled.div`
  font-family: SpaceGrotesk;
  font-weight: 700;
  font-size: 1.8rem;
  padding: 8px;
  background-color: ${Colors.PURPLE[400]};
  line-height: 41px;
  margin-left: 4px;
`

export const NavLink = styled(StyledNavLink)`
  color: ${Colors.WHITE};
`
