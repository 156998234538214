import { useEffect } from 'react'
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import {
  initializeAnalytics,
  logEvent,
  setUserId,
  setUserProperties,
} from 'firebase/analytics'
import { useLocation } from 'react-router-dom'

import { Config } from '../settings/config'

const firebaseApp = initializeApp(Config.firebase)
export const auth = getAuth(firebaseApp)
export const firestore = getFirestore(firebaseApp)
export const analytics = initializeAnalytics(firebaseApp, {
  config: {
    send_page_view: false,
  },
})

export const setAnalyticsUserId = (userId: string) => {
  setUserId(analytics, userId)
}

export const setAnalyticsUserProperties = (userPropeties: {
  [key: string]: any
}) => {
  setUserProperties(analytics, userPropeties)
}

export const logAnalyticsEvent = (
  eventName: string,
  eventParams: {
    [key: string]: any
  },
): void => {
  logEvent(analytics, eventName, eventParams)
}

export const useFirebaseAnalyticsScreenViewLogger = () => {
  const location = useLocation()
  useEffect(() => {
    if (location) {
      const page_path = location.pathname + location.search
      logEvent(analytics, 'screen_view', {
        firebase_screen: page_path,
      })
    }
  }, [location])
}
