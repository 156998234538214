import React from 'react'
import { TabPanelStyled } from './Podcast.styled'

interface TabPanelProps {
  value: string
  index: string
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      style={{ width: '100%' }}
    >
      {value === index && <TabPanelStyled>{children}</TabPanelStyled>}
    </div>
  )
}

export default TabPanel
