import styled from 'styled-components'
import { Chip, InputLabel, Select } from '@material-ui/core'
import { Colors } from '../../../styles'

export const Wrapper = styled.div<{ fullWidth?: boolean }>`
  display: ${(props) => (props.fullWidth ? `block` : `flex`)};
  margin: 12px 0;
  align-items: center;
  ${(props) => props.fullWidth && `width: 100%;`}
`

export const StyledLabel = styled(InputLabel)<{
  fullWidth?: boolean
  darkMode?: boolean
}>`
  margin-right: 12px;

  &.MuiFormLabel-root {
    font-family: Inter;
    color: ${(props) =>
      props.darkMode ? Colors.GREY[400] : Colors.TEXT_LIGHT} !important;
    font-size: ${(props) => (props.darkMode ? '1rem' : '22px')};
    font-weight: ${(props) => (props.darkMode ? 'normal' : 'bold')} !important;
    ${(props) => props.fullWidth && `font-weight: bold; margin-bottom: 8px;`}
  }

  &.MuiInputLabel-filled {
    transform: translate(12px, 36px) scale(1);
  }
`

export const StyledSelect = styled(Select)<{
  fullWidth?: boolean
  darkMode?: boolean
}>`
  &.MuiOutlinedInput-root {
    border-radius: ${(props) => (props.darkMode ? '24px' : '12px')};
    border-style: solid;
    border-width: ${(props) => (props.darkMode ? '2px' : '1px')};
    border-color: ${(props) =>
      props.darkMode ? Colors.GREY[200] : Colors.TEXT_LIGHT};
    overflow: hidden;
  }

  &.MuiInputBase-root {
    font-family: 'Gellix';
    ${(props) => props.fullWidth && `width: 100%`}
  }

  .MuiSelect-root {
    color: ${(props) => (props.darkMode ? '#000' : Colors.TEXT_LIGHTEN)};
  }

  .MuiFormLabel-root {
    color: ${(props) => (props.darkMode ? '#000' : Colors.TEXT_LIGHT)};

    .fieldset {
      display: none;
    }
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => (props.darkMode ? '#000' : Colors.TEXT_LIGHTEN)};
    border: ${(props) => (props.darkMode ? '0' : 'inherit')} !important;
  }

  .MuiSelect-iconOutlined {
    color: ${(props) => (props.darkMode ? '#000' : Colors.TEXT_LIGHTEN)};
  }

  .Mui-disabled {
    background: #c9c9c9;
  }

  .MuiOutlinedInput-notchedOutline {
    border: ${(props) => (props.darkMode ? '0' : 'inherit')} !important;
  }
`

export const NewStyledSelect = styled(Select)<{ darkMode?: boolean }>`
  .MuiOutlinedInput-input {
    padding: 25px 14px 18.5px 14px;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) =>
      props.darkMode ? '#000' : 'inherit'} !important;
    border-width: ${(props) => (props.darkMode ? '2px' : 'inherit')} !important;
  }
`

export const StyledChip = styled(Chip)`
  &.MuiChip-root {
    background: #000;
    color: #fff;
  }
`
