import styled from 'styled-components'

export const SectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1.5px solid #212427;
  padding: 5% 10%;
  background-color: #e7e3dd;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    padding: 0px;
  }
`
export const TextWrapper = styled.div`
  align-self: center;
  width: 50%;
  @media (max-width: 767px) {
    width: 85%;
    justify-content: center;
    margin: auto;
  }
`

export const Title = styled.h2`
  text-transform: uppercase;
  margin-bottom: 0px;
  font-size: 2.5rem;
  color: #212427;
  line-height: 47px;
  width: 75%;

  @media (max-width: 767px) {
    font-size: 32px;
    line-height: 35px;
    width: 85%;
  }
`

export const Description = styled.p`
  text-transform: uppercase;
  font-size: 1.25rem;
  line-height: 100%;
  color: #212427;
  width: 80%;
  @media (max-width: 767px) {
    font-size: 14px;
  }
`

export const Image = styled.img`
  max-width: 45%;
  max-height: 45%;
  align-self: center;
  @media (max-width: 767px) {
    align-self: center;
    margin-top: 20px;
    margin-left: -20px;
    width: 100%;
    height: auto;
    max-width: 400px;
    margin-bottom: 30px;
  }
`
