import styled from 'styled-components'
import { Colors } from 'styles'

export const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`

export const WebContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 80px 3.5rem;

  @media (max-width: 900px) {
    padding: 60px 1rem;
  }
`

export const PageWrapper = styled.div.attrs(
  (props: { darkMode?: boolean }) => props,
)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  color: ${(props) => (props.darkMode ? Colors.GREY[400] : Colors.WHITE)};
  width: 100%;
`
