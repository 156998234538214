import React, { useState } from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import * as Styled from './TopicForm.styled'
import TextFieldField from '../Fields/TextFieldField/TextFieldField'
import RichTextEditorField from '../Fields/RichTextEditorField/RichTextEditorField'
import { uploadTopicImage } from '../../services/api'
import { CircularProgress } from '@material-ui/core'
import { useQuery } from '../../Routes/RouteAux'
import ButtonShadow from 'components/ButtonShadow/ButtonShadow'

interface FormValues {
  title: string
  body: string
}

const createArticleValidationSchema = Yup.object().shape({
  title: Yup.string()
    .required('Campo obrigatório')
    .min(1, 'Campo obrigatório')
    .max(62, 'Tamanho máximo 62 caracteres'),
  body: Yup.string().required('Campo obrigatório'),
})

export interface TopicFormProps {
  initialValues?: {
    title?: string
    body?: string
  }
  onSubmit: (values: FormValues) => void | Promise<void>
  podcastId: string
  isEdit?: boolean
  loading?: boolean
  darkMode?: boolean
}

const TopicForm: React.FC<TopicFormProps> = ({
  initialValues,
  onSubmit,
  isEdit,
  podcastId,
  loading,
  darkMode
}) => {
  const [waitingForResponse, setWaitingForResponse] = useState(false)

  const query = useQuery()
  const token = query.get('token')
  const userProfileId = query.get('podcastId')

  const uploadFile = async (file: File) => {
    const uploadFileResponse = {
      error: null,
      imageUrl: null,
    }

    const impersonateId = userProfileId ? { podcastId: userProfileId } : null

    const res = await uploadTopicImage(
      podcastId,
      file,
      impersonateId,
      token,
    )

    if (res.error) {
      uploadFileResponse.error = 'Erro ao fazer upload da imagem. Tente novamente ou entre em contato com o suporte'
    } else {
      uploadFileResponse.imageUrl = res.data
    }
    return uploadFileResponse
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => {
        setWaitingForResponse(true)
        await onSubmit(values as FormValues)
        setWaitingForResponse(false)
      }}
      validationSchema={createArticleValidationSchema}
    >
      {({ handleSubmit, errors, touched, values, setFieldValue }) => {
        return (
          <Styled.FlexForm>
            <Styled.InputWrapper>
              <TextFieldField
                placeholder="Título"
                name="title"
                variant="outlined"
                error={!!(touched.title && errors?.title)}
                helperText={touched.title && errors?.title}
                transparentBorder
                fullWidth
                style={{ fontSize: 48, fontWeight: 800 }}
                value={values?.title}
                disabled={loading}
                darkMode={darkMode}
              />
            </Styled.InputWrapper>
            <RichTextEditorField
              name="body"
              placeholder="Digite seu texto aqui"
              error={!!(touched.body && errors?.body)}
              helperText={touched.body && errors?.body}
              toolbarOptions={[
                'bold',
                'italic',
                'underline',
                'code',
                'heading',
                'block-quote',
                'numbered-list',
                'bulleted-list',
                'hyperlink',
                'image',
              ]}
              uploadFile={uploadFile}
              onInsertImage={(imageUrl) => setFieldValue('image', imageUrl)}
              darkMode={darkMode}
            />
            <Styled.SubmitButtonWrapper>
              <ButtonShadow
                type='black'
                variant='secondary'
                label={!loading ? 'Publicar' : ''}
                leftIcon={loading && <CircularProgress size={24} />}
                onPress={() => handleSubmit()}
                disabled={waitingForResponse || loading}
              />
              {waitingForResponse && <Styled.LoadingCircle />}
            </Styled.SubmitButtonWrapper>
          </Styled.FlexForm>
        )
      }}
    </Formik>
  )
}

export default TopicForm
