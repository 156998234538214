import React, { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'

import BasePage from '../../components/BasePage/BasePage'
import * as Styled from './EpisodeEditPage.styled'
import { useGoToRoute } from '../../Routes/RouteAux'
import EpisodeForm from '../../components/EpisodeForm/EpisodeForm'
import { Snackbar } from '@material-ui/core'
import {
  getEpisodeDetails,
  updateEpisode,
  uploadEpisodeAudio,
  uploadEpisodeImage,
} from '../../services/api'
import useFetch from '../../hooks/useFetch'
import { Episode } from '../../types'
import { DateClass } from '../../utils/domain/DateClass'
import { getEpisodeListingPath } from '../../Routes/RouteNames'
import ReturnButton from '../../components/ReturnButton/ReturnButton'
import Dialog from '../../components/Dialog/Dialog'

interface EditPodcastSupportPageProps {
  signOut: () => Promise<void>
}

interface FormValues {
  title: string
  description: string
  playlistIds: string[]
  visibility: string
  releaseDate?: Date
  audio?: {
    url?: string
    size?: string
    audioType?: string
  }
  image?: string
}

const EpisodeEditPage: React.FC<EditPodcastSupportPageProps> = ({
  signOut,
}) => {
  const { podcastId, episodeId } =
    useParams<{ podcastId: string; episodeId: string }>()

  const [errorOnSave, setErrorOnSave] = useState<string>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showReleaseDateWarningModal, setShowReleaseDateWarningModal] =
    useState<boolean>(false)
  const [lastFormValues, setLastFormValues] = useState<FormValues>()

  const goToRoute = useGoToRoute()

  const {
    isLoading: isLoadingEpisode,
    data: episodeInformation,
    error: errorEpisode,
    fetchData: fetchEpisode,
  } = useFetch<Episode>(
    useCallback(() => {
      return getEpisodeDetails(episodeId)
    }, [episodeId]),
  )

  const getInitialValues = (): FormValues => {
    return {
      title: episodeInformation?.title,
      description: episodeInformation?.description,
      releaseDate: DateClass.fromLongDTO(episodeInformation?.longReleaseDate)
        .getValue()
        .toValue(),
      visibility: episodeInformation?.exclusiveToSupporters
        ? 'exclusiveToSupporters'
        : 'open',
      playlistIds: [],
      image: episodeInformation?.image,
      audio: {
        size: episodeInformation.audioFileSizeInBytes.toString(),
        audioType: episodeInformation.audioType,
        url: episodeInformation.audioUrl,
      },
    }
  }

  // todo use it when we switch to RichTextEditor
  // const parseDescription = (description: string | undefined) => {
  //   if (description) {
  //     try {
  //       JSON.parse(description)
  //     } catch (e) {
  //       description = JSON.stringify([{'type': 'paragraph', 'children': [{'text': description}]}])
  //     }
  //   }
  //
  //   return description
  // };

  const checkReleaseDate = (releaseDate?: Date) => {
    if (releaseDate) {
      const date = DateClass.fromPersistence(releaseDate).getValue()
      if (
        date.toLongDTO() !== episodeInformation.longReleaseDate &&
        date.isAfter(DateClass.now())
      ) {
        setShowReleaseDateWarningModal(true)
        return false
      }
    }

    return true
  }

  const onUpdateEpisode = async (formValues: FormValues): Promise<void> => {
    setLastFormValues(formValues)

    if (
      !showReleaseDateWarningModal &&
      !checkReleaseDate(formValues.releaseDate)
    ) {
      return
    }

    setIsLoading(true)

    const res = await updateEpisode(episodeId, podcastId, {
      title: formValues.title,
      description: formValues.description,
      exclusiveToSupporters: formValues.visibility === 'exclusiveToSupporters',
      releaseDate: DateClass.fromPersistence(formValues.releaseDate)
        .getValue()
        .toLongDTO(),
      audio: formValues.audio,
      image: formValues.image,
    })

    if (res.error) {
      setErrorOnSave(res.error.message)
    } else {
      goToRoute(getEpisodeListingPath(podcastId))
    }

    setIsLoading(false)
  }

  const onUploadImage = (file: File): Promise<string> => {
    return new Promise((resolve) =>
      uploadEpisodeImage(podcastId, undefined, file).then((value) =>
        resolve(value.data),
      ),
    )
  }

  const onUploadAudio = (file: File): Promise<string> => {
    return new Promise((resolve) =>
      uploadEpisodeAudio(podcastId, undefined, file).then((value) =>
        resolve(value.data),
      ),
    )
  }

  if (isLoading || isLoadingEpisode) {
    return (
      <BasePage signOut={signOut} showNavigationBar isDark>
        <Styled.MessageWrapper>Carregando...</Styled.MessageWrapper>
      </BasePage>
    )
  }

  return (
    <BasePage signOut={signOut} showNavigationBar>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={!!errorEpisode}
        autoHideDuration={10000}
        onClose={() => setErrorOnSave(undefined)}
        message={`Ocorreu um erro inesperado. Tente novamente ou entre em contato com o suporte`}
      />

      <Dialog
        isDialogOpen={showReleaseDateWarningModal}
        handleCloseDialog={() => setShowReleaseDateWarningModal(false)}
        dialogText="Você tem certeza que deseja fazer essa alteração?"
        secondaryDialogText="Ao alterar a data de lançamento para uma data futura, o episódio deixará de estar disponível até que a nova data de lançamento chegue."
        dialogActionButtonText="Confirmar"
        onClickDialogActionButton={() => onUpdateEpisode(lastFormValues)}
        noActionText="Cancelar"
      />

      <Styled.PageWrapper>
        <Styled.TitleAndLinkWrapper>
          <ReturnButton route={getEpisodeListingPath(podcastId)} />
          <Styled.PageTitle>Editar Episódio</Styled.PageTitle>

          <EpisodeForm
            initialValues={getInitialValues()}
            onSubmit={(values) => onUpdateEpisode(values)}
            podcastId={podcastId}
            isEdit
            episode={episodeInformation}
            uploadImage={onUploadImage}
            uploadAudio={onUploadAudio}
          />
        </Styled.TitleAndLinkWrapper>
      </Styled.PageWrapper>
    </BasePage>
  )
}

export default EpisodeEditPage
