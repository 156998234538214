import styled from 'styled-components'

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 0.6px solid #fff;
  background-color: #000;
  padding-bottom: 80px;
  @media (max-width: 767px) {
    padding-bottom: 40px;
  }
`

export const BrandRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #000;
  padding: 5% 15% 0px;
`

export const SloganRow = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #000;
  padding: 0px 15% 5%;
`

export const UppercaseText = styled.p`
  text-transform: uppercase;
  font-size: 20px;
  line-height: 23px;
  color: #fff;
  width: 110px;
`

export const Text = styled.p`
  font-size: 12px;
  line-height: 12px;
  text-align: right;
  font-weight: 500;
  color: #fff;
  width: 175px;
  letter-spacing: 0.02em;
`

export const LogoImg = styled.img`
  width: 83px;
  heigth: auto;
`

export const BrandImg = styled.img``
