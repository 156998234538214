import React from 'react'
import * as Styled from './PodcastCard.styled'
import { useGoToRoute } from '../../Routes/RouteAux'
import { PodcastSummary } from 'types'
import { getPodcastPath } from '../../Routes/RouteNames'

interface PodcastCardProps
  extends Pick<PodcastSummary, 'podcastId' | 'image' | 'author' | 'title'> { }

const PodcastCard: React.FC<PodcastCardProps> = ({
  podcastId,
  author,
  title,
  image,
}) => {
  const goToRoute = useGoToRoute()

  return (
    <Styled.PodcastCard
      isDark
      fullWidth
      variant="outlined"
      onClick={() => {
        goToRoute(getPodcastPath(podcastId))
      }}
      key={podcastId}
    >
      <Styled.PodcastImage src={image} />
      <Styled.TitleAndAuthorWrapper>
        <Styled.PodcastTitle>{title}</Styled.PodcastTitle>
        <Styled.PodcastAuthor>{author}</Styled.PodcastAuthor>
      </Styled.TitleAndAuthorWrapper>
    </Styled.PodcastCard>
  )
}

export default PodcastCard
