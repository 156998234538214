import styled from 'styled-components'

interface StickerSeparatorProps {
  positionX: number
  maxWidth: number
}

export const StickerSeparator = styled.img<StickerSeparatorProps>`
  position: absolute;
  left: ${(props) => props.positionX}%;
  max-width: ${(props) => props.maxWidth}px;
  height: auto;
  transform: translate(0, -50%);

  @media (max-width: 1023px) {
    max-width: 15%;
  }
`
