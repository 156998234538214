import React from 'react'
import { useField } from 'formik'
import { OutlinedTextFieldProps } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import * as Styled from './DateField.styled'
import { transparentBorderStyle } from './DateField.styled'
import { DateClass } from "../../../utils/domain/DateClass";
import DatePicker from "react-datepicker";

interface TextFieldFieldProps extends OutlinedTextFieldProps {
  name: string
  label?: string
  placeholder?: string
  borderColor?: string
  classes?: any
  transparentBorder?: boolean
  fullWidth?: boolean
  style?: {
    fontSize?: number
    fontWeight?: number
    backgroundColor?: string
  }
  darkMode?: boolean
}

const DateField: React.FC<TextFieldFieldProps> = ({
  name,
  label,
  borderColor,
  classes,
  transparentBorder,
  fullWidth,
  style,
  children,
  darkMode,
  placeholder,
  ...props
}) => {
  const [_field, meta, helpers] = useField(name)

  return (
    <Styled.Wrapper fullWidth={fullWidth}>
      {label && <Styled.StyledLabel htmlFor={name} darkMode={darkMode}>{label}</Styled.StyledLabel>}
      <Styled.DatePickerFieldWrapper
        darkMode={darkMode}
        fullWidth={fullWidth}
        id={name}
      >
        <DatePicker
          selected={_field.value}
          onChange={(newDate, event) => {
            helpers.setValue(newDate)
            // const dateOrError = DateClass.fromPersistence(newDate)
            // if (dateOrError.isFailure) {
            //   helpers.setError('Data inválida')
            // } else {
            //   helpers.setValue(dateOrError.getValue().toLongDTO())
            // }
          }}
          // onChange={()}
          placeholderText={placeholder}
          showTimeInput
          dateFormat={`dd/MM/yyyy HH:mm`}
        />
        {(meta.error && meta.touched) && <p className="MuiFormHelperText-root Mui-error">{meta.error}</p>}
        {children}
      </Styled.DatePickerFieldWrapper>
    </Styled.Wrapper>
  )
}

export default withStyles(transparentBorderStyle)(DateField)
