import React, { useState } from 'react'
import { Collapse as MUICollapse } from '@material-ui/core'
import * as Styled from './Collapse.styled.'

interface CollapseProps {
  collapsedSize?: string
  color?: string
  gradientColor?: string
}

const Collapse: React.FC<CollapseProps> = ({
  collapsedSize,
  color,
  gradientColor,
  children,
}) => {
  const [expandedDescription, setExpandedDescription] = useState(false)

  return (
    <Styled.CollapsedArea
      expandedDescription={expandedDescription}
      color={color}
      gradientColor={gradientColor}
    >
      <Styled.SeeMoreInteractiveArea
        onClick={() => setExpandedDescription(!expandedDescription)}
      >
        <MUICollapse
          in={expandedDescription}
          collapsedSize={collapsedSize || '4rem'}
        >
          {children}
        </MUICollapse>
      </Styled.SeeMoreInteractiveArea>
    </Styled.CollapsedArea>
  )
}

export default Collapse
