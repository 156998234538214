import styled from 'styled-components'

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1.5px solid #212427;
  background-color: #beb3f4;
  padding-top: 90px;
  padding-bottom: 80px;

  @media (max-width: 767px) {
    padding: auto;
    justify-content: center;
  }
`
export const Title = styled.h2`
  text-transform: uppercase;
  margin-bottom: 0px;
  font-size: 44px;
  color: #212427;
  line-height: 47px;
  width: 40%;
  text-align: center;

  @media (max-width: 767px) {
    font-size: 32px;
    line-height: 35px;
    width: 85%;
  }
`

export const Description = styled.p`
  texf-family: Inter !important;
  font-size: 18px;
  line-height: 27px;
  padding-bottom: 25px;
  color: #212427;
  width: 480px;
  text-align: center;
  letter-spacing: -0.02em;

  @media (max-width: 767px) {
    font-size: 14px;
    width: auto;
    line-height: 16px;
    padding-left: 28px;
    padding-right: 28px;
  }
`

export const Image = styled.img`
  align-self: center;
`
