import React, { useState, Fragment } from 'react'
import { IconButton, Menu } from '@material-ui/core'

import * as Styled from './DropdownMenu.styled'

interface DropdownMenuItem {
  onClick: () => void
  title?: string
  children?: any
}

interface DropdownMenuProps {
  items: DropdownMenuItem[]
  color?: string
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({ items, color }) => {
  const [anchorMenu, setAnchorMenu] = useState<null | HTMLElement>(null)
  const [isPodcastMenuOpen, setIsPodcastMenuOpen] = useState<boolean>(false)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setIsPodcastMenuOpen(true)
    setAnchorMenu(event.currentTarget)
  }

  const handleClose = () => {
    setIsPodcastMenuOpen(false)
    setAnchorMenu(null)
  }

  return (
    <Fragment>
      <IconButton onClick={handleClick} size="small">
        <Styled.MenuIcon iconColor={color} />
      </IconButton>
      <Menu
        anchorEl={anchorMenu}
        open={isPodcastMenuOpen}
        onClose={handleClose}
      >
        {items.map((item) => (
          <Styled.MenuItem
            onClick={() => {
              handleClose()
              item.onClick()
            }}
          >
            {!!item.title && item.title}
            {!!item.children && item.children}
          </Styled.MenuItem>
        ))}
      </Menu>
    </Fragment>
  )
}

export default DropdownMenu
