import React, { useCallback, useState, useEffect } from 'react'
import { IconButton } from '@material-ui/core'
import { MoreHoriz, MoreVert, Pause, PlayArrow, Stop } from '@material-ui/icons'
import BasePage from 'components/BasePage/BasePage'
import RoundedCard from 'components/RoundedCard'
import Button from 'components/Button/Button'
import * as Styled from './PodcastEpisode.styled'
import { getEpisodeDetails } from 'services/api'
import usePlayer from 'hooks/usePlayer'
import { useSelector } from 'react-redux'
import { RootState } from 'reducers'
import { useGoToRoute, usePathName, useQuery } from 'Routes/RouteAux'
import { getPodcastPath } from 'Routes/RouteNames'
import OptionsButton from 'components/OptionsButton/OptionsButton'

interface PodcastEpisodeProps {
  signOut: () => Promise<void>
}

const PodcastEpisode: React.FC<PodcastEpisodeProps> = ({ signOut }) => {
  const pathName = usePathName()
  const goToRoute = useGoToRoute()
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState<any>(null)
  const [error, setError] = useState<any>(null)

  const playerController = usePlayer()
  const { isPlaying } = useSelector((state: RootState) => state.playerReducer)
  const episodeId = pathName.split('/')[2]

  const handlePlayEpisode = () => {
    if (isPlaying) {
      return playerController.pause()
    }

    playerController.playNewAudio({
      currentAudioUrl: data?.audioUrl,
      title: data?.title,
      link: data?.link,
      author: data?.author,
      podcastTitle: data?.podcast.title,
      currentTime: data.userProfileData.currentPlayerTime,
      thumbnail: data?.thumbnail,
    })
  }

  const handleGoToCreator = () => {
    const path = getPodcastPath(data?.podcast?.podcastId)
    return goToRoute(path)
  }

  const handleGetEpisodeData = useCallback(async () => {
    setLoading(true)

    try {
      const response = await getEpisodeDetails(episodeId)
      if (response?.error) {
        return setError(response?.error)
      }
      return setData(response?.data)
    } catch (e) {
      setError(e?.response || true)
    } finally {
      setLoading(false)
    }
  }, [data])

  useEffect(() => {
    setLoading(false)
    setError(false)
    handleGetEpisodeData()
  }, [])

  const Error = () => {
    return (
      <BasePage signOut={signOut} isDark>
        <Styled.PageWrapper>
          <RoundedCard flex={1}>
            <Styled.LoaderWrapper>
              Ocorreu um erro inesperado.
            </Styled.LoaderWrapper>
          </RoundedCard>
        </Styled.PageWrapper>
      </BasePage>
    )
  }

  const Loader = () => {
    return (
      <BasePage signOut={signOut} isDark>
        <Styled.PageWrapper>
          <RoundedCard flex={1}>
            <Styled.LoaderWrapper>
              <Styled.Loader />
              Carregando episodio ...
            </Styled.LoaderWrapper>
          </RoundedCard>
        </Styled.PageWrapper>
      </BasePage>
    )
  }

  const Header = () => {
    return (
      <Styled.Header>
        <Styled.Column>
          <Styled.Thumbnail>
            <Styled.EpisodeImage src={data?.thumbnail} />
          </Styled.Thumbnail>
        </Styled.Column>
        <Styled.Column flex={1} justify="center">
          <Styled.Author>
            <span>{data?.author}</span>
          </Styled.Author>
          <Styled.Title>{data?.title}</Styled.Title>
          <Styled.Row>
            <Styled.Info>
              <Styled.InfoItem>{data?.releaseDate}</Styled.InfoItem>
              <Styled.InfoItem>
                {Math.round(data?.duration / 60)} min
              </Styled.InfoItem>
            </Styled.Info>
          </Styled.Row>
        </Styled.Column>
      </Styled.Header>
    )
  }

  const Controlls = () => {
    return (
      <Styled.EpisodeControls>
        <Styled.PlayButton>
          <IconButton onClick={handlePlayEpisode}>
            {isPlaying ? <Pause /> : <PlayArrow />}
          </IconButton>
        </Styled.PlayButton>
        <Styled.ActionsButton>
          <OptionsButton
            podcastId={data?.podcast?.podcastId}
            episodeId={episodeId}
          />
        </Styled.ActionsButton>
      </Styled.EpisodeControls>
    )
  }

  const Footer = () => {
    return (
      <Styled.Footer>
        <Button
          onClick={handleGoToCreator}
          fontFamily="Fraunces"
          size="large"
          fontSize="small"
          variant="outlined"
          borderColor="white"
        >
          Ir para pagina do criador
        </Button>
      </Styled.Footer>
    )
  }

  if (error) return <Error />

  return isLoading ? (
    <Loader />
  ) : (
    <BasePage signOut={signOut} isDark>
      <Styled.PageWrapper>
        <RoundedCard flex={1}>
          <Styled.Body>
            <Header />
            <Controlls />
            <Styled.Description>
              <div>{data?.description}</div>
            </Styled.Description>
            <Footer />
          </Styled.Body>
        </RoundedCard>
      </Styled.PageWrapper>
    </BasePage>
  )
}

export default PodcastEpisode
