import React from 'react'
import BasePage from '../../components/BasePage/BasePage'
import { useGoToRoute } from '../../Routes/RouteAux'
import * as Styled from './RegisterCreator.styled'
import { getRegisterCreatorPath } from '../../Routes/RouteNames'
import { useTypedSelector } from '../../reducers'
import { ProfileType } from '../../reducers/profiles'
import ButtonShadow from 'components/ButtonShadow/ButtonShadow'

interface RegisterCreatorIntroProps {
  signOut: () => Promise<void>
}

const RegisterCreatorIntro: React.FC<RegisterCreatorIntroProps> = ({
  signOut,
}) => {
  const goToRoute = useGoToRoute()

  const currentProfile = useTypedSelector<ProfileType>(
    (state) => state && state.profiles && state.profiles.currentProfile,
  )

  return (
    <BasePage signOut={signOut}>
      <Styled.PageWrapper>
        <Styled.TextPageWrapper>
          <Styled.PageTitle>olá, {currentProfile?.name}</Styled.PageTitle>
          <Styled.PageSubTitle>
            a orelo é sobre criar comunidades em torno de creators. e a gente
            fica muito feliz por você estar começando a sua!
          </Styled.PageSubTitle>
          <Styled.PageSubTitle>
            agora, vamos precisar de algumas informações para completar o seu
            cadastro de creator. não se preocupa, é jogo rápido!
          </Styled.PageSubTitle>
          <ButtonShadow
            type='black'
            variant='secondary'
            onPress={() => goToRoute(getRegisterCreatorPath())}
            label='Beleza! Vamos lá!'
          />
        </Styled.TextPageWrapper>
      </Styled.PageWrapper>
    </BasePage>
  )
}

export default RegisterCreatorIntro
