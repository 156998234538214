import BasePage from 'components/BasePage/BasePage'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useGoToRoute } from 'Routes/RouteAux'
import { confirmSecondaryEmail, } from 'services/api'
import { getHomePath } from '../../Routes/RouteNames'
import * as Styled from '../topic/Topic.styled'
import Button from '../../components/Button/Button'
import { refreshAvailableProfiles } from "../../reducers/profiles";
import { useDispatch } from "react-redux";

type TopicProps = {
  signOut: () => Promise<void>
}

interface FormValues {
  title: string
  body?: string
}

const ConfirmSecondaryEmail: React.FC<TopicProps> = ({ signOut }) => {
  const { userId } = useParams<{ userId: string }>()
  const [isLoading, setLoading] = useState<boolean>(false)
  const [confirmationResult, setConfirmationResult] = useState(false)
  const reduxDispatch = useDispatch()

  const goToRoute = useGoToRoute()

  const sendConfirmation = async (): Promise<void> => {
    setLoading(true)
    setConfirmationResult(false)

    const res = await confirmSecondaryEmail(userId)

    if (!res.hasFailed()) {
      await reduxDispatch(refreshAvailableProfiles())
      setConfirmationResult(true);
    } else {
      setConfirmationResult(false);
    }

    setLoading(false)
  }

  useEffect(() => {
    if (userId) {
      sendConfirmation()
    }
  }, [userId])

  if (isLoading) {
    return (
      <BasePage signOut={signOut}>
        <Styled.PageWrapper>
          <Styled.MessageWrapper>Carregando...</Styled.MessageWrapper>
        </Styled.PageWrapper>
      </BasePage>
    )
  }

  return (
    <BasePage signOut={signOut} isDark>
      <Styled.PageWrapper>
        <Styled.MessageWrapper>
          {confirmationResult
            ? 'Seu e-mail secundário foi confirmado com sucesso!'
            : 'Ops, parece que ocorreu um erro ao tentar confirmar seu e-mail secundário, por favor tente novamente.'}

        </Styled.MessageWrapper>
        <Styled.ButtonWrapper>
          <Button
            variant="contained"
            buttonColorOnHover="#303030"
            buttonColor="#414141"
            onClick={() => {
              goToRoute(getHomePath())
            }}
          >
            Voltar para página inicial
          </Button>
        </Styled.ButtonWrapper>
      </Styled.PageWrapper>
    </BasePage>
  )
}

export default ConfirmSecondaryEmail
