import React from 'react'
import BasePage from '../../components/BasePage/BasePage'
import {
  BodyText,
  BodyTextWithMargin,
  ButtonsWrapper,
  PageWrapper,
  StyledLink,
} from './Error.styled'
import { useGoToRoute, useQuery } from '../../Routes/RouteAux'
import { getHomePath } from '../../Routes/RouteNames'
import ButtonShadow from 'components/ButtonShadow/ButtonShadow'

interface ErrorProps {
  signOut: () => Promise<void>
}

const ErrorPage: React.FC<ErrorProps> = ({ signOut }) => {
  const query = useQuery()
  const goToRoute = useGoToRoute()

  const redirectTo = query.get('redirectTo')

  return (
    <BasePage signOut={signOut}>
      <PageWrapper>
        <div>
          <BodyText>Ops...</BodyText>
          <BodyTextWithMargin>Ocorreu um erro inesperado.</BodyTextWithMargin>
          <BodyText>Clique abaixo para entrar em contato com o</BodyText>
          <BodyTextWithMargin>
            nosso time ou voltar para a página anterior
          </BodyTextWithMargin>
          <ButtonsWrapper>
            <StyledLink
              href={'https://orelohelp.zendesk.com/hc/pt-br'}
              target="_blank"
            >
              <ButtonShadow
                label='Ajuda'
              />
            </StyledLink>
            <div>
              <ButtonShadow
                onPress={() =>
                  redirectTo ? goToRoute(redirectTo) : goToRoute(getHomePath())
                }
                variant="secondary"
                label='Voltar'
              />
            </div>
          </ButtonsWrapper>
        </div>
      </PageWrapper>
    </BasePage>
  )
}

export default ErrorPage
