import React, {useState, Fragment} from 'react'

import {
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  Snackbar,
} from '@material-ui/core'

import Button from '../Button/Button'

import * as Styled from './ReportContentDialog.styled'
import {reportContent} from '../../services/api'

export interface DialogProps {
  isDialogOpen: boolean
  handleCloseDialog: () => void
  contentType: 'podcast' | 'article' | 'topic' | 'comment'
  contentId: string
}

const ReportContentDialog: React.FC<DialogProps> = ({
                                                      isDialogOpen,
                                                      handleCloseDialog,
                                                      contentType,
                                                      contentId,
                                                    }) => {
  const [reportDescription, setReportDescription] = useState<string>('')
  const [emptyDescriptionError, setEmptyDescriptionError] =
    useState<boolean>(false)
  const [reportSuccessful, setReportSuccessful] = useState<boolean>(false)
  const [alreadyReported, setAlreadyReported] = useState<boolean>(false)
  const [reportError, setReportError] = useState<boolean>(false)

  const onSubmitReport = async (): Promise<void> => {
    setAlreadyReported(false)
    setReportError(false)
    setEmptyDescriptionError(false)

    if (reportDescription.length === 0) {
      setEmptyDescriptionError(true)
      return
    }

    const req = await reportContent({
      contentId,
      contentType,
      description: reportDescription,
    })

    if (req.hasFailed()) {
      if (req.error.status === 409) {
        setAlreadyReported(true)
      } else {
        setReportError(true)
      }
    } else {
      setReportSuccessful(true)
      handleCloseDialog()
    }
  }

  let contentDescription: string;
  if (contentType === 'podcast') {
    contentDescription = 'creator';
  } else if (contentType === 'article') {
    contentDescription = '.txt';
  } else if (contentType === 'topic') {
    contentDescription = 'tópico';
  } else if (contentType === 'comment') {
    contentDescription = 'comentário';
  }

  return (
    <Fragment>
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        open={reportSuccessful}
        autoHideDuration={10000}
        onClose={() => {
          setReportSuccessful(false)
        }}
        message={`Sua denuncia foi registrada com sucesso! Em breve entraremos em contato.`}
      />
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        open={alreadyReported}
        autoHideDuration={10000}
        onClose={() => {
          setAlreadyReported(false)
        }}
        message={`Você já denunciou este ${
          contentDescription
        }, em breve entraremos em contato.`}
      />
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        open={reportError}
        autoHideDuration={10000}
        onClose={() => {
          setReportError(false)
        }}
        message={`Ops, parece que houve um erro! Tente novamente mais tarde.`}
      />
      <MuiDialog open={isDialogOpen} onClose={handleCloseDialog}>
        <Styled.DialogTextWrapper>
          <Styled.DialogText disableTypography>
            nos ajude a entender o problema!
          </Styled.DialogText>
          <Styled.DialogText disableTypography>
            por favor, descreva por que você deseja denunciar esse{' '}
            {contentDescription}
          </Styled.DialogText>
        </Styled.DialogTextWrapper>
        <DialogContent>
          <Styled.TextField
            variant="outlined"
            onChange={(event) => {
              const {target} = event
              setReportDescription(target.value)
            }}
            label="Descrição"
            error={emptyDescriptionError}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} fontColor="black">
            Cancelar
          </Button>
          <Button
            onClick={onSubmitReport}
            fontColor="white"
            buttonColor="#cc0033"
            buttonColorOnHover="#940a00"
          >
            Denunciar
          </Button>
        </DialogActions>
      </MuiDialog>
    </Fragment>
  )
}

export default ReportContentDialog
