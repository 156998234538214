function dimensions(
  top: number,
  right: number = top,
  bottom: number = top,
  left: number = right,
  property: string,
): object {
  return {
    [`${property}Top`]: top,
    [`${property}Right`]: right,
    [`${property}Bottom`]: bottom,
    [`${property}Left`]: left,
  }
}

export function margin(
  top: number,
  right: number,
  bottom: number,
  left: number,
): object {
  return dimensions(top, right, bottom, left, 'margin')
}

export function padding(
  top: number,
  right: number,
  bottom: number,
  left: number,
): object {
  return dimensions(top, right, bottom, left, 'padding')
}

export function boxShadow(
  color: string,
  offset = { height: 4, width: 4 },
  radius = 8,
  opacity = 0.2,
): object {
  return {
    shadowColor: color,
    shadowOffset: offset,
    shadowOpacity: opacity,
    shadowRadius: radius,
    elevation: radius,
  }
}
