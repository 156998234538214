import styled from 'styled-components'
import { InputLabel } from '@material-ui/core'

export const Wrapper = styled.div`
  display: flex;
  margin: 12px 0;
  align-items: center;
`

export const StyledLabel = styled(InputLabel)`
  margin-right: 12px;

  &.MuiFormLabel-root {
    font-family: 'Gellix';
    font-weight: bold;
    font-size: 22px;
    color: black;
  }
`

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`
