import styled from 'styled-components'
import Button from 'components/Button/Button'
import { Colors } from 'styles'

export const PageWrapper = styled.div<{ backGroundColor?: string }>`
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  align-items: center;
  color: ${Colors.GREY[400]};
  ${(props) =>
    props.backGroundColor &&
    `background-image: linear-gradient(transparent, ${props.backGroundColor});`}
`

export const MessageWrapper = styled.div`
  margin-top: 50px;
  margin-bottom: 20px;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`

export const ButtonWrapper = styled.div`
  margin-left: 5px;
  margin-right: 5px;

  @media (max-width: 600px) {
    margin-top: 5px;
    margin-bottom: 5px;
  }
`

export const PodcastMenuWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

export const ImageAndTitleWrapper = styled.div`
  margin-top: 12px;
  margin-bottom: 20px;
  justify-content: center;

  @media (max-width: 600px) {
    margin-top: 40px;
    margin-bottom: 0px;
  }
`

export const DescriptionAndTiersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`

export const DescriptionAndButtons = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  @media (max-width: 600px) {
    width: 100%;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 40px;

  @media (max-width: 600px) {
    margin-top: 10px;
    flex-direction: column;
    align-items: center;
  }
`

export const Tiers = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 300px;
  margin-left: 5%;
  margin-top: 22px;
  @media (max-width: 900px) {
    justify-content: center;
    align-items: center;
    margin-left: 0;
    flex-wrap: wrap;
    > div {
      width: 100%;
    }
  }
`

export const Whitespace = styled.div`
  margin-bottom: 15px;
`

export const DescriptionParagraph = styled.p`
  width: 95%;
  font-size: 21px;
  margin-bottom: 0;

  @media (max-width: 600px) {
    font-size: 20px;
  }
`

export const ListItem = styled.li`
  width: 95%;
  font-size: 25px;
  text-align: justify;
  text-justify: inter-word;

  @media (max-width: 600px) {
    font-size: 20px;
    text-align: start;
  }
`

export const FixedSizeButton = styled(Button)`
  width: 180px;
  height: 30px;
  white-space: nowrap;
`
