import React from 'react'
import { useField } from 'formik'
import { Checkbox, CheckboxProps, FormHelperText } from '@material-ui/core'
import * as Styled from './CheckboxField.styled'

interface CheckboxFieldProps extends CheckboxProps {
  name: string
  label?: string | React.ReactElement
  helperText?: string
  error?: boolean
}

const CheckboxField: React.FC<CheckboxFieldProps> = ({
  name,
  label,
  helperText,
  error,
  children,
  ...rest
}) => {
  const [_field, meta, helpers] = useField(name)

  return (
    <Styled.Wrapper>
      <Styled.FlexWrapper>
        <Checkbox
          defaultValue={meta.initialValue}
          onChange={(event) => {
            const { target } = event
            helpers.setValue(target.checked)
          }}
          id={name}
          {...rest}
        />
        {label && (
          <Styled.StyledLabel htmlFor={name}>{label}</Styled.StyledLabel>
        )}
      </Styled.FlexWrapper>
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </Styled.Wrapper>
  )
}

export default CheckboxField
