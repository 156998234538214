import React from 'react'
import { Portal } from '@material-ui/core'
import AudioPlayer, { AudioPlayerProps } from 'components/AudioPlayer'
import { useSelector } from 'react-redux'
import { RootState } from 'reducers'

interface BottomPlayerProps extends AudioPlayerProps {}

const BottomPlayer: React.FC = () => {
  const player = useSelector(
    (state: RootState) => state.playerReducer,
  )

  return player.isShowing ? (
    <Portal>
      <AudioPlayer />
    </Portal>
  ) : null
}

export default BottomPlayer
