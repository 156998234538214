import React from 'react'
import { Route, RouteProps } from 'react-router-dom'
import Login from '../pages/login/Login'

export interface AuthRouteProps extends RouteProps {
  isAuth: boolean
}

const RouteAuth: React.FC<AuthRouteProps> = ({ children, isAuth, ...rest }) => {
  return <Route {...rest}>{isAuth ? children : <Login />}</Route>
}

export default RouteAuth
