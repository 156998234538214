import styled from 'styled-components'
import Button from '../../components/Button/Button'

export const MessageWrapper = styled.div`
  margin-top: 100px;
  margin-bottom: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
`

export const Text = styled.div`
  text-align: center;
  margin: 12px;
  font-size: 1rem;
`

export const DeleteAccountButton = styled(Button)`
  width: 400px;
  place-self: center;
`

export const Title = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 1.6rem;
  margin: 24px;
`
