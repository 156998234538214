import React, { useContext } from 'react'
import { Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faApple, faGoogle } from '@fortawesome/free-brands-svg-icons'
import { AuthContext } from '../../authentication/context'
import { useTypedSelector } from '../../reducers'
import { useQuery } from '../../Routes/RouteAux'
import BasePage from '../../components/BasePage/BasePage'
import OreloLogo from '../../assets/images/logo_light.png'
import * as Styled from './Login.styled'
import { Colors } from '../../styles'
import GoogleLogo from '../../assets/images/google-logo.png'
import { getHomePath } from '../../Routes/RouteNames'

interface LoginProps { }

const Login: React.FC<LoginProps> = () => {
  const { facebookSignIn, googleSignIn, appleSignIn } = useContext(
    AuthContext,
  ) as {
    facebookSignIn: () => Promise<void>
    googleSignIn: () => Promise<void>
    appleSignIn: () => Promise<void>
  }

  const currentProfile = useTypedSelector(
    (state) => state && state.profiles && state.profiles.currentProfile,
  )

  const query = useQuery()
  const redirectTo = query.get('redirectTo')

  if (currentProfile) {
    if (redirectTo) {
      return <Redirect to={redirectTo} />
    }
    return <Redirect to={getHomePath()} />
  }

  // If you're on the login page, you don't need to sign out
  const emptyFunction = async (): Promise<void> => { }

  return (
    <BasePage signOut={emptyFunction} >
      <Styled.PageWrapper>
        <Styled.LogoWrapper>
          <Styled.Logo src={OreloLogo} />
        </Styled.LogoWrapper>
        <Styled.MainText>que bom ter você por aqui!</Styled.MainText>
        <Styled.Text>
          escolha abaixo como prefere fazer o seu login para construir e fazer
          parte de comunidades com a gente.
        </Styled.Text>
        <Styled.SocialButton
          onPress={facebookSignIn}
          variant="secondary"
          type='black'
          label='entrar com facebook'
          leftIcon={<FontAwesomeIcon
            icon={faFacebookF}
            color={Colors.GREY[400]}
            name="facebook"
            style={{ marginBottom: '5px' }}

          />}
        />
        <Styled.SocialButton
          onPress={googleSignIn}
          variant="secondary"
          type='black'
          label='entrar com google'
          leftIcon={<FontAwesomeIcon
            icon={faGoogle}
            color={Colors.GREY[400]}
            name="facebook"
            style={{ marginBottom: '3px' }}

          />}
        />
        <Styled.SocialButton
          onPress={appleSignIn}
          variant="secondary"
          type='black'
          label='entrar com apple'
          leftIcon={<FontAwesomeIcon
            icon={faApple}
            color={Colors.GREY[400]}
            name="appStore"
            style={{ marginBottom: '5px' }}
          />}
        />
      </Styled.PageWrapper>
    </BasePage >
  )
}

export default Login
