import styled from 'styled-components'

export const SectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1.5px solid #212427;
  padding: 5% 10%;
  background-color: #f7c644;
`

export const SectionShowcase = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 35px;
  background-color: #b3c9de;
  box-shadow: #000 5px 5px 0 0;
  border: 1.5px solid #000000;
  border-radius: 5px;
  width: 100%;
`

export const Title = styled.h2`
  text-transform: uppercase;
  text-shadow: -1px 0 #212427, 0 1px #212427, 1px 0 black, 0 -1px #212427;
  color: white;
  font-size: 56px;
  font-weight: 800;
  font-size: 56px;
  line-height: 53px;
  letter-spacing: -0.02em;
  margin: 0;
  flex: 1;

  @media (max-width: 767px) {
    font-size: 44px;
  }
`
export const Description = styled.p`
  text-transform: uppercase;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #212427;
  flex: 1;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 16px;
    text-align: center;
  }
`

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1023px) {
    flex-direction: column;
  }
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  border: 1px solid #212427;
  background: white;
  cursor: pointer;

  @media (min-width: 768px) {
    :last-child {
      margin-right: 0px;
    }
  }

  @media (max-width: 1023px) {
    margin: 0;
    align-self: center;
  }

  @media (max-width: 425px) {
    width: 50vw;
  }
`

export const CardImg = styled.img`
  height: 15vw;
  border-radius: 5px 5px 0px 0px;
  border-bottom: 1px solid #212427;
  object-fit: cover;

  @media (max-width: 1023px) {
    height: 20vw;
    width: 100%;
  }

  @media (max-width: 425px) {
    height: 50vw;
  }
`

export const CardTitle = styled.div`
  overflow: hidden;
  height: 5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 70%;
  text-align: center;
  font-weight: bold;
  padding: auto 0px;

  @media (max-width: 1023px) {
    height: 5vw;
    padding: 10px 0px;
    font-size: 11px;
  }
`

export const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 1023px) {
    gap: 10px;
    justify-content: center;
  }
`
export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;
  margin-right: 3px;
`
