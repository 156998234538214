import { useLocation, useHistory } from 'react-router-dom'

export const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

export const usePathName = () => {
  const { pathname } = useLocation()
  return pathname
}

export const createPathWithQueryParam = (
  baseRoute: string,
  queryParams: { [key: string]: string },
): string => {
  const keys = Object.keys(queryParams)

  let routeToReturn = baseRoute

  keys.forEach((key) => {
    routeToReturn = `${routeToReturn}?${key}=${queryParams[key]}`
  })

  return routeToReturn
}

export const useGoToRoute = (): ((routeToGo: string) => void) => {
  const history = useHistory()

  return (routeToGo: string) => history.push(routeToGo)
}
