import styled from 'styled-components'
import { Card as MUICard, CardProps } from '@material-ui/core'

interface RoundedStyledI extends CardProps {
  flex?: number
  isLight?: boolean
}
export const RoundedStyled = styled(MUICard).attrs(
  (props: RoundedStyledI) => props,
)`
  background-color: black;
  &.MuiPaper-root {
    margin-left: 15px;
    margin-right: 15px;
    max-width: 1100px;
    width: 90vw;
    background-color: ${(props) => props.isLight ? 'white' : 'black'};
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2em;
    padding: 28px 90px;
    margin-bottom: 0;
    color: ${(props) => props.isLight ? 'black' : 'white'};
    flex: ${(props) => props.flex};
    @media (max-width: 600px) {
      width: calc(100% - 48px);
      padding: 24px;
      margin-left: 0;
      margin-right: 0
    }
  }
  &.MuiPaper-rounded {
    border-radius: 105px;
    @media (max-width: 600px) {
      border-radius: 55px;
    }
  }
`
