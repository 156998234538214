import React from 'react'
import { useField } from 'formik'
import {
  Box,
  Chip,
  FormControl,
  FormHelperText,
  SelectProps,
} from '@material-ui/core'
import * as Styled from './SelectField.styled'

interface SelectFieldProps extends SelectProps {
  name: string
  label?: string | React.ReactElement
  helperText?: string
  error?: boolean
  fullWidth?: boolean
  darkMode?: boolean
  useFormControl?: boolean
}

const SelectField: React.FC<SelectFieldProps> = ({
  name,
  label,
  helperText,
  error,
  children,
  fullWidth,
  darkMode,
  useFormControl,
  ...rest
}) => {
  const [_field, meta, helpers] = useField(name)

  if (useFormControl) {
    return (
      <Styled.Wrapper>
        <FormControl variant="filled" fullWidth>
          {label && (
            <Styled.StyledLabel
              htmlFor={name}
              darkMode={darkMode}
              id={`${name}-label`}
            >
              {label}
            </Styled.StyledLabel>
          )}
          <Styled.NewStyledSelect
            labelId={`${name}-label`}
            value={meta.value}
            onChange={(event) => {
              const { target } = event
              helpers.setValue(target.value)
            }}
            id={name}
            label={label}
            error={error}
            darkMode={darkMode}
            renderValue={(selected) => {
              return (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {_field.value.map((value) => (
                    <Styled.StyledChip key={value} label={value} />
                  ))}
                </Box>
              )
            }}
            {...rest}
          >
            {children}
          </Styled.NewStyledSelect>
          {helperText && (
            <FormHelperText error={error}>{helperText}</FormHelperText>
          )}
        </FormControl>
      </Styled.Wrapper>
    )
  }

  return (
    <Styled.Wrapper fullWidth={fullWidth}>
      {label && (
        <Styled.StyledLabel
          htmlFor={name}
          fullWidth={fullWidth}
          darkMode={darkMode}
        >
          {label}
        </Styled.StyledLabel>
      )}
      <div style={{ width: fullWidth ? '100%' : 'initial' }}>
        <Styled.StyledSelect
          defaultValue={meta.initialValue}
          onChange={(event) => {
            const { target } = event
            helpers.setValue(target.value)
          }}
          id={name}
          label={label}
          error={error}
          fullWidth={fullWidth}
          darkMode={darkMode}
          {...rest}
        >
          {children}
        </Styled.StyledSelect>
        {helperText && (
          <FormHelperText error={error}>{helperText}</FormHelperText>
        )}
      </div>
    </Styled.Wrapper>
  )
}

export default SelectField
