import styled from 'styled-components'

interface SectionWrapperProps {
  bgColor: string
}

interface TextProps {
  fontColor: string
}

export const SectionWrapper = styled.div<SectionWrapperProps>`
  display: flex;
  justify-content: center;
  border-bottom: 1.5px solid #212427;
  padding: 90px 180px 60px 230px;
  background-color: ${({ bgColor }) => bgColor};

  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0px 30px 0px;
  }
`

export const TextWrapper = styled.div`
  width: 450px;
  align-self: center;

  @media (max-width: 1023px) {
    width: 90%;
    justify-content: center;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 30px;
    padding-left: 28px;
    padding-right: 28px;
  }
`

export const Title = styled.h2<TextProps>`
  text-transform: uppercase;
  margin-bottom: 0px;
  font-size: 44px;
  color: ${({ fontColor }) => fontColor};
  line-height: 47px;
  width: 390px;

  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 35px;
    width: 85%;
  }
`

export const Description = styled.p<TextProps>`
  font-size: 16px;
  line-height: 20px;
  font-weigth: 500;
  letter-spacing: -0.02em;
  color: ${({ fontColor }) => fontColor};
  padding-bottom: 15px;

  @media (max-width: 600px) {
    font-size: 14px;
  }
`

export const ImageWrapper = styled.div`
  position: relative;
  margin-right: 60px;

  @media (max-width: 1023px) {
    align-self: center;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
  }
`
export const PrimarySticker = styled.img`
  position: absolute;
  width: 130px;
  height: auto;
  left: 0px;

  @media (max-width: 1023px) {
    width: 25%;
    margin: 0 auto;
    top: 0px;
    left: 7%;
  }
`
export const SecondarySticker = styled(PrimarySticker)`
  position: absolute;
  width: 130px;
  height: auto;
  left: 0;
  transform: translate(-40%, 75%);
  z-index: 1;

  @media (max-width: 1023px) {
    width: 25%;
    margin: 0 auto;
    transform: translate(0%, 75%);
  }
`
export const MainImg = styled.img`
  @media (max-width: 1023px) {
    width: 85%;
    margin: 0 auto;
  }
`
