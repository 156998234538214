import { Colors, Typography } from 'styles'
import styled from 'styled-components'
import Divider from '@material-ui/core/Divider'

export const WhiteBackground = styled.div`
  background-color: #ffffff;
`

export const ColoredBackground = styled.div`
  @media (max-width: 600px) {
    border-radius: 0;
  }
`

export const PageWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 80px auto;
  color: ${Colors.GREY[400]};
  width: 50%;
  @media (max-width: 600px) {
    width: 90%;
  }
`

export const ErrorWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const MessageWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  text-align: center;
`

export const PodcastInfoAndFollowButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`

export const TitleAndImageWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const PodcastProfileImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-right: 14px;
`

export const PodcastTitle = styled.div`
  font-size: 0.8rem;
  font-weight: 800;
  font-family: Inter;
`

export const ArticleWrapper = styled.div`
  margin-top: 24px;
  width: 100%;
`

export const ArticleTitle = styled.div`
  font-size: 2.5em;
  margin-bottom: 16px;
  font-family: Fraunces;
  font-weight: 700;
  line-height: 1em;
  font-family: PP-Neue-Machina;

  @media (max-width: 600px) {
    margin-bottom: 8px;
  }
`

export const ArticleDescription = styled.div`
  font-style: italic;
  font-size: 1.1em;
  margin-bottom: 12px;
  font-weight: 400;
  font-family: Inter;
`

export const InfoAndArticleMenuWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
`

export const AdditionalInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 600px) {
    margin-bottom: 8px;
  }
`

export const AdditionalInfo = styled.div`
  font-size: 0.8em;
  margin-right: 10px;
  font-family: Inter;
  letter-spacing: -${Typography.LETTER_SPACING_1}px;
`

export const FullWidthDivider = styled(Divider)`
  width: 100%;

  &.MuiDivider-root {
    background-color: rgba(255, 255, 255, 0.5);
    margin-top: 48px;

    @media (max-width: 600px) {
      margin-top: 24px;
    }
  }
`

export const CreatorResume = styled.div`
  display: flex;
  padding: 24px;
  justify-content: space-between;
  background-color: ${Colors.GREY[400]};
  color: ${Colors.WHITE};
  border-radius: 16px;
`

export const CreatorResumeImage = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 40px;
  margin: 0 7%;
  cursor: pointer;

  @media (max-width: 600px) {
    margin: 0 20px;
  }
`

export const CreatorResumeContent = styled.div`
  width: 80%;
  margin-bottom: 20px;
`

export const CreatorResumeImageWithFollowButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

export const CreatorResumeTitleWithFollowButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

export const FollowButtonWrapper = styled.div`
  margin-top: 20px;
`

export const CreatorResumeTitle = styled.div`
  font-family: PP-Neue-Machina;
  font-weight: 900;
  font-size: 1.5em;
  cursor: pointer;
`

export const CreatorResumeDescription = styled.div`
  margin-top: 16px;
  font-family: Inter;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 1.2em;
`
