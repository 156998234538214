import styled from 'styled-components'
import { CircularProgress, InputLabel } from '@material-ui/core'
import { Form } from 'formik'
import Info from '@material-ui/icons/Info'
import Button from '../../components/Button/Button'

export const StyledLabel = styled(InputLabel)`
  margin-right: 12px;

  &.MuiFormLabel-root {
    font-family: 'Gellix';
    font-weight: bold;
    font-size: 22px;
    color: black;
  }
`

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const FlexForm = styled(Form)`
  display: flex;
  width: 80%;
  flex-direction: column;
  align-items: flex-start;
  align-self: center;

  @media (max-width: 600px) {
    width: 95%;
  }
`

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  padding-top: 32px;
  color: #efefef;
  .MuiInputBase-root {
    background-color: #1f1f1f;
    color: #fff;
    input, textarea, select, checkbox, radio, label {
      color: white;
    }
  }
  .MuiInputLabel-root {
    color: white;
  }
  .MuiSwitch-track {
    background-color: #666;
  }
`

export const PageSubTitle = styled.div`
  font-size: 32px;
  font-weight: bold;
  width: 100%;
  margin: 24px 0;
  text-align: center;

  @media (max-width: 600px) {
    text-align: center;
  }
`

export const TitleWrapper = styled.div`
  width: 40%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media (max-width: 600px) {
    align-items: center;
    width: 95%;
  }
`

export const LargeButton = styled(Button)`
  width: 250px;
  height: 32px;
  align-self: center;
  &.MuiButtonBase-root {
    margin: 24px 0;
  }
`

export const AddressWrapper = styled.div`
  padding-left: 24px;
  width: calc(100% - 24px);
`

export const PodcastImage = styled.img`
  width: 160px;
  height: 160px;
  border-radius: 20px;
  margin-bottom: 18px;
`

export const ImageInput = styled.input`
  display: none;
`

export const LoadingImageUpload = styled(CircularProgress)`
  margin-left: 12px;
`

export const TooltipText = styled.div`
  font-size: 1.7em;
  font-family: 'Gellix';
`

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
`

export const InfoIconWithMargin = styled(Info)`
  margin-left: 12px;
  margin-top: 12px;
`
