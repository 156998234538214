import React from 'react'
import { useField } from 'formik'
import { FormHelperText } from '@material-ui/core'
import { RichTextEditorProps } from '../../RichTextEditor'
import * as Styled from './RichtextEditorField.styled'

interface RichTextEditorFieldProps extends RichTextEditorProps {
  name: string
  label?: string
  error?: boolean
  helperText?: string
  borderColor?: string
  disabled?: boolean
  readOnly?: boolean
  darkMode?: boolean
  noBorder?: boolean
}

const RichTextEditorField: React.FC<RichTextEditorFieldProps> = ({
  name,
  label,
  error,
  helperText,
  borderColor,
  disabled,
  readOnly,
  darkMode = true,
  noBorder,
  ...props
}) => {
  const [_field, meta, helpers] = useField(name)

  return (
    <Styled.Wrapper>
      {label && <Styled.StyledLabel htmlFor={name}>{label}</Styled.StyledLabel>}
      <Styled.Border error={error} borderColor={borderColor} noBorder={noBorder}>
        <Styled.RichTextEditorWidth
          disabled={disabled}
          readonly={readOnly}
          initialValue={meta.initialValue}
          onChange={(value) => helpers.setValue(value)}
          id={name}
          handleBlur={() => helpers.setTouched(true)}
          darkMode={darkMode}
          {...props}
        />
      </Styled.Border>
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </Styled.Wrapper>
  )
}

export default RichTextEditorField
