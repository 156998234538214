import React, { useMemo, useState } from 'react'
import * as Styled from './NavigationBar.styled'
import {
  getCreatorDashBoardPath,
  getEditSupportPath,
  getEpisodeListingPath,
  getCreatorProfilePath,
  getListArticlesPath,
  getMetricsPath,
} from '../../Routes/RouteNames'
import { useTypedSelector } from '../../reducers'
import { ProfileType } from '../../reducers/profiles'
import { useLocation } from 'react-router-dom'
import StyledNavLink from 'components/StyledNavLink'

const NavigationBar: React.FC = () => {

  const location = useLocation()

  const currentProfile = useTypedSelector<ProfileType>(
    (state) => state && state.profiles && state.profiles.currentProfile,
  )

  const currentTab = useMemo(() => {
    const tabs = ['dashboard', 'support', 'articles', 'episodes', 'metricas', 'settings']
    for (let i = 0; i < tabs.length; i++) {
      if (location.pathname.includes(tabs[i])) {
        return tabs[i]
      }
      if (location.pathname.includes('playlists')) {
        return 'episodes'
      }
    }
  }, [location.pathname])

  return (
    <Styled.NavigationBarWrapper>
      <Styled.MenuWrapper>
        <div style={{ overflowY: 'scroll', whiteSpace: 'nowrap' }}>
          <StyledNavLink to={getCreatorDashBoardPath(currentProfile.id)}>
            <Styled.StyledTab label="painel" value={'dashboard'} selected={currentTab === 'dashboard'} />
          </StyledNavLink>
          <StyledNavLink to={getListArticlesPath(currentProfile.id)}>
            <Styled.StyledTab label="Textos e newsletters" value={'articles'} selected={currentTab === 'articles'} />
          </StyledNavLink>
          <StyledNavLink to={getEpisodeListingPath(currentProfile.id)}>
            <Styled.StyledTab label="Episódios" value={'episodes'} selected={currentTab === 'episodes'} />
          </StyledNavLink>
          {currentProfile.podcastRole === 'manager' &&
            <StyledNavLink to={getEditSupportPath(currentProfile.id)}>
              <Styled.StyledTab label="Campanhas e apoios" value={'support'} selected={currentTab === 'support'} />
            </StyledNavLink>}
          {currentProfile.podcastRole === 'manager' &&
            <StyledNavLink to={getMetricsPath(currentProfile.id)}>
              <Styled.StyledTab label="Métricas" value={'metricas'} selected={currentTab === 'metricas'} />
            </StyledNavLink>}
          {currentProfile.podcastRole === 'manager' &&
            <StyledNavLink to={getCreatorProfilePath(currentProfile.id)}>
              <Styled.StyledTab label="Configurações" value={'settings'} selected={currentTab === 'settings'} />
            </StyledNavLink>}
        </div>
      </Styled.MenuWrapper>
    </Styled.NavigationBarWrapper >
  )
}

export default NavigationBar
