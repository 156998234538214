import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import BasePage from '../../components/BasePage/BasePage'
import * as Styled from './ArticleListingPage.styled'
import useFetch from '../../hooks/useFetch'
import { ArticleDTO, FullSupportInformation } from '../../types'
import {
  archiveArticle,
  getPodcastArticlesList,
  getSupportInformationByPodcastId,
} from '../../services/api'
import Button from '../../components/Button/Button'
import {
  MessageWrapper,
  PageWrapper,
} from '../podcastSupport/PodcastSupport.styled'
import { CircularProgress, Snackbar, Tooltip } from '@material-ui/core'
import { useGoToRoute } from '../../Routes/RouteAux'
import handleCopyToClipboard from '../../services/copyToClipboard'
import SearchBar from '../../components/SearchBar/SearchBar'
import {
  getArticlePath,
  getCreateArticlePath,
  getEditArticlePath,
} from '../../Routes/RouteNames'
import Dialog from '../../components/Dialog/Dialog'
import ButtonShadow from 'components/ButtonShadow/ButtonShadow'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Colors } from 'styles'
import isMobile from '../../utils/isMobile'
import { IconWrapper } from './ArticleListingPage.styled'

interface EditPodcastSupportPageProps {
  signOut: () => Promise<void>
}

const ArticleListingPage: React.FC<EditPodcastSupportPageProps> = ({
  signOut,
}) => {
  const { podcastId } = useParams<{ podcastId: string }>()

  const [isLoadingArticles, setIsLoadingArticles] = useState(false)
  const [errorArticles, setErrorArticles] = useState(false)
  const [articlesPage, setArticlesPage] = useState(0)
  const [hasFetchedAllArticles, setHasFetchedAllArticles] = useState(false)
  const [isLoadingMoreArticles, setIsLoadingMoreArticles] = useState(false)
  const [isShowingAllArticles, setIsShowingAllArticles] = useState(false)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [articles, setArticles] = useState<ArticleDTO[]>([])
  const [errorOnDelete, setErrorOnDelete] = useState<string>()
  const [articleToDelete, setArticleToDelete] = useState<ArticleDTO>()

  const goToRoute = useGoToRoute()

  const { isLoading, data, error, fetchData } =
    useFetch<FullSupportInformation>(
      useCallback(() => {
        return getSupportInformationByPodcastId(podcastId)
      }, [podcastId]),
    )

  const initialFetchArticles = async () => {
    setIsLoadingArticles(true)
    const res = await getPodcastArticlesList(
      podcastId,
      true,
      articlesPage,
      undefined,
      'desc',
    )

    if (res.hasFailed()) {
      setErrorArticles(true)
    } else {
      if (res.data?.length < 100) {
        setHasFetchedAllArticles(true)
      } else {
        setHasFetchedAllArticles(false)
      }

      setArticles(res.data)
    }

    setIsLoadingArticles(false)
  }

  const fetchArticlesWithPage = async () => {
    const res = await getPodcastArticlesList(
      podcastId,
      true,
      articlesPage,
      undefined,
      'desc',
    )

    if (!res.hasFailed()) {
      const currentArticles = articles.slice()

      if (res.data?.length < 100) {
        setHasFetchedAllArticles(true)
      } else {
        setHasFetchedAllArticles(false)
      }

      currentArticles.push(...res.data)

      setArticles(currentArticles)
    } else {
      alert(
        'Ops! Não conseguimos carregar mais txts. Por favor, tente novamente.',
      )
    }

    setIsLoadingMoreArticles(false)
  }

  const fetchArticlesBySearchTerm = async () => {
    const res = await getPodcastArticlesList(
      podcastId,
      true,
      undefined,
      searchTerm,
      'desc',
    )

    if (!res.hasFailed()) {
      if (res.data?.length < 100) {
        setHasFetchedAllArticles(true)
      } else {
        setHasFetchedAllArticles(false)
      }

      setArticles(res.data)
    } else {
      alert(
        'Ops! Não conseguimos carregar mais txts. Por favor, tente novamente.',
      )
    }

    setIsLoadingMoreArticles(false)
  }

  useEffect(() => {
    initialFetchArticles()
  }, [])

  useEffect(() => {
    if (articlesPage !== null && articlesPage !== 0) {
      fetchArticlesWithPage()
    }
  }, [articlesPage])

  useEffect(() => {
    if (searchTerm) {
      fetchArticlesBySearchTerm()
    } else {
      initialFetchArticles()
    }
  }, [searchTerm])

  if (isLoading || isLoadingArticles) {
    return (
      <BasePage signOut={signOut} showNavigationBar>
        <Styled.PageWrapper>
          <Styled.MessageWrapper>Carregando...</Styled.MessageWrapper>
        </Styled.PageWrapper>
      </BasePage>
    )
  }

  if (error || errorArticles) {
    return (
      <BasePage signOut={signOut} isDark showNavigationBar>
        <PageWrapper>
          <MessageWrapper>Ops, parece que tivemos um erro aqui.</MessageWrapper>
          <Button variant="contained" fontColor="black" onClick={fetchData}>
            Tentar novamente
          </Button>
        </PageWrapper>
      </BasePage>
    )
  }

  const onAddNewArticle = () => {
    goToRoute(getCreateArticlePath(podcastId))
  }

  const onEditArticle = (article: ArticleDTO) => {
    goToRoute(getEditArticlePath(podcastId, article.id))
  }

  const onCopyArticleUrl = async (article: ArticleDTO) => {
    const link = `${window.location.origin}${getArticlePath(article.id)}`
    await handleCopyToClipboard(link)
    alert(`Link copiado! (${link})`)
  }

  const renderSeeMoreButtonOrLoading = () => {
    if (!isShowingAllArticles) {
      return (
        <ButtonShadow
          variant="secondary"
          onPress={() => {
            setIsShowingAllArticles(true)
          }}
          label="Ver mais"
        />
      )
    }

    if (hasFetchedAllArticles) return

    if (isLoadingMoreArticles) {
      return <CircularProgress />
    } else {
      return (
        <Button
          buttonColor="white"
          buttonColorOnHover="#919191"
          fontColor="black"
          borderColor="black"
          style={{ marginLeft: 'auto' }}
          onClick={() => {
            setIsLoadingMoreArticles(true)

            setArticlesPage(articlesPage + 1)
          }}
        >
          Ver mais...
        </Button>
      )
    }
  }

  const onArchiveArticle = async (article: ArticleDTO) => {
    setArticleToDelete(article)
  }

  const doArchiveArticle = async (article: ArticleDTO) => {
    setIsLoadingArticles(true)

    const res = await archiveArticle(article.id)
    if (res.error) {
      setErrorOnDelete(res.error.message)
    } else {
      await initialFetchArticles()
    }

    setArticleToDelete(null)
    setIsLoadingArticles(false)
  }

  const handleCloseModal = () => {
    setArticleToDelete(null)
  }
  return (
    <BasePage signOut={signOut} showNavigationBar>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={!!errorOnDelete}
        autoHideDuration={10000}
        onClose={() => setErrorOnDelete(undefined)}
        message={`Ocorreu um erro inesperado. Tente novamente ou entre em contato com o suporte`}
      />

      <Dialog
        isDialogOpen={!!articleToDelete}
        handleCloseDialog={handleCloseModal}
        dialogText="tem certeza que deseja apagar o texto?"
        secondaryDialogText="essa ação não pode ser desfeita  "
        dialogActionButtonText="apagar"
        onClickDialogActionButton={async () =>
          doArchiveArticle(articleToDelete)
        }
        noActionText="cancelar"
      />

      <Styled.PageWrapper>
        <Styled.TitleAndLinkWrapper>
          <Styled.MainTitleWrapper>
            <Styled.PageTitle>Meus textos e newsletters</Styled.PageTitle>
            <ButtonShadow
              onPress={() => onAddNewArticle()}
              label={'CRIAR NOVO TEXTO'}
              leftIcon={
                <FontAwesomeIcon
                  icon={faPlus}
                  color={Colors.BRAND_SECONDARY}
                  name="plus-icon"
                />
              }
            />
          </Styled.MainTitleWrapper>
          <Styled.ListHeader>
            <span>Textos e newsletters</span>
          </Styled.ListHeader>

          <Styled.ItemGrid>
            {(!articles || articles?.length <= 0) && !isShowingAllArticles ? (
              <Styled.EmptyStateMesage>
                Você ainda não possui nenhum TXT registrado.
                <br />
                <u onClick={() => onAddNewArticle()}>
                  Clique aqui para criar um.
                </u>
              </Styled.EmptyStateMesage>
            ) : null}

            {isShowingAllArticles ? (
              <Styled.SearchBarWrapper>
                <SearchBar
                  initialSearchTerm={searchTerm}
                  placeholder="Buscar por txts"
                  onChange={(term) => setSearchTerm(term)}
                  fullWidth={true}
                />
              </Styled.SearchBarWrapper>
            ) : null}

            {(!articles || articles?.length <= 0) && isShowingAllArticles ? (
              <Styled.EmptyStateMesage>
                Nenhum texto encontrado com os termos informados.
              </Styled.EmptyStateMesage>
            ) : null}
            {articles?.length > 0 && !isMobile() && (
              <Styled.HeaderTable>
                <Styled.HeaderTableTitle>Nome</Styled.HeaderTableTitle>
                <Styled.HeaderTableLabel grow={2}>Data</Styled.HeaderTableLabel>
                <Styled.HeaderTableLabel>Leituras</Styled.HeaderTableLabel>
                <Styled.HeaderTableLabel grow={2}>
                  Ações
                </Styled.HeaderTableLabel>
              </Styled.HeaderTable>
            )}

            {articles?.length > 0 &&
              articles.map((article, index) => (
                <Styled.Item isOdd={index % 2 === 1}>
                  <Styled.ItemWrapper>
                    <Styled.ItemInformation
                      disabled={article.state !== 'published'}
                    >
                      <Styled.ItemDescription
                        disabled={article.state !== 'published'}
                      >
                        <Styled.ItemTitle>
                          {article.title}{' '}
                          {article.visibility !== 'open' && (
                            <Styled.IconWrapper>
                              <Styled.ExclusiveIcon />
                            </Styled.IconWrapper>
                          )}
                        </Styled.ItemTitle>
                        <Styled.ItemReleaseDate>
                          {article.createdAt}
                        </Styled.ItemReleaseDate>
                        {!isMobile() && (
                          <Styled.ItemPlayCount>
                            {article.pageViews ?? 0}
                          </Styled.ItemPlayCount>
                        )}
                      </Styled.ItemDescription>

                      <Styled.ItemActions>
                        <Tooltip title="Editar">
                          <Styled.ClickableIcon
                            onClick={() => onEditArticle(article)}
                          >
                            <Styled.EditIcon fill={Colors.PURPLE[400]} />
                          </Styled.ClickableIcon>
                        </Tooltip>
                        <Tooltip title="Copiar url">
                          <Styled.ClickableIcon
                            onClick={() => onCopyArticleUrl(article)}
                          >
                            <Styled.CopyIcon />
                          </Styled.ClickableIcon>
                        </Tooltip>
                        <Tooltip title="Arquivar">
                          <Styled.ClickableIcon
                            onClick={() => onArchiveArticle(article)}
                          >
                            <Styled.ArchiveFolderIcon />
                          </Styled.ClickableIcon>
                        </Tooltip>
                      </Styled.ItemActions>
                    </Styled.ItemInformation>
                  </Styled.ItemWrapper>
                </Styled.Item>
              ))}
          </Styled.ItemGrid>
          <div style={{ width: '70%' }}>
            <div style={{ float: 'right' }}>
              {renderSeeMoreButtonOrLoading()}
            </div>
          </div>
        </Styled.TitleAndLinkWrapper>
      </Styled.PageWrapper>
    </BasePage>
  )
}

export default ArticleListingPage
