import styled from 'styled-components'
import { Card } from '@material-ui/core'
import { Colors, Typography } from 'styles'

export const MetricsCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 320px;

  margin-top: 20px;
  margin-right: 10px;
  margin-left: 10px;
  &.MuiCard-root {
    box-shadow: 2px 2px 8px rgba(33, 36, 39, 0.25) !important;
    background-color: white;
    color: #000;
    border-radius: 16px;
    box-shadow: none;
    padding: 8px;
  }
  @media (max-width: 600px) {
    width: 280px;
    height: 180px;
  }
`

export const CardTitle = styled.div`
  font-family: SpaceGrotesk;
  font-weight: 700;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${Colors.PURPLE[400]};
  margin-top: 32px;
  text-align: center;
  letter-spacing: -${Typography.LETTER_SPACING_1}px;

  @media (max-width: 600px) {
    font-size: 18px;
  }
`

export const ValuesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`

export const Value = styled.div`
  font-family: PP-Neue-Machina;
  font-weight: 700;
  font-size: 36px;
  text-transform: uppercase;
  color: ${Colors.GREY[400]};
  margin-bottom: 16px;
  margin-top: 30px;

  @media (max-width: 600px) {
    font-size: 24px;

  }
`

export const SecondaryValue = styled.div`
  font-size: 24px;
  color: ${Colors.GREY[400]};
  margin-left: 40px;

  @media (max-width: 600px) {
    font-size: 20px;
  }
`

export const SubtitlesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 48px;
`

export const Subtitle = styled.div`
  font-family: SpaceGrotesk;
  letter-spacing: -${Typography.LETTER_SPACING_1}px;
  font-weight: 700;
  font-size: 16px;
  color: ${Colors.GREY[400]};


  @media (max-width: 600px) {
    font-size: 16px;
  }
`

export const SecondarySubtitle = styled.div`
font-family: SpaceGrotesk;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.2);

  @media (max-width: 600px) {
    font-size: 14px;
  }
`