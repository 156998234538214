/**
 * ApiFetch class
 *
 * The ApiFetch is intended to handle web requests, in the end this is nothing
 * more than a thin wrapper around the [Fetch API](https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API).
 *
 * The divergences with the Fetch API are made to customize it to the Orelo
 * specific needs, that is, to expect and handle errors in the Orelo internal
 * format, automatic paginate routes using the Link header and etc.
 *
 * ```
 * import { ApiFetch } from 'path/ApiFetch'
 *
 * const res = await ApiFetch.get('http://api.orelo.audio/api/v1/content/podcasts')
 * const body = res.data //Get the server response
 *
 * if (res.isPaginated()) {
 *   await res.next()
 * }
 *
 * const newBody = res.data
 * ```
 *
 * Available methods:
 *  - get(url): get url
 *  - post(url, body): post body to url
 *  - put(url, body): put body to url
 *  - del(url): del url
 *  - hasFailed(): return if the fetch has failed, if it is true the property
 *    .error have the error value, else data has the successful response body
 *  - isPaginated(): returns true if the request may be paginated
 *  - hasNext(): returns true if the request is paginated and it exists a next
 *    page
 *  - pagination methods:
 *    - next()
 *    - previous()
 *    - first()
 *    - last()
 */
import { auth } from './firebase'
import * as R from 'ramda'
// import { paginationParser, PaginationObject } from './paginationHeaders'
import { store } from '../App'

type QueryObject = {
  [key: string]: string | string[] | number | number[] | undefined
}
type CleanQueryObject = { [key: string]: string[] }

type Init =
  | {
      method: string
      headers?: {
        'Content-Type': string
      }
      body?: string
      signal?: AbortSignal
    }
  | null
  | undefined

function stringify(value: string | number): string {
  if (typeof value === 'number') return `${value}`
  return value
}

function cleanQueryObject(options: any): CleanQueryObject {
  const cleanObject: CleanQueryObject = {}
  Object.entries(options).forEach(([key, value]: [string, any]) => {
    if (value === undefined) return []
    const arr = R.flatten([value])
    cleanObject[key] = arr.map(stringify)
  })
  return cleanObject
}

function toPairs(options: CleanQueryObject): string[][] {
  const pairs: string[][] = []
  Object.entries(options).forEach(([key, values]: [string, string[]]) => {
    values.forEach((value) => pairs.push([key, value]))
  })
  return pairs
}

// Safari will strip headers on redirected requests, so we have to re-add them
// more info:
// https://stackoverflow.com/questions/53488315/what-is-fetchs-redirect-and-authorization-headers-expected-behavior-safari-ha
async function handleSafariRedirect(
  res: Response,
  config: any,
): Promise<Response> {
  if (res.status === 401 && res.redirected) return await fetch(res.url, config)
  return res
}

export class ApiFetch {
  private input: string
  private init: Init
  public error: any | null
  // private pagination: PaginationObject | null
  public data: any

  private constructor(input: string, init = {} as Init) {
    this.input = input
    this.init = init
    this.error = null
    // this.pagination = null
    this.data = undefined
  }

  private async run(userToken?: string): Promise<any> {
    const currentUser = auth.currentUser

    let token
    if (userToken) {
      token = userToken
    } else {
      token = currentUser ? await currentUser.getIdToken() : ''
    }

    const state = store.getState()
    const profileType = R.path(
      ['profiles', 'currentProfile', 'profile'],
      state,
    ) as string
    const profileId = R.path(
      ['profiles', 'currentProfile', 'id'],
      state,
    ) as string
    const profileHeaderInfo =
      profileType === 'brand'
        ? { 'x-brand-id': profileId }
        : profileType === 'podcast'
        ? { 'x-podcast-id': profileId }
        : {}

    const config = {
      ...this.init,
      headers: {
        ...(this.init ? this.init.headers : {}),
        Authorization: `Bearer ${token}`,
        ...profileHeaderInfo,
      },
      // redirect: 'manual',
    }

    try {
      // console.log(
      //   `\x1b[36m[ApiFetch req] ${R.pathOr('GET', ['init', 'method'], this)} ${
      //     this.input
      //   }\x1b[0m`,
      // )
      let res = await fetch(this.input, config as any)

      res = await handleSafariRedirect(res, config as any)

      // const headers = res.headers
      if (res.ok) {
        // this.pagination = paginationParser(headers.get('Link'))
        try {
          this.data = await res.json()
        } catch {
          try {
            this.data = await res.text()
          } catch {}
        }
        // console.log('\x1b[36m[ApiFetch data received]', this.input) //this.data)
      } else {
        const error = new Error()
        const errorDetails = {
          status: res.status,
          message: `errorcode ${res.status}`,
          url: this.input,
        }
        try {
          const data = await res.json()
          errorDetails.message = data.message
        } catch {}
        this.error = Object.assign(error, errorDetails)
        // console.log(
        //   `\u001b[31m[ApiFetch status err ${this.input}]`,
        //   errorDetails,
        // )
      }
    } catch (err) {
      // console.log(`\u001b[31m[ApiFetch err ${this.input}] ${err}`)
      this.error = err
    }
  }

  hasFailed(): boolean {
    return !!this.error
  }

  // isPaginated(): boolean {
  //   return !!this.pagination
  // }

  // hasNext(): boolean {
  //   return !!(this.pagination && this.pagination.next)
  // }

  // async first(): Promise<ApiFetch> {
  //   if (!this.pagination || !this.pagination.first) {
  //     this.error = { message: 'No first' }
  //   } else {
  //     this.input = this.pagination.first
  //     await this.run()
  //   }

  //   return this
  // }

  // async previous(): Promise<ApiFetch> {
  //   if (!this.pagination || !this.pagination.previous) {
  //     this.error = { message: 'No previous' }
  //   } else {
  //     this.input = this.pagination.previous
  //     await this.run()
  //   }

  //   return this
  // }

  // async next(): Promise<ApiFetch> {
  //   if (!this.pagination || !this.pagination.next) {
  //     this.error = { message: 'No next' }
  //   } else {
  //     this.input = this.pagination.next
  //     await this.run()
  //   }

  //   return this
  // }

  // async last(): Promise<ApiFetch> {
  //   if (!this.pagination || !this.pagination.last) {
  //     this.error = { message: 'No last' }
  //   } else {
  //     this.input = this.pagination.last
  //     await this.run()
  //   }

  //   return this
  // }

  static async get(url: string, signal?: AbortSignal): Promise<ApiFetch> {
    const instance = new ApiFetch(url, {
      signal: signal,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
    await instance.run()
    return instance
  }

  static async post(
    url: string,
    body?: any,
    signal?: AbortSignal,
    authToken?: string
  ): Promise<ApiFetch> {
    const instance = new ApiFetch(url, {
      signal: signal,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: body ? JSON.stringify(body) : undefined,
    })
    await instance.run(authToken)
    return instance
  }

  static async put(
    url: string,
    body?: any,
    signal?: AbortSignal,
  ): Promise<ApiFetch> {
    const instance = new ApiFetch(url, {
      signal: signal,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: body ? JSON.stringify(body) : undefined,
    })
    await instance.run()
    return instance
  }

  static async del(
    url: string,
    body?: any,
    signal?: AbortSignal,
  ): Promise<any> {
    const instance = new ApiFetch(url, {
      signal: signal,
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: body ? JSON.stringify(body) : undefined,
    })
    await instance.run()
    return instance
  }

  static toQueryString(options: string | QueryObject): string {
    if (typeof options === 'string') return options
    let query = ''
    const pairs = toPairs(cleanQueryObject(options))
    pairs.forEach(([key, value]) => {
      if (query) {
        query = `${query}&${key}=${value}`
      } else {
        query = `${key}=${value}`
      }
    })
    return query
  }

  static async upload(
    url: string,
    file: File,
    body?: any,
    impersonateId?: { podcastId?: string; brandId?: string },
    userToken?: string,
  ): Promise<ApiFetch> {
    const data = new FormData()
    data.append('file', file)

    if (body) {
      Object.keys(body).forEach((key) => {
        data.append(key, body[key])
      })
    }

    const requestConfig: any = {
      method: 'POST',
      headers: {},
      body: data,
    }
    if (impersonateId?.podcastId) {
      requestConfig.headers['x-podcast-id'] = impersonateId.podcastId
    } else if (impersonateId?.brandId) {
      requestConfig.headers['x-brand-id'] = impersonateId.brandId
    }

    const instance = new ApiFetch(url, requestConfig)
    await instance.run(userToken)
    return instance
  }
}
