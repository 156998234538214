import React, { useEffect, useRef, useState } from 'react'
import { useField } from 'formik'
import { FormHelperText, OutlinedTextFieldProps } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import * as Styled from './InputField.styled'
import { transparentBorderStyle } from './InputField.styled'
import { Duration } from '../../../utils/domain/Duration'
import { Colors } from 'styles'

interface TextFieldFieldProps extends OutlinedTextFieldProps {
  name: string
  label?: string
  classes?: any
  fullWidth?: boolean
  style?: {
    fontSize?: number
    fontWeight?: number
    backgroundColor?: string
  }
  darkMode?: boolean
  accept?: string
  onChangeFile?: (file: File) => Promise<void>
  setAudioDuration?: (duration: number) => Promise<void>
  isAudio?: boolean
}

const MAX_SIZE_PER_MINUTE = 2000000 // 2 MB

const MINIMUM_FILE_SIZE = 15000000 // 15 MB

const InputField: React.FC<TextFieldFieldProps> = ({
  name,
  label,
  classes,
  fullWidth,
  style,
  children,
  darkMode,
  accept,
  onChangeFile,
  placeholder,
  isAudio,
  helperText,
  error,
  setAudioDuration,
  ...props
}) => {
  const [_field, meta, helpers] = useField(name)

  const audioInputRef = useRef<any>(null)
  const [beforeUploadFile, setBeforeUploadFile] = useState<File>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const verifySizeAndHandleUpload = async(event: any) => {
    const duration = Duration.create(event.target.duration)

    if (!duration.isSuccess) {
      setBeforeUploadFile(undefined)
      onChangeFile(undefined)
      return alert(
        'Ops! Não conseguimos verificar a duração desse audio. Tente novamente, por favor.',
      )
    }
    setAudioDuration(duration.getValue().toSeconds())

    const durationInMinutes = duration.getValue().toSeconds() / 60
    if (
      beforeUploadFile.size >
      durationInMinutes * MAX_SIZE_PER_MINUTE + MINIMUM_FILE_SIZE
    ) {
      alert(
        `O arquivo é muito grande para a duração que ele possui.
        Duração (min): ${durationInMinutes.toPrecision(3)}.
        Tamanho: ${(beforeUploadFile.size / 1000000).toPrecision(3)} MB.
        Tamanho máximo ${MAX_SIZE_PER_MINUTE / 1000000} MB por minuto + 20 MB.`,
      )
      if (audioInputRef.current) {
        audioInputRef.current.value = ''
      }

      setBeforeUploadFile(undefined)
      onChangeFile(undefined)
      return
    }
  }

  const clickInputButton = () => {
    document.getElementById(name)?.click()
  }

  return (
    <Styled.Wrapper fullWidth={fullWidth}>
      {label && (
        <Styled.StyledLabel htmlFor={name} darkMode={darkMode}>
          {label}
          <Styled.ContentWrapper>{children}</Styled.ContentWrapper>
        </Styled.StyledLabel>
      )}
      <Styled.InputFileWrapper
        darkMode
        onClick={() => {
          if (!isLoading) {
            clickInputButton()
          }
        }}
      >
        {_field.value?.name ?? placeholder}
        <input
          type="file"
          style={{ display: 'none' }}
          id={name}
          accept={accept}
          ref={audioInputRef}
          onChange={async (e) => {
            helpers.setError(null)

            setIsLoading(true)

            if (!e.target.files) return
            const file = e.target.files.item(0)
            if (!file) return

            setBeforeUploadFile(file)

            if (onChangeFile) {
              await onChangeFile(file)
            }

            setIsLoading(false)
          }}
        />
        {helperText && (
          <FormHelperText error={error}>{helperText}</FormHelperText>
        )}
        <Styled.UploadIcon fill={Colors.GREY[200]} />
      </Styled.InputFileWrapper>
      {isLoading && <Styled.LoadingSpinner size={24} />}
      {isAudio && (
        <audio
          controls
          hidden
          onLoadedMetadata={(e) => {
            verifySizeAndHandleUpload(e)
          }}
          src={
            beforeUploadFile ? URL.createObjectURL(beforeUploadFile) : undefined
          }
        />
      )}
    </Styled.Wrapper>
  )
}

export default withStyles(transparentBorderStyle)(InputField)
