import React, { useState, useEffect, useCallback } from 'react'
import { Snackbar } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import {
  deactivateAllEmails,
  deactivatePodcastEmails,
  getPodcastDetails,
} from '../../services/api'
import Button from '../../components/Button/Button'
import * as Styled from './DeactivateAllEmails.styled'
import { useTypedSelector } from '../../reducers'
import { changeProfile, ProfileType } from '../../reducers/profiles'
import useFetch from '../../hooks/useFetch'
import { PodcastDTO } from '../../types'
import { ApiFetch } from '../../services/ApiFetch'
import { useQuery } from '../../Routes/RouteAux'

const DeactivateAllEmails: React.FC = () => {
  const dispatch = useDispatch()
  const [requestEnded, setRequestEnded] = useState(false)
  const [requestSucessfull, setRequestSuccessful] = useState(false)

  const currentProfile = useTypedSelector<ProfileType>(
    (state) => state && state.profiles && state.profiles.currentProfile,
  )

  const availableProfiles = useTypedSelector<ProfileType[]>(
    (state) => state && state.profiles && state.profiles.availableProfiles,
  )

  const query = useQuery()

  const podcastId = query.get('podcastId')

  const {
    isLoading: isLoadingPodcastDetails,
    data: podcastDetails,
    error: errorPodcastDetails,
    fetchData: fetchPodcastDetails,
  } = useFetch<PodcastDTO>(
    useCallback(() => {
      return getPodcastDetails(podcastId)
    }, [podcastId]),
  )

  useEffect(() => {
    if (currentProfile.profile !== 'listener') {
      const listenerProfile = availableProfiles.find((profile) => {
        return profile.profile === 'listener'
      })

      dispatch(changeProfile(listenerProfile))
    }
  }, [currentProfile, availableProfiles, dispatch])

  if (isLoadingPodcastDetails) {
    return (
      <Styled.PageWrapper>
        <Styled.MessageWrapper>
          <Styled.LoadingSpinner />
          Carregando...
        </Styled.MessageWrapper>
      </Styled.PageWrapper>
    )
  }

  return (
    <Styled.PageWrapper>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={requestEnded && !requestSucessfull}
        autoHideDuration={10000}
        onClose={() => setRequestEnded(false)}
        message={'Ops, parece que tivemos um erro, tente novamente.'}
      />
      {errorPodcastDetails ? (
        <Styled.MessageWrapper>
          <Styled.Text>
            Para desativar toda a comunicação via e-mail da Orelo clique no
            botão abaixo:
          </Styled.Text>
        </Styled.MessageWrapper>
      ) : (
        <Styled.MessageWrapper>
          <Styled.Text>
            Para desativar toda a comunicação via e-mail da Orelo de:{' '}
          </Styled.Text>
          <Styled.Text>{podcastDetails.title}</Styled.Text>
          <Styled.Text> clique no botão abaixo:</Styled.Text>
        </Styled.MessageWrapper>
      )}
      {!requestEnded || !requestSucessfull ? (
        <Button
          variant="contained"
          fontColor="black"
          onClick={async () => {
            setRequestEnded(false)
            let res: ApiFetch
            if (errorPodcastDetails) {
              res = await deactivateAllEmails()
            } else {
              res = await deactivatePodcastEmails(podcastId)
            }

            if (res.hasFailed()) {
              setRequestSuccessful(false)
            } else {
              setRequestSuccessful(true)
            }

            setRequestEnded(true)
          }}
        >
          {errorPodcastDetails
            ? `Desativar emails da Orelo`
            : `Desativar emails de ${podcastDetails.title} da Orelo`}
        </Button>
      ) : (
        <Styled.MessageWrapper>
          Você desativou a comunicação da Orelo
        </Styled.MessageWrapper>
      )}
    </Styled.PageWrapper>
  )
}

export default DeactivateAllEmails
