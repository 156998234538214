import React, { useCallback, useEffect, useState } from 'react'
import * as Yup from 'yup'
import CPF from 'cpf'
import { Formik } from 'formik'
import {
  CircularProgress,
  FormHelperText,
  Snackbar,
  Switch,
  Tooltip,
} from '@material-ui/core'
import BasePage from '../../components/BasePage/BasePage'
import TextFieldField from '../../components/Fields/TextFieldField/TextFieldField'
import NumberFormatField from '../../components/Fields/NumberFormatField/NumberFormatField'
import * as Styled from './EditPodcastInfo.styled'
import {
  getPodcastDetails,
  getPodcastReceivingInfo,
  updatePodcastInfo,
} from '../../services/api'
import { useParams } from 'react-router-dom'
import useFetch from '../../hooks/useFetch'
import { ReceivingInfoDTO, PodcastDTO } from '../../types'
import UploadImageButton from './UploadImageButton'

interface EditPodcastInfoProps {
  signOut: () => Promise<void>
}

interface FormValues {
  title: string
  email: string
  description?: string
  phone?: string
  userCpf?: string
  userCnpj?: string
  companyName?: string
}

const editPodcastInfoValidationSchema = Yup.object().shape({
  title: Yup.string()
    .required('Campo obrigatório')
    .min(6, 'Tamanho mínimo de 6 caracteres')
    .max(62, 'Tamanho máximo 62 caracteres'),
  email: Yup.string().required('Campo obrigatório'),
  description: Yup.string(),
  phone: Yup.string(),
  userCpf: Yup.string()
    .when('userCnpj', {
      is: (value) => !value,
      then: Yup.string().required('É necessário informar o CPF ou o CNPJ'),
    })
    .test(
      'cpfValidation',
      'CPF inválido',
      (value) => !value || CPF.isValid(value),
    ),
  userCnpj: Yup.string().test(
    'length',
    'CNPJ inválido',
    (value) => !value || value?.length === 14,
  ),
  companyName: Yup.string().required('Campo obrigatório'),
})

const EditPodcastInfo: React.FC<EditPodcastInfoProps> = ({ signOut }) => {
  const { podcastId } = useParams<{ podcastId: string }>()

  const [snackbarMessage, setSnackbarMessage] = useState<string>()
  const [isCpf, setIsCpf] = useState(true)
  const [podcastImage, setPodcastImage] = useState<string>()
  const [initialValues, setInitialValues] = useState({
    title: undefined,
    description: undefined,
    email: undefined,
    phone: undefined,
    userCpf: undefined,
    userCnpj: undefined,
    companyName: undefined,
  })

  const {
    isLoading: isLoadingReceivingInfo,
    data: receivingInfo,
    error: errorReceivingInfo,
    fetchData: fetchReceivingInfo,
  } = useFetch<ReceivingInfoDTO>(
    useCallback(() => {
      return getPodcastReceivingInfo(podcastId)
    }, [podcastId]),
  )

  const {
    isLoading: isLoadingPodcastDetails,
    data: podcastDetails,
    error: errorPodcastDetails,
    fetchData: fetchPodcastDetails,
  } = useFetch<PodcastDTO>(
    useCallback(() => {
      return getPodcastDetails(podcastId)
    }, [podcastId]),
  )

  useEffect(() => {
    if (
      !isLoadingReceivingInfo &&
      !isLoadingPodcastDetails &&
      receivingInfo &&
      podcastDetails
    ) {
      setInitialValues({
        title: podcastDetails.title,
        description: podcastDetails.description,
        email: receivingInfo.email,
        phone: receivingInfo.phoneNumber,
        userCpf: receivingInfo.cpf,
        userCnpj: receivingInfo.cnpj,
        companyName: receivingInfo.companyName,
      })

      setPodcastImage(podcastDetails.image)
    }
  }, [
    receivingInfo,
    isLoadingReceivingInfo,
    isLoadingPodcastDetails,
    podcastDetails,
  ])

  const isExclusivePodcast = !!podcastDetails?.isExclusive

  const switchIsCpf = () => {
    setIsCpf((prevState) => !prevState)
  }

  const submitForm = async (formValues: FormValues): Promise<void> => {
    const body = {
      podcastInfo: {
        title: formValues.title,
        description: formValues.description,
        email: formValues.email,
      },
      receivingInfo: {
        userCpf: formValues.userCpf,
        userCnpj: formValues.userCnpj,
        companyName: formValues.companyName,
        phone: formValues.phone,
      },
    }
    const res = await updatePodcastInfo(podcastId, body)
    if (res.error) {
      setSnackbarMessage(
        'Ocorreu um erro inesperado. Tente novamente ou entre em contato com o suporte',
      )
    } else {
      setSnackbarMessage('Informações atualizadas com sucesso!')
    }
  }

  const onUploadImage = (imageUrl: string): void => {
    setPodcastImage(imageUrl)
  }
  if (isLoadingReceivingInfo || isLoadingPodcastDetails) {
    return (
      <BasePage signOut={signOut} isDark>
        <Styled.PageWrapper>
          <Styled.TitleWrapper>
            <Styled.PageSubTitle>Carregando...</Styled.PageSubTitle>
          </Styled.TitleWrapper>
          <CircularProgress />
        </Styled.PageWrapper>
      </BasePage>
    )
  }

  if (errorReceivingInfo || errorPodcastDetails) {
    return (
      <BasePage signOut={signOut} isDark>
        <Styled.PageWrapper>
          <Styled.TitleWrapper>
            <Styled.PageSubTitle>
              Ops, parece que ocorreu um erro ao tentar carregar as suas
              informações
            </Styled.PageSubTitle>
            <Styled.LargeButton
              variant="contained"
              buttonColorOnHover="#303030"
              buttonColor="#414141"
              onClick={() => {
                fetchReceivingInfo()
                fetchPodcastDetails()
              }}
            >
              Tentar novamente
            </Styled.LargeButton>
          </Styled.TitleWrapper>
        </Styled.PageWrapper>
      </BasePage>
    )
  }

  return (
    <BasePage signOut={signOut} isDark>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={!!snackbarMessage}
        autoHideDuration={15000}
        onClose={() => setSnackbarMessage(undefined)}
        message={snackbarMessage}
      />
      <Styled.PageWrapper>
        <Styled.PodcastImage src={podcastImage} />
        <UploadImageButton
          podcastId={podcastId}
          onUploadImage={onUploadImage}
          disabled={!isExclusivePodcast}
        />
        <Styled.TitleWrapper>
          <Styled.TextWrapper>
            <Styled.PageSubTitle>minhas informações</Styled.PageSubTitle>
            {!isExclusivePodcast && (
              <Tooltip
                title={
                  <Styled.TooltipText>
                    Existe um feed xml atrelado a esse perfil, por isso alguns
                    campos são atualizados automaticamente e não podem ser
                    editados
                  </Styled.TooltipText>
                }
                placement="top"
              >
                <Styled.InfoIconWithMargin />
              </Tooltip>
            )}
          </Styled.TextWrapper>
          <Formik
            validationSchema={editPodcastInfoValidationSchema}
            initialValues={initialValues}
            onSubmit={async (values) => {
              await submitForm(values)
            }}
            enableReinitialize
          >
            {({ handleSubmit, values, touched, errors }) => {
              return (
                <Styled.FlexForm>
                  <TextFieldField
                    name="title"
                    variant="outlined"
                    label="nome do seu projeto"
                    value={values.title}
                    error={!!(touched.title && errors?.title)}
                    helperText={touched.title && errors?.title}
                    fullWidth
                    disabled={!isExclusivePodcast}
                  />
                  <TextFieldField
                    name="description"
                    variant="outlined"
                    label="descrição"
                    value={values.description}
                    error={!!(touched.description && errors?.description)}
                    helperText={touched.description && errors?.description}
                    fullWidth
                    multiline
                    disabled={!isExclusivePodcast}
                  />
                  <TextFieldField
                    name="email"
                    variant="outlined"
                    label="email"
                    value={values.email}
                    error={!!(touched.email && errors?.email)}
                    helperText={touched.email && errors?.email}
                    fullWidth
                    disabled={!isExclusivePodcast}
                  />
                  <NumberFormatField
                    name="phone"
                    variant="outlined"
                    label="telefone"
                    fullWidth
                    format={'(##) #####-####'}
                    mask={'_'}
                    value={values.phone}
                    error={!!(touched.phone && errors?.phone)}
                    helperText={touched.phone && (errors?.phone as string)}
                    hasEmptyValue
                  />
                  <Styled.SwitchWrapper>
                    <Styled.StyledLabel>cnpj</Styled.StyledLabel>
                    <Switch
                      checked={isCpf}
                      onChange={switchIsCpf}
                      color="default"
                    />
                    <Styled.StyledLabel>cpf</Styled.StyledLabel>
                  </Styled.SwitchWrapper>
                  <FormHelperText
                    error={
                      !!(touched.userCpf && errors?.userCpf) ||
                      !!(touched.userCnpj && errors?.userCnpj)
                    }
                  >
                    {(touched.userCpf && (errors?.userCpf as string)) ||
                      (touched.userCnpj && (errors?.userCnpj as string))}
                  </FormHelperText>
                  <NumberFormatField
                    name={isCpf ? 'userCpf' : 'userCnpj'}
                    variant="outlined"
                    label={isCpf ? 'cpf' : 'cnpj'}
                    fullWidth
                    format={isCpf ? '###.###.###-##' : '##.###.###/####-##'}
                    mask={'_'}
                    value={isCpf ? values.userCpf : values.userCnpj}
                    error={
                      isCpf
                        ? !!(touched.userCpf && errors?.userCpf)
                        : !!(touched.userCnpj && errors?.userCnpj)
                    }
                    helperText={
                      isCpf
                        ? touched.userCpf && (errors?.userCpf as string)
                        : touched.userCnpj && (errors?.userCnpj as string)
                    }
                    hasEmptyValue
                  />
                  <TextFieldField
                    name="companyName"
                    variant="outlined"
                    label={isCpf ? 'nome completo' : 'razão social'}
                    value={values.companyName}
                    error={!!(touched.companyName && errors?.companyName)}
                    helperText={touched.companyName && errors?.companyName}
                    fullWidth
                  />
                  <Styled.LargeButton
                    variant="contained"
                    buttonColorOnHover="#303030"
                    buttonColor="#414141"
                    onClick={() => handleSubmit()}
                  >
                    Atualizar informações
                  </Styled.LargeButton>
                </Styled.FlexForm>
              )
            }}
          </Formik>
        </Styled.TitleWrapper>
      </Styled.PageWrapper>
    </BasePage>
  )
}

export default EditPodcastInfo
