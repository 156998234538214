import styled from 'styled-components'
import { CircularProgress, Dialog, DialogActions } from '@material-ui/core'
import Button from '../../components/Button/Button'
import { Form } from 'formik'
import CloseIconSvg from '@material-ui/icons/Close'
import MenuIcon from '@material-ui/icons/Menu'
import { ReactComponent as PadlockFillIconSvg } from '../../assets/icons/padlock_fill.svg'
import { DialogTitle as MuiDialogTitle } from '@material-ui/core'
import MicIcon from '@material-ui/icons/Mic'
import { Colors, Typography } from 'styles'

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  padding: 20px 64px;
  color: ${Colors.GREY[400]};

  @media (max-width: 600px) {
    padding: 16px;
  }
`

export const TitleAndLinkWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 42px;
  width: 100%;

  @media (max-width: 600px) {
    align-items: center;
  }
`

export const MenuWrapper = styled.div`
  display: flex;
  align-items: end;
  flex-direction: column;
  margin-bottom: 42px;
  width: 30%;

  background: #000000;
  border-radius: 24px;
  padding: 24px 32px;
  margin-top: 4rem;
  gap: 12px;

  @media (max-width: 600px) {
    align-items: center;
  }
`

export const MenuHeader = styled.div`
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 600;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;

  text-align: right;
  text-transform: uppercase;

  color: #ffffff;
`

export const MenuButton = styled.div``

export const ItemGrid = styled.div`
  display: flex;
  gap: 36px;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 24px;
  justify-content: flex-start;

  @media (max-width: 600px) {
    padding: 0;
  }
`

interface ItemStylesInterface {
  isDark?: boolean
  textCenter?: boolean
}

export const EmptyStateMesage = styled.div`
  font-family: SpaceGrotesk;
  font-weight: 700;
  font-size: 1rem;
  line-height: 40px;
  text-align: center;

  color: ${Colors.GREY[400]};

  display: flex;
  align-items: stretch;
  flex-direction: column;
  letter-spacing: -${Typography.LETTER_SPACING_2}px;
  flex: 1;
  width: 100%;

  u {
    cursor: pointer;
    color: ${Colors.PURPLE[400]};
  }

  @media (max-width: 600px) {
    order: 1;
    margin-top: 0;
  }
`

export const Item = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  margin-bottom: 20px;
  flex: 0 0 30%;
  overflow: hidden;
  box-shadow: 2px 2px 8px rgba(33, 36, 39, 0.25);
  background-color: ${Colors.GREY[100]};

  @media (max-width: 600px) {
    flex: 0 0 100%;
    order: 2;
  }
`

export const ItemHead = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

export const ItemOptions = styled.div`
  flex: 0 0 20%;
  text-align: right;
`

export const ItemList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  margin: 24px 0;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 35vh;
`

export const LoadMoreButtonWrapper = styled.div`
  margin: 0 12px;
  width: 200px;
  place-self: center;
`

export const ItemListItem = styled.div.attrs(
  (props: { disabled: boolean; isOdd?: boolean }) => props,
)`
  opacity: ${(props) => (props.disabled ? '0.21' : '1')};
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
  background-color: ${({ isOdd }) =>
    isOdd ? Colors.GREY[100] : Colors.GREY[50]};
`

export const ItemHeader = styled.div.attrs(
  (props: ItemStylesInterface) => props,
)`
  font-family: SpaceGrotesk;
  letter-spacing: -${Typography.LETTER_SPACING_1}px;
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 40px;
  padding-bottom: 12px;
  border-color: ${(props) => (props.isDark ? 'white' : 'black')} !important;
  text-align: ${(props) => (props.textCenter ? 'center' : 'left')};

  flex: 0 0 80%; ;
`

export const ItemSubHeader = styled.div.attrs(
  (props: ItemStylesInterface) => props,
)`
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 40px;
  border-bottom: 1px solid #000000;
  padding: 12px 32px;
  border-color: ${(props) => (props.isDark ? 'white' : 'black')} !important;
  text-align: ${(props) => (props.textCenter ? 'center' : 'left')};
`

export const ItemWrapper = styled.div.attrs(
  (props: ItemStylesInterface) => props,
)`
  color: black;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 36px;
  height: 100%;
  padding: 16px;
`

export const ItemIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const ItemDescription = styled.div`
  overflow: hidden;

  @media (max-width: 900px) {
    padding-left: 16px;
  }
`

export const ItemTitle = styled.div<{ isDialog?: boolean }>`
  font-family: Inter;
  gap: 8px;
  font-weight: 700;
  padding: 12px 6px 12px 0;
  align-self: center;
  color: ${Colors.PURPLE[400]};
  white-space: nowrap;
  overflow: hidden;
  text-decoration: underline;
  text-overflow: ellipsis;
  align-self: center;
  margin: auto;
`

export const ItemReleaseDate = styled.div`
  font-family: Inter;
  font-weight: 500;
  font-size: 0.9rem;
  display: inline;
`

export const SeeMoreButton = styled(Button)`
  &.MuiButton-root {
    margin-top: 24px;
    display: block;
    background-color: #000;

    &:hover {
      color: #000;
    }
  }
`

export const ItemActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  flex: 0 0 16%;
`

export const ItemBody = styled.div`
  font-family: Inter;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  padding: 32px 32px 48px 32px;
  word-break: break-word;
`

export const ButtonWrapper = styled.div`
  justify-content: end;
  width: 100%;
  display: flex;

  @media (max-width: 600px) {
    justify-content: center;
  }
`

export const PageTitle = styled.div`
  font-family: PP-Neue-Machina;
  font-weight: 800;
  font-size: 48px;
  color: ${Colors.GREY[400]};
  margin-top: 16px;

  @media (max-width: 600px) {
    text-align: center;
    font-size: 2rem;
    line-height: normal;
    margin-bottom: 0;
    margin-left: 0;
  }
`

export const ReturnText = styled.div`
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #000000;
  padding-left: 8px;
  padding-bottom: 4px;
`

export const ReturnButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const MessageWrapper = styled.div`
  margin-top: 50px;
  margin-bottom: 20px;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`

export const SpinnerWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`

export const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const LoadingCircle = styled(CircularProgress)`
  margin-left: 12px;
`

export const SupportTierCardWrapper = styled.div`
  width: 40%;
  margin-bottom: 16px;

  &.MuiCard-root {
    background-color: #1f1f1f;
    color: white;
  }

  @media (max-width: 600px) {
    width: 90%;
  }
`

export const TiersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`

export const AddTiersButton = styled(Button)`
  align-self: center;

  &.MuiButtonBase-root {
    margin-bottom: 24px;
  }
`

export const ErrorText = styled.div`
  color: red;
  margin-bottom: 12px;
`

export const FlexForm = styled(Form)`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 32px;
`

export const FullWidthForm = styled(Form)`
  width: 100%;
`

export const ListHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  margin: 3rem 0 2rem 0;

  span {
    font-family: 'Gellix';
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 25px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;

    color: #ffffff;
    background: black;
    padding: 0.5rem 2rem;
    border: 2px solid #000000;
    border-radius: 99px;

    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background: #000;
      left: 0;
      top: 50%;
      position: absolute;
      z-index: -1;
    }
  }
`

export const MicrophoneCircledIcon = styled(MicIcon)`
  background: black;
  color: #fff;
  border-radius: 100%;
  width: 1.3rem !important;
  height: 1.3rem !important;
  padding: 4px;
`

export const OrderIcon = styled(MenuIcon)`
  cursor: move;
  color: ${Colors.PURPLE[400]};
`

export const CloseIcon = styled(CloseIconSvg)`
  cursor: pointer;
  color: ${Colors.PURPLE[400]};
`

export const MuiDialog = styled(Dialog)`
  &.MuiPaper-root {
    padding: 24px;
  }
`

export const MuiDialogActions = styled(DialogActions)`
  &.MuiDialogActions-root {
    padding: 24px;
  }
`

export const DialogTextWrapper = styled.div`
  padding: 18px 24px;
`

export const PodcastModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px 12px;
  align-items: stretch;

  @media (max-width: 900px) {
    width: calc(100% - 16px);
  }
`

export const DialogText = styled(MuiDialogTitle)`
  &.MuiDialogTitle-root {
    font-family: SpaceGrotesk;
    letter-spacing: -${Typography.LETTER_SPACING_1}px;
    font-size: 1.2em;
    font-weight: bold;
    padding: 0px 0px;
  }
`
export const HeaderTableTitle = styled.span`
  letter-spacing: -${Typography.LETTER_SPACING_2}px;
  font-family: SpaceGrotesk;
  font-weight: 600;
`

export const HeaderTableLabel = styled.span`
  letter-spacing: -${Typography.LETTER_SPACING_1}px;
  font-family: SpaceGrotesk;
  font-weight: 600;
`

export const HeaderTable = styled.div`
  display: flex;
  justify-content: space-between;
  width: auto;
  color: ${Colors.GREY[400]};
  border-bottom: 1px solid ${Colors.GREY[200]};
`
export const ExclusiveIcon = styled(PadlockFillIconSvg)`
  position: relative;
  top: 5px;
  margin-left: 8px;
`
