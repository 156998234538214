import React from 'react'

import { ContentWrapper, PageWrapper } from './BasePage.styled'
import HeaderWrapper from '../Header/HeaderWrapper'
import Footer from '../Footer/Footer'
import BottomPlayer from 'components/BottomPlayer'
import NavigationBar from '../NavigationBar/NavigationBar'

interface BasePageProps {
  signOut: () => Promise<void>
  hideSearchBar?: boolean
  isDark?: boolean
  flex?: number
  hidePlayer?: boolean
  showNavigationBar?: boolean
}

const BasePage: React.FC<BasePageProps> = ({
  children,
  signOut,
  hideSearchBar,
  isDark,
  flex,
  hidePlayer,
  showNavigationBar,
}) => {
  return (
    <PageWrapper isDark={isDark} flex={flex}>
      <HeaderWrapper
        signOut={signOut}
        hideSearchBar={hideSearchBar}
        isDark={isDark}
      />
      {showNavigationBar && <NavigationBar />}
      <ContentWrapper>{children}</ContentWrapper>
      <Footer />
      {!hidePlayer && <BottomPlayer />}
    </PageWrapper>
  )
}

export default BasePage
