import React, { useEffect } from 'react'
import styled from 'styled-components'

import BasePage from '../../components/BasePage/BasePage'
import ComparativeTable from './components/ComparativeTable/ComparativeTable'
import CreatePage from './components/CreatePage/CreatePage'
import HomeFooter from './components/HomeFooter/HomeFooter'
import Showcase from './components/Showcase/Showcase'
import StartProject from './components/StartProject/StartProject'
import Publish from './components/Publish/Publish'
import HeroSection from './components/HeroSection/HeroSection'
import CreatorHighlight from './components/CreatorHighlight/CreatorHighlight'
import * as Styled from './Home.styled'
import sticker from '../../assets/images/home/sticker_hands_up.png'
import sticker_2 from '../../assets/images/home/sticker_orelo.png'
import sticker_3 from '../../assets/images/home/sticker_smile_faces.png'
import nao_inviabilize_sticker from '../../assets/images/home/nao_inviabilize_sticker.png'
import modus_operandi_sticker from '../../assets/images/home/modus_operandi_img.png'
import { ProfileType, refreshAvailableProfiles } from '../../reducers/profiles'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../reducers'

interface HomeProps {
  signOut: () => Promise<void>
}

const FontStylingWrapper = styled.div`
  font-family: PP-Neue-Machina;
`

const Home: React.FC<HomeProps> = ({ signOut }) => {
  const currentProfile = useTypedSelector<ProfileType>(
    (state) => state && state.profiles && state.profiles.currentProfile,
  )

  const reduxDispatch = useDispatch()

  useEffect(() => {
    if (currentProfile) reduxDispatch(refreshAvailableProfiles())
  }, [])

  return (
    <BasePage signOut={signOut}>
      <FontStylingWrapper>
        <HeroSection />
        <Publish />
        <Showcase />
        <CreatePage />
        <CreatorHighlight
          bgColor="#FFA3CE"
          fontColor="#212427"
          image={nao_inviabilize_sticker}
          title={'Não inviabilize'}
          podcastId={'5e8a485d83d35d7aae91c591'}
          description={
            <div>
              <p>
                Déia Freitas é uma das maiores roteiristas e contadoras de
                histórias do Brasil hoje. No Não Inviabilize, ela conta
                histórias enviadas pela sua audiência misturadas às delas. Na
                sua própria definição, é “um espaço de contos e crônicas, um{' '}
                <b>laboratório de histórias reais</b>”
              </p>
              <p>
                São histórias de se impressionar, de dar risada, de chorar e
                também de chorar de dar risada. E também histórias de dar
                arrepios na nuca (de medo ou de outras coisas…).
              </p>
              <p>
                O Não Inviabilize é um dos principais projetos de conteúdo do
                país. E o podcast brasileiro de maior audiência.
              </p>
            </div>
          }
          stickers={['homeGrown']}
        />
        <Styled.StickerSeparator src={sticker} positionX={60} maxWidth={130} />
        <StartProject />
        <Styled.StickerSeparator
          src={sticker_2}
          positionX={65}
          maxWidth={145}
        />
        <CreatorHighlight
          bgColor="#005744"
          fontColor="#fff"
          image={modus_operandi_sticker}
          title={'Modus operandi'}
          podcastId={'5e7f6ef361d8770ee8e4ac49'}
          description={
            <div>
              <p>
                O Modus Operandi é um dos podcast mais queridos dentro de um dos
                gêneros mais aclamados: o true crime.
              </p>
              <p>
                O projeto foi lançado em 2020 e é apresentado por Carol Moreira
                e Mabê Bonafé. Nas palavras delas, o Modus Operandi “sempre
                busca contar as histórias de uma maneira sensível, trazendo os
                contextos envolvidos e propondo debates e reflexões”.
              </p>
              <p>
                Em outras palavras, os conteúdos despertam a curiosidade ao
                mesmo tempo em que são tratados de um jeito muito especial pelas
                apresentadoras. Impossível não querer ouvir mais e mais, além de
                fazer parte da comunidade com recompensas especiais para os
                apoiadores.
              </p>
            </div>
          }
          stickers={['homeGrown', 'micSticker']}
        />
        <Styled.StickerSeparator src={sticker_3} positionX={45} maxWidth={145} />
        <HomeFooter />
      </FontStylingWrapper>
    </BasePage>
  )
}

export default Home
