import React, { useEffect } from 'react'
import {
  auth,
  firestore,
  setAnalyticsUserId,
  setAnalyticsUserProperties,
} from '../services/firebase'
import {
  onAuthStateChanged,
  UserInfo,
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
} from 'firebase/auth'
import { doc, onSnapshot } from 'firebase/firestore'

import { Config } from '../settings/config'

import { useDispatch } from 'react-redux'
import * as R from 'ramda'

import * as monitor from 'services/monitor'
import { updateUser } from 'services/api'

import {
  refreshAvailableProfiles,
  setProfileStoreFromStorage,
  clearProfileState,
} from '../reducers/profiles'

const localStorage = window.localStorage

const AuthContext = React.createContext({})
const AuthConsumer = AuthContext.Consumer

let userNameToUpdateAfterCreation = undefined as undefined | string | null
let userAvatarToUpdateAfterCreation = undefined as undefined | string | null

function AuthProvider(props: any): JSX.Element {
  const reduxDispatch = useDispatch()
  const [state, dispatch] = React.useReducer(
    (prevState: any, action: any) => {
      switch (action.type) {
        case 'LOGGED_OUT':
          return {
            ...prevState,
            user: null,
            isLoading: false,
          }
        case 'LOGGED_IN':
          return {
            ...prevState,
            user: action.user,
            isLoading: false,
          }
        case 'SET_LOGIN_LOADING':
          return {
            ...prevState,
            isLoading: true,
          }
      }
    },
    { isLoading: true, user: null, mobileEnv: Config.WEB_ENV },
  )

  async function updateUserIfNecessary(): Promise<void> {
    if (userNameToUpdateAfterCreation || userAvatarToUpdateAfterCreation) {
      const updateUserRes = await updateUser(
        R.reject(R.isNil, {
          name: userNameToUpdateAfterCreation,
          avatar: userAvatarToUpdateAfterCreation,
        }),
      )
      if (updateUserRes.hasFailed()) {
        monitor.notify(updateUserRes.error, 'update user after creation failed')
      }
      userNameToUpdateAfterCreation = undefined
      userAvatarToUpdateAfterCreation = undefined
    }
  }

  async function signOut(): Promise<void> {
    try {
      dispatch({ type: 'LOGGED_OUT' })
      reduxDispatch(clearProfileState())
      await localStorage.removeItem('@UserProfilesState')
      await auth.signOut()
    } catch (e) {
      if (e.code === 'auth/no-current-user') return
      monitor.notify(e, 'signout error')
    }
  }

  async function handleSignInError(
    error: any,
    errorType: string,
  ): Promise<void> {
    if (
      error &&
      (error.code === 'auth/account-exists-with-different-credential' ||
        error.message ===
          'An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address.')
    ) {
      alert(
        'Ops! Login duplicado... Parece que você já fez login com outro provedor. Escolha o mesmo provedor do seu primeiro login para continuar.',
      )
    }
    monitor.notify(error, `${errorType} signin`)
    signOut()
  }

  async function verifySignedIn(user: UserInfo): Promise<void> {
    let unsubscribe: { (): void; (): void } | null = null
    try {
      if (Config.WEB_ENV === 'local') {
        await updateUserIfNecessary()
        await reduxDispatch(refreshAvailableProfiles())
        return dispatch({ type: 'LOGGED_IN', user })
      }

      const timeout = setTimeout(function () {
        monitor.notify(new Error('loginWhenUserIsCreatedInOurDatabase timeout'))
        unsubscribe && unsubscribe()
        clearTimeout(timeout)
        signOut()
      }, 15000)

      const loginWhenUserIsCreatedInOurDatabase = async (
        doc: any,
      ): Promise<any> => {
        try {
          if (doc.data()) {
            await updateUserIfNecessary()
            await reduxDispatch(refreshAvailableProfiles())
            dispatch({ type: 'LOGGED_IN', user })
            clearTimeout(timeout)
            unsubscribe && unsubscribe()
          }
        } catch (error) {
          handleSignInError(
            Object.assign(error, doc.data() || {}),
            'loginWhenUserIsCreatedInOurDatabase',
          )
        }
      }

      unsubscribe = onSnapshot(
        doc(firestore, 'users/' + user.uid),
        loginWhenUserIsCreatedInOurDatabase,
        (e) => console.warn(e),
      )
    } catch (e) {
      handleSignInError(e, 'verifySignedIn')
    }
  }

  // Handle user state changes
  async function onAuthStateChangedCallback(user: UserInfo): Promise<void> {
    if (!user) return dispatch({ type: 'LOGGED_OUT' })
    monitor.setupUser(user.uid, user.displayName, user.email)
    setAnalyticsUserId(user.uid)
    setAnalyticsUserProperties({ email: user.email, name: user.displayName })
    const userProfileStateStr = await localStorage.getItem(`@UserProfilesState`)
    if (!userProfileStateStr) return verifySignedIn(user)
    const userProfileState = JSON.parse(userProfileStateStr)
    if (user.email === userProfileState.email) {
      reduxDispatch(setProfileStoreFromStorage(userProfileState.profilesState))
      dispatch({ type: 'LOGGED_IN', user })
      return
    }
    return verifySignedIn(user)
  }

  useEffect(() => {
    const subscriber = onAuthStateChanged(auth, onAuthStateChangedCallback)
    return subscriber // unsubscribe on unmount
    // eslint-disable-next-line
  }, [])

  const authContext = React.useMemo(
    () => ({
      facebookSignIn: async (): Promise<void> => {
        try {
          const provider = new FacebookAuthProvider()
          dispatch({ type: 'SET_LOGIN_LOADING' })
          await signInWithPopup(auth, provider)
        } catch (e) {
          if (e.message === 'User cancelled the login process') {
            return dispatch({ type: 'LOGGED_OUT' })
          }
          handleSignInError(e, 'facebook')
        }
      },
      googleSignIn: async (): Promise<void> => {
        try {
          const provider = new GoogleAuthProvider()
          dispatch({ type: 'SET_LOGIN_LOADING' })
          await signInWithPopup(auth, provider)
        } catch (e) {
          if (
            (e.userInfo &&
              e.userInfo.NSLocalizedDescription ===
                'The user canceled the sign-in flow.') ||
            e.message === 'Sign in action cancelled' ||
            e.code === 'auth/popup-closed-by-user' ||
            e.message ===
              'The popup has been closed by the user before finalizing the operation.'
          ) {
            return dispatch({ type: 'LOGGED_OUT' })
          }
          handleSignInError(e, 'google')
        }
      },
      appleSignIn: async (): Promise<void> => {
        try {
          const provider = new OAuthProvider('apple.com')
          provider.addScope('email')
          provider.addScope('name')
          provider.setCustomParameters({
            locale: 'pt_BR',
          })
          dispatch({ type: 'SET_LOGIN_LOADING' })
          await signInWithPopup(auth, provider)
        } catch (e) {
          console.log(e)
          if (
            (e.userInfo &&
              e.userInfo.NSLocalizedDescription ===
                'The user canceled the sign-in flow.') ||
            e.message === 'Sign in action cancelled' ||
            e.code === 'auth/popup-closed-by-user' ||
            e.message ===
              'The popup has been closed by the user before finalizing the operation.'
          ) {
            return dispatch({ type: 'LOGGED_OUT' })
          }
          handleSignInError(e, 'apple')
        }
      },
      signOut: async (): Promise<void> => {
        signOut()
      },
      signUP: async (): Promise<void> => {
        dispatch({ type: 'SIGN_IN', token: 'dummy-auth-token' })
      },
    }),
    // eslint-disable-next-line
    [],
  )

  return (
    <AuthContext.Provider value={{ ...authContext, ...state }}>
      {props.children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider, AuthConsumer }
