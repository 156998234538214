import React, {
  ReactElement,
  SyntheticEvent,
  useCallback,
  useMemo,
  useState,
} from 'react'
import isHotkey from 'is-hotkey'
import {
  Editable,
  ReactEditor,
  RenderElementProps,
  RenderLeafProps,
  Slate,
  useSlate,
  withReact,
} from 'slate-react'
import {
  BaseEditor,
  createEditor,
  Descendant,
  Editor,
  Element as SlateElement,
  Transforms,
} from 'slate'
import { withHistory } from 'slate-history'
import {
  Code,
  FormatBold,
  FormatItalic,
  FormatListBulleted,
  FormatListNumbered,
  FormatQuote,
  FormatSize,
  FormatUnderlined,
} from '@material-ui/icons'
import { isEmpty } from 'ramda'
import { Button, Icon, Toolbar } from './components'
import * as Styled from './TextEditor.styled'
import { LinkButton, withLinks } from './Plugins/Link'
import {
  InsertImageButton,
  RenderImage,
  UploadFileResponse,
  withImages,
} from './Plugins/Image'
import { withHtml } from './Plugins/HTML'

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
}

const LIST_TYPES = ['numbered-list', 'bulleted-list']

type ToolbarOption =
  | 'bold'
  | 'italic'
  | 'underline'
  | 'code'
  | 'heading'
  | 'block-quote'
  | 'numbered-list'
  | 'bulleted-list'
  | 'hyperlink'
  | 'image'

export interface RichTextEditorProps {
  onChange?: (value?: string) => void
  initialValue?: string
  readonly?: boolean
  id?: string
  placeholder?: string
  handleBlur?: (e: React.FocusEvent<any>) => void
  toolbarOptions?: ToolbarOption[]
  uploadFile?: (file: File) => Promise<UploadFileResponse>
  onInsertImage?: (imageUrl: string) => void
  disabled?: boolean
  styleProperties?: {
    linkColor?: string
  }
  onKeyUp?: () => void
  darkMode?: boolean
  preview?: boolean
  numOfLines?: number
}

const RichTextEditor: React.FC<RichTextEditorProps> = ({
  onChange,
  initialValue,
  readonly,
  id,
  placeholder,
  handleBlur,
  toolbarOptions,
  uploadFile,
  onInsertImage,
  disabled,
  styleProperties,
  onKeyUp,
  darkMode,
  preview,
  numOfLines,
}) => {
  let parsedInitialValue = null as any
  try {
    parsedInitialValue = JSON.parse(initialValue)
  } catch (e) {
    console.error(e)
  }

  const [value, setValue] = useState<Descendant[]>(
    parsedInitialValue ? parsedInitialValue : initialValueDefault,
  )

  const renderElement = useCallback(
    (props) => (
      <Element
        {...props}
        styleProperties={styleProperties}
        numberOfLines={numOfLines ? numOfLines : preview ? 4 : undefined}
      />
    ),
    [],
  )
  const renderLeaf = useCallback(
    (props) => <Leaf {...props} />,
    [],
  )
  const editor = useMemo(
    () =>
      withHtml(
        withImages(
          withLinks(withReact(withHistory(createEditor() as ReactEditor))),
        ),
      ),
    [],
  )

  const getTextContent = (value: Descendant[]): string | undefined => {
    const hasContent = value.some((descendant: any) => {
      if (descendant.children) {
        return descendant.children.some((child: any) => !isEmpty(child.text))
      }

      return false
    })

    return hasContent ? JSON.stringify(value) : undefined
  }

  const getToolbarButtonComponent = (
    option: ToolbarOption,
  ): React.ReactElement => {
    switch (option) {
      case 'bold':
        return <MarkButton key="bold" format="bold" icon={<FormatBold />} />
      case 'italic':
        return (
          <MarkButton key="italic" format="italic" icon={<FormatItalic />} />
        )
      case 'underline':
        return (
          <MarkButton
            key="underline"
            format="underline"
            icon={<FormatUnderlined />}
          />
        )
      case 'code':
        return <MarkButton key="code" format="code" icon={<Code />} />
      case 'heading':
        return (
          <BlockButton
            key="heading"
            format="heading-two"
            icon={<FormatSize />}
          />
        )
      case 'block-quote':
        return (
          <BlockButton
            key="block-quote"
            format="block-quote"
            icon={<FormatQuote />}
          />
        )
      case 'numbered-list':
        return (
          <BlockButton
            key="numbered-list"
            format="numbered-list"
            icon={<FormatListNumbered />}
          />
        )
      case 'bulleted-list':
        return (
          <BlockButton
            key="bulleted-list"
            format="bulleted-list"
            icon={<FormatListBulleted />}
          />
        )
      case 'hyperlink':
        return <LinkButton key="hyperlink" reversed={!darkMode} />
      case 'image':
        return (
          <InsertImageButton
            key="image"
            uploadFile={uploadFile}
            onInsertImage={onInsertImage}
          />
        )
    }
  }

  return (
    <Styled.TextEditorWrapper id={id} darkMode={darkMode} preview={preview}>
      <Slate
        editor={editor}
        value={value}
        onChange={(value) => {
          onChange && onChange(getTextContent(value))
          setValue(value)
        }}
      >
        {!readonly && (
          <Toolbar>
            {toolbarOptions.map((option) => getToolbarButtonComponent(option))}
          </Toolbar>
        )}
        <Editable
          className="rich-text-editor"
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder={placeholder}
          readOnly={readonly}
          onBlur={handleBlur}
          spellCheck
          disabled={disabled}
          autoFocus
          onKeyDown={(event) => {
            for (const hotkey in HOTKEYS) {
              if (isHotkey(hotkey, event as any)) {
                event.preventDefault()
                // @ts-ignore
                const mark = HOTKEYS[hotkey]
                toggleMark(editor, mark)
              }
            }
          }}
          onKeyUp={() => {
            if (onKeyUp) {
              onKeyUp()
            }
          }}
        />
      </Slate>
    </Styled.TextEditorWrapper>
  )
}

const toggleBlock = (editor: BaseEditor, format: string) => {
  const isActive = isBlockActive(editor, format)
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      LIST_TYPES.includes(
        // @ts-ignore
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type,
      ),
    split: true,
  })
  const newProperties: Partial<SlateElement> = {
    // @ts-ignore
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  }
  Transforms.setNodes(editor, newProperties)
  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

const toggleMark = (editor: BaseEditor, format: string) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const isBlockActive = (editor: BaseEditor, format: string) => {
  // @ts-ignore
  const [match] = Editor.nodes(editor, {
    match: (n) =>
      // @ts-ignore
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
  })

  return !!match
}

const isMarkActive = (editor: BaseEditor, format: string) => {
  const marks = Editor.marks(editor)
  // @ts-ignore
  return marks ? marks[format] === true : false
}

const Element: React.FC<RenderElementProps> = (props: {
  attributes
  children
  element
  styleProperties
}) => {
  const { attributes, children, element, styleProperties } = props

  // @ts-ignore
  switch (element.type) {
    case 'image':
      return <RenderImage {...props} />
    case 'link':
      return (
        <Styled.Link
          {...attributes}
          color={styleProperties?.linkColor}
          href={element.url}
          target="_blank"
        >
          {children}
        </Styled.Link>
      )
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>
    case 'list-item':
      return <li {...attributes}>{children}</li>
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>
    default:
      return <p {...attributes}>{children}</p>
  }
}

interface LeafProps extends RenderLeafProps {
  fontFamily?: string
}

const Leaf: React.FC<LeafProps> = ({
  attributes,
  children,
  leaf,
  fontFamily,
}) => {
  // @ts-ignore
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  // @ts-ignore
  if (leaf.code) {
    children = <code>{children}</code>
  }

  // @ts-ignore
  if (leaf.italic) {
    children = <em>{children}</em>
  }

  // @ts-ignore
  if (leaf.underline) {
    children = <u>{children}</u>
  }

  return (
    <Styled.BaseSpan {...attributes}>
      {children}
    </Styled.BaseSpan>
  )
}

interface ButtonProps {
  format: string
  icon: ReactElement
}

const BlockButton: React.FC<ButtonProps> = ({ format, icon }) => {
  const editor = useSlate() as ReactEditor
  return (
    <Button
      active={isBlockActive(editor, format)}
      onMouseDown={(event: SyntheticEvent) => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  )
}

const MarkButton: React.FC<ButtonProps> = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={(event: SyntheticEvent) => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  )
}

export type EmptyText = {
  text: string
}

const initialValueDefault: any[] = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
]

export default RichTextEditor
