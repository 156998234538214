const parseDateToString = (date: Date): string => {
  const rawDay = date.getDate()
  const day = rawDay < 10 ? `0${rawDay}` : rawDay

  const rawMonth = date.getMonth() + 1
  const month = rawMonth < 10 ? `0${rawMonth}` : rawMonth

  const year = date.getFullYear()

  return `${day}/${month}/${year}`
}

export const parseDateToStringWithTime = (date: Date): string => {
  const day = parseDateToString(date)

  const hour = date.getHours()
  const minutes = date.getMinutes()
  const seconds = date.getSeconds()

  return `${day}-${hour}:${minutes}:${seconds}`
}

export default parseDateToString
