import React, {Fragment, useState} from 'react'
import {FormHelperText} from '@material-ui/core'
import * as Styled from './EditPodcastInfo.styled'
import {uploadPodcastImage} from '../../services/api'
import Button from '../../components/Button/Button'
import {ApiFetch} from '../../services/ApiFetch'
import styled from "styled-components";
import {Colors} from "../../styles";

interface UploadImageButtonProps {
  podcastId?: string
  onUploadImage?: (imageUrl: string) => void
  disabled?: boolean
  imageCallback?: (file: File) => void
  style?: {
    margin?: string
  }
}

const UploadImageButtonWrapper = styled.div<{ margin?: string }>`
  margin: ${({margin}) => margin}
`

const UploadImageButton: React.FC<UploadImageButtonProps> = ({podcastId, onUploadImage, disabled, imageCallback, style}) => {
  const clickInputButton = () => {
    document.getElementById('inputImageButton').click()
  }

  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>()

  const uploadImage = async (file: File): Promise<ApiFetch> => {
    return await uploadPodcastImage(podcastId, file, 'image')
  }

  return (
    <UploadImageButtonWrapper margin={style?.margin}>
      <Button
        onClick={() => {
          if (!loading) clickInputButton()
        }}
        variant="contained"
        size="large"
        fontSize="large"
        buttonColorOnHover={Colors.TEXT_DARK}
        buttonColor={Colors.BRAND_PRIMARY}
        fontColor={Colors.TEXT_LIGHTEN}
        disabled={disabled}
      >
        ATUALIZAR IMAGEM {loading && <Styled.LoadingImageUpload size={24}/>}
        <Styled.ImageInput
          id="inputImageButton"
          type="file"
          onChange={async (e) => {
            setErrorMessage(null)
            if (!e.target.files) return
            const file = e.target.files.item(0)
            if (!file) return
            if (file.size > 150000) {
              setErrorMessage('Imagem muito grande. Tamanho máximo 150KB')
              return
            }

            setLoading(true)
            if (podcastId) {
              uploadImage(file).then((res) => {
                if (res.error) {
                  setErrorMessage('Falha ao fazer upload da imagem')
                } else {
                  onUploadImage(`${res.data}?lastmod=${Date.now()}`)

                  if (imageCallback) {
                    imageCallback(file)
                  }
                }
              })
            }

            setLoading(false)
          }}
        />
      </Button>
      {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
    </UploadImageButtonWrapper>
  )
}

export default UploadImageButton
