import styled from 'styled-components'
import { Chip } from '@material-ui/core'
import { Colors } from 'styles'
import { LETTER_SPACING_1 } from 'styles/typography'
import {ReactComponent as Padlock} from "../../assets/icons/padlock_fill.svg";

export const ArticleCardContainer = styled.div``

export const ArticleCard = styled.div<{ isOdd?: boolean, clickable?: boolean }>`
  display: flex;
  width: 100%; 
  align-items: center;
  background-color: ${({ isOdd }) => isOdd ? Colors.GREY[50] : Colors.WHITE};
  padding: 8px 0px;
  ${(props) => props.clickable && `cursor: pointer;`};
`

export const InfosTitleAndDescriptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const InfoAndTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`


export const AdditionalInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

export const Description = styled.div<{ hasMinHeight?: boolean }>`
  justify-content: flex-start;
  white-space: pre-line;
  ${(props) => props.hasMinHeight && `min-height: 84px;`};

  @media (max-width: 600px) {
    font-size: 10px;
    ${(props) => props.hasMinHeight && `min-height: 40px;`}
  }
`

export const ArticleCover = styled.div<{ lowOpacity?: boolean; image: string }>`
  width: 48px;
  height: 48px;
  border-radius: 10px;
  position: absolute;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  opacity: ${(props) => (props.lowOpacity ? '0.8' : '1')};

  @media (max-width: 900px) {
    display: none;
  }
`

export const Icon = styled.img`
  width: 80px;
  height: 80px;
  object-fit: scale-down;
  z-index: 1;

  @media (max-width: 900px) {
    width: 40px;
    height: 40px;
  }
`

export const CoverAndIconWrapper = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 900px) {
    width: 40px;
    height: 40px;
    diplay: none;
  }
`

export const IconButtonWrapper = styled.div`
  margin-left: 5px;
`

export const IconButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  color: white;
`

export const StyledChip = styled(Chip)`
  &.MuiChip-root {
    background-color: #d34242;
    border-color: #d34242;
    color: #fff;
  }
`
export const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%
`

export const ArticleTitle = styled.span`
  font-family: SpaceGrotesk;
  max-width: 75%;
  margin-left: 16px;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: -${LETTER_SPACING_1}px;
  display: flex;
  align-items: center;
`

export const AdditionalInfo = styled.span`
  font-family: Inter;
  width: 20%;
  font-size: 14px;
  font-weight: 200;
  letter-spacing: -${LETTER_SPACING_1}px;

  @media (max-width: 300px) {
    display: none;
  }
`

export const AdditionalInfoRight = styled.span<{ variant?: string }>`
  font-family: Inter;
  margin-left: 16px;
  font-size: 12px;
  font-weight: 200;
  letter-spacing: -${LETTER_SPACING_1}px;
  ${({ variant }) => variant && variant === 'alert' && `color: ${Colors.RED}`}
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const PadlockWithMargin = styled(Padlock)`
  margin-left: 8px;
`
