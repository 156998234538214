import React from 'react'

import * as Styled from './MetricsCard.styled'

interface MetricsCardProps {
  title: string
  value: string
  secondaryValue?: string
  subtitle: string
  secondarySubtitle?: string
  currency?: boolean
}

const MetricsCard: React.FC<MetricsCardProps> = ({
  title,
  value,
  secondaryValue,
  subtitle,
  secondarySubtitle,
  currency,
}) => {

  const formattedValue = currency ? `${parseInt(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}` : value
  return (
    <Styled.MetricsCard variant="elevation">
      <Styled.CardTitle>{title}</Styled.CardTitle>
      <Styled.ValuesWrapper>
        <Styled.Value>{formattedValue}</Styled.Value>
        {secondaryValue && (
          <Styled.SecondaryValue>{secondaryValue}</Styled.SecondaryValue>
        )}
      </Styled.ValuesWrapper>
      <Styled.SubtitlesWrapper>
        <Styled.Subtitle>{subtitle}</Styled.Subtitle>
        {secondarySubtitle && <Styled.SecondarySubtitle>{secondarySubtitle}</Styled.SecondarySubtitle>}
      </Styled.SubtitlesWrapper>
    </Styled.MetricsCard>
  )
}

export default MetricsCard
