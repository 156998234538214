import React from 'react'
import * as Styled from './CreatePage.styled'
import createPage from '../../../../assets/images/home/createPage.png'
import StyledButton from '../Button/Button'
import sticker_5 from '../../../../assets/images/home/sticker_orelo_.png'
import { getRegisterCreatorIntroPath } from '../../../../Routes/RouteNames'
import StyledNavLink from '../../../../components/StyledNavLink'

const CreatePage: React.FC<{}> = () => {
  return (
    <Styled.SectionWrapper>
      <Styled.TextWrapper>
        <Styled.Title> Uma página para chamar de sua</Styled.Title>
        <Styled.Description>
          e um lugar para publicar seus podcasts, seus textos e newsletters, e
          fazer a gestão de todos esses conteúdos, apoiadores e $
        </Styled.Description>
        <StyledNavLink to={getRegisterCreatorIntroPath()}>
          <StyledButton fontSize="small" labelColor="black" buttonColor="white">
            Comece seu projeto
          </StyledButton>
        </StyledNavLink>
      </Styled.TextWrapper>
      <Styled.ImageWrapper>
        <Styled.StickerSeparatorSvg />
        <Styled.StickerSeparatorLogoDark src={sticker_5} />
        <Styled.Image
          src={createPage}
          alt="Quadro com a sombra de duas pessoas se olhando, com fundo colorido e algumas folhas grandes ao redor."
        />
      </Styled.ImageWrapper>
    </Styled.SectionWrapper>
  )
}

export default CreatePage
