import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { userRequestAccountDelete } from '../../services/api'
import * as Styled from './DeleteUserAccount.styled'
import { useTypedSelector } from '../../reducers'
import { changeProfile, ProfileType } from '../../reducers/profiles'
import BasePage from '../../components/BasePage/BasePage'
import Dialog from '../../components/Dialog/Dialog'

interface DeleteUserAccountProps {
  signOut: () => Promise<void>
}

const DeleteUserAccount: React.FC<DeleteUserAccountProps> = ({ signOut }) => {
  const dispatch = useDispatch()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const currentProfile = useTypedSelector<ProfileType>(
    (state) => state && state.profiles && state.profiles.currentProfile,
  )

  const availableProfiles = useTypedSelector<ProfileType[]>(
    (state) => state && state.profiles && state.profiles.availableProfiles,
  )

  useEffect(() => {
    if (currentProfile.profile !== 'listener') {
      const listenerProfile = availableProfiles.find((profile) => {
        return profile.profile === 'listener'
      })

      dispatch(changeProfile(listenerProfile))
    }
  }, [currentProfile, availableProfiles, dispatch])

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  return (
    <BasePage signOut={signOut}>
      <Dialog
        isDialogOpen={isModalOpen}
        handleCloseDialog={handleCloseModal}
        dialogText="Tem certeza que deseja deletar a sua conta?"
        secondaryDialogText="Essa ação não pode ser desfeita  "
        dialogActionButtonText="Apagar"
        onClickDialogActionButton={async () => {
          await userRequestAccountDelete()
          handleCloseModal()
        }}
        noActionText="Cancelar"
      />
      <Styled.MessageWrapper>
        <Styled.Title>
          Exclusão de Conta
        </Styled.Title>
        <Styled.Text>
          Para solicitar a exclusão da sua conta e não ter mais acesso aos
          conteúdos e apoios, clique no botão abaixo e confirme a sua opção.
        </Styled.Text>
        <Styled.Text>
          Ao avançar, lembre-se que os seus dados compartilhados com a gente (nome,
          e-mail e imagem de perfil) serão excluídos, conforme o previsto nos
          Termos de Uso da plataforma.
        </Styled.Text>
      </Styled.MessageWrapper>
      <Styled.DeleteAccountButton
        variant="contained"
        fontColor="black"
        onClick={async () => {
          setIsModalOpen(true)
        }}
      >
        Deletar conta
      </Styled.DeleteAccountButton>
    </BasePage>
  )
}

export default DeleteUserAccount
