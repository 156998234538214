import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { Config } from '../settings/config'
// import DeviceInfo from 'react-native-device-info'
// console.log(Config)
// console.log(process.env)
Bugsnag.start({
  apiKey: Config.BUGSNAG_API_KEY || '',
  plugins: [new BugsnagPluginReact()],
  releaseStage: Config.WEB_ENV,
  // notifyReleaseStages: ['production', 'staging'],
})

export const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(
  React,
)

// const crashlyticsSetup = async (
//   user: FirebaseAuthTypes.User,
// ): Promise<void> => {
//   // crashlytics().log('User signed in.')
//   const attributes = {
//     firebaseId: user.uid,
//     appName: DeviceInfo.getApplicationName(),
//     deviceBrand: DeviceInfo.getBrand(),
//     buildNumber: DeviceInfo.getBuildNumber(),
//     bundleId: DeviceInfo.getBundleId(),
//     codename: DeviceInfo.getCodenameSync(),
//     device: DeviceInfo.getDeviceSync(),
//     deviceId: DeviceInfo.getDeviceId(),
//     firstInstallTime: DeviceInfo.getFirstInstallTimeSync().toString(),
//     fingerprint: DeviceInfo.getFingerprintSync(),
//     appStartupFreeDiskStorageBytes: DeviceInfo.getFreeDiskStorageSync().toString(),
//     installerPackageName: DeviceInfo.getInstallerPackageNameSync(),
//     lastUpdatedTime: DeviceInfo.getLastUpdateTimeSync().toString(),
//     vmMaxMemory: DeviceInfo.getMaxMemorySync().toString(),
//     deviceModel: DeviceInfo.getModel(),
//     systemVersion: DeviceInfo.getSystemVersion(),
//     buildTags: DeviceInfo.getTagsSync(),
//     initialAppUsedMemory: DeviceInfo.getUsedMemorySync().toString(),
//     version: DeviceInfo.getVersion(),
//   }
//   console.log(attributes)
//   // await Promise.all([
//   //   crashlytics().setUserName(user.displayName || 'N/A'),
//   //   crashlytics().setUserEmail(user.email || 'N/A'),
//   //   crashlytics().setAttributes(attributes),
//   // ])
// }

export const setupUser = (
  id: string,
  name: string | null,
  email?: string | null,
): void => {
  Bugsnag.setUser(id, name || 'n/a', email || 'n/a')
}

export const notify = (
  error: Error | string | object | any,
  extraInfo?: string | { [key: string]: any },
): void => {
  const bugsnagError =
    error instanceof Error
      ? error
      : typeof error === 'string'
      ? new Error(error)
      : Object.assign(new Error(), error)
  if (!extraInfo) Bugsnag.notify(bugsnagError)
  else {
    // console.log('notify extraInfo:', extraInfo)
    Bugsnag.notify(bugsnagError, function (event) {
      event.addMetadata(
        'extraInfo',
        typeof extraInfo === 'object'
          ? { extraInfo }
          : { extraInfo: { extraInfo } },
      )
    })
  }
  // console.log('notify error:', error)
  // console.log('notify error.code:', error.code)
  // console.log('notify error.message:', error.message)
  // console.log('notify error.userInfo:', error.userInfo)
}

export const log = (name: string, msg: string): void => {
  // console.log(msg)
  Bugsnag.leaveBreadcrumb(name, { type: 'log', message: msg })
}
