import { boxShadow } from './mixins'

// BORDER WIDTHS
export const BORDER_WIDTH_NONE = 0
export const BORDER_WIDTH_THIN = 1
export const BORDER_WIDTH_MEDIUM = 2
export const BORDER_WIDTH_THICK = 4

// BORDER RADIUS
export const BORDER_RADIUS_NONE = 0
export const BORDER_RADIUS_XS = 4
export const BORDER_RADIUS_SM = 8
export const BORDER_RADIUS_MD = 16

// SHADOWS
export const SHADOW_LEVEL_1 = boxShadow(
  'grey',
  { height: 4, width: 4 },
  8,
  0.08,
)

export const SHADOW_LEVEL_2 = boxShadow(
  'grey',
  { height: 8, width: 8 },
  16,
  0.08,
)

export const SHADOW_LEVEL_3 = boxShadow(
  'grey',
  { height: 16, width: 16 },
  32,
  0.08,
)

export const SHADOW_LEVEL_4 = boxShadow(
  'grey',
  { height: 32, width: 32 },
  64,
  0.08,
)

// SHAPES
export const circle = (radius: number): object => ({
  width: radius,
  height: radius,
  borderRadius: radius / 2,
})

export const pill = (width: number, height: number): object => ({
  width: width,
  height: height,
  borderRadius: height / 2,
})
