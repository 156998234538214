import React from 'react'
import { CardProps as MUICardProps } from '@material-ui/core'
import { RoundedStyled } from './BlackRounedCard.styled'

interface CardProps extends MUICardProps {
  flex?: number;
  isLight?: boolean;
}

const RoundedCard: React.FC<CardProps> = ({ children, ...rest }) => {
  return <RoundedStyled {...rest} >{children}</RoundedStyled>
}

export default RoundedCard
