import styled from 'styled-components'
import { Form } from 'formik'
import {Typography} from "../../styles";

export const FlexForm = styled(Form)`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 32px;
`

export const SupportText = styled.p`
  font-size: 26px;
  font-family: SpaceGrotesk;
  font-weight: 600;
  letter-spacing: -${Typography.LETTER_SPACING_1}px;
`
