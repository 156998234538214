import React from 'react'

import { Dialog as MuiDialog, DialogActions } from '@material-ui/core'

import Button from '../Button/Button'

import * as Styled from './Dialog.styled'

export interface DialogProps {
  isDialogOpen: boolean
  handleCloseDialog: () => void
  dialogText: string
  secondaryDialogText?: string
  dialogActionButtonText?: string
  onClickDialogActionButton?: () => void
  noActionText: string
}

const Dialog: React.FC<DialogProps> = ({
  isDialogOpen,
  handleCloseDialog,
  dialogText,
  secondaryDialogText,
  dialogActionButtonText,
  onClickDialogActionButton,
  noActionText,
}) => {
  return (
    <MuiDialog open={isDialogOpen} onClose={handleCloseDialog}>
      <Styled.DialogTextWrapper>
        <Styled.DialogText disableTypography>{dialogText}</Styled.DialogText>
        {secondaryDialogText && (
          <Styled.DialogText disableTypography>
            {secondaryDialogText}
          </Styled.DialogText>
        )}
      </Styled.DialogTextWrapper>
      <DialogActions>
        <Button onClick={handleCloseDialog} fontColor="black">
          {noActionText}
        </Button>
        {!!dialogActionButtonText && !!onClickDialogActionButton && (
          <Button
            onClick={onClickDialogActionButton}
            fontColor="white"
            buttonColor="#cc0033"
            buttonColorOnHover="#940a00"
          >
            {dialogActionButtonText}
          </Button>
        )}
      </DialogActions>
    </MuiDialog>
  )
}

export default Dialog
