import React, { useState } from 'react'
import DropdownMenu from 'components/DropdownMenu/DropdownMenu'
import ReportContentDialog from 'components/ReportContentDialog/ReportContentDialog'
import { Container } from './OptionsButton.styled'
import ShareButton from 'components/ShareButton/ShareButton'
import { Colors } from '../../styles'

interface OptionsButtonProps {
  podcastId?: string
  episodeId?: string
  articleId?: string
  topicId?: string
}

const OptionsButton = ({
  podcastId,
  episodeId,
  articleId,
  topicId,
}: OptionsButtonProps) => {
  const [reportDialogOpen, setReportDialogOpen] = useState(false)

  const iframeCodeGenerator = (url: string) =>
    `<iframe
    src="${url}?background=white"
    width="100%"
    height="400"
    frameborder="none"
    scrolling="no"
  ></iframe>`

  const handleOpenReporter = () => {
    setReportDialogOpen((prevState) => !prevState)
  }

  const handleCopyLink = async (link: string, message: string) => {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(`${link}`)
    } else {
      document.execCommand('copy', true, `${link}`)
    }
    alert(message)
  }

  const handleShareLink = () => { }

  const handleIframeCode = () => {
    const url =
      process.env.REACT_APP_WEB_ENV === 'local'
        ? `http://localhost:3001/embed/${episodeId}`
        : `https://orelo.cc/embed/${episodeId}`

    const iframeCode = iframeCodeGenerator(url)

    handleCopyLink(iframeCode, 'Código embed, copiado com sucesso!')
  }

  const items = () => {
    const list = [
      {
        title: 'Denunciar',
        onClick: handleOpenReporter,
      },
    ] as any

    if (articleId) {
      list.push({
        children: (
          <ShareButton
            text
            showAlert
            shareType="article"
            linkTitle="Compartilhar"
            shareId={articleId}
            linkImage=""
            linkDescription="Compartilhar"
            iconButton={false}
            redirectToMobileApp={false}
          />
        ),
        onClick: handleShareLink,
      })
    }

    if (topicId) {
      list.push({
        children: (
          <ShareButton
            text
            showAlert
            shareType="topic"
            linkTitle="Compartilhar"
            shareId={topicId}
            linkImage=""
            linkDescription="Compartilhar"
            iconButton={false}
            redirectToMobileApp={false}
          />
        ),
        onClick: handleShareLink,
      })
    }

    if (podcastId && !episodeId) {
      list.push({
        children: (
          <ShareButton
            text
            showAlert
            shareType="podcast"
            linkTitle="Compartilhar"
            shareId={podcastId}
            linkImage=""
            linkDescription="Compartilhar"
            iconButton={false}
            redirectToMobileApp={false}
          />
        ),
        onClick: handleShareLink,
      })
    }

    if (episodeId) {
      list.push({
        title: 'Episodio embed',
        onClick: handleIframeCode,
      })

      list.push({
        children: (
          <ShareButton
            showAlert
            text
            shareType="episode"
            linkTitle="Compartilhar episodio"
            shareId={episodeId}
            linkImage=""
            linkDescription="Compartilhar episodio"
            iconButton={false}
            redirectToMobileApp={false}
          />
        ),
        onClick: handleShareLink,
      })
    }

    return list
  }

  return (
    <div className="options-button-container">
      <Container>
        <DropdownMenu items={items()} color={Colors.BRAND_PRIMARY} />
      </Container>
      <ReportContentDialog
        contentType="podcast"
        contentId={podcastId}
        isDialogOpen={reportDialogOpen}
        handleCloseDialog={() => handleOpenReporter()}
      />
    </div>
  )
}

export default OptionsButton
