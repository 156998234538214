import styled from 'styled-components'
import { Typography } from 'styles'
import * as Colors from './../../styles/colors'

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  text-align: center;
  color: ${Colors.TEXT_LIGHT};
`

export const StyledLink = styled.a`
  text-decoration: none;
  
  &:focus,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
  
  margin: 12px 0;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const BodyText = styled.div`
  font-size: 18px;
  font-family: SpaceGrotesk;
  letter-spacing: -${Typography.LETTER_SPACING_1}px;
  color: ${Colors.GREY[400]};
`

export const BodyTextWithMargin = styled(BodyText)`
  margin-bottom: 16px;
`
