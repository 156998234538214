import React from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { changeProfile, ProfileType } from '../../reducers/profiles'
import {
  createPathWithQueryParam,
  usePathName,
  useGoToRoute,
} from '../../Routes/RouteAux'
import {
  getCreatorDashBoardPath,
  getHomePath,
  getLoginPath,
  getPodcastPath,
  getPodcastUrlPathName,
} from '../../Routes/RouteNames'
import Header from './Header'
import { useTypedSelector } from '../../reducers'

interface HeaderProps {
  signOut: () => Promise<void>
  hideSearchBar?: boolean
  isDark?: boolean
}

const HeaderWrapper: React.FC<HeaderProps> = ({ signOut, hideSearchBar, isDark }) => {
  const { podcastId } = useParams<{ podcastId: string }>()
  const { podcastUrlPathName } = useParams<{ podcastUrlPathName: string }>()

  const dispatch = useDispatch()
  const goToRoute = useGoToRoute()

  const currentProfile = useTypedSelector<ProfileType>(
    (state) => state && state.profiles && state.profiles.currentProfile,
  )

  const availableProfiles = useTypedSelector<ProfileType[]>(
    (state) => state && state.profiles && state.profiles.availableProfiles,
  )

  const availableProfilesWithoutCurrentProfile: ProfileType[] =
    availableProfiles.filter(
      (profile) =>
        profile.profile !== currentProfile.profile &&
        profile.id !== currentProfile.id,
    )

  const pathName = usePathName()
  const loginRoute = createPathWithQueryParam(getLoginPath(), {
    redirectTo: pathName,
  })

  const onSignOut = async () => {
    if (podcastId) {
      goToRoute(getPodcastPath(podcastId))
    } else if (podcastUrlPathName) {
      goToRoute(getPodcastUrlPathName(podcastUrlPathName))
    } else {
      goToRoute(getHomePath())
    }
    await signOut()
  }

  const changeTo = (profile: ProfileType) => {
    dispatch(changeProfile(profile))
    if (profile.profile === 'podcast') {
      goToRoute(getCreatorDashBoardPath(profile.id))
    } else {
      if (podcastId) {
        goToRoute(getPodcastPath(podcastId))
      } else if (podcastUrlPathName) {
        goToRoute(getPodcastUrlPathName(podcastUrlPathName))
      } else {
        goToRoute(getHomePath())
      }
    }
  }

  return (
    <Header
      isDark={isDark}
      currentProfile={
        currentProfile
          ? {
              name: currentProfile.name,
              image: currentProfile.avatar,
              type: currentProfile.profile,
              id: currentProfile.id,
            }
          : null
      }
      otherProfiles={
        availableProfilesWithoutCurrentProfile
          ? availableProfilesWithoutCurrentProfile.map((profile) => ({
              name: profile.name,
              image: profile.avatar,
              type: profile.profile,
              id: profile.id,
              action: () => {
                changeTo(profile)
              },
            }))
          : null
      }
      loginRoute={loginRoute.toString()}
      signOut={onSignOut}
      hideSearchBar={hideSearchBar}
    />
  )
}

export default HeaderWrapper
