import React, { useEffect, useState } from 'react'
import * as Styled from './Styled'
import TextFieldField from '../Fields/TextFieldField/TextFieldField'
import ButtonShadow from '../ButtonShadow/ButtonShadow'
import { Formik } from 'formik'
import * as Yup from 'yup'
import NumberFormatField from '../Fields/NumberFormatField/NumberFormatField'
import SelectField from '../Fields/SelectField/SelectField'
import { MenuItem } from '@material-ui/core'
import { updateUser } from '../../services/api'
import { refreshAvailableProfiles } from '../../reducers/profiles'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../reducers'

interface Props {
  onSubmitAddress: () => void
}

const createAddressValidationSchema = Yup.object().shape({
  phone: Yup.string()
    .required('Campo obrigatório')
    .min(10, 'Número inválido')
    .max(11, 'Número inválido'),
  zipCode: Yup.string()
    .required('Campo obrigatório')
    .min(8, 'Campo obrigatório')
    .max(8, 'Campo obrigatório'),
  streetAddress: Yup.string()
    .required('Campo obrigatório')
    .min(1, 'Campo obrigatório')
    .max(255),
  number: Yup.string()
    .required('Campo obrigatório')
    .min(1, 'Campo obrigatório')
    .max(255),
  complement: Yup.string().nullable(),
  state: Yup.string()
    .required('Campo obrigatório')
    .min(1, 'Campo obrigatório')
    .max(255),
  city: Yup.string()
    .required('Campo obrigatório')
    .min(1, 'Campo obrigatório')
    .max(255),
})

interface FormValues {
  phone: string
  zipCode: string
  streetAddress: string
  number: string
  complement: string
  state: string
  city: string
}

const Address = ({ onSubmitAddress }: Props): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false)
  const reduxDispatch = useDispatch()

  const profile = useTypedSelector(
    (state) => state.profiles && state.profiles.currentProfile,
  )

  useEffect(() => {
    reduxDispatch(refreshAvailableProfiles())
  }, [])

  const submitForm = async (formValues: FormValues): Promise<void> => {
    setIsLoading(true)

    const body = {
      name: profile.name,
      phone: formValues.phone,
      address: {
        streetAddress: formValues.streetAddress,
        number: formValues.number,
        complement: formValues.complement,
        city: formValues.city,
        country: 'BR', // formValues.country,
        state: formValues.state,
        zipCode: formValues.zipCode,
      },
    }

    const res = await updateUser(body)
    if (res.error) {
      alert(
        'Ocorreu um erro inesperado. Tente novamente ou entre em contato com o suporte',
      )
    } else {
      await reduxDispatch(refreshAvailableProfiles())
      onSubmitAddress()
    }

    setIsLoading(false)
  }

  return (
    <div>
      <Styled.SupportText>Dados de cobrança</Styled.SupportText>
      <Formik
        initialValues={{
          phone: profile?.phone || '',
          streetAddress: profile?.streetAddress || '',
          number: profile?.number || '',
          complement: profile?.complement || '',
          city: profile?.city || '',
          // country: profile?.country || '',
          state: profile?.state || '',
          zipCode: profile?.zipCode || '',
        }}
        onSubmit={(values) => submitForm(values)}
        validationSchema={createAddressValidationSchema}
        enableReinitialize
      >
        {({ handleSubmit, errors, touched, values }) => {
          return (
            <Styled.FlexForm>
              <NumberFormatField
                name={'phone'}
                variant="outlined"
                label={'Telefone'}
                fullWidth
                format={'(##) #####-####'}
                mask={'_'}
                value={values.phone}
                error={!!(touched.phone && errors?.phone)}
                helperText={touched.phone && (errors?.phone as string)}
                hasEmptyValue
                style={{ fontSize: 16, fontWeight: 500 }}
              />
              <NumberFormatField
                name={'zipCode'}
                variant="outlined"
                label={'Insira seu CEP'}
                fullWidth
                format={'#####-###'}
                mask={'_'}
                value={values.zipCode}
                error={!!(touched.zipCode && errors?.zipCode)}
                helperText={touched.zipCode && (errors?.zipCode as string)}
                hasEmptyValue
                style={{ fontSize: 16, fontWeight: 500 }}
              />
              <TextFieldField
                label="Endereço"
                placeholder="Endereço"
                name="streetAddress"
                variant="outlined"
                error={!!(touched.streetAddress && errors?.streetAddress)}
                helperText={touched.streetAddress && errors?.streetAddress}
                fullWidth
                value={values.streetAddress}
                darkMode
                style={{ fontSize: 16, fontWeight: 500 }}
              />
              <TextFieldField
                label="Número"
                placeholder="Número"
                name="number"
                variant="outlined"
                error={!!(touched.number && errors?.number)}
                helperText={touched.number && errors?.number}
                fullWidth
                value={values.number}
                darkMode
                style={{ fontSize: 16, fontWeight: 500 }}
              />
              <TextFieldField
                label="Complemento"
                placeholder="Complemento"
                name="complement"
                variant="outlined"
                error={!!(touched.complement && errors?.complement)}
                helperText={touched.complement && errors?.complement}
                fullWidth
                value={values.complement}
                darkMode
                style={{ fontSize: 16, fontWeight: 500 }}
              />
              <div>
                <SelectField
                  name="state"
                  label="Estado"
                  placeholder="Estado"
                  variant="outlined"
                  displayEmpty
                  error={!!(touched.state && errors?.state)}
                  helperText={touched.state && (errors?.state as string)}
                  value={values.state}
                  darkMode
                  fullWidth
                  style={{ fontSize: 16, fontWeight: 500 }}
                >
                  <MenuItem value="AC">Acre</MenuItem>
                  <MenuItem value="AL">Alagoas</MenuItem>
                  <MenuItem value="AP">Amapá</MenuItem>
                  <MenuItem value="AM">Amazonas</MenuItem>
                  <MenuItem value="BA">Bahia</MenuItem>
                  <MenuItem value="CE">Ceará</MenuItem>
                  <MenuItem value="DF">Distrito Federal</MenuItem>
                  <MenuItem value="ES">Espírito Santo</MenuItem>
                  <MenuItem value="GO">Goiás</MenuItem>
                  <MenuItem value="MA">Maranhão</MenuItem>
                  <MenuItem value="MT">Mato Grosso</MenuItem>
                  <MenuItem value="MS">Mato Grosso do Sul</MenuItem>
                  <MenuItem value="MG">Minas Gerais</MenuItem>
                  <MenuItem value="PA">Pará</MenuItem>
                  <MenuItem value="PB">Paraíba</MenuItem>
                  <MenuItem value="PR">Paraná</MenuItem>
                  <MenuItem value="PE">Pernambuco</MenuItem>
                  <MenuItem value="PI">Piauí</MenuItem>
                  <MenuItem value="RJ">Rio de Janeiro</MenuItem>
                  <MenuItem value="RN">Rio Grande do Norte</MenuItem>
                  <MenuItem value="RS">Rio Grande do Sul</MenuItem>
                  <MenuItem value="RO">Rondônia</MenuItem>
                  <MenuItem value="RR">Roraima</MenuItem>
                  <MenuItem value="SC">Santa Catarina</MenuItem>
                  <MenuItem value="SP">São Paulo</MenuItem>
                  <MenuItem value="SE">Sergipe</MenuItem>
                  <MenuItem value="TO">Tocantins</MenuItem>
                </SelectField>
              </div>
              <TextFieldField
                label="Cidade"
                placeholder="Cidade"
                name="city"
                variant="outlined"
                error={!!(touched.city && errors?.city)}
                helperText={touched.city && errors?.city}
                fullWidth
                value={values.city}
                darkMode
                style={{ fontSize: 16, fontWeight: 500 }}
              />
              <ButtonShadow
                onPress={() => {
                  if (!isLoading) handleSubmit()
                }}
                label="Salvar"
                disabled={isLoading}
              />
            </Styled.FlexForm>
          )
        }}
      </Formik>
    </div>
  )
}

export default Address
