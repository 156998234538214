import { Colors } from './../../styles'
import styled from 'styled-components'
import { Form } from 'formik'
import { Avatar, CircularProgress } from '@material-ui/core'
import { ReactComponent as HelpIconSvg } from '../../assets/icons/help.svg'

export const FlexForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: ${Colors.TEXT_LIGHT};
  width: 100%;

  @media (max-width: 600px) {
    width: 90%;
  }

  .MuiFormControl-root {
    border: 2px solid ${Colors.TEXT_LIGHT};
    border-radius: 12px;

    input,
    textarea,
    select {
      color: ${Colors.TEXT_LIGHT};
    }
  }

  .MuiFormLabel-root {
    color: ${Colors.TEXT_LIGHT};

    .fieldset {
      display: none;
    }
  }
`

export const LoadingCircle = styled(CircularProgress)`
  margin-left: 12px;
`

export const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: center;

  .MuiButton-root {
    color: ${Colors.TEXT_DARK};
  }
`

export const InputWrapper = styled.div.attrs(
  (props: { shortWidth?: boolean }) => props,
)`
  display: flex;
  width: ${(props) => (props.shortWidth ? '60%' : '100%')};
  @media (max-width: 600px) {
    width: 100%;
  }
`

export const InputWrapperColumn = styled.div.attrs(
  (props: { shortWidth?: boolean }) => props,
)`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.shortWidth ? '60%' : '100%')};
  @media (max-width: 600px) {
    width: 100%;
  }
`

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const LastAutoSave = styled.div`
  margin: 13px 22px;
  color: #8f8f8f;
  font-size: 0.7rem;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 8px;
`

export const Column = styled.div<{
  flex?: number
  justify?: string
  align?: string
}>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ justify }) => (justify ? justify : 'inherit')};
  flex: ${({ flex }) => (flex ? flex : 'inherit')};
  align-items: ${({ align }) => (align ? align : 'inherit')};
`

export const InfoCircledIcon = styled(HelpIconSvg)`
  align-self: center;
`

export const EpisodeCover = styled.img`
  width: 112px;
  height: 112px;
  border-radius: 10px;
`
