import React from 'react'
import * as Styled from './CreatorHighlight.styled'
import StyledButton from '../Button/Button'
import sticker_mic from '../../../../assets/images/home/sticker_mic.png'
import sticker_artist from '../../../../assets/images/home/artista_casa_sticker.png'
import { getPodcastPath } from '../../../../Routes/RouteNames'
import StyledNavLink from '../../../../components/StyledNavLink'

interface CreatorHighlightProps {
  bgColor: string
  fontColor: string
  image: string
  title: string
  description: string | React.ReactElement
  podcastId: string
  stickers?: string[]
}

const CreatorHighlight: React.FC<CreatorHighlightProps> = ({
  bgColor,
  fontColor,
  image,
  title,
  description,
  stickers,
  podcastId,
}) => {
  return (
    <Styled.SectionWrapper bgColor={bgColor}>
      <Styled.ImageWrapper>
        <StyledNavLink to={getPodcastPath(podcastId)}>
          <Styled.MainImg src={image} />
        </StyledNavLink>
        {stickers &&
          stickers.map((sticker) => {
            if (sticker === 'homeGrown') {
              return (
                <Styled.PrimarySticker src={sticker_artist} key={sticker} />
              )
            }
            if (sticker === 'micSticker') {
              return <Styled.SecondarySticker src={sticker_mic} key={sticker} />
            }
          })}
      </Styled.ImageWrapper>
      <Styled.TextWrapper>
        <Styled.Title fontColor={fontColor}>{title}</Styled.Title>
        <Styled.Description
          fontColor={fontColor}
          style={{ fontFamily: 'Inter' }}
        >
          {description}
        </Styled.Description>
        <StyledNavLink to={getPodcastPath(podcastId)}>
          <StyledButton fontSize="small" labelColor="black" buttonColor="white">
            Conheça
          </StyledButton>
        </StyledNavLink>
      </Styled.TextWrapper>
    </Styled.SectionWrapper>
  )
}

export default CreatorHighlight
