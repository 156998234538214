import type { FC } from 'react'
import React from 'react'
import { PlaylistItemDetailDTO } from 'types'
import * as Styled from "./PlaylistListingPage.styled";

export interface PlaylistEpisodeProps {
  episode: PlaylistItemDetailDTO
  onRemoveItemFromPlaylist: () => Promise<void>
  index?: number
}

export const PlaylistEpisode: FC<PlaylistEpisodeProps> = ({
  episode,
  onRemoveItemFromPlaylist,
  index
}) => {
  return (
    <Styled.ItemListItem isOdd={index % 2 === 1}>
      <Styled.ItemDescription>
        <Styled.ItemTitle title={episode.episode.title}>{episode.episode.title}  {episode.episode.exclusiveToSupporters && <Styled.ExclusiveIcon />}</Styled.ItemTitle>
      </Styled.ItemDescription>

      <Styled.ItemActions>
        <Styled.OrderIcon />
        <Styled.CloseIcon onClick={() => onRemoveItemFromPlaylist()} />
      </Styled.ItemActions>
    </Styled.ItemListItem>
  )
}
