import React, { useState } from 'react'
import styled from 'styled-components'
import { CircularProgress, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { FullSupportInformation, SupportData } from '../../../../../types'

import { ProfileType } from '../../../../../reducers/profiles'

import {
  cancelMonthlySupport,
  unfollowPodcast,
} from '../../../../../services/api'

import { useGoToRoute } from '../../../../../Routes/RouteAux'
import {
  getPaymentsPath,
  getSupportPodcastPath,
} from '../../../../../Routes/RouteNames'

import { useTypedSelector } from '../../../../../reducers'

import Dialog from '../../../../../components/Dialog/Dialog'

import FollowerImages from './FollowerImages'
import { isBefore, parse as parseDate } from 'date-fns'
import { Colors, Typography } from 'styles'
import ButtonShadow from 'components/ButtonShadow/ButtonShadow'

const ModalWrapper = styled.div`
  display: flex;
  align-self: center;
  justify-content: space-around;
  width: 80%;
  height: 80%;
  margin: auto;
  margin-top: 5%;
  flex-direction: column;
  align-items: center;
  padding-horizontal: 1rem;
  background-color: ${Colors.GREY[50]};
  border-radius: 1rem;

  @media (max-width: 600px) {
    width: 100%;
    height: 100%;
    margin-top: 0;
    border-radius: 0;
  }
`

const IconButtonWrapper = styled.div`
  width: 100%;
  padding-top: 0.5rem;
  padding-right: 1rem;
  display: flex;
  justify-content: flex-end;
  border: 2px solid;
`

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  font-family: Fraunces;
  color: ${Colors.GREY[400]};
`

const UserMembershipTitle = styled.div`
  font-size: 1.6rem;
  color: black;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;
  font-family: Inter;
`

const PodcastImage = styled.img`
  width: 8rem;
  height: 8rem;
  border-radius: 10px;
  margin-bottom: 1rem;
  color: ${Colors.GREY[400]};
  border: 5px solid ${Colors.GREY[400]};
  z-index: 3;
  position: relative;
`

const PodcastImageShadow = styled.div`
  width: 8rem;
  height: 8rem;
  border-radius: 10px;
  border: 5px solid ${Colors.GREY[400]};
  transform: translate(0.8rem, -9rem);
  position: absolute;
`

const PodcastTitle = styled.div`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  text-align: center;
  font-family: Fraunces;
  color: ${Colors.GREY[400]};
  font-family: PP-Neue-Machina;
  text-transform: uppercase;
  font-weight: 700;
  padding-left: 0.8rem;

  @media (max-width: 600px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`

const SupportValue = styled.div`
  color: ${Colors.PURPLE[400]};
  text-align: center;
  font-size: 1.5rem;
  font-family: SpaceGrotesk;
  letter-spacing: -${Typography.LETTER_SPACING_1}px;
  font-weight: 600;
`

const SupportNextPayment = styled.div`
  text-align: center;
  margin-top: 0.5rem;
  color: ${Colors.GREY[400]};
  font-family: Inter;
`

const CircularButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  align-self: center;
  flex-direction: row;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
`

const ButtonsSpacing = styled.div`
  width: 1rem;

  @media (max-width: 600px) {
    width: 0.5rem;
  }
`

type FollowerImagesProps = {
  podcastFullSupportInformation: FullSupportInformation
  supportData: SupportData | null
  closeModal: () => void
  showErrorSnack: () => void
}

const UserMembershipModal = ({
  podcastFullSupportInformation,
  supportData,
  closeModal,
  showErrorSnack,
}: FollowerImagesProps): JSX.Element => {
  const goToRoute = useGoToRoute()

  const [isLoading, setIsLoading] = useState(false)
  const [leaveCommunityModalOpen, setLeaveCommunityModalOpen] = useState(false)
  const [cancelSupportModalOpen, setCancelSupportModalOpen] = useState(false)

  const currentProfile = useTypedSelector<ProfileType>(
    (state) => state && state.profiles && state.profiles.currentProfile,
  )

  const unfollow = async () => {
    setIsLoading(true)
    const req = await unfollowPodcast(
      podcastFullSupportInformation.supportSummary.podcastId,
    )

    if (req.hasFailed()) {
      setIsLoading(false)
      showErrorSnack()
    } else {
      window.location.reload()
    }
  }

  const cancelSupport = async () => {
    setIsLoading(true)
    const req = await cancelMonthlySupport(supportData.supportId)

    if (req.hasFailed()) {
      setIsLoading(false)
      showErrorSnack()
    } else {
      window.location.reload()
    }
  }

  if (isLoading) {
    return (
      <ModalWrapper>
        <CircularProgress />
      </ModalWrapper>
    )
  }

  return (
    <>
      <Dialog
        isDialogOpen={leaveCommunityModalOpen}
        handleCloseDialog={() => setLeaveCommunityModalOpen(false)}
        dialogText="Você tem certeza que deseja sair desta comunidade?"
        dialogActionButtonText="sim"
        onClickDialogActionButton={unfollow}
        noActionText="não"
      />
      <Dialog
        isDialogOpen={cancelSupportModalOpen}
        handleCloseDialog={() => setCancelSupportModalOpen(false)}
        dialogText="Você tem certeza que deseja cancelar o seu apoio?"
        dialogActionButtonText="sim"
        onClickDialogActionButton={cancelSupport}
        noActionText="não"
      />

      <ModalWrapper>
        <IconButton
          onClick={closeModal}
          size="medium"
          style={{ alignSelf: 'end' }}
        >
          <CloseIcon />
        </IconButton>

        <UserMembershipTitle>
          {supportData ? 'Você está apoiando' : 'Você está seguindo'}
        </UserMembershipTitle>
        <div>
          <PodcastImage
            src={podcastFullSupportInformation.supportSummary.image}
          />
          <PodcastImageShadow />
        </div>
        <PodcastTitle>
          {podcastFullSupportInformation.supportSummary.title}
        </PodcastTitle>

        <FollowerImages
          images={podcastFullSupportInformation.followersInfo.thumbnails.slice(
            0,
            10,
          )}
          isFollower={podcastFullSupportInformation.userInformation.isFollower}
        />

        {supportData && (
          <>
            <SupportValue>
              R$ {supportData.value.replace('.', ',')} mensais
            </SupportValue>

            {supportData.paymentMethodType === 'pix' &&
            supportData.isActive &&
            isBefore(
              parseDate(supportData.nextBillingDate, 'dd/MM/yyyy', Date.now()),
              Date.now(),
            ) ? (
              <SupportNextPayment
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                onClick={() =>
                  goToRoute(
                    getPaymentsPath(
                      supportData.podcastId,
                    ),
                  )
                }
              >
                Você tem um pagamento pendente, por favor clique aqui para
                normalizar
              </SupportNextPayment>
            ) : (
              <SupportNextPayment>
                Seu próximo pagamento está previsto para{' '}
                {supportData.nextBillingDate}
              </SupportNextPayment>
            )}

            {supportData.paymentMethodType === 'creditCard' ? (
              <SupportNextPayment>
                {`Com o cartão: **** **** **** ${supportData.creditCardNumber.substring(
                  12,
                  16,
                )}`}
              </SupportNextPayment>
            ) : null}
          </>
        )}

        <CircularButtonsWrapper>
          <ButtonShadow
            onPress={() => {
              supportData
                ? setCancelSupportModalOpen(true)
                : setLeaveCommunityModalOpen(true)
            }}
            label={supportData ? 'Cancelar apoio' : 'Sair da comunidade'}
            variant="secondary"
          />
          {!supportData &&
            podcastFullSupportInformation.supportSummary.canBeSupported &&
            currentProfile.profile === 'listener' && (
              <>
                <ButtonsSpacing />
                <ButtonShadow
                  type={supportData ? 'black' : 'colored'}
                  onPress={async () => {
                    goToRoute(
                      getPaymentsPath(
                        podcastFullSupportInformation.supportSummary.podcastId,
                        podcastFullSupportInformation.supportTiers[0].id,
                      ),
                    )
                  }}
                  label="Apoiar"
                />
              </>
            )}
        </CircularButtonsWrapper>
      </ModalWrapper>
    </>
  )
}

export default UserMembershipModal
