import React from 'react'
import * as Styled from './Publish.styled'
import site_2 from '../../../../assets/images/home/site_2.png'
import StyledButton from '../Button/Button'
import { getRegisterCreatorIntroPath } from '../../../../Routes/RouteNames'
import StyledNavLink from 'components/StyledNavLink'

const SubTextAndImage: React.FC<{}> = () => {
  return (
    <Styled.SectionWrapper>
      <Styled.TextWrapper>
        <Styled.Title>
          Publique seus conteúdos e viva da sua audiência
        </Styled.Title>
        <Styled.Description>
          {'Tudo no mesmo lugar: conteúdos, pagamentos e gestão de apoiadores'}
          <br />
          <br />
          {
            'JÁ ERA HORA, NÉ? Na Orelo, VOCÊ PODE PUBLICAR podcasts, textos, newsletters e fóruns de DISCUSSÃO. Seja COMO CONTEÚDO ABERTO OU exclusivo PARA SEUS APOIADORES. Com APOIOS RECORRENTES, CROWDFUNDINGS, VENDAS PONTUAIS.'
          }
        </Styled.Description>
        <StyledNavLink to={getRegisterCreatorIntroPath()}>
          <StyledButton fontSize="small" labelColor="black" buttonColor="white">
            Bora criar!
          </StyledButton>
        </StyledNavLink>
      </Styled.TextWrapper>
      <Styled.Image
        src={site_2}
        alt="Quadro com a sombra de duas pessoas se olhando, com fundo colorido e algumas folhas grandes ao redor."
      />
    </Styled.SectionWrapper>
  )
}

export default SubTextAndImage
