import React from 'react'
import * as Styled from './Footer.styled'
import logo_dark from '../../assets/images/home/logo_dark.png'
import instagram_dark from '../../assets/images/home/instagram_dark.png'
import twitter_dark from '../../assets/images/home/twitter_dark.png'
const width = window.innerWidth

const Footer: React.FC = () => {
  return (
    <Styled.FooterWrapper>
      {width > 768 && <Styled.IconsWrapper />}
      <Styled.Copyright>
        © Orelo 2023. Todos os direitos reservados.
      </Styled.Copyright>

      <Styled.IconsWrapper>
        <Styled.Link href="https://www.instagram.com/orelo.cc">
          <Styled.Icons
            src={instagram_dark}
            alt="logo orelo tema light"
            height={22}
          />
        </Styled.Link>
        <Styled.Link href="https://twitter.com/orelo_cc" target="_blank">
          <Styled.Icons
            src={twitter_dark}
            alt="logo orelo tema light"
            height={18}
          />
        </Styled.Link>

        <Styled.Link href="https://www.instagram.com/orelo.cc">
          <Styled.Icons
            src={logo_dark}
            alt="logo orelo tema light"
            height={24}
          />
        </Styled.Link>
      </Styled.IconsWrapper>
    </Styled.FooterWrapper>
  )
}

export default Footer

{
  /* <Logo src={LogoOrelo} />
      <LinksSectionWrapper>
        <LinksTitle>Social</LinksTitle>
        <LinksWrapper>
          <StyledLink
            href={'https://www.instagram.com/orelo.cc/'}
            target="_blank"
          >
            Instagram
          </StyledLink>
          <StyledLink href={'https://twitter.com/orelo_cc'} target="_blank">
            Twitter
          </StyledLink>
        </LinksWrapper>
      </LinksSectionWrapper>
      <LinksSectionWrapper>
        <LinksTitle>Links Úteis</LinksTitle>
        <LinksWrapper>
          <StyledLink
            href={'https://orelohelp.zendesk.com/hc/pt-br'}
            target="_blank"
          >
            Ajuda
          </StyledLink>
          <StyledLink
            href={'https://apps.apple.com/us/app/id1512799781'}
            target="_blank"
          >
            Apple Store
          </StyledLink>
          <StyledLink
            href={
              'https://play.google.com/store/apps/details?id=com.orelo.OreloMobileApp.production'
            }
            target="_blank"
          >
            Google Play
          </StyledLink>
        </LinksWrapper>
      </LinksSectionWrapper> */
}
