import BasePage from 'components/BasePage/BasePage'
import RoundedCard from 'components/RoundedCard'
import React, { useEffect, useState } from 'react'
import { Container, WebContainer, PageWrapper } from './Topic.styled'
import { useParams } from 'react-router-dom'
import { useGoToRoute } from 'Routes/RouteAux'
import TopicForm from 'components/TopicForm/TopicForm'
import {
  createTopic,
  getSupportInformationByPodcastId,
  TopicCreationProps,
} from 'services/api'
import { Snackbar } from '@material-ui/core'
import { getHomePath, getPodcastPath } from '../../../../Routes/RouteNames'
import * as Styled from '../../../topic/Topic.styled'
import { FullSupportInformation } from '../../../../types'
import { Colors } from '../../../../styles'
import { FORUM_FF } from '../../../../services/featureFlags'
import Button from '../../../../components/Button/Button'

type TopicProps = {
  signOut: () => Promise<void>
  appMode?: boolean
}

interface FormValues {
  title: string
  body?: string
}

const CreateTopic: React.FC<TopicProps> = ({ signOut, appMode }) => {
  const { podcastId } = useParams<{ podcastId: string }>()
  const [isLoading, setLoading] = useState<boolean>(false)
  const [errorPodcast, setErrorPodcast] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState<string>()
  const [podcastInformation, setPodcastInformation] =
    useState<FullSupportInformation>()

  const goToRoute = useGoToRoute()

  if (!FORUM_FF) {
    goToRoute(getHomePath())
  }

  const initialValues: FormValues = {
    title: '',
    body: null,
  }

  const fetchPodcast = async (podcastId: string): Promise<void> => {
    setLoading(true)
    setErrorPodcast(false)

    const res = await getSupportInformationByPodcastId(podcastId)

    if (res.hasFailed()) {
      setErrorPodcast(true)
    } else {
      setPodcastInformation(res.data)
    }

    setLoading(false)
  }

  useEffect(() => {
    if (podcastId) {
      fetchPodcast(podcastId)
    }
  }, [podcastId])

  const handleSubmit = async (formValues: FormValues) => {
    setLoading(true)

    const data = { ...formValues } as TopicCreationProps

    if (appMode) {
      return (window as any).ReactNativeWebView.postMessage(
        JSON.stringify(data),
      )
    }

    const res = await createTopic(podcastId, data)

    if (res.error) {
      setSnackbarMessage(
        'Ocorreu um erro inesperado. Tente novamente ou entre em contato com o suporte',
      )
    } else {
      setSnackbarMessage('Informações atualizadas com sucesso!')
      goToRoute(getPodcastPath(podcastId, true))
    }

    setLoading(false)
  }

  if (errorPodcast) {
    return (
      <BasePage signOut={signOut}>
        <Styled.PageWrapper>
          <Styled.MessageWrapper>
            Ops, parece que ocorreu um erro ao tentar carregar as informações
          </Styled.MessageWrapper>
          <Styled.ButtonWrapper>
            <Button
              variant="contained"
              buttonColorOnHover="#303030"
              buttonColor="#414141"
              onClick={() => {
                fetchPodcast(podcastId)
              }}
            >
              Tentar novamente
            </Button>
          </Styled.ButtonWrapper>
        </Styled.PageWrapper>
      </BasePage>
    )
  }

  if (isLoading || !podcastInformation) {
    return (
      <BasePage signOut={signOut}>
        <Styled.PageWrapper>
          <Styled.MessageWrapper>Carregando...</Styled.MessageWrapper>
        </Styled.PageWrapper>
      </BasePage>
    )
  }

  const mainContent = () => (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={!!snackbarMessage}
        autoHideDuration={15000}
        onClose={() => setSnackbarMessage(undefined)}
        message={snackbarMessage}
      />

      <PageWrapper darkMode={true}>
        <Styled.PodcastInfoAndFollowButton>
          <Styled.TitleAndImageWrapper
            onClick={() => goToRoute(getPodcastPath(podcastId))}
          >
            <Styled.PodcastProfileImage
              src={
                podcastInformation.supportSummary.thumbnail ||
                podcastInformation.supportSummary.image
              }
            />
            <Styled.PodcastTitle>
              Conversa em <br />
              <b>{podcastInformation.supportSummary.title}</b>
            </Styled.PodcastTitle>
          </Styled.TitleAndImageWrapper>
        </Styled.PodcastInfoAndFollowButton>

        <TopicForm
          podcastId={podcastId}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          loading={isLoading}
          darkMode={true}
        />
      </PageWrapper>
    </>
  )

  const content = (
    <Container
      style={{
        background: Colors.BRAND_SECONDARY,
        minHeight: '100vh',
        minWidth: '100vw',
      }}
    >
      <RoundedCard flex={1} isLight={!!appMode}>
        {mainContent()}
      </RoundedCard>
    </Container>
  )

  if (appMode) {
    return content
  }

  return (
    <BasePage isDark signOut={signOut}>
      <WebContainer
        style={{
          background: Colors.BRAND_SECONDARY,
          minHeight: '100vh',
          minWidth: '80vw',
        }}
      >
        {mainContent()}
      </WebContainer>
    </BasePage>
  )
}

export default CreateTopic
