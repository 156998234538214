import styled from 'styled-components'
import { InputBase } from '@material-ui/core'

export const SearchWrapper = styled.div<{ fullWidth?: boolean }>`
  background-color: white;
  border: 2px solid black;
  border-radius: 30px;
  box-shadow: #422800 4px 4px 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const SearchIconWrapper = styled.div`
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
  z-index: 2;
`

export const SearchInput = styled(InputBase)`
  &.MuiInputBase-root {
    width: 100%;
    font-family: 'PP-Neue-Machina';
    padding-left: 20px;
    font-weight: 700;
    color: #121212;
    padding-top: 4px;
  }

  #searchInput {
    text-transform: uppercase !important;
  }
`
