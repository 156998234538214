import React, { useState } from 'react'
import { Episode } from 'types'
import * as Styled from './EpisodeCard.styled'
import EpisodeCoverButton from '../EpisodeCoverButton/EpisodeCoverButton'
import {
  createPathWithQueryParam,
  useGoToRoute,
  usePathName,
} from '../../Routes/RouteAux'
import { useTypedSelector } from '../../reducers'
import { getLoginPath, getSupportPodcastPath } from '../../Routes/RouteNames'
import Dialog from '../Dialog/Dialog'

export interface EpisodeCardProps
  extends Pick<Episode, 'image' | 'title' | 'releaseDate' | 'description'> {
  blockedForSupporters: boolean
  duration: string
  onPlay: () => Promise<void> | void
  onStop: () => void
  onSkip: () => void
  onRewind: () => void
  isPlaying: boolean
  currentTime?: string
  userLoggedIn: boolean
  podcastId: string
  episodeId?: string
  idx?: number
}

const EpisodeCard: React.FC<EpisodeCardProps> = ({
  blockedForSupporters,
  duration,
  image,
  title,
  releaseDate,
  description,
  onPlay,
  onStop,
  onSkip,
  onRewind,
  isPlaying,
  currentTime,
  userLoggedIn,
  podcastId,
  episodeId,
  idx,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const currentProfile = useTypedSelector(
    (state) => state && state.profiles && state.profiles.currentProfile,
  )

  const pathName = usePathName()
  const loginRoute = createPathWithQueryParam(getLoginPath(), {
    redirectTo: pathName,
  })

  const goToRoute = useGoToRoute()

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  return (
    <>
      <Dialog
        isDialogOpen={isModalOpen}
        handleCloseDialog={handleCloseModal}
        dialogText={
          currentProfile
            ? `episódio exclusivo para apoiadores`
            : `você precisa estar logado`
        }
        dialogActionButtonText={currentProfile ? `apoiar podcast` : `login`}
        onClickDialogActionButton={() =>
          currentProfile
            ? goToRoute(getSupportPodcastPath(podcastId))
            : goToRoute(loginRoute)
        }
        noActionText="ok"
      />
      <Styled.EpisodeCard isOdd={idx && idx % 2 === 1}>
        <EpisodeCoverButton
          isPlaying={isPlaying}
          episodeImage={image}
          onPlay={onPlay}
          onStop={onStop}
          onSkip={onSkip}
          onRewind={onRewind}
          blockedForSupporters={blockedForSupporters}
          userLoggedIn={userLoggedIn}
          onExclusiveToSupporter={handleOpenModal}
          onNonLoggedIn={handleOpenModal}
        />
        <Styled.InfoWrapper>
          <Styled.EpisodeTitle numOfCharacters={title.length}>
            {title} {blockedForSupporters && <Styled.PadlockWithMargin />}
          </Styled.EpisodeTitle>
          <Styled.AdditionalInfo>{releaseDate}</Styled.AdditionalInfo>
        </Styled.InfoWrapper>
      </Styled.EpisodeCard>
    </>
  )
}

export default EpisodeCard
