import React from 'react'
import * as Styled from './HeroSection.styled'
import StyledButton from '../Button/Button'
import { getRegisterCreatorIntroPath } from '../../../../Routes/RouteNames'
import StyledNavLink from '../../../../components/StyledNavLink'

const width = window.innerWidth
const HeroSection: React.FC<{}> = () => {
  return (
    <Styled.SectionWrapper>
      <Styled.TextWrapper>
        <Styled.MainTitle>Criar é</Styled.MainTitle>
        <Styled.MainTitleStroke>urgente</Styled.MainTitleStroke>
        <Styled.Description>
          E poder viver disso também. A Orelo é o lugar onde quem quer viver da
          criatividade encontra o seu lugar.
        </Styled.Description>
        <StyledNavLink to={getRegisterCreatorIntroPath()}>
          <StyledButton fontSize="small" labelColor="black" buttonColor="white">
            Comece seu projeto
          </StyledButton>
        </StyledNavLink>
      </Styled.TextWrapper>
      {width > 768 && (
        <Styled.ImageWrapper>
          <Styled.Image />
        </Styled.ImageWrapper>
      )}
    </Styled.SectionWrapper>
  )
}

export default HeroSection
