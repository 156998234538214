import styled from 'styled-components'
import { Card } from '@material-ui/core'
import { Colors, Typography } from 'styles';

interface PodcastCardI {
  fullWidth: boolean;
  isDark: boolean;
}

export const PodcastCard = styled(Card).attrs((props: PodcastCardI) => props)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
  padding: 16px;
  width: ${props => props.fullWidth ? '100%' : '80%'};
  cursor: pointer;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  &.MuiPaper-rounded {
    border-radius: 12px;
  }
`

export const PodcastImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 10px;

`

export const TitleAndAuthorWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  padding-left: 32px;
  flex-direction: column;
  justify-content: flex-start;
  color: ${Colors.GREY[400]};
`

export const PodcastTitle = styled.div`
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 5px;
  color: white;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-family: SpaceGrotesk;
  text-transform: uppercase;
  letter-spacing: -${Typography.LETTER_SPACING_1}px;
  flex-grow: 1;
  color: ${Colors.GREY[400]};

  @media (max-width: 600px) {
    -webkit-line-clamp: 2;
    font-size: 20px;
  }
`

export const PodcastAuthor = styled.div`
  justify-content: flex-start;
  text-align: justify;
  white-space: pre-line;
  font-family: Inter;

  @media (max-width: 600px) {
    font-size: 12px;
  }
`