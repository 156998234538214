import React from 'react'
import StyledButton from '../Button/Button'
import * as Styled from './StartProject.styled'
import { getRegisterCreatorIntroPath } from '../../../../Routes/RouteNames'
import StyledNavLink from 'components/StyledNavLink'

const StartProject: React.FC<{}> = () => {
  return (
    <Styled.SectionWrapper>
      <Styled.Title> QUE TAL COMEÇAR SEU PROJETO?</Styled.Title>
      <Styled.Description style={{ fontFamily: 'Inter' }}>
        Apoios, assinaturas, podcasts, newsletters. Comece seu projeto, reúna
        apoiadores, viva da sua criatividade.
      </Styled.Description>
      <StyledNavLink to={getRegisterCreatorIntroPath()}>
        <StyledButton fontSize="small" labelColor="black" buttonColor="white">
          É pra já
        </StyledButton>
      </StyledNavLink>
    </Styled.SectionWrapper>
  )
}

export default StartProject
