import React from 'react'
import { Provider } from 'react-redux'
import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, rootReducer } from './reducers'
import { AuthProvider } from './authentication/context'
import AppContainer from './Routes'
import SmartBanner from 'react-smartbanner'
import 'SmartBanner.css'

export const store = createStore(rootReducer, applyMiddleware(thunk))

const App = (): JSX.Element => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <AuthProvider style={{ flex: 1 }}>
        <AppContainer />
        <SmartBanner
          title={'Orelo'}
          position={'bottom'}
          button={'Ver'}
          storeText={{
            ios: 'Na App Store',
            android: 'Na Google Play',
          }}
          price={{
            ios: 'Grátis',
            android: 'Grátis',
          }}
        />
      </AuthProvider>
    </PersistGate>
  </Provider>
)

export default App
