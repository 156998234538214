import React, { useState } from 'react'
import {
  Snackbar,
  IconButton,
  CircularProgress,
  Tooltip,
} from '@material-ui/core'
import * as Styled from './ShareButton.styled'
import { getShareLink } from '../../services/api'
import handleCopyToClipboard from '../../services/copyToClipboard'
import {Colors} from "../../styles";

interface ShareButtonProps {
  shareType: string
  shareId: string
  linkTitle: string
  linkImage: string
  linkDescription: string
  redirectToMobileApp: boolean
  iconButton?: boolean
  text?: boolean
  showAlert?: boolean
  color?: string
  fontColor?: string
  colorOnHover?: string
  icon?: boolean
}

const ShareButton: React.FC<ShareButtonProps> = ({
  shareType,
  shareId,
  linkTitle,
  linkImage,
  linkDescription,
  redirectToMobileApp,
  iconButton,
  text,
  showAlert,
  color,
  fontColor,
  colorOnHover,
  icon
}) => {
  const [shareLinkLoading, setShareLinkLoading] = useState(false)
  const [errorOnShareLink, setErrorOnShareLink] = useState(false)
  const [shareLink, setShareLink] = useState<string | undefined>()

  const buttonContent = () => {
    if (shareLinkLoading) {
      return <CircularProgress size="1em" />
    } else if (shareLink) {
      return 'Link copiado!'
    } else {
      return 'Compartilhar'
    }
  }

  const getLink = async (): Promise<void> => {
    setShareLinkLoading(true)

    const res = await getShareLink({
      shareType,
      shareId,
      linkTitle,
      linkImage,
      linkDescription,
      redirectToMobileApp,
    })

    if (!res.hasFailed()) {
      await handleCopyToClipboard(res.data)
      if (showAlert) alert(`Link copiado! (${res.data})`)
      setShareLink(res.data)
    } else {
      setErrorOnShareLink(true)
    }
    setShareLinkLoading(false)
  }

  const handleClick = async () => {
    if (shareLinkLoading) return

    if (shareLink) {
      await handleCopyToClipboard(shareLink)
      if (showAlert) alert(`Link copiado! (${shareLink})`)
    } else {
      getLink()
    }
  }

  const iconButtonOrLoading = () => {
    if (shareLinkLoading) {
      return <CircularProgress size="1.72em" />
    } else if (shareLink) {
      return (
        <Tooltip disableFocusListener title="Link copiado">
          <IconButton
            onClick={() => {
              handleCopyToClipboard(shareLink)
              if (showAlert) alert(`Link copiado! (${shareLink})`)
            }}
            size="small"
          >
            <Styled.CopiedIcon />
          </IconButton>
        </Tooltip>
      )
    } else {
      return (
        <Tooltip disableFocusListener title="Copiar link">
          <IconButton onClick={getLink} size="small">
            <Styled.ShareIcon iconColor={color} />
          </IconButton>
        </Tooltip>
      )
    }
  }

  return (
    <div className="ShareButton-root">
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!errorOnShareLink}
        autoHideDuration={10000}
        onClose={() => {
          setErrorOnShareLink(false)
        }}
        message={`Ops, parece que houve um erro! Tente novamente mais tarde.`}
      />
      {iconButton ? (
        iconButtonOrLoading()
      ) : (
        <Styled.FixedSizeButton
          variant={text ? 'text' : 'contained'}
          buttonColorOnHover={colorOnHover || '#675b1b'}
          buttonColor={color || '#C0AC43'}
          fontColor={fontColor || Colors.GREY[400]}
          onClick={handleClick}
        >
          {buttonContent()}
        </Styled.FixedSizeButton>
      )}
    </div>
  )
}

export default ShareButton
