import { Colors, Typography } from 'styles'
import styled from 'styled-components'
import Divider from '@material-ui/core/Divider'
import { Avatar } from '@material-ui/core'

export const WhiteBackground = styled.div`
  background-color: #ffffff;
`

export const ColoredBackground = styled.div`
  width: 100%;
  @media (max-width: 600px) {
    border-radius: 0;
  }
`

export const PageWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 80px auto;
  color: ${Colors.GREY[400]};
  width: 50%;

  @media (max-width: 600px) {
    width: 100%;
    margin: 80px 1.5rem;
  }
`

export const ErrorWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const MessageWrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  text-align: center;
  color: ${Colors.GREY[400]};
`

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const PodcastInfoAndFollowButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const TitleAndImageWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const PodcastProfileImage = styled.img`
  width: 48px;
  height: 46.8px;
  border-radius: 12px;
  margin-right: 14px;
`

export const PodcastTitle = styled.div`
  font-size: 0.875em;
  line-height: 24px;
  font-family: PP-Neue-Machina;
  text-transform: uppercase;
  letter-spacing: -${Typography.LETTER_SPACING_1}px;
  color: ${Colors.GREY[400]};
`

export const TopicWrapper = styled.div`
  margin-top: 24px;
  width: 100%;
`

export const TopicTitle = styled.div`
  font-family: 'Inter';
  font-weight: 700;
  font-size: 36px;
  line-height: 30px;
  letter-spacing: -0.02em;
  margin-top: 45px;
  margin-bottom: 18px;
  color: ${Colors.GREY[400]};

  @media (max-width: 600px) {
    margin-bottom: 8px;
  }
`

export const TopicDescription = styled.div`
  font-family: 'PT Serif';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 39px;
`

export const InfoAndTopicMenuWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: ${Colors.GREY[400]};
`

export const AdditionalInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 600px) {
    margin-bottom: 8px;
  }
`

export const AdditionalInfo = styled.div`
  font-size: 14px;
  margin-right: 20px;
  align-items: baseline;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 600px) {
    font-size: 8px;
    margin-right: 4px;
  }
`

export const FullWidthDivider = styled(Divider)`
  width: 100%;

  &.MuiDivider-root {
    background-color: rgba(255, 255, 255, 0.5);
    margin-top: 48px;

    @media (max-width: 600px) {
      margin-top: 24px;
    }
  }
`

export const CreatorResume = styled.div`
  margin-top: 32px;
  display: flex;
  width: 100%;
  justify-content: space-between;
`

export const CreatorResumeImage = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 40px;
  margin: 0 7%;
  cursor: pointer;

  @media (max-width: 600px) {
    margin: 0 20px;
  }
`

export const CreatorResumeContent = styled.div`
  width: 80%;
  margin-bottom: 20px;
`

export const CreatorResumeImageWithFollowButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

export const CreatorResumeTitleWithFollowButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

export const FollowButtonWrapper = styled.div`
  margin-top: 20px;
`

export const CreatorResumeTitle = styled.div`
  font-family: Fraunces;
  font-weight: 900;
  font-size: 1.7em;
  cursor: pointer;
`

export const CreatorResumeDescription = styled.div`
  font-family: PT-Serif;
  font-weight: 400;
  font-size: 1em;
`

export const AuthorAvatar = styled.img`
  width: 40px;
  height: 40px;
  border: 1px solid white;
  border-radius: 30px;
  object-fit: cover;
  color: ${Colors.GREY[400]};
`

export const PaddedText = styled.span`
  padding: 0 10px;
  color: ${Colors.GREY[400]};
`

export const ImageWrapper = styled.div`
  width: 100%;
  padding: 0 5%;
  height: 50%;
  border-radius: 12px;
  margin-top: 12px;
`

export const CommentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #161414;
  border-radius: 12px;
  margin-bottom: 46px;
  padding: 12px 16px;
`

export const CommentHeadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const CommentBodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const AuthorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  margin-bottom: 16px;
  align-items: center;
`

export const AuthorName = styled.p`
  font-family: 'Fraunces';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  margin-left: 20px;
  color: #ffffff;
`

export const TopicDate = styled.p`
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  margin-right: 20px;
  letter-spacing: -0.03em;
  color: #ffffff;
  opacity: 0.84;
  margin-left: auto;
`

export const CommentText = styled.p`
  font-family: 'Fraunces';
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 21px;
  letter-spacing: -0.02em;
  color: #ffffff;
  opacity: 0.84;
`

export const StyledAvatar = styled(Avatar)`
  border: 1px solid #fff;
`
