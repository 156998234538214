import React, { useEffect } from 'react'
import { useLocation, matchPath } from 'react-router-dom'
import RouteAuth, { AuthRouteProps } from './RouteAuth'
import { getErrorPath } from './RouteNames'
import { useTypedSelector } from '../reducers'
import { ProfileType } from '../reducers/profiles'
import { createPathWithQueryParam, useGoToRoute, usePathName } from './RouteAux'

interface RoutePodcastProps extends AuthRouteProps {}

const RoutePodcast: React.FC<RoutePodcastProps> = ({
  children,
  path,
  ...rest
}) => {
  const { pathname } = useLocation()
  const { params }: { params: { podcastId: string } } = matchPath(pathname, {
    path: path,
  })

  const currentProfile = useTypedSelector<ProfileType>(
    (state) => state && state.profiles && state.profiles.currentProfile,
  )
  const pathName = usePathName()
  const errorRoute = createPathWithQueryParam(getErrorPath(), {
    redirectTo: pathName,
  })

  const goToRoute = useGoToRoute()

  useEffect(() => {
    if (
      !currentProfile ||
      currentProfile.profile !== 'podcast' ||
      currentProfile.id !== params.podcastId
    ) {
      goToRoute(errorRoute)
    }
  }, [currentProfile, params.podcastId])

  return (
    <RouteAuth path={path} {...rest}>
      {children}
    </RouteAuth>
  )
}

export default RoutePodcast
