import styled from 'styled-components'
import { TextField } from '@material-ui/core'
import { LIGHTER_GRAY } from '../../../styles/colors'
import { Colors, Typography } from 'styles'

export const Wrapper = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  flex-direction: column;
  margin: 12px 0;
  ${(props) => props.fullWidth && `width: 100%`};
  position: relative;
`

export const StyledLabel = styled.label.attrs(
  (props: { darkMode?: boolean; borderless?: boolean, color?: string }) => props,
)`
  font-weight: bold;
  font-size: 1.4rem;
  margin-bottom: 8px;
  color: ${(props) => (props.color || Colors.GREY[400])};
  margin-right: 8px;
  font-family: Inter;
  letter-spacing: -${Typography.LETTER_SPACING_1}px;
`

export const StyledTextField = styled(TextField).attrs(
  (props: { darkMode?: boolean; borderless?: boolean }) => props,
)`
  border: ${(props) =>
    props.borderless || !props.darkMode
      ? '0'
      : `2px solid ${Colors.GREY[200]}`} !important;
  border-radius: ${(props) => (props.darkMode ? '24px' : '12px')} !important;

  .MuiOutlinedInput-root {
    &.Mui-disabled input {
      background: ${LIGHTER_GRAY} !important;
      cursor: not-allowed;
    }

    input,
    textarea {
      color: ${(props) =>
        props.darkMode ? Colors.GREY[400] : '#fff'} !important;
      padding: ${(props) =>
        props.borderless
          ? '18.5px 0'
          : props.darkMode
          ? '12px 18px'
          : '18.5px 14px'};
    }

    fieldset {
      border: ${(props) => (props.borderless ? '0' : 'inherit')};

      &.MuiOutlinedInput-notchedOutline {
        border: ${(props) => (props.darkMode ? '0' : 'inherit')};
      }
    }
  }
`

// This is a material-ui style. We are using this here, because is easier to override MUI style this way
export const transparentBorderStyle = (theme) => ({
  cssFocused: {
    borderColor: 'transparent',
    '&$cssFocused $notchedOutline': {
      borderColor: 'transparent',
    },
  },
  notchedOutline: {
    borderColor: 'transparent',
  },
  root: {
    '&:hover:not($disabled):not($error) $notchedOutline': {
      borderColor: 'transparent',
    },
  },
  input: {
    fontFamily: 'Inter',
  },
  error: {},
  disabled: {},
})
