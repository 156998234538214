import styled from 'styled-components'
import { Colors } from '../../styles'

export const SeeMoreInteractiveArea = styled.div``

export const CollapsedArea = styled.div<{
  expandedDescription: boolean
  color?: string
  gradientColor?: string
}>`
  background: ${(props) =>
    !props.expandedDescription
      ? `-webkit-linear-gradient(top, ${props.color || Colors.BLACK} 70%, ${
          props.gradientColor || Colors.WHITE
        })`
      : `${props.color || Colors.BLACK}`};
  -webkit-background-clip: text;
  word-break: break-word;
  justify-content: start;
  align-self: start;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
`
