import React from 'react'
import { CardContent } from '@material-ui/core'
import * as Styled from './SupportTierCard.styled'
import StyledNavLink from '../StyledNavLink'
import ButtonShadow from '../ButtonShadow/ButtonShadow'
import Collapse from '../Collapse/Collapse'
import { Colors } from '../../styles'

interface SupportTierCardProps {
  title: string | React.ReactElement
  value: string
  description: string | React.ReactElement
  hideValue?: boolean
  supportRoute?: string
  color?: string
  fontColor?: string
  colorOnHover?: string
  onPress?: () => void
  horizontal?: boolean
}

const SupportTierCard: React.FC<SupportTierCardProps> = ({
  title,
  value,
  description,
  supportRoute,
  onPress,
  hideValue,
  horizontal = false,
}) => {
  return (
    <Styled.StyledCard>
      <CardContent>
        <Styled.CardTitleAndValue>
          <Styled.CardTitle>{title}</Styled.CardTitle>
          {!hideValue &&
            (supportRoute ? (
              <Styled.NavLink to={supportRoute}>
                <Styled.CardValue>R${Number(value)}</Styled.CardValue>
              </Styled.NavLink>
            ) : (
              <Styled.CardValue>R${Number(value)}</Styled.CardValue>
            ))}
        </Styled.CardTitleAndValue>
        <Collapse
          color={Colors.WHITE}
          gradientColor={Colors.BLACK}
          collapsedSize={'10rem'}
        >
          <Styled.CardDescription>
            <Styled.DescriptionTitle>Descrição</Styled.DescriptionTitle>
            {description}
          </Styled.CardDescription>
        </Collapse>
        {supportRoute ? (
          <StyledNavLink to={supportRoute}>
            <ButtonShadow
              variant="primary"
              label={hideValue ? `apoiar` : `apoiar com R$ ${Number(value)}`}
              onPress={null}
            />
          </StyledNavLink>
        ) : onPress ? (
          <ButtonShadow
            variant="primary"
            label={hideValue ? `apoiar` : `apoiar com R$ ${Number(value)}`}
            onPress={onPress}
            style={{ margin: '10px 24px' }}
          />
        ) : (
          <></>
        )}
      </CardContent>
    </Styled.StyledCard>
  )
}

export default SupportTierCard
