import styled from 'styled-components'
import { Colors } from 'styles'
import { ReactComponent as Padlock } from 'assets/icons/padlock_fill.svg'
import { LETTER_SPACING_1 } from 'styles/typography'

export const EpisodeCard = styled.div<{ isOdd?: boolean }>`
  display: flex;
  width: 100%; 
  flex-direction: row;
  align-items: center;
  background-color: ${({ isOdd }) => isOdd ? Colors.GREY[50] : Colors.WHITE};
  padding: 8px 0px;
`

export const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%
`

export const EpisodeTitle = styled.span<{ numOfCharacters?: number }>`
  font-family: SpaceGrotesk;
  max-width: 75%;
  margin-left: 16px;
  font-weight: 700;
  font-size: ${({ numOfCharacters }) => numOfCharacters && numOfCharacters > 30 ? 16 : 18}px ;
  letter-spacing: -${LETTER_SPACING_1}px;
  display: flex;
  align-items: center;
`

export const PadlockWithMargin = styled(Padlock)`
  margin-left: 8px;
`

export const AdditionalInfo = styled.span`
  font-family: Inter;
  width: 20%;
  font-size: 14px;
  font-weight: 200;
  letter-spacing: -${LETTER_SPACING_1}px;

  @media (max-width: 300px) {
    display: none;
  }
`
