import { CircularProgress, IconButton } from '@material-ui/core'
import { PlayArrow, Stop, Replay30, Forward30 } from '@material-ui/icons'
import styled from 'styled-components'
import { Colors } from 'styles'

export const EpisodeImage = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 4px;
  position: absolute;
  background-color: #D9D9D9;
  opacity: 0.8;

  @media (max-width: 900px) {
    display: none;
  }
`

export const EpisodeAndButtonWrapper = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 600px) {
    width: 24px;
    height: 24px;
  }
`

export const StyledIconButton = styled(IconButton) <{ small?: boolean }>`
  width: ${(props) => (props.small ? '12px' : '24px')};
  height: ${(props) => (props.small ? '12px' : '24px')};

  &.MuiButtonBase-root {
    &:hover {
      background-color: ${Colors.WHITE};
    }

    opacity: 0.9;
  }

  @media (max-width: 600px) {
    width: ${(props) => (props.small ? '12px' : '8px')};
    height: ${(props) => (props.small ? '12px' : '8px')};
  }
`

export const PlayIcon = styled(PlayArrow)`
  &.MuiSvgIcon-root {
    color: ${Colors.PURPLE[400]};
    width: 24px;
    height: 24px;

    @media (max-width: 900px) {
      width: 24px;
      height: 24px;
    }
  }
`

export const LoadingIcon = styled(CircularProgress)`
  &.MuiCircularProgress-colorPrimary {
    color: white;
  }
`

export const StopRewindAndSkipWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`

export const StopIcon = styled(Stop)`
  &.MuiSvgIcon-root {
    color: white;
    width: 48px;
    height: 48px;
  }

  @media (max-width: 600px) {
    width: 20px;
    height: 20px;
  }
`

export const RewindIcon = styled(Replay30)`
  &.MuiSvgIcon-root {
    color: white;
    width: 24px;
    height: 24px;
  }

  @media (max-width: 600px) {
    width: 12px;
    height: 12px;
  }
`

export const SkipIcon = styled(Forward30)`
  &.MuiSvgIcon-root {
    color: white;
    width: 24px;
    height: 24px;
  }

  @media (max-width: 600px) {
    width: 12px;
    height: 12px;
  }
`

export const Image = styled.img`
  width: 80px;
  height: 80px;
  object-fit: scale-down;
  z-index: 1;

  @media (max-width: 600px) {
    width: 40px;
    height: 40px;
  }
`
