import React, { useState } from 'react'
import { Transforms } from 'slate'
import { useFocused, useSelected, useSlateStatic } from 'slate-react'
import isUrl from 'is-url'
import imageExtensions from 'image-extensions'
import { CircularProgress } from '@material-ui/core'
import { InsertPhoto } from '@material-ui/icons'
import * as Styled from '../TextEditor.styled'
import { Button, Icon } from '../components'
import { EmptyText } from '../index'

export type ImageElement = {
  type: 'image'
  url: string
  children: EmptyText[]
}

export const insertImage = (editor, url) => {
  const text = { text: '' }
  const image: ImageElement = { type: 'image', url, children: [text] }
  const emptyTag = {
    type: 'paragraph',
    children: [{ text: '' }],
  }
  Transforms.insertNodes(editor, [image, emptyTag])
}

export const RenderImage = ({ attributes, children, element }) => {
  const selected = useSelected()
  const focused = useFocused()
  return (
    <div {...attributes}>
      {children}
      <div contentEditable={false}>
        <Styled.Image src={element.url} selected={selected} focused={focused} />
      </div>
    </div>
  )
}

export const isImageUrl = (url) => {
  if (!url) return false
  if (!isUrl(url)) return false
  const ext = new URL(url).pathname.split('.').pop()
  return imageExtensions.includes(ext)
}

export type UploadFileResponse = {
  imageUrl?: string
  error?: string
}

export interface InsertImageButtonProps {
  uploadFile: (file: File) => Promise<UploadFileResponse>
  onInsertImage?: (imageUrl: string) => void
}

export const InsertImageButton: React.FC<InsertImageButtonProps> = ({
  uploadFile,
  onInsertImage,
}) => {
  const editor = useSlateStatic()
  const clickInputButton = () => {
    document.getElementById('inputImageButton').click()
  }

  const [loading, setLoading] = useState(false)

  return (
    <Button
      active={false}
      onMouseDown={() => {
        if (!loading) clickInputButton()
      }}
      reversed
    >
      <Icon>{loading ? <CircularProgress size={24} /> : <InsertPhoto />}</Icon>
      <input
        id="inputImageButton"
        type="file"
        style={{ display: 'none' }}
        onChange={(e) => {
          setLoading(true)
          uploadFile(e.target.files[0]).then((res) => {
            if (res.error) {
              alert(res.error)
            } else {
              insertImage(editor, res.imageUrl)
              if (onInsertImage) onInsertImage(res.imageUrl)
            }
            setLoading(false)
          })
        }}
      />
    </Button>
  )
}

export const withImages = (editor) => {
  const { isVoid, insertData } = editor

  editor.isVoid = (element) => {
    return element.type === 'image' ? true : isVoid(element)
  }

  editor.insertData = (data) => {
    const text = data.getData('text/plain')
    const { files } = data

    if (files && files.length > 0) {
      for (const file of files) {
        const reader = new FileReader()
        const [mime] = file.type.split('/')

        if (mime === 'image') {
          reader.addEventListener('load', () => {
            const url = reader.result
            try {
              // This method encodes a string in base64. If we can do this, this means that the url is actually an image copied and pasted
              // We don't allow these images because they don't render in emails
              const a = window.btoa(url as string)
              alert('Não é possível colar imagens no editor de texto')
            } catch (e) {
              insertImage(editor, url)
            }
          })

          reader.readAsDataURL(file)
        }
      }
    } else if (isImageUrl(text)) {
      insertImage(editor, text)
    } else {
      insertData(data)
    }
  }

  return editor
}
