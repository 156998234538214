import styled from 'styled-components'
import {
  Avatar,
  CircularProgress,
  DialogTitle as MuiDialogTitle,
  InputLabel,
  Tab,
  Tabs,
} from '@material-ui/core'
import Button from '../../components/Button/Button'
import { Form } from 'formik'
import { ReactComponent as QuoteCircledIconSvg } from '../../assets/icons/quote-circled.svg'
import { ReactComponent as PrivateIconSvg } from '../../assets/icons/private.svg'
import { ReactComponent as QuoteIconSvg } from '../../assets/icons/quote.svg'
import { ReactComponent as PigIconSvg } from '../../assets/icons/pig.svg'
import { ReactComponent as DollarCircledIconSvg } from '../../assets/icons/dollar-circled.svg'
import { ReactComponent as GraphsIconSvg } from '../../assets/icons/graphs.svg'
import { ReactComponent as SettingsCircledIconSvg } from '../../assets/icons/settings-cricled.svg'
import MuiInfoIcon from '@mui/icons-material/Info'
import { Colors } from 'styles'

export const PageWrapper = styled.div`
  display: flex;
  align-items: start;
  padding: 64px 96px;
  gap: 64px;
  color: ${Colors.GREY[400]};
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: 600px) {
    padding: 16px;
    flex-wrap: wrap;
  }
`

export const TitleAndLinkWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  flex: 0 0 100%;

  font-family: 'Gellix';
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  color: #000000;

  @media (max-width: 600px) {
    align-items: center;
    font-size: 32px;
  }
`

export const MenuButton = styled(Button)`
  &.MuiButtonBase-root {
    .MuiButton-label {
      text-transform: uppercase;
      gap: 8px;
    }
  }

  @media (max-width: 600px) {
    &.MuiButtonBase-root {
      width: 100%;
    }
  }
`

export const MenuWrapper = styled.div`
  display: flex;
  align-items: end;
  flex-direction: column;

  gap: 14px;

  @media (max-width: 600px) {
    align-items: center;
    width: 100%;
    margin-top: 42px;
  }
`

export const MenuHeader = styled.div`
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 600;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;

  text-align: right;
  text-transform: uppercase;

  color: #ffffff;
`

export const ItemGrid = styled.div`
  display: flex;
  gap: 12px;
  padding: 0 6%;
  flex-wrap: wrap;
  flex: 0 0 100%;

  @media (max-width: 600px) {
    padding: 0;
  }
`

interface ItemStylesInterface {
  isDark?: boolean
  textCenter?: boolean
}

export const EmptyStateMesage = styled.div`
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 40px;

  text-align: center;

  color: #000000;

  display: flex;
  align-items: stretch;
  flex-direction: column;
  margin-top: 3rem;

  flex: 1;

  u {
    cursor: pointer;
  }

  @media (max-width: 600px) {
    order: 1;
    margin-top: 0;
  }
`

export const Item = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  margin-bottom: 8px;
  flex: 0 0 100%;

  @media (max-width: 600px) {
    flex: 0 0 100%;
    order: 2;
  }
`

export const ItemHeader = styled.div.attrs(
  (props: ItemStylesInterface) => props,
)`
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 40px;
  border-bottom: 1px solid #000000;
  padding: 12px 32px;
  border-color: ${(props) => (props.isDark ? 'white' : 'black')} !important;
  text-align: ${(props) => (props.textCenter ? 'center' : 'left')};
`

export const ItemSubHeader = styled.div.attrs(
  (props: ItemStylesInterface) => props,
)`
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 40px;
  border-bottom: 1px solid #000000;
  padding: 12px 32px;
  border-color: ${(props) => (props.isDark ? 'white' : 'black')} !important;
  text-align: ${(props) => (props.textCenter ? 'center' : 'left')};
`

export const ItemWrapper = styled.div.attrs(
  (props: ItemStylesInterface) => props,
)`
  color: black;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
`

export const ItemIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  position: absolute;
  top: 0;
  left: -70px;
  bottom: 0;
  width: 60px;

  @media (max-width: 600px) {
    flex-wrap: nowrap;
    display: flex;
  }
`

export const ItemDescription = styled.div.attrs(
  (props: { disabled: boolean }) => props,
)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const ItemInformation = styled.div.attrs(
  (props: { disabled: boolean }) => props,
)`
  opacity: ${(props) => (props.disabled ? '0.21' : '1')};
  display: flex;
  align-items: center;
  flex: 0 0 50%;
  position: relative;
  cursor: pointer;

  @media (max-width: 600px) {
    flex: 0 0 100%;
  }
`

export const ItemTitle = styled.div`
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  color: #000000;
`

export const ItemReleaseDate = styled.div`
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem;
  display: inline;
  margin-right: 2rem;
`

export const ItemPlayCount = styled.div`
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  color: #000000;
`

export const ItemActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  @media (max-width: 600px) {
    width: 100%;
  }
`

export const ItemBody = styled.div`
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  padding: 32px 32px 48px 32px;
  word-break: break-word;
`

export const ButtonWrapper = styled.div`
  justify-content: end;
  width: 100%;
  display: flex;

  @media (max-width: 600px) {
    justify-content: center;
  }
`

export const PageTitle = styled.div`
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 80px;
  color: #000000;
  margin-top: 16px;
  width: 100%;
  margin-left: 42px;

  @media (max-width: 600px) {
    text-align: center;
    font-size: 2.5rem;
    line-height: normal;
    margin-bottom: 0;
    margin-left: 0;
  }
`

export const MessageWrapper = styled.div`
  margin-top: 50px;
  margin-bottom: 20px;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`

export const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const LoadingCircle = styled(CircularProgress)`
  margin-left: 12px;
`

export const SupportTierCardWrapper = styled.div`
  width: 40%;
  margin-bottom: 16px;

  &.MuiCard-root {
    background-color: #1f1f1f;
    color: white;
  }

  @media (max-width: 600px) {
    width: 90%;
  }
`

export const TiersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`

export const AddTiersButton = styled(Button)`
  align-self: center;

  &.MuiButtonBase-root {
    margin-bottom: 24px;
  }
`

export const ErrorText = styled.div`
  color: red;
  margin-bottom: 12px;
`

export const FlexForm = styled(Form)`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: start;
  padding: 52px 0 24px 0;
`

export const SearchBarWrapper = styled.div`
  margin: 32px 0 16px 0;
  width: 100%;
`

export const ListHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  margin: 3rem 0 2rem 0;

  span {
    font-family: 'Gellix';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;

    color: #ffffff;
    background: black;
    padding: 0.5rem 2rem;
    border: 2px solid #000000;
    border-radius: 99px;

    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background: #000;
      left: 0;
      top: 50%;
      position: absolute;
      z-index: -1;
    }
  }
`

export const QuoteCircledIcon = styled(QuoteCircledIconSvg)`
  width: 1.6rem;
  height: 1.6rem;
`

export const PrivateIcon = styled(PrivateIconSvg)`
  width: 2rem;
  height: 2rem;
`

export const QuoteIcon = styled(QuoteIconSvg)`
  width: 1.4rem;
  height: 1.4rem;
`

export const LeftWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  flex: 0 0 47%;
  width: 47%;

  @media (max-width: 600px) {
    flex-direction: column;
    flex: 1;
  }
`

export const MainWrapper = styled.div`
  flex: 1;
  gap: 12px;
  display: flex;
  flex-direction: column;
  align-items: baseline;

  @media (max-width: 600px) {
    flex: 0 0 100%;
  }
`

export const AvatarWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 16px;
  width: 100%;
`

export const PictureWrapper = styled.div`
  border-radius: 100%;
  height: 100px;
  flex: 0 0 100px;
  border: 2px solid #000000;
`

export const MenuAvatar = styled(Avatar)`
  &.MuiAvatar-root {
    width: 100%;
    height: 100%;
  }
`

export const TitleWrapper = styled.div`
  flex: 0 0 75%;
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 700;
  font-size: 2.9rem;
  color: #000000;
  
  @media (max-width: 600px) {
    font-size: 1.5rem;
  }
`

export const CreatorTitle = styled.div`
  margin-bottom: 8px;
`

export const CreatorLink = styled.div`
  font-size: 1rem;
  font-weight: 500;
`

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 0 10%;
`

export const MembersInfoWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
`

export const SupportersInfoWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
`

export const MembersInfoCount = styled.div`
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 40px;
  color: #000000;
`

export const SupportersInfoCount = styled.div`
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 40px;
  color: #000000;
`

export const RightWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  flex: 0 0 47%;
  width: 47%;

  flex-wrap: wrap;

  @media (max-width: 600px) {
    flex-direction: column;
    width: 100%;
  }
`

export const TabWrapper = styled.div`
  flex: 1;
  //flex: 0 0 100%;
  border: 1px solid #000000;
  border-radius: 40px;
  padding: 8px 36px;
`

export const FooterWrapper = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 18px 0;
  gap: 12px;
`

export const FooterButtons = styled.div`
  display: flex;
  gap: 8px;
`

export const FooterInfo = styled.a`
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;

  color: #000000;

  text-align: center;
  width: 100%;
  gap: 8px;
  justify-content: center;
  cursor: pointer;
`

export const LatestContentsWrapper = styled.div`
  flex: 0 0 50%;
`

export const LatestContentContainer = styled.div`
  padding: 32px 24px;
  gap: 26px;
  display: flex;
  flex-direction: column;

  @media (max-width: 600px) {
    padding: 32px 24px 32px 48px;
  }
`

export const LatestContentsTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;

  span {
    font-family: 'Gellix';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;

    color: #ffffff;
    background: black;
    padding: 0.5rem 2rem;
    border: 2px solid #000000;
    border-radius: 99px;

    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background: #000;
      left: 0;
      top: 50%;
      position: absolute;
      z-index: -1;
    }
  }
`

export const LatestSupportsWrapper = styled.div`
  flex: 0 0 50%;
`

export const LatestSupportsTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;

  span {
    font-family: 'Gellix';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;

    color: #ffffff;
    background: black;
    padding: 0.5rem 2rem;
    border: 2px solid #000000;
    border-radius: 99px;

    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background: #000;
      left: 0;
      top: 50%;
      position: absolute;
      z-index: -1;
    }
  }
`

export const BottomWrapperDivider = styled.div`
  height: auto;
  width: 2px;
  background: #000000;
  display: block;
  flex: 0 0 2px;
  margin-top: 20px;

  @media (max-width: 600px) {
    display: none;
  }
`

export const Table = styled.table`
  padding: 32px 24px;
  width: 100%;
`

export const TableRow = styled.tr`
  border: 0.3px solid #000000;
`

export const TableData = styled.td`
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  color: #000000;
  border-bottom: 1px solid #b5b5b5;
  padding-bottom: 12px;
  text-transform: uppercase;
  border-bottom: 1px solid #b5b5b5;
`

export const PigIcon = styled(PigIconSvg)`
  width: 1.6rem;
  height: 1.6rem;
`

export const DollarCircledIcon = styled(DollarCircledIconSvg)`
  width: 1.6rem;
  height: 1.6rem;
`

export const GraphsIcon = styled(GraphsIconSvg)`
  width: 1.6rem;
  height: 1.6rem;
`

export const SettingsCircledIcon = styled(SettingsCircledIconSvg)`
  width: 1.6rem;
  height: 1.6rem;
`

export const InfoIcon = styled(MuiInfoIcon)`
  width: 1.6rem;
  height: 1.6rem;
`

export const DialogTextWrapper = styled.div`
  padding: 18px 24px;
`

export const PodcastModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px 12px;
`

export const DialogText = styled(MuiDialogTitle)`
  &.MuiDialogTitle-root {
    font-family: 'Gellix';
    font-size: 1.2em;
    font-weight: bold;
    padding: 0px 0px;
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 4px;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`

export const Column = styled.div<{
  flex?: string | number
  justify?: string
  align?: string
}>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ justify }) => (justify ? justify : 'inherit')};
  flex: ${({ flex }) => (flex ? flex : 'inherit')};
  align-items: ${({ align }) => (align ? align : 'inherit')};

  @media (max-width: 600px) {
    flex: 1;
  }
`

export const StyledTabs = styled(Tabs)`
  &.MuiTabs-root {
    .MuiTabs-flexContainer {
      justify-content: space-between;
    }
  }
`

export const StyledTab = styled(Tab)`
  &.MuiTab-root {
    font-family: 'Gellix';
    font-style: normal;
    font-weight: 700;
    font-size: 1.2rem;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
  }

  &.MuiTab-textColorPrimary.Mui-selected {
    color: #cc0033;
  }
`

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledLabel = styled(InputLabel)`
  margin-right: 12px;

  &.MuiFormLabel-root {
    font-family: 'Gellix';
    font-weight: bold;
    font-size: 22px;
    color: black;
  }
`
