import React from 'react'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import * as Styled from './ReturnButton.styled'
import StyledNavLink from '../StyledNavLink'

interface ReturnButtonProps {
  route: string
}

const ReturnButton: React.FC<ReturnButtonProps> = ({ route }) => {
  return (
    <StyledNavLink to={route}>
      <Styled.ReturnButtonWrapper>
        <ArrowBackIcon />
        <Styled.ReturnText>{'voltar'}</Styled.ReturnText>
      </Styled.ReturnButtonWrapper>
    </StyledNavLink>
  )
}

export default ReturnButton
