import React from 'react'
import {Transforms,} from 'slate'
import { jsx } from 'slate-hyperscript'

const ELEMENT_TAGS = {
  A: el => ({type: 'link', url: el.getAttribute('href')}),
  BLOCKQUOTE: () => ({type: 'quote'}),
  // H1: () => ({type: 'heading-one'}),
  // H2: () => ({type: 'heading-two'}),
  // H3: () => ({type: 'heading-three'}),
  // H4: () => ({type: 'heading-four'}),
  // H5: () => ({type: 'heading-five'}),
  // H6: () => ({type: 'heading-six'}),
  IMG: el => ({type: 'image', url: el.getAttribute('src')}),
  LI: () => ({type: 'list-item'}),
  OL: () => ({type: 'numbered-list'}),
  P: () => ({type: 'paragraph'}),
  PRE: () => ({type: 'code'}),
  UL: () => ({type: 'bulleted-list'}),
}

// COMPAT: `B` is omitted here because Google Docs uses `<b>` in weird ways.
const TEXT_TAGS = {
  CODE: () => ({code: true}),
  DEL: () => ({strikethrough: true}),
  EM: () => ({italic: true}),
  I: () => ({italic: true}),
  S: () => ({strikethrough: true}),
  STRONG: () => ({bold: true}),
  U: () => ({underline: true}),
  SPAN: (el) => {
    const params = {} as any

    if (el.style.fontStyle === 'italic') {
      params.italic = true;
    }

    if (el.style.fontWeight >= 500) {
      params.bold = true;
    }

    return params
  },
}

const deserialize = el => {
  if (el.nodeType === 3) {
    return el.textContent
  } else if (el.nodeType !== 1) {
    return null
  } else if (el.nodeName === 'BR') {
    return '\n'
  }

  const {nodeName} = el
  let parent = el

  if (
    nodeName === 'PRE' &&
    el.childNodes[0] &&
    el.childNodes[0].nodeName === 'CODE'
  ) {
    parent = el.childNodes[0]
  }
  let children = Array.from(parent.childNodes)
    .map(deserialize)
    .flat()

  if (children.length === 0) {
    children = [{text: ''}]
  }

  if (el.nodeName === 'BODY') {
    return jsx('fragment', {}, children)
  }

  if (ELEMENT_TAGS[nodeName]) {
    const attrs = ELEMENT_TAGS[nodeName](el)
    return jsx('element', attrs, children)
  }

  if (TEXT_TAGS[nodeName] && el.textContent.trim()) {
    const attrs = TEXT_TAGS[nodeName](el)
    return children.map(child => jsx('text', attrs, child))
  }

  return children
}

export const withHtml = editor => {
  const {insertData, isInline, isVoid} = editor

  editor.isInline = element => {
    return element.type === 'link' ? true : isInline(element)
  }

  editor.isVoid = element => {
    return element.type === 'image' ? true : isVoid(element)
  }

  editor.insertData = data => {
    try {
      const html = data.getData('text/html')

      if (html) {
        const parsed = new DOMParser().parseFromString(html, 'text/html')
        const fragment = deserialize(parsed.body)
        Transforms.insertFragment(editor, fragment)
        return
      }

      insertData(data)
    } catch (e) {
      // silence is golden
    }
  }

  return editor
}
