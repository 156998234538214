import styled from 'styled-components'

import {
  DialogTitle as MuiDialogTitle,
  DialogContentText as MuiDialogContentText,
  TextField as MuiTextField,
} from '@material-ui/core'

export const DialogTextWrapper = styled.div`
  padding: 18px 24px;
`

export const DialogText = styled(MuiDialogTitle)`
  &.MuiDialogTitle-root {
    font-family: 'Gellix';
    font-size: 1.2em;
    font-weight: bold;
    padding: 0px 0px;
  }
`

export const DialogContentText = styled(MuiDialogContentText)`
  .MuiDialogContentText-root {
    font-family: 'Gellix';
    font-size: 3em;
  }
`

export const TextField = styled(MuiTextField)`
  &.MuiInputBase-input {
    font-family: 'Gellix';
  }
`
