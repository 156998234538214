import React, { useState } from 'react'
import { Formik } from 'formik'
import * as CPF from 'cpf'
import TextFieldField from '../../components/Fields/TextFieldField/TextFieldField'
import BasePage from '../../components/BasePage/BasePage'
import * as Styled from './RegisterCreator.styled'
import CheckboxField from '../../components/Fields/CheckboxField/CheckboxField'
import * as Yup from 'yup'
import NumberFormatField from '../../components/Fields/NumberFormatField/NumberFormatField'
import {
  createPodcastProfile,
  PodcastProfileCreationProps,
  verifyPathName,
} from '../../services/api'
import { Snackbar } from '@material-ui/core'
import { useGoToRoute } from '../../Routes/RouteAux'
import { getRegisterCreatorOutroPath } from '../../Routes/RouteNames'
import { ApiFetch } from '../../services/ApiFetch'
import ButtonShadow from 'components/ButtonShadow/ButtonShadow'

interface RegisterCreatorFormProps {
  signOut: () => Promise<void>
}

const createCreatorRegisterValidationSchema = Yup.object().shape({
  podcastTitle: Yup.string()
    .required('Campo obrigatório')
    .min(6, 'Tamanho mínimo de 6 caracteres')
    .max(62, 'Tamanho máximo 62 caracteres'),
  podcastUrlPathName: Yup.string()
    .required('Campo obrigatório')
    .min(6, 'Tamanho mínimo de 6 caracteres')
    .max(62, 'Tamanho máximo 62 caracteres')
    .test('pathNameValidation', 'creatorname não disponível', async (value) => {
      if (value) {
        const res = await verifyPathName(value)
        if (res.error) {
          return false
        }
      }
      return true
    }),
  userCpf: Yup.string()
    .required('Campo obrigatório')
    .test('cpfValidation', 'CPF inválido', (value) => CPF.isValid(value)),
  optInTermsOfUse: Yup.boolean()
    .required('É necessário aceitar os termos para continuar')
    .oneOf([true], 'É necessário aceitar os termos para continuar'),
})

const RegisterCreatorForm: React.FC<RegisterCreatorFormProps> = ({
  signOut,
}) => {
  const [errorOnSave, setErrorOnSave] = useState<string>()
  const goToRoute = useGoToRoute()

  const CheckboxText = (
    <div>
      eu estou de acordo com os{' '}
      <a href="https://orelo.audio/legal/tccreators" target="_blank">
        termos gerais para creators
      </a>
      .
    </div>
  )

  const createPodcast = async (
    formValues: PodcastProfileCreationProps,
  ): Promise<ApiFetch> => {
    const res = await createPodcastProfile(formValues)
    if (res.error) {
      setErrorOnSave(res.error.message)
    }
    return res
  }

  return (
    <BasePage signOut={signOut}>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={!!errorOnSave}
        autoHideDuration={10000}
        onClose={() => setErrorOnSave(undefined)}
        message={`Ocorreu um erro inesperado. Tente novamente ou entre em contato com o suporte`}
      />
      <Styled.PageWrapper>
        <Styled.TitleWrapper>
          <Styled.PageSubTitle>Vamos lá!</Styled.PageSubTitle>
          <Styled.PageSubTitle>
            Logo abaixo, você vai preencher informações básicas sobre o seu
            projeto e confirmar alguns dados pessoais.
          </Styled.PageSubTitle>
          <Formik
            initialValues={{
              podcastTitle: '',
              podcastUrlPathName: '',
              userCpf: undefined,
              optInTermsOfUse: undefined,
            }}
            validationSchema={createCreatorRegisterValidationSchema}
            onSubmit={async (values) => {
              const res = await createPodcast(values)
              if (!res.error) {
                goToRoute(getRegisterCreatorOutroPath())
              }
            }}
          >
            {({ handleSubmit, values, touched, errors }) => (
              <Styled.FlexForm>
                <TextFieldField
                  name="podcastTitle"
                  variant="outlined"
                  label="O nome do seu projeto"
                  value={values.podcastTitle}
                  error={!!(touched.podcastTitle && errors?.podcastTitle)}
                  helperText={touched.podcastTitle && errors?.podcastTitle}
                  fullWidth
                  darkMode
                />
                <TextFieldField
                  name="podcastUrlPathName"
                  variant="outlined"
                  label="O seu creatorname (orelo.cc/creatorname)"
                  value={values.podcastUrlPathName}
                  error={
                    !!(touched.podcastUrlPathName && errors?.podcastUrlPathName)
                  }
                  helperText={
                    touched.podcastUrlPathName && errors?.podcastUrlPathName
                  }
                  fullWidth
                  darkMode

                />
                <NumberFormatField
                  name="userCpf"
                  variant="outlined"
                  label="O seu cpf (para a segurança dos seus pagamentos)"
                  fullWidth
                  format={'###.###.###-##'}
                  mask={'_'}
                  value={values.userCpf}
                  error={!!(touched.userCpf && errors?.userCpf)}
                  helperText={touched.userCpf && (errors?.userCpf as string)}
                  hasEmptyValue
                  darkMode

                />
                <CheckboxField
                  name="optInTermsOfUse"
                  label={CheckboxText}
                  color="primary"
                  value={values.optInTermsOfUse}
                  error={!!(touched.optInTermsOfUse && errors?.optInTermsOfUse)}
                  helperText={
                    touched.optInTermsOfUse &&
                    (errors?.optInTermsOfUse as string)
                  }
                />
                <ButtonShadow
                  onPress={() => handleSubmit()}
                  label='Registrar'
                  type='black'
                  style={{ alignSelf: 'center' }}
                />


              </Styled.FlexForm>
            )}
          </Formik>
        </Styled.TitleWrapper>
      </Styled.PageWrapper>
    </BasePage>
  )
}

export default RegisterCreatorForm
