import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface PlayerEpisodeInterface {
  currentTime?: number
  totalPlayedTime?: number
  currentAudioUrl: string
  currentEpisodeId?: string
  title: string
  author?: string
  podcastTitle?: string
  thumbnail?: string
  link?: string
}

interface PlayerStateInterface {
  isShowing: boolean
  buffering?: boolean
  isPlaying?: boolean
  speed: number
  currentEpisode?: PlayerEpisodeInterface
}

const INITIAL_STATE: PlayerStateInterface = {
  isShowing: false,
  buffering: false,
  isPlaying: false,
  speed: 1,
  currentEpisode: {
    currentTime: 0,
    totalPlayedTime: 0,
    currentAudioUrl: '',
    currentEpisodeId: '',
    title: '',
    author: '',
    image: '',
    podcastTitle: '',
    thumbnail: '',
    link: '',
  },
} as any

const player = createSlice({
  name: 'player',
  initialState: INITIAL_STATE,
  reducers: {
    changeEpisode: (state, action: PayloadAction<any>) => {
      state.currentEpisode = action.payload
      state.currentEpisode.currentTime = action.payload.currentTime
      state.isPlaying = true
    },
    setCurrentTime: (state, action: PayloadAction<any>) => {
        state.currentEpisode.currentTime = action.payload
    },
    skipForward: (state) => {
      state.currentEpisode.currentTime += 15
    },
    skipBack: (state) => {
      state.currentEpisode.currentTime -= 15
    },
    showPlayer: (state) => {
      state.isShowing = true
    },
    hidePlayer: (state, action: PayloadAction<any>) => {
      state.isShowing = false
    },
    play: (state) => {
      state.isPlaying = true
    },
    pause: (state) => {
      state.isPlaying = false
    },
  },
})

export const {
  skipForward,
  skipBack,
  showPlayer,
  hidePlayer,
  play,
  pause,
  changeEpisode,
  setCurrentTime
} = player.actions

export default player.reducer
