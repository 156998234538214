import styled from 'styled-components'
import { Colors } from 'styles'
import RichTextEditor from '../../RichTextEditor'

interface BorderProps {
  borderColor?: string
  error?: boolean
  noBorder?: boolean
}

export const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 16px;
`

export const Border = styled.div<BorderProps>`
  ${({ noBorder, error, borderColor }) =>
    !noBorder &&
    `
padding: 20px;
border: 2px solid ${error ? '#f44336' : borderColor || Colors.GREY[400]};
border-radius: 8px;
`}
`

export const RichTextEditorWidth = styled(RichTextEditor)`
  width: 100%;
`

export const StyledLabel = styled.label`
  font-weight: bold;
  font-size: 22px;
`
