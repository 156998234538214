import React, { Fragment, useEffect, useState } from 'react'
import {
  ArticleSummaryDTO,
  EpisodeDTO,
  FeedDTO,
  FeedItemDTO,
  TopicSummaryDTO,
} from '../../types'
import ArticleCard from '../../components/ArticleCard/ArticleCard'
import TopicCard from 'components/TopicCard/TopicCard'
import styled from 'styled-components'
import Button from '../../components/Button/Button'
import { CircularProgress } from '@material-ui/core'
import EpisodeCardWrapper from '../../components/EpisodeCard/EpisodeCardWrapper'
import { logAnalyticsEvent } from '../../services/firebase'
import { getEpisodeDetails } from '../../services/api'
import usePlayer from '../../hooks/usePlayer'
import { PlayerCurrentEpisode } from './PodcastContent'
import ButtonShadow from 'components/ButtonShadow/ButtonShadow'
import isMobile from '../../utils/isMobile'

export const LoadMoreButton = styled(Button)`
  &.MuiButton-root {
    justify-content: flex-start;
    font-size: 16px;
    margin: 16px auto;
    display: block;
  }
`

export const Row = styled.div`
  diplay: flex;
  justify-content: center;
`

export const FullWidth = styled.div`
  width: 100%;
`

interface FeedTabProps {
  podcastImage: string
  podcastId: string
  setOldestFeedItemDate: (timestamp: number) => void
  feedList: FeedDTO
  isFetching: boolean
  playerCurrentEpisode: PlayerCurrentEpisode
  playerIsPlaying: boolean
  setIsPlaying: (isPlaying: boolean) => void
  skipTime: (timeToSkip: number) => void
  forumVisibility: string
  userCanManageForum: boolean
  onDeleteTopic: (topic: FeedItemDTO) => void
}

const FeedTab: React.FC<FeedTabProps> = ({
  podcastImage,
  feedList,
  podcastId,
  setOldestFeedItemDate,
  isFetching,
  playerCurrentEpisode,
  playerIsPlaying,
  setIsPlaying,
  skipTime,
  forumVisibility,
  userCanManageForum,
  onDeleteTopic,
}) => {
  const handleGetMoreData = () => {
    if (feed.length < feedList.items.length) {
      setFeed(feedList.items)
    }

    setOldestFeedItemDate(feedList.oldestItemDate)
  }
  const playerController = usePlayer()
  const [feed, setFeed] = useState<FeedItemDTO[]>()

  useEffect(() => {
    let feedPreview: FeedItemDTO[]
    if (isMobile()) {
      feedPreview = feedList.items.slice(0, 5)
    } else {
      feedPreview = feedList.items
    }

    setFeed(feedPreview)
  }, [])

  return (
    <FullWidth>
      {feed?.map((item, idx) => {
        if (item.itemType === 'episode') {
          const episode = item.item as EpisodeDTO

          return (
            <Fragment key={episode.episodeId}>
              <EpisodeCardWrapper
                idx={idx}
                key={episode.episodeId}
                episode={episode}
                episodeId={episode.episodeId}
                isPlaying={
                  playerCurrentEpisode?.currentEpisodeId ===
                    episode.episodeId && playerIsPlaying
                }
                onPlay={async () => {
                  logAnalyticsEvent('play', {
                    podcastId: episode.podcast.podcastId,
                    episodeId: episode.episodeId,
                    audioLength: episode.duration,
                    episodeName: episode.title,
                    podcastName: episode.podcast.title,
                  })

                  try {
                    const response = await getEpisodeDetails(episode.episodeId)
                    if (response?.error) {
                      playerController.playNewAudio({
                        currentAudioUrl: episode.audioUrl,
                        title: episode.title,
                        link: episode.link,
                        author: episode.author,
                        podcastTitle: episode.podcast.title,
                        currentTime: episode.userProfileData.currentPlayerTime,
                        thumbnail: episode.thumbnail,
                        currentEpisodeId: episode.episodeId,
                      })
                    } else {
                      const episodeUpdated = response.data
                      playerController.playNewAudio({
                        currentAudioUrl: episodeUpdated.audioUrl,
                        title: episodeUpdated.title,
                        link: episodeUpdated.link,
                        author: episodeUpdated.author,
                        podcastTitle: episodeUpdated.podcast.title,
                        currentTime:
                          episodeUpdated.userProfileData.currentPlayerTime,
                        thumbnail: episodeUpdated.thumbnail,
                        currentEpisodeId: episodeUpdated.episodeId,
                      })
                    }
                  } catch (e) {
                    playerController.playNewAudio({
                      currentAudioUrl: episode.audioUrl,
                      title: episode.title,
                      link: episode.link,
                      author: episode.author,
                      podcastTitle: episode.podcast.title,
                      currentTime: episode.userProfileData.currentPlayerTime,
                      thumbnail: episode.thumbnail,
                      currentEpisodeId: episode.episodeId,
                    })
                  }
                }}
                onStop={() => setIsPlaying(false)}
                onRewind={() => skipTime(-30)}
                onSkip={() => skipTime(30)}
                currentTime={
                  playerCurrentEpisode?.currentEpisodeId ===
                    episode.episodeId && playerCurrentEpisode?.currentTime
                }
                podcastId={podcastId}
              />
            </Fragment>
          )
        }

        if (item.itemType === 'article') {
          const articleSummary = item.item as ArticleSummaryDTO

          return (
            <ArticleCard
              idx={idx}
              podcastId={podcastId}
              {...articleSummary}
              image={articleSummary.image || podcastImage}
            />
          )
        }
        if (item.itemType === 'topic') {
          const topic = item.item as TopicSummaryDTO
          return (
            <TopicCard
              {...topic}
              podcastId={podcastId}
              image={topic.image || podcastImage}
              forumVisibility={forumVisibility}
              editable={userCanManageForum}
              handleDelete={() => onDeleteTopic(item)}
            />
          )
        }
      })}

      {feedList.oldestItemDate && !isFetching ? (
        <Row>
          <ButtonShadow
            variant="secondary"
            onPress={() => {
              handleGetMoreData()
            }}
            label={'Carregar mais ...'}
          />
        </Row>
      ) : null}
      {isFetching ? (
        <CircularProgress
          size={48}
          style={{ color: 'white', display: 'block', margin: '16px auto' }}
        />
      ) : null}
    </FullWidth>
  )
}

export default FeedTab
