import styled from 'styled-components'
import { Colors, Typography } from '../../styles'

export const TextEditorWrapper = styled.div.attrs(
  (props: { darkMode?: boolean; preview?: boolean }) => props,
)`
  color: ${({ darkMode }) => (darkMode ? Colors.TEXT_DARK : Colors.GREY[400])};
  text-align: ${({ preview }) => (preview ? 'left' : 'inherit')};
`

export const BaseSpan = styled.span`
  font-family: Inter;
  font-size: 1em;
  word-break: break-word;
  letter-spacing: -${Typography.LETTER_SPACING_1}px;
`

interface ImageProps {
  selected: boolean
  focused: boolean
}

export const Image = styled.img<ImageProps>`
  display: block;
  max-width: 100%;
  max-height: 40em;
  box-shadow: ${(props) =>
    props.selected && props.focused ? '0 0 0 3px #B4D5FF' : 'none'};
  border-radius: 20px;
  margin: auto;
`

interface LinkProps {
  color?: string
}

export const Link = styled.a<LinkProps>`
  ${(props) => (props.color ? `color: ${props.color}` : '')}
`
