import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Button, CircularProgress, IconButton, Slider, Snackbar } from '@material-ui/core'
import { MoreHoriz, Pause, PlayArrow } from '@material-ui/icons'
import { getEpisodeDetails, updatePlayedTime } from 'services/api'
import Avatar from '@material-ui/core/Avatar'
import {
  Author,
  Date,
  Logo,
  PageContent,
  PageWrapper,
  PlayController,
  PlayerCenter,
  PlayerLeft,
  PlayerRight,
  PlayerTimerWrapper,
  PlayerWrapper,
  Row,
  SkipContainer,
  SliderContainer,
  Thumbnail,
  ThumbnailContainer,
  Time,
  RateSelect,
  RateSelectItem,
  Title,
  SkipBack,
  SkipForward,
  BasePlayer,
  LoginButton,
  LoginButtons,
  LoginWrapper,
  UserAvatar,
  UserMenuItem,
  UserMenu,
  OptionsWrapper,
  OptionsContent,
  Column,
  OptionsList,
  OptionsListItem,
  CloseButton,
  LoadingWrapper,
} from './EmbedPlayer.styled'
import * as AuthStyles from './../login/Login.styled'
import LogoImage from './../../assets/images/orelo-v-branco.png'
import ReactPlayer from 'react-player'
import usePlayer from 'hooks/usePlayer'
import { useSelector } from 'react-redux'
import { RootState } from 'reducers'
import { Colors } from 'styles'
import { AuthContext } from 'authentication/context'
import { faFacebookF, faApple } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import GoogleLogo from './../../assets/images/google-logo.png'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import parseSecondsToText from 'utils/parseSecondsToText'
import Icon from 'components/Icon'
import Close from '@material-ui/icons/Close'
import { useParams, useLocation } from 'react-router-dom'

const PROGRESS_INTERVAL = 1000

interface PodcastEpisodeProps {
  signOut: () => Promise<void>
}
const EmbedPlayer: React.FC<PodcastEpisodeProps> = ({ signOut }) => {
  const playerRef = useRef<ReactPlayer>(null)
  const player = useSelector((state: RootState) => state.playerReducer)
  const { facebookSignIn, googleSignIn, appleSignIn, user } = useContext(
    AuthContext,
  ) as {
    facebookSignIn: () => Promise<void>
    googleSignIn: () => Promise<void>
    appleSignIn: () => Promise<void>
    user: any
  }

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<any>(null)
  const [error, setError] = useState<any>(null)
  const [rate, setRate] = useState<string>('1')
  const [duration, setDuration] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)
  const [showAuth, setShowAuth] = useState(false)
  const [showAlertMessage, setShowAlertMessage] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [showOptions, setShowOptions] = useState(false)
  const [totalPlayedTime, setTotalPlayedTime] = useState(0)

  const { episodeId } = useParams<{ episodeId: string }>()

  function useQuery() {
    const { search } = useLocation()
    return React.useMemo(() => new URLSearchParams(search), [search])
  }

  const query = useQuery()

  useEffect(() => {
    if (query.get('background') && document) {
      document.body.style.backgroundColor = query.get('background')
    }
  }, [document, query])

  const playerController = usePlayer()

  const handleAlertMessage = (message?: string) => {
    setAlertMessage(message)
    setShowAlertMessage((prev) => !prev)
  }

  const handleOptions = (state = false) => {
    setShowOptions((prevState) => state || !prevState)
  }

  const handlePressPlay = () => {
    if (user) {
      return setIsPlaying((prev) => !prev)
    }

    handleAlertMessage('Faça login na Orelo, para ter acesso ao audio!')
  }

  const skipTime = (timeToSkip: number) => {
    playerRef.current?.seekTo(
      (playerRef.current ? playerRef.current.getCurrentTime() : 0) + timeToSkip,
      'seconds',
    )
    playerController.updateCurrentTime(playerRef.current.getCurrentTime())
  }

  const skipToTime = (timeToSkip: number) => {
    playerRef.current?.seekTo(timeToSkip, 'seconds')
    playerController.updateCurrentTime(playerRef.current.getCurrentTime())
  }

  const handleDuration = (value: number): void => {
    setDuration(value)
  }

  const handleProgress = (state): void => {
    playerController.updateCurrentTime(state.playedSeconds)

    setTotalPlayedTime((prevState) => prevState + PROGRESS_INTERVAL / 1000)

    updatePlayedTime(episodeId, {
      currentTime: state.playedSeconds,
      duration: duration,
      totalPlayedTime: totalPlayedTime,
    })
  }

  const handleGetEpisodeData = useCallback(async () => {
    setLoading(true)

    try {
      const response = await getEpisodeDetails(episodeId)
      if (response?.error) {
        return setError(response?.error)
      }
      return setData(response?.data)
    } catch (e) {
      setError(e?.response || true)
    } finally {
      setLoading(false)
    }
  }, [data])

  useEffect(() => {
    setLoading(false)
    setError(false)
    handleGetEpisodeData()
  }, [])

  const LoginArea = () => {
    return (
      <LoginButton onClick={() => setShowAuth(true)} variant="text">
        Login
      </LoginButton>
    )
  }

  const User = () => {
    return (
      <UserAvatar>
        <PopupState variant="popover">
          {(popupState) => (
            <>
              <Button
                {...bindTrigger(popupState)}
              >
                <Avatar src={user?.photoURL} />
              </Button>
              <UserMenu keepMounted {...bindMenu(popupState)}>
                <UserMenuItem onClick={signOut}>Logout</UserMenuItem>
              </UserMenu>
            </>
          )}
        </PopupState>
      </UserAvatar>
    )
  }

  const OptionItem = ({
    icon,
    label,
    action,
  }: {
    icon: string
    label: string
    action: () => void
  }) => {
    return (
      <OptionsListItem onClick={action}>
        <Icon icon={icon} />
        {label}
      </OptionsListItem>
    )
  }

  const Options = () => {
    const handleOpenLink = () => {
      window.open(`https://orelo.cc/episode/${data.episodeId}`)
    }

    const handleCopyLink = async () => {
      if ('clipboard' in navigator) {
        await navigator.clipboard.writeText(
          `https://orelo.cc/episode/${data.episodeId}`,
        )
      } else {
        document.execCommand(
          'copy',
          true,
          `https://orelo.cc/episode/${data.episodeId}`,
        )
      }
      alert('Link copiado')
    }

    return (
      <OptionsWrapper visible={showOptions}>
        <OptionsContent>
          <Column flex={1}>
            <OptionsList>
              <OptionItem
                icon="arrow-up-right"
                label="Abrir na Orelo"
                action={handleOpenLink}
              />
              <OptionItem
                icon="share"
                label="Copiar link"
                action={handleCopyLink}
              />
              {!user && (
                <OptionItem
                  icon="orelo-login"
                  label="Login"
                  action={() => setShowAuth(true)}
                />
              )}
            </OptionsList>
          </Column>
          <Column>
            <CloseButton onClick={() => handleOptions(false)}>
              <Close />
            </CloseButton>
          </Column>
        </OptionsContent>
      </OptionsWrapper>
    )
  }

  const Error = () => {
    return (
      <PageWrapper>
        <PageContent>
          <PlayerWrapper>
            <LoginWrapper>
              <Logo>
                <a
                  href={`https://orelo.cc/episode/${episodeId}`}
                  target="_blank"
                  rel="noopener"
                >
                  <img src={LogoImage} />
                </a>
              </Logo>
              <AuthStyles.Text>
                Ocorreu um erro ao carregar os dados do episodio
              </AuthStyles.Text>
              <AuthStyles.Text>
                tente novamente em alguns minutos
              </AuthStyles.Text>
            </LoginWrapper>
          </PlayerWrapper>
        </PageContent>
      </PageWrapper>
    )
  }

  const Auth = () => {
    return (
      <PageWrapper>
        <PageContent>
          <PlayerWrapper>
            <LoginWrapper>
              <AuthStyles.Text>
                Faça login para ter acesso ao audio.
              </AuthStyles.Text>
              <AuthStyles.Text>
                escolha abaixo como prefere fazer o seu login
              </AuthStyles.Text>
              <LoginButtons>
                <AuthStyles.SocialButton
                  onClick={facebookSignIn}
                  variant="contained"
                  buttonColorOnHover="#303030"
                  buttonColor="#414141"
                  rounderEdges
                >
                  <AuthStyles.ButtonContentWrapper>
                    <FontAwesomeIcon
                      icon={faFacebookF}
                      color={Colors.BRAND_SECONDARY}
                      name="facebook"
                    />
                    <div>entrar com facebook</div>
                    <div />
                  </AuthStyles.ButtonContentWrapper>
                </AuthStyles.SocialButton>
                <AuthStyles.SocialButton
                  onClick={googleSignIn}
                  variant="contained"
                  buttonColorOnHover="#303030"
                  buttonColor="#414141"
                  rounderEdges
                >
                  <AuthStyles.ButtonContentWrapper>
                    <AuthStyles.SocialIcon src={GoogleLogo} />
                    <div>entrar com google</div>
                    <div />
                  </AuthStyles.ButtonContentWrapper>
                </AuthStyles.SocialButton>
                <AuthStyles.SocialButton
                  onClick={appleSignIn}
                  variant="contained"
                  buttonColorOnHover="#303030"
                  buttonColor="#414141"
                  rounderEdges
                >
                  <AuthStyles.ButtonContentWrapper>
                    <FontAwesomeIcon
                      icon={faApple}
                      color={Colors.BRAND_SECONDARY}
                      name="appStore"
                    />
                    <div>entrar com apple</div>
                    <div />
                  </AuthStyles.ButtonContentWrapper>
                </AuthStyles.SocialButton>
              </LoginButtons>
            </LoginWrapper>
          </PlayerWrapper>
        </PageContent>
      </PageWrapper>
    )
  }

  const Loading = () => loading && (
    <LoadingWrapper>
      <CircularProgress/>
    </LoadingWrapper>
  ) || null

  if (error) {
    return <Error />
  }

  return showAuth && !user ? (
    <Auth />
  ) : (
    <>
      <PageWrapper background={query.get('background')}>
        <Loading />
        <Options />
        <PageContent>
          <PlayerWrapper>
            <PlayerLeft>
              <ThumbnailContainer>
                <Thumbnail src={data?.thumbnail} />
              </ThumbnailContainer>
            </PlayerLeft>
            <PlayerCenter>
              <Title>{data?.title}</Title>
              <Author>{data?.author}</Author>
              <Date>{data?.releaseDate}</Date>
              <PlayerTimerWrapper>
                <SkipContainer>
                  <SkipBack onClick={() => skipTime(-15)} />
                </SkipContainer>
                <SliderContainer>
                  <Slider
                    onChange={(event, newValue) => skipToTime(Number(newValue))}
                    max={duration}
                    value={player.currentEpisode.currentTime}
                  />
                </SliderContainer>
                <SkipContainer>
                  <SkipForward onClick={() => skipTime(15)} />
                </SkipContainer>
              </PlayerTimerWrapper>
            </PlayerCenter>
            <PlayerRight>
              <Row align="space-between">
                <IconButton onClick={() => handleOptions(true)}>
                  <MoreHoriz fontSize="large" />
                </IconButton>
                <Logo>
                  <a
                    href={`https://orelo.cc/episode/${episodeId}`}
                    target="_blank"
                    rel="noopener"
                  >
                    <img src={LogoImage} />
                  </a>
                </Logo>
                {(!!user && <User />) || <LoginArea />}
              </Row>
              <Row align="space-between">
                <Time>
                  {`${parseSecondsToText(
                    player.currentEpisode.currentTime,
                  )}/${parseSecondsToText(duration)}`}
                </Time>
                <RateSelect
                  className="rate-select"
                  defaultValue={'1'}
                  onChange={(event: any) => setRate(event?.target?.value)}
                >
                  <RateSelectItem value="0.5">0.5x</RateSelectItem>
                  <RateSelectItem value="0.75">0.75x</RateSelectItem>
                  <RateSelectItem value="1">1.0x</RateSelectItem>
                  <RateSelectItem value="1.25">1.25x</RateSelectItem>
                  <RateSelectItem value="1.5">1.5x</RateSelectItem>
                  <RateSelectItem value="1.75">1.75x</RateSelectItem>
                  <RateSelectItem value="2">2x</RateSelectItem>
                </RateSelect>
                <PlayController>
                  <IconButton onClick={handlePressPlay}>
                    {(isPlaying && <Pause />) || <PlayArrow />}
                  </IconButton>
                </PlayController>
              </Row>
            </PlayerRight>
          </PlayerWrapper>
        </PageContent>
      </PageWrapper>
      <BasePlayer
        ref={playerRef}
        url={data?.audioUrl}
        controls
        playing={isPlaying}
        progressInterval={1000}
        onProgress={handleProgress}
        onDuration={handleDuration}
        width={0}
        height={0}
        playbackRate={Number(rate)}
      />
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={showAlertMessage}
        onClose={() => handleAlertMessage('')}
        message={alertMessage}
      />
    </>
  )
}

export default EmbedPlayer
