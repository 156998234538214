import React from 'react'
import { useField } from 'formik'
import { FormHelperText } from '@material-ui/core'
import { NumberFormatProps } from 'react-number-format'
import * as Styled from './NumberFormat.styled'

interface NumberFormatFieldProps extends NumberFormatProps {
  name: string
  hasEmptyValue?: boolean
  label?: string
  error?: boolean
  helperText?: string
  fullWidth?: boolean
  darkMode?: boolean
}

const NumberFormatField: React.FC<NumberFormatFieldProps> = ({
  name,
  label,
  value,
  error,
  helperText,
  hasEmptyValue,
  fullWidth,
  darkMode,
  ...props
}) => {
  const [_field, meta, helpers] = useField(name)

  return (
    <Styled.Wrapper fullWidth={fullWidth}>
      {label && <Styled.StyledLabel htmlFor={name} darkMode={darkMode}>{label}</Styled.StyledLabel>}
      <Styled.StyledNumberFormat
        defaultValue={
          hasEmptyValue ? meta.initialValue : Number(meta.initialValue)
        }
        onValueChange={(value) => helpers.setValue(value.value)}
        id={name}
        value={hasEmptyValue ? value : Number(value)}
        error={error}
        darkMode={darkMode}
        {...props}
      />
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </Styled.Wrapper>
  )
}

export default NumberFormatField
