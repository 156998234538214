import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Avatar } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { isMobile } from 'react-device-detect'

import { useTypedSelector } from 'reducers'

interface WrapperProps {
  readonly imagesLength: number
}

const useStyles = makeStyles((theme) => ({
  root: {

  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: row;
  height: 2rem;
  width: ${({ imagesLength }) => imagesLength * 1.7}rem;
  align-items: center;
  padding-right: 8px;
`

interface CircularImageWrapperProps {
  readonly index: number
}

const StyledAvatar = styled(Avatar)`
  border: 1px solid #fff;
`

const CircularImageWrapper = styled.div<CircularImageWrapperProps>`
  position: absolute;
  padding-left: ${({ index }) => index * 1.4}rem;
`

type FollowerImagesProps = {
  images: string[]
  isFollower: boolean
}

const FollowerImages = ({
  images,
  isFollower,
}: FollowerImagesProps): JSX.Element => {
  const classes = useStyles();
  const [imagesToRender, setImagesToRender] = useState<string[]>(images)

  const currentProfileAvatar = useTypedSelector(
    (state) =>
      state.profiles &&
      state.profiles.currentProfile &&
      state.profiles.currentProfile.avatar,
  )

  useEffect(() => {
    const auxArray = [...images]
    if (isFollower) {
      if (imagesToRender.includes(currentProfileAvatar)) {
        const currentProfileAvatarIndex =
          imagesToRender.indexOf(currentProfileAvatar)

        auxArray.splice(currentProfileAvatarIndex, 1)
        auxArray.push(currentProfileAvatar)
      } else {
        auxArray.splice(auxArray.length - 1, 1)
        auxArray.push(currentProfileAvatar)
      }
    }

    setImagesToRender(
      isMobile && auxArray.length > 6
        ? auxArray.splice(auxArray.length - 6, 6)
        : auxArray,
    )
  }, [images, isFollower])

  return (
    <Wrapper imagesLength={imagesToRender.length}>
      {imagesToRender.map((image, index) => (
        <CircularImageWrapper key={index} index={index}>
          <StyledAvatar src={image} className={classes.small} />
        </CircularImageWrapper>
      ))}
    </Wrapper>
  )
}

export default FollowerImages
