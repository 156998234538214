import React, { useState } from 'react'
import styled from 'styled-components'
import DOMPurify from 'dompurify'
import { Snackbar, Modal } from '@material-ui/core'

import {
  useGoToRoute,
  usePathName,
  createPathWithQueryParam,
} from '../../../../Routes/RouteAux'
import { getLoginPath } from '../../../../Routes/RouteNames'

import { useTypedSelector } from '../../../../reducers'

import { followPodcast, getUserSupports } from '../../../../services/api'

import { FullSupportInformation, SupportData } from '../../../../types'

import FollowerImages from './components/FollowerImages'
import UserMembershipModal from './components/UserMembershipModal'

import Dialog from '../../../../components/Dialog/Dialog'
import { FORUM_FF } from '../../../../services/featureFlags'
import { Colors } from 'styles'
import ButtonShadow from 'components/ButtonShadow/ButtonShadow'
import { LETTER_SPACING_1 } from 'styles/typography'
import Collapse from '../../../../components/Collapse/Collapse'
import isMobile from '../../../../utils/isMobile'
import RichTextEditor from '../../../../components/RichTextEditor'

const Wrapper = styled.div`
  display: flex;
  width: calc(65% - 5rem);
  align-items: center;
  margin: 0 3.5rem;
  color: ${Colors.BLACK};
  flex-direction: column;
  align-self: start;

  @media (max-width: 900px) {
    width: calc(100% - 4rem);
    margin: 20px 2rem;
  }
`

const PodcastImage = styled.img`
  width: 6rem;
  height: 6rem;
  border-radius: 10px;
  margin-bottom: 1rem;
  border: 5px solid ${Colors.GREY[400]};
  z-index: 1;
`

const PodcastImageShadow = styled.div`
  width: 6rem;
  height: 6rem;
  border-radius: 10px;
  margin-bottom: 1rem;
  border: 5px solid ${Colors.GREY[400]};
  transform: translate(0.8rem, 0.8rem);
  position: absolute;
`

const PodcastTitle = styled.div`
  font-size: 2.5rem;
  color: ${Colors.GREY[400]};
  font-weight: 800;
  margin-bottom: 1rem;
  text-align: left;
  font-family: PP-Neue-Machina;
  text-transform: uppercase;
  line-height: 3rem;
  word-break: break-word;

  @media (max-width: 900px) {
    font-size: 1.6rem;
    text-align: left;
    line-height: 1.6rem;
  }
`

export const Description = styled.div`
  word-break: break-word;
  font-size: 14px;
  font-family: Inter;
  letter-spacing: -${LETTER_SPACING_1}px;

  @media (max-width: 900px) {
    font-size: 14px;
    line-height: 100%;
  }
`

const FollowerImagesAndButton = styled.div<{ imagesLength: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 600px) {
    height: auto;
    margin-bottom: 16px;
  }
`
const FollowerImagesContainer = styled.div`
  margin-right: 75px;

  @media (max-width: 500px) {
    margin-right: 0;
  }
`

const Row = styled.div`
  display: flex;
  width: 100%;
  align-self: start;

  @media (max-width: 600px) {
  }
`

const Text = styled.span`
  font-family: Inter;
  color: ${Colors.GREY[400]};
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: -${LETTER_SPACING_1}px;
`

const PodcastInfoContainer = styled.div`
  margin-left: 1.5rem;
  @media (max-width: 400px) {
    margin-left: 24px;
  }
`

const CollapseWrapper = styled.div`
  width: 75%;
`

interface PodcastSummaryInterface {
  podcastFullSupportInformation: FullSupportInformation
  onToggleViewingForum?: Function
  isViewingForum: boolean
  showPodcastOptions: boolean
  onClick?: () => void
}

const PodcastSummary: React.FC<PodcastSummaryInterface> = ({
  podcastFullSupportInformation,
  onToggleViewingForum,
  isViewingForum,
  showPodcastOptions,
  onClick,
}) => {
  const goToRoute = useGoToRoute()
  const pathName = usePathName()
  const loginRoute = createPathWithQueryParam(getLoginPath(), {
    redirectTo: pathName,
  })

  const [followersThumbImages] = useState(
    podcastFullSupportInformation?.followersInfo?.thumbnails.slice(
      0,
      isMobile() ? 3 : 10,
    ),
  )
  const [podcastInfo, setPodcastInfo] = useState(podcastFullSupportInformation)
  const [isLoadingJoinButton, setIsLoadingJoinButton] = useState(false)
  const [joinButtonError, setJoinButtonError] = useState<boolean>()
  const [userMembershipModalOpen, setUserMembershipModalOpen] =
    useState<boolean>(false)
  const [loginDialogOpen, setLoginDialogOpen] = useState<boolean>(false)
  const [wrongProfileDialogOpen, setWrongProfileDialogOpen] =
    useState<boolean>(false)
  const [supportData, setSupportData] = useState<SupportData | null>(null)

  const currentProfile = useTypedSelector(
    (state) => state && state.profiles && state.profiles.currentProfile,
  )

  const openUserMembershipModal = async (): Promise<void> => {
    const supportDataRes = await getUserSupports(
      podcastFullSupportInformation.supportSummary.podcastId,
    )
    setIsLoadingJoinButton(false)
    if (supportDataRes.error && supportDataRes.error.status !== 404) {
      setJoinButtonError(true)
    } else {
      setSupportData(supportDataRes.data)
      setUserMembershipModalOpen(true)
    }
  }

  const onPressButton = async (): Promise<void> => {
    if (!currentProfile) return setLoginDialogOpen(true)
    if (currentProfile.profile !== 'listener')
      return setWrongProfileDialogOpen(true)
    if (!podcastInfo?.userInformation.isFollower) {
      setIsLoadingJoinButton(true)
      const req = await followPodcast(
        podcastFullSupportInformation.supportSummary.podcastId,
      )

      if (req.hasFailed()) {
        setIsLoadingJoinButton(false)
        setJoinButtonError(true)
      } else {
        setPodcastInfo({
          ...podcastInfo,
          userInformation: { ...podcastInfo.userInformation, isFollower: true },
        })
        openUserMembershipModal()
      }
    } else {
      openUserMembershipModal()
    }
  }

  const onForum = () => {
    onToggleViewingForum()
  }

  return (
    <Wrapper>
      <Row>
        <>
          <PodcastImage
            src={podcastInfo?.supportSummary.image}
            onClick={onClick ? onClick : null}
          />
          <PodcastImageShadow />
        </>
        <PodcastInfoContainer>
          <PodcastTitle>{podcastInfo?.supportSummary.title}</PodcastTitle>
          <FollowerImagesAndButton
            imagesLength={followersThumbImages?.length || 0}
          >
            <FollowerImagesContainer>
              <FollowerImages
                images={followersThumbImages || []}
                isFollower={podcastInfo?.userInformation.isFollower}
              />
            </FollowerImagesContainer>
            <Text>
              {podcastFullSupportInformation?.followersInfo?.count} membros
            </Text>
          </FollowerImagesAndButton>
        </PodcastInfoContainer>
      </Row>
      {showPodcastOptions && (
        <>
          <Row
            style={{
              justifyContent: 'start',
              width: '100%',
              gap: 24,
              marginTop: 16,
            }}
          >
            {FORUM_FF && podcastFullSupportInformation.canBeSupported && (
              <ButtonShadow
                variant="secondary"
                onPress={onForum}
                label={isViewingForum ? 'ver conteúdos' : 'ver mural'}
              />
            )}
            {!isLoadingJoinButton && (
              <ButtonShadow
                label={
                  podcastInfo?.userInformation.isFollower
                    ? 'opções'
                    : 'fazer parte'
                }
                variant="primary"
                onPress={onPressButton}
              />
            )}
          </Row>
          <Collapse>
            <CollapseWrapper>
              {podcastInfo?.supportSummary?.supportDescription ? (
                <RichTextEditor
                  readonly
                  initialValue={podcastInfo?.supportSummary?.supportDescription}
                />
              ) : (
                <Description
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      podcastInfo?.supportSummary?.description,
                    ),
                  }}
                />
              )}
            </CollapseWrapper>
          </Collapse>
        </>
      )}

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={!!joinButtonError}
        autoHideDuration={10000}
        onClose={() => setJoinButtonError(undefined)}
        message={`Ocorreu um erro inesperado. Tente novamente ou entre em contato com o suporte`}
      />

      <Dialog
        isDialogOpen={loginDialogOpen}
        handleCloseDialog={() => setLoginDialogOpen(false)}
        dialogText={`você precisa estar logado`}
        dialogActionButtonText={`login`}
        onClickDialogActionButton={() => goToRoute(loginRoute)}
        noActionText="ok"
      />

      <Dialog
        isDialogOpen={wrongProfileDialogOpen}
        handleCloseDialog={() => setWrongProfileDialogOpen(false)}
        dialogText={`Mude para o seu perfil de usuário para realizar essa ação`}
        noActionText="ok"
      />

      <Modal
        open={userMembershipModalOpen}
        onClose={() => setUserMembershipModalOpen(false)}
      >
        <UserMembershipModal
          podcastFullSupportInformation={podcastInfo}
          supportData={supportData}
          closeModal={() => setUserMembershipModalOpen(false)}
          showErrorSnack={() => setJoinButtonError(true)}
        />
      </Modal>
    </Wrapper>
  )
}

export default PodcastSummary
