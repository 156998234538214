import React from 'react'
import {
  Transforms,
  Editor,
  Element as SlateElement,
  Descendant,
  Range as SlateRange,
} from 'slate'
import { useSlate } from 'slate-react'
import isUrl from 'is-url'
import { Button, Icon } from '../components'
import { InsertLink } from '@material-ui/icons'

export type LinkElement = { type: 'link'; url: string; children: Descendant[] }

export const withLinks = (editor) => {
  const { insertData, insertText, isInline } = editor

  editor.isInline = (element) => {
    return element.type === 'link' ? true : isInline(element)
  }

  editor.insertText = (text) => {
    if (text && isUrl(text)) {
      wrapAndOrUnwrapLink(editor, text)
    } else {
      insertText(text)
    }
  }

  editor.insertData = (data) => {
    const text = data.getData('text/plain')

    if (text && isUrl(text)) {
      wrapAndOrUnwrapLink(editor, text)
    } else {
      insertData(data)
    }
  }

  return editor
}

const insertLink = (editor, url) => {
  let urlToSave = url
  if (!url.startsWith('https://')) {
    urlToSave = 'https://' + url
  }
  if (editor.selection) {
    wrapAndOrUnwrapLink(editor, urlToSave)
  }
}

const isLinkActive = (editor) => {
  // @ts-ignore
  const [link] = Editor.nodes(editor, {
    match: (n) =>
      // @ts-ignore
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  })
  return !!link
}

const unwrapLink = (editor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) =>
      // @ts-ignore
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  })
}

const wrapLink = (editor, url) => {
  const { selection } = editor
  const isCollapsed = selection && SlateRange.isCollapsed(selection)
  const link: LinkElement = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: url }] : [],
  }

  if (isCollapsed) {
    Transforms.insertNodes(editor, link)
  } else {
    Transforms.wrapNodes(editor, link, { split: true })
    Transforms.collapse(editor, { edge: 'end' })
  }
}

const wrapAndOrUnwrapLink = (editor, url) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor)
  }

  wrapLink(editor, url)
}

export const LinkButton = ({reversed}: {reversed?: true}) => {
  const editor = useSlate()
  return (
    <Button
      active={isLinkActive(editor)}
      onMouseDown={(event) => {
        if (isLinkActive(editor)) {
          unwrapLink(editor)
        } else {
          event.preventDefault()
          const url = window.prompt('Digite a URL do link:')
          if (!url) return
          insertLink(editor, url)
        }
      }}
      reversed={reversed}
    >
      <Icon>
        <InsertLink />
      </Icon>
    </Button>
  )
}
