import styled from 'styled-components'

export const ReturnText = styled.div`
  font-family: 'Gellix';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #000000;
  padding-left: 8px;
  padding-bottom: 4px;
`

export const ReturnButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`
