import styled from 'styled-components'
import { ReactComponent as TopicIconSvg } from '../../assets/icons/topic.svg';
import { Colors } from 'styles';
import { LETTER_SPACING_1 } from 'styles/typography';

export const TopicCardContainer = styled.div`
`

export const TopicCard = styled.div <{ clickable?: boolean }>`
  display: flex;
  padding-top: 32px;
  ${(props) => props.clickable && `cursor: pointer;`};
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  color: ${Colors.GREY[400]};

  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
`

export const InfosTitleAndDescriptionWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  flex-direction: column;
  justify-content: flex-start;
`

export const InfoAndTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ArticleTitle = styled.div`
  font-family: SpaceGrotesk;
  max-width: 75%;
  font-weight: 700;
  font-size: 24px;
  letter-spacing: -${LETTER_SPACING_1}px;

  @media (max-width: 600px) {
    -webkit-line-clamp: 2;
    font-size: 12px;
  }
`

export const AdditionalInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: 4px;

  @media (max-width: 600px) {
    flex: 0 0 100%;
    margin-top: 16px;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`

export const PaddedText = styled.span`
  padding: 0 8px;
  letter-spacing: -${LETTER_SPACING_1}px;
  font-family: Inter;
  font-weight: 600;
`

export const AdditionalInfo = styled.div`
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Inter;

  @media (max-width: 600px) {
    font-size: 8px;
  }
`

export const Row = styled.div`
  display: flex;
  width: calc(100% - 32px);
  align-items: center;
`

export const RowSpaced = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
`

export const Description = styled.div<{ hasMinHeight?: boolean }>`
  display: -webkit-box;
  width: 400px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;

  .rich-text-editor {
    max-height: 5em;

    p {
      margin: 0;
      font-family: Inter;
      font-weight: 400;
      font-size: 14px;
      line-height: 26px;
    }

    img {
      display: none;
    }

    &:after {
      content: '';
      height: 40px;
      width: 100%;
      position: absolute;
      bottom: 0;
    }
  }

  ${(props) => props.hasMinHeight && `min-height: 84px;`};

  @media (max-width: 600px) {
    font-size: 10px;
    ${(props) => props.hasMinHeight && `min-height: 40px;`}
  }
`

export const AuthorAvatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 30px;
  object-fit: cover;
`

export const ArticleCover = styled.div<{ lowOpacity?: boolean; image: string }>`
  width: 100px;
  height: 100px;
  border-radius: 4px;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-repeat: no-repeat;
  opacity: ${(props) => (props.lowOpacity ? '0.8' : '1')};

  @media (max-width: 600px) {
    width: 80px;
    height: 80px;
  }
`

export const Icon = styled.img`
  width: 80px;
  height: 80px;
  object-fit: scale-down;
  z-index: 1;

  @media (max-width: 600px) {
    width: 40px;
    height: 40px;
  }
`

export const CoverAndIconWrapper = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;

  @media (max-width: 600px) {
    width: 80px;
    height: 80px;
  }
`

export const IconButtonWrapper = styled.div`
`

export const IconButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  color: white;
  position: absolute;
  top: 12px;
  right: 46px;
`

export const TopicIcon = styled(TopicIconSvg) <{ size: 'small' | 'large' }>`
  margin-left: ${(props) => (props.size === 'small' ? '8' : '0')}px;
  width: ${(props) => (props.size === 'small' ? '40' : '80')}px;
  height: ${(props) => (props.size === 'small' ? '40' : '80')}px;
  object-fit: scale-down;
  z-index: 1;

  @media (max-width: 600px) {
    width: 30px;
    height: 30px;
  }
`