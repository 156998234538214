import React, { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import useFetch from '../../hooks/useFetch'
import { FullSupportInformation } from '../../types'
import {
  getPodcastId,
  getSupportInformationByPodcastId,
  getSupportInformationByUrlPathName,
} from '../../services/api'
import BasePage from '../../components/BasePage/BasePage'
import * as Styled from './Podcast.styled'
import Button from '../../components/Button/Button'
import { getHomePath } from '../../Routes/RouteNames'
import { useGoToRoute } from '../../Routes/RouteAux'
import PodcastContent from './PodcastContent'

interface PodcastProps {
  signOut: () => Promise<void>
}

const PodcastContentPage: React.FC<PodcastProps> = ({ signOut }) => {
  const { podcastId } = useParams<{ podcastId: string }>()

  return (
    <PodcastContent signOut={signOut} podcastId={podcastId} avoidRedirect />
  )
}

export default PodcastContentPage
