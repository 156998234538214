import styled from 'styled-components'
import Button from '../Button/Button'
import { Share, LibraryAddCheck } from '@material-ui/icons'
import { Colors } from 'styles'

export const FixedSizeButton = styled(Button)`
  width: 180px;
  height: 30px;

  &.MuiButton-text {
    background-color: transparent;
    color: ${Colors.TEXT_DARK};
    text-align: left;
    justify-content: flex-start;
    padding: 0;
    &:hover {
      background-color: transparent;
    }
    .MuiButton-label {
      text-align: left;
      font-weight: normal;
      font-family: Inter;
    }
  }
`

export const ShareIcon = styled(Share) <{ iconColor?: string }>`
  &.MuiSvgIcon-root {
    font-size: 1.2em;
  }
  color: ${(props) => props.iconColor || Colors.GREY[400]};
`

export const CopiedIcon = styled(LibraryAddCheck) <{ iconColor?: string }>`
  &.MuiSvgIcon-root {
    font-size: 1.2em;
  }
  color: ${(props) => props.iconColor};
`
