import React, {useRef} from 'react'
import {StyleSheet, Animated, Image} from 'react-native'
import styled from 'styled-components/native'
import {Colors, Shapes} from '../styles'

const ForegroundLayer = styled.View`
  height: 100%;
  width: 100%;
  background-color: ${Colors.BRAND_SECONDARY};
  justify-content: center;
`

const PlaceholderContainer = styled.View`
  height: 80%;
  width: 80%;
  align-self: center;
  justify-content: center;
  border-radius: ${Shapes.BORDER_RADIUS_MD}px;
`

const Placeholder = styled(Image)`
  height: 100%;
  width: 100%;
  border-radius: ${Shapes.BORDER_RADIUS_MD}px;
`

const styles = StyleSheet.create({
  imageOverlay: {
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
})

type Props = {
  style?: any
  thumbnail?: string
  image?: string
  resizeMode?: 'contain' | 'cover' | 'stretch' | 'center'
}

const ProgressiveImage = ({
                            style = {},
                            thumbnail,
                            image,
                            resizeMode = 'cover',
                          }: Props): JSX.Element => {
  const imageOpacity = useRef(new Animated.Value(0.1)).current

  const onImageLoaded = (): void => {
    Animated.timing(imageOpacity, {
      toValue: 1,
      duration: 150,
      delay: 5,
      useNativeDriver: true,
    }).start()
  }

  return (
    <ForegroundLayer
      style={{
        ...style,
        borderRadius: style.borderRadius || 0,
      }}
    >
      <PlaceholderContainer>
        <Placeholder
          source={require('./../assets/images/logo-black-on-white.png')}
          style={{opacity: 0.1}}
          resizeMode="contain"
          blurRadius={1}
        />
      </PlaceholderContainer>
      <Animated.View
        style={[
          styles.imageOverlay,
          {
            ...style,
            opacity: imageOpacity,
          },
        ]}
      >
        {!!image && image.length > 0 ? (
          <Image
            style={[
              {
                ...style,
              },
            ]}
            onLoadEnd={onImageLoaded}
            source={{uri: image}}
            resizeMode={resizeMode}
          />
        ) : !!thumbnail && thumbnail.length > 0 ? (
          <Image
            style={[
              {
                ...style,
              },
            ]}
            onLoadEnd={onImageLoaded}
            source={{uri: thumbnail}}
            resizeMode="cover"
          />
        ) : null}
      </Animated.View>
    </ForegroundLayer>
  )
}

export default ProgressiveImage
