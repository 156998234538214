import React from 'react'
import { ButtonProps as MUIButtonProps } from '@material-ui/core'

import { StyledButton } from './Button.styled'

interface ButtonProps extends MUIButtonProps {
  buttonColor?: string
  borderColor?: string
  buttonColorOnHover?: string
  labelColor?: string
  fontSize?: 'small' | 'large'
  searchIcon?: boolean
}

const Button: React.FC<ButtonProps> = ({ searchIcon, children, ...rest }) => {
  const width = window.innerWidth
  return (
    <StyledButton
      {...rest}
      endIcon={
        searchIcon ? (
          <svg
            width={width > 748 ? '17' : '8'}
            height={width > 748 ? '17' : '8'}
            viewBox="0 0 25 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_1_101)">
              <path
                d="M11.2586 21.8818C5.04662 21.8818 0 16.9754 0 10.9458C0 4.91626 5.04662 0 11.2586 0C17.4706 0 22.5172 4.9064 22.5172 10.9458C22.5172 16.9852 17.4706 21.8916 11.2586 21.8916V21.8818ZM11.2586 3.64532C7.12404 3.64532 3.75963 6.91626 3.75963 10.936C3.75963 14.9557 7.12404 18.2266 11.2586 18.2266C15.3932 18.2266 18.7576 14.9557 18.7576 10.936C18.7576 6.91626 15.3932 3.64532 11.2586 3.64532Z"
                fill="black"
              />
              <path
                d="M18.1171 16.7092L15.4587 19.2938L22.352 25.9957L25.0105 23.4111L18.1171 16.7092Z"
                fill="black"
              />
            </g>
            <defs>
              <clipPath id="clip0_1_101">
                <rect width="25" height="26" fill="white" />
              </clipPath>
            </defs>
          </svg>
        ) : (
          <svg
            width={width > 748 ? '47' : '24'}
            height={width > 748 ? '17' : '8'}
            viewBox="0 0 47 17"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M39.8041 0.333496H37.8963C37.8963 3.32914 39.2908 5.98808 41.4455 7.69139H0.33252V9.64227H41.4455C39.2908 11.3456 37.8963 14.0095 37.8963 17.0002H39.8041C39.8041 12.9449 43.0336 9.64227 46.9992 9.64227V7.69139C43.0336 7.69139 39.8041 4.38875 39.8041 0.333496Z"
              fill="black"
            />
          </svg>
        )
      }
    >
      {children}
    </StyledButton>
  )
}

export default Button
