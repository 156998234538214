import React, { useCallback, useState } from 'react'
import BasePage from '../../components/BasePage/BasePage'
import * as Styled from './ArticleEditPage.styled'
import ArticleForm from '../../components/ArticleForm/ArticleForm'
import useFetch from '../../hooks/useFetch'
import { ArticleDTO } from '../../types'
import { getArticle, publishArticle, updateArticle, updateDraftArticle, } from '../../services/api'
import { useParams } from 'react-router-dom'
import Button from '../../components/Button/Button'
import { Snackbar } from '@material-ui/core'
import { useGoToRoute } from '../../Routes/RouteAux'
import { getListArticlesPath } from '../../Routes/RouteNames'

interface EditPodcastArticleProps {
  signOut: () => Promise<void>
}

interface FormValues {
  title: string
  description: string
  body?: string
  visibility: string
  stringNotifyOnPublish: string
  image?: string
}

const EditPodcastArticle: React.FC<EditPodcastArticleProps> = ({ signOut }) => {
  const { podcastId } = useParams<{ podcastId: string }>()
  const { articleId } = useParams<{ articleId: string }>()
  const [errorOnSave, setErrorOnSave] = useState<string>()

  const goToRoute = useGoToRoute()

  const {
    isLoading: isLoadingArticle,
    data: articleInformation,
    error: errorArticle,
    fetchData: fetchArticle,
  } = useFetch<ArticleDTO>(
    useCallback(() => {
      return getArticle(articleId)
    }, [articleId]),
  )

  const saveArticle = async (formValues: FormValues): Promise<void> => {
    const notifyOnPublish = formValues.stringNotifyOnPublish === 'true'

    let res = await updateArticle(articleId, {
      body: formValues.body,
      title: formValues.title,
      description: formValues.description,
      image: formValues.image
    })

    if (res.error) {
      setErrorOnSave(res.error.message)
    }

    if (articleInformation.state !== 'published') {
      res = await publishArticle(articleId, notifyOnPublish, formValues.visibility)

      if (res.error) {
        setErrorOnSave(res.error.message)
      }
    }
  }

  const saveDraftArticle = async (formValues: FormValues): Promise<void> => {
    const res = await updateDraftArticle(articleId, {
      image: formValues.image,
      title: formValues.title,
      description: formValues.description,
      body: formValues.body
    })

    if (res.error) {
      setErrorOnSave(res.error.message)
    }
  }

  if (errorArticle) {
    return (
      <BasePage signOut={signOut} isDark showNavigationBar>
        <Styled.PageWrapper>
          <Styled.MessageWrapper>
            Ops, parece que tivemos um erro aqui.
          </Styled.MessageWrapper>
          <Button
            variant="contained"
            fontColor="black"
            onClick={() => {
              fetchArticle()
            }}
          >
            Tentar novamente
          </Button>
        </Styled.PageWrapper>
      </BasePage>
    )
  }

  return (
    <BasePage signOut={signOut} showNavigationBar>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={!!errorOnSave}
        autoHideDuration={10000}
        onClose={() => setErrorOnSave(undefined)}
        message={`Ocorreu um erro inesperado. Tente novamente ou entre em contato com o suporte`}
      />
      <Styled.PageWrapper>
        <Styled.TitleAndLinkWrapper>
          <Styled.PageTitle>Editar texto</Styled.PageTitle>

          <Styled.ArticleFormWrapper>
            {isLoadingArticle ? (
              <Styled.MessageWrapper>Carregando...</Styled.MessageWrapper>
            ) : (
              <ArticleForm
                initialValues={articleInformation}
                onSubmit={async (values) => {
                  await saveArticle(values)
                  goToRoute(getListArticlesPath(podcastId))
                }}
                isEdit={articleInformation.state !== 'draft'}
                podcastId={podcastId}
                onAutoSave={saveDraftArticle}
                isPublished={articleInformation.state === 'published'}
              />
            )}
          </Styled.ArticleFormWrapper>

        </Styled.TitleAndLinkWrapper>
      </Styled.PageWrapper>
    </BasePage>
  )
}

export default EditPodcastArticle
