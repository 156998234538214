import styled from 'styled-components'
import {
  CircularProgress,
  DialogTitle as MuiDialogTitle,
} from '@material-ui/core'
import Button from '../../components/Button/Button'
import { Form } from 'formik'
import { ReactComponent as QuoteCircledIconSvg } from '../../assets/icons/quote-circled.svg'
import { ReactComponent as PigIconSvg } from '../../assets/icons/pig.svg'
import { ReactComponent as DollarCircledIconSvg } from '../../assets/icons/dollar-circled.svg'
import MicIcon from '@material-ui/icons/Mic'
import { Colors, Typography } from 'styles'
import { ReactComponent as PadlockFillIconSvg } from '../../assets/icons/padlock_fill.svg'
import { ReactComponent as SingleQuote } from '../../assets/icons/single-quote.svg'
import { PlayArrow } from '@material-ui/icons'

export const PageWrapper = styled.div`
  display: flex;
  align-items: start;
  padding: 32px 60px;
  gap: 64px;
  color: ${Colors.GREY[400]};
  flex-direction: column;

  @media (max-width: 600px) {
    gap: 0;
    padding: 16px;
    flex-wrap: wrap;
  }
`

export const TitleAndLinkWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 42px;
  width: 70%;

  @media (max-width: 600px) {
    align-items: center;
    width: 100%;
  }
`

export const MenuButton = styled(Button)`
  &.MuiButtonBase-root {
    .MuiButton-label {
      text-transform: uppercase;
      gap: 8px;
      font-weight: bold;
    }
  }

  @media (max-width: 600px) {
    &.MuiButtonBase-root {
      width: 100%;
    }
  }
`

export const MenuWrapper = styled.div`
  display: flex;
  align-items: end;
  flex-direction: column;

  gap: 14px;

  @media (max-width: 600px) {
    align-items: center;
    width: 100%;
    margin-top: 42px;
  }
`

export const MenuHeader = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;

  text-align: right;
  text-transform: uppercase;

  color: #ffffff;
`

export const ItemGrid = styled.div`
  display: flex;
  gap: 12px;
  padding: 0 6%;
  flex-wrap: wrap;
  flex: 0 0 100%;

  @media (max-width: 600px) {
    padding: 0;
  }
`

interface ItemStylesInterface {
  isDark?: boolean
  textCenter?: boolean
}

export const EmptyStateMesage = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 40px;

  text-align: center;

  color: #000000;

  display: flex;
  align-items: stretch;
  flex-direction: column;
  margin-top: 3rem;

  flex: 1;

  u {
    cursor: pointer;
  }

  @media (max-width: 600px) {
    order: 1;
    margin-top: 0;
  }
`

export const ItemSubHeader = styled.div.attrs(
  (props: ItemStylesInterface) => props,
)`
  font-style: normal;
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 40px;
  border-bottom: 1px solid #000000;
  padding: 12px 32px;
  border-color: ${(props) => (props.isDark ? 'white' : 'black')} !important;
  text-align: ${(props) => (props.textCenter ? 'center' : 'left')};
`

export const ItemIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  margin-right: 12px;

  @media (max-width: 600px) {
    flex-wrap: nowrap;
    display: flex;
  }
`

export const ItemInformation = styled.div.attrs(
  (props: { disabled: boolean }) => props,
)`
  opacity: ${(props) => (props.disabled ? '0.21' : '1')};
  display: flex;
  align-items: center;
  flex: 0 0 95%;
  position: relative;
  cursor: pointer;

  @media (max-width: 600px) {
    flex: 0 0 100%;
  }
`

export const ItemReleaseDate = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem;
  display: inline;
  margin-right: 2rem;

  span {
    margin-left: 4px;
  }
`

export const ItemActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  @media (max-width: 600px) {
    width: 100%;
  }
`

export const ItemBody = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  padding: 32px 32px 48px 32px;
  word-break: break-word;
`

export const ButtonWrapper = styled.div`
  justify-content: end;
  width: 100%;
  display: flex;

  @media (max-width: 600px) {
    justify-content: center;
  }
`

export const PageTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 80px;
  color: #000000;
  margin-top: 16px;
  width: 100%;
  margin-left: 42px;

  @media (max-width: 600px) {
    text-align: center;
    font-size: 2.5rem;
    line-height: normal;
    margin-bottom: 0;
    margin-left: 0;
  }
`

export const MessageWrapper = styled.div`
  margin-top: 50px;
  margin-bottom: 20px;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`

export const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const LoadingCircle = styled(CircularProgress)`
  margin-left: 12px;
`

export const SupportTierCardWrapper = styled.div`
  width: 40%;
  margin-bottom: 16px;

  &.MuiCard-root {
    background-color: #1f1f1f;
    color: white;
  }

  @media (max-width: 600px) {
    width: 90%;
  }
`

export const TiersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`

export const AddTiersButton = styled(Button)`
  align-self: center;

  &.MuiButtonBase-root {
    margin-bottom: 24px;
  }
`

export const ErrorText = styled.div`
  color: red;
  margin-bottom: 12px;
`

export const FlexForm = styled(Form)`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`

export const SearchBarWrapper = styled.div`
  margin: 32px 0 16px 0;
  width: 100%;
`

export const ListHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  margin: 3rem 0 2rem 0;

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;

    color: #ffffff;
    background: black;
    padding: 0.5rem 2rem;
    border: 2px solid #000000;
    border-radius: 99px;

    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background: #000;
      left: 0;
      top: 50%;
      position: absolute;
      z-index: -1;
    }
  }
`

export const QuoteCircledIcon = styled(QuoteCircledIconSvg)`
  width: 1.6rem;
  height: 1.6rem;
`

export const TopWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`

export const MainWrapper = styled.div`
  flex: 0 0 100%;
  gap: 12px;
  display: flex;
  flex-direction: column;
  align-items: baseline;

  @media (max-width: 600px) {
    flex: 0 0 100%;
  }
`

export const AvatarWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 16px;
  width: 100%;
`

export const PictureWrapper = styled.div`
  border-radius: 100%;
  height: 100px;
  flex: 0 0 100px;
  border: 2px solid #000000;
`

export const PodcastImageWrapper = styled.div`
  place-self: flex-start;
  display: flex;
`

export const TitleWrapper = styled.div`
  flex: 0 0 75%;
  font-style: normal;

  margin-left: 32px;

  @media (max-width: 600px) {
    margin-left: 12px;
  }
`

export const CreatorTitle = styled.div`
  margin-bottom: 8px;
  font-family: PP-Neue-Machina;
  font-weight: 800;
  font-size: 3rem;
  color: ${Colors.GREY[400]};
  text-transform: uppercase;

  @media (max-width: 600px) {
    width: calc(100% - 24px);
    font-size: 1.6rem;
  }
`

export const CreatorLink = styled.a`
  font-family: Inter;
  font-size: 1rem;
  font-weight: 400;

  @media (max-width: 600px) {
    font-size: 0.8rem;
  }
`

export const InfoWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;

  @media (max-width: 600px) {
    padding: 0;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
    margin-top: 8px;
  }
`

export const MembersInfoWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  padding-right: 2rem;

  @media (max-width: 600px) {
    padding-right: 1rem;
  }
`

export const SupportersInfoWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
`

export const MembersInfoCount = styled.div`
  font-family: Inter;
  font-weight: 600;
  font-size: 1rem;
  line-height: 40px;
  color: ${Colors.GREY[400]};
`

export const SupportersInfoCount = styled.div`
  font-family: Inter;
  font-weight: 600;
  font-size: 1rem;
  line-height: 40px;
  color: ${Colors.GREY[400]};
`

export const BottomWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`

export const LatestContentsWrapper = styled.div`
  flex: 0 0 47%;

  @media (max-width: 600px) {
    margin-top: 32px;
  }
`

export const LatestContentContainer = styled.div`
  padding: 32px 64px;
  gap: 26px;
  display: flex;
  flex-direction: column;

  @media (max-width: 600px) {
    padding: 32px 24px 32px 48px;
  }
`

export const LatestContentsTitle = styled.div`
  font-family: SpaceGrotesk;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  color: ${Colors.GREY[400]};
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 24px;
`

export const LatestSupportsWrapper = styled.div`
  flex: 0 0 50%;
`

export const LatestSupportsTitle = styled.div`
  font-family: SpaceGrotesk;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  color: ${Colors.GREY[400]};
  font-size: 1.8rem;
  font-weight: 700;
`

export const Table = styled.table`
  padding: 32px 24px;
  width: 100%;
`

export const TableRow = styled.tr`
  border: 0.3px solid #000000;
`

export const TableData = styled.td`
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  color: #000000;
  border-bottom: 1px solid #b5b5b5;
  padding-bottom: 12px;
  text-transform: uppercase;
  border-bottom: 1px solid #b5b5b5;
`

export const PigIcon = styled(PigIconSvg)`
  width: 1.6rem;
  height: 1.6rem;
`

export const MicrophoneCircledIcon = styled(MicIcon)`
  width: 1.4rem;
  height: 1.4rem;
  background-color: black;
  border-radius: 1.4rem;
`

export const DollarCircledIcon = styled(DollarCircledIconSvg)`
  width: 1.6rem;
  height: 1.6rem;
`

export const DialogTextWrapper = styled.div`
  padding: 18px 24px;
`

export const PodcastModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px 12px;
`

export const DialogText = styled(MuiDialogTitle)`
  &.MuiDialogTitle-root {
    font-size: 1.2em;
    font-weight: bold;
    padding: 0px 0px;
  }
`

export const SupportsButtonWrapper = styled.div`
  margin-top: 24px;
  display: flex;

  @media (max-width: 600px) {
    padding: 0;
    flex-direction: column;
  }
`

export const RaisedValue = styled.div`
  font-family: PP-Neue-Machina;
  font-weight: 800;
  font-size: 1.6rem;
  line-height: 40px;
  color: ${Colors.PURPLE[400]};
  margin-right: 0.8rem;

  @media (max-width: 600px) {
    font-size: 1.2rem;
    line-height: 1.2rem;
  }
`

export const RaisedText = styled.div`
  font-family: SpaceGrotesk;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 40px;
  color: ${Colors.GREY[400]};

  @media (max-width: 600px) {
    font-size: 1rem;
    line-height: 1rem;
  }
`

export const PodcastImage = styled.img`
  width: 10rem;
  height: 10rem;
  border-radius: 10px;
  margin-bottom: 1rem;
  border: calc(10rem / 25) solid ${Colors.GREY[400]};
  z-index: 1;

  @media (max-width: 600px) {
    width: 5rem;
    height: 5rem;
    border: calc(5rem / 25) solid ${Colors.GREY[400]};
  }
`

export const PodcastImageShadow = styled.div`
  width: 10rem;
  height: 10rem;
  border-radius: 10px;
  margin-bottom: 1rem;
  border: calc(10rem / 25) solid ${Colors.GREY[400]};
  transform: translate(calc(10rem / 10), calc(10rem / 10));
  position: absolute;

  @media (max-width: 600px) {
    width: 5rem;
    height: 5rem;
    border: calc(5rem / 25) solid ${Colors.GREY[400]};
    transform: translate(calc(5rem / 10), calc(5rem / 10));
  }
`

export const HeaderTable = styled.div`
  display: flex;
  color: ${Colors.GREY[400]};
  width: 100%;
  flex: 0 0 100%;
  border-bottom: 1px solid ${Colors.GREY[200]};
  font-family: SpaceGrotesk;
  font-weight: 600;
`

export const HeaderTableTitle = styled.div`
  flex-grow: 4;
  width: 35%;
  padding-left: 12px;
  font-size: 1.3rem;
  letter-spacing: -${Typography.LETTER_SPACING_1}px;
`

export const HeaderTableLabel = styled.div<{ grow?: number }>`
  flex-grow: ${({ grow }) => (grow ? grow : 1)};
  font-size: 1.3rem;
  letter-spacing: -${Typography.LETTER_SPACING_1}px;
  text-align: center;
`

export const Item = styled.div<{ isOdd?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 12px 0px 12px 16px;
  width: 100%;
  color: ${Colors.GREY[400]};
  background-color: ${({ isOdd }) => (isOdd ? Colors.GREY[50] : Colors.WHITE)};

  @media (max-width: 900px) {
    flex: 0 0 100%;
    order: 2;
  }
`

export const ItemWrapper = styled.div`
  color: black;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 900px) {
    flex-wrap: wrap;
  }
`

export const ItemTitle = styled.div`
  display: flex;
  align-items: center;
  font-family: SpaceGrotesk;
  font-weight: 600;
  font-size: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 4;
  width: 30%;
  gap: 8px;
  margin-right: 16px;

  @media (max-width: 900px) {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 30%;
  }
`

export const ItemPlayCount = styled.div`
  font-weight: 400;
  font-size: 1rem;
  flex-grow: 1;
  font-family: Inter;
  letter-spacing: -${Typography.LETTER_SPACING_1}px;
  text-align: center;

  @media (max-width: 900px) {
    text-align: center;
  }
`

export const ItemValue = styled.div`
  font-weight: 400;
  font-size: 1rem;
  flex-grow: 1;
  font-family: Inter;
  letter-spacing: -${Typography.LETTER_SPACING_1}px;
  text-align: right;
  width: 1.5rem;

  @media (max-width: 900px) {
    text-align: center;
  }
`

export const ItemDescription = styled.div.attrs(
  (props: { disabled: boolean }) => props,
)`
  display: flex;
  align-items: center;
  width: 100%;
`

export const ExclusiveIcon = styled(PadlockFillIconSvg)``
export const QuoteIcon = styled(SingleQuote)`
  margin-right: 4px;
`

export const PlayIcon = styled(PlayArrow)`
  &.MuiSvgIcon-root {
    color: ${Colors.PURPLE[400]};
    width: 24px;
    height: 24px;

    @media (max-width: 900px) {
      width: 24px;
      height: 24px;
    }
  }
`
