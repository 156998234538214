import { Result } from '../core/Result'
import { ValueObject } from './ValueObject'

const hourInSeconds = 60 * 60
const minuteInSecons = 60
const hoursToSeconds = (hours: number): number => hours * hourInSeconds
const minutesToSeconds = (min: number): number => min * minuteInSecons

function toTwoDigits(n: number): string {
  const str = n.toString()
  if (str.length === 1) return `0${str}`
  return str
}

export interface DurationProps {
  value: string
}

/**
 * Internal represantation of the duration af an audio file
 */
export class Duration extends ValueObject<DurationProps> {
  /**
   * Get object value in original form
   */
  get value(): string {
    return this.props.value
  }

  /**
   * Transforme duration to a string
   *
   * @returns string the duration string in the format HH:MM:SS
   */
  public toString(): string {
    return this.props.value
  }

  /**
   * Transforme duration to seconds
   *
   * @return number of seconds represented by current duration
   */
  public toSeconds(): number {
    const [hours, minutes, seconds] = this.props.value
      .split(':')
      .map((str) => parseInt(str, 10))
    return hoursToSeconds(hours) + minutesToSeconds(minutes) + seconds
  }

  private constructor(props: DurationProps) {
    super(props)
  }

  private static isValid(duration: string): boolean {
    const re = /^\d\d:\d\d:\d\d$/
    return re.test(duration)
  }

  private static fromSeconds(n: number): string {
    const seconds = Math.floor(n)
    const hours = toTwoDigits(Math.floor(seconds / hourInSeconds))
    const remainingTime = seconds % hourInSeconds
    const minutes = toTwoDigits(Math.floor(remainingTime / minuteInSecons))
    const s = toTwoDigits(remainingTime % minuteInSecons)
    return `${hours}:${minutes}:${s}`
  }

  private static createFromSeconds(seconds: number): Result<Duration> {
    const durationString = this.fromSeconds(seconds)
    return this.create(durationString)
  }

  /**
   * Create a duration instance using a neutral value in case of an invalid
   * input
   */
  public static createWithDefault(duration: string): Duration {
    if (this.isValid(duration)) {
      return new Duration({ value: duration })
    } else {
      return new Duration({ value: '00:00:00' })
    }
  }

  /**
   * Create a duration instance using a properly formatted string or a number of
   * seconds.
   *
   * The string should have the format HH:MM:SS.
   *
   * Throws if the string is not properly formatted
   */
  public static create(duration?: string | number): Result<Duration> {
    if (duration === undefined) {
      return this.createFromSeconds(0)
    } else if (typeof duration === 'number') {
      return this.createFromSeconds(duration)
    } else if (this.isValid(duration)) {
      return Result.ok<Duration>(new Duration({ value: duration }))
    } else {
      return Result.fail<Duration>(`Duration ${duration} is not valid`)
    }
  }
}
