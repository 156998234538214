const HOUR_IN_SECONDS = 60 * 60
const MINUTE_IN_SECONS = 60

function toTwoDigits(n: number): string {
  const str = n.toString()
  if (str.length === 1) return `0${str}`
  return str
}

const parseSecondsToText = (seconds: number): string => {
  const hours = toTwoDigits(Math.floor(seconds / HOUR_IN_SECONDS))
  const remainingTime = seconds % HOUR_IN_SECONDS
  const minutes = toTwoDigits(Math.floor(remainingTime / MINUTE_IN_SECONS))
  const s = toTwoDigits(Math.round(remainingTime % MINUTE_IN_SECONS))
  return hours === '00' ? `${minutes}:${s}` : `${hours}:${minutes}:${s}`
}

export default parseSecondsToText
