import { CircularProgress } from '@material-ui/core'
import styled from 'styled-components'
import { Colors } from 'styles'

export const EpisodeImage = styled.img<{ lowOpacity?: boolean }>`
  width: 210px;
  height: 210px;
  border-radius: 10px;
  position: absolute;
  background-color: grey;
  filter: brightness(50%);

  opacity: ${(props) => (props.lowOpacity ? '0.8' : '1')};

  @media (max-width: 600px) {
    width: 80px;
    height: 80px;
  }
`

export const PageWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  margin: auto;
  width: 60%;
  color: ${Colors.TEXT_LIGHT};
`
export const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`
export const Thumbnail = styled.div`
  width: 210px;
  height: 210px;
  margin-right: 20px;
  border-radius: 12px;
`
export const Title = styled.h1`
  font-family: Gellix;
  font-weight: bold;
  font-size: 42px;
`
export const Author = styled.div`
  font-family: Fraunces;
  font-size: 27px;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`
export const Column = styled.div<{
  flex?: number
  justify?: string
  align?: string
}>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ justify }) => (justify ? justify : 'inherit')};
  flex: ${({ flex }) => (flex ? flex : 'inherit')};
  align-items: ${({ align }) => (align ? align : 'inherit')};
`
export const Info = styled.div`
  flex-direction: row;
  display: flex;
`
export const InfoItem = styled.div<{ flex?: number }>`
  margin-right: 22px;
`

export const EpisodeControls = styled.div`
  padding: 65px 0;
  display: flex;
  align-items: center;
  flex-direction: row;
`
export const PlayButton = styled.div`
  background-color: #fff;
  width: 75px;
  height: 75px;
  border-radius: 37.5px;
  .MuiIconButton-root {
    height: 100%;
    width: 100%;
  }
  .MuiSvgIcon-root {
    fill: black;
    font-size: 2.5rem;
  }
`
export const ActionsButton = styled.div`
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-radius: 27.5px;
  margin-left: 16px;
  .MuiIconButton-root {
    height: 100%;
    width: 100%;
  }
  .MuiSvgIcon-root {
    fill: black;
    font-size: 2rem;
  }
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  justify-content: flex-start;
  padding-left: 16px;
`
export const Description = styled.div`
  max-width: 900px;
  font-size: 24px;
  font-family: Fraunces;
`
export const Footer = styled.div`
  display: flex;
  padding: 20px 0;
  padding-top: 60px;
`

export const LoaderWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${Colors.GREY[400]};
`

export const Loader = styled(CircularProgress)`
  color: ${Colors.GREY[400]};
`