import cardValidator from 'card-validator'

export const cardNumberValidator = (number: string): boolean => {
  const numberValidation = cardValidator.number(number)
  return numberValidation.isValid
}

export const cvvValidator = (cvv: string): boolean =>
  cvv.length === 3 || cvv.length === 4

type ValidThruValidator = {
  isValid: boolean
  message?: string
}

export const validThruValidator = (validThru: string): ValidThruValidator => {
  if (validThru.length < 5) {
    return {
      isValid: false,
      message: 'Data incompleta',
    }
  }

  const currentYear = new Date().getFullYear()
  const currentYear2Digit = Number(currentYear.toString().substr(2, 2))
  const currentMonth = new Date().getMonth() + 1

  const [month, year] = validThru.split('/')

  if (Number(month) < 1 || Number(month) > 12) {
    return {
      isValid: false,
      message: 'Mês inválido',
    }
  }
  if (
    Number(year) < currentYear2Digit ||
    (Number(year) === currentYear2Digit && Number(month) < currentMonth)
  ) {
    return {
      isValid: false,
      message: 'Cartão expirado',
    }
  }

  return { isValid: true }
}

export const phoneValidator = (phone: string): boolean => {
  const phoneRegex =
    /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/
  const rawPhone = phone
    .split('')
    .filter(
      (char) => char !== '(' && char !== ')' && char !== '-' && char !== ' ',
    )
    .join('')

  return phoneRegex.test(rawPhone)
}

export const zipCodeValidator = (zipCode: string): boolean => {
  const zipCodeRegex = /^[0-9]{5}-[0-9]{3}$/
  const rawZipCode = zipCode.split('').join('')

  return zipCodeRegex.test(rawZipCode)
}
