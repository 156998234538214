import React from 'react'
import { Button, Menu, MenuItem, Select } from '@material-ui/core'
import Icon from 'components/Icon'
import ButtonIcon from '@material-ui/core/IconButton'

import styled from 'styled-components'
import { Colors } from 'styles'
import ReactPlayer from 'react-player'

export const Column = styled.div<{ flex?: number; vAlign?: string }>`
  display: flex;
  flex-direction: column;
  flex: ${({ flex }) => flex || 'inherit'};
  justify-content: ${({ vAlign }) => vAlign || 'center'};
  height: 100%;
`
export const OptionsList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  height: 100%;
  justify-content: space-evenly;
`
export const OptionsListItem = styled.li`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  svg {
    height: 30px;
    margin-right: 20px;
  }
`

export const CloseButton = styled(ButtonIcon)`
  &.MuiIconButton-root {
    position: absolute;
    right: 20px;
    top: 20px;
  }
`

export const LoadingWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${Colors.TEXT_DARK};
  z-index:9;
  opacity:0.8;
`

export const OptionsWrapper = styled.div<{ visible: boolean }>`
  position: absolute;
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  font-size: 24px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 9;
  background: ${Colors.TEXT_DARK};
  border-radius: 12px;
  overflow: hidden;
  color: white;
  .MuiIconButton {
    color: white;
  }
  .MuiSvgIcon-root {
    color: white;
  }
  @media (max-width: 600px) {
    font-size: 12px;
  }
`

export const PageWrapper = styled.div<{background?: string}>`
  font-size: 24px;
  position: relative;
  background-color: ${({background}) => background || 'inherit'}
  .MuiIconButton {
    color: white;
  };
  .MuiSvgIcon-root {
    color: white;
  };
  @media (max-width: 600px) {
    font-size: 12px;
  };
`
export const LoginButton = styled(Button)`
  &.MuiButton-root {
    color: white;
    .MuiButton-label {
      text-transform: lowercase;
      font-size: 18px;
      padding: 0 15px;
    }
  }
`
export const OptionsContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 100%;
  background: ${Colors.TEXT_DARK};
`
export const PageContent = styled.div``
export const PlayerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 180px;
  background-color: ${Colors.TEXT_DARK};
  color: ${Colors.TEXT_LIGHT};
  font-size: 18px;
  border-radius: 10px;
  padding: 30px;
  @media (max-width: 425px) {
    flex-direction: column;
  }
`
export const ThumbnailContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 180px;
  max-height: 180px;
  overflow: hidden;

  @media (max-width: 1200px) {
    max-width: 150px;
    max-height: 150px;
  }
  @media (max-width: 900px) {
    max-width: 100px;
    max-height: 100px;
  }
`
export const Thumbnail = styled.img`
  border-radius: 12px;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
`
export const PlayerLeft = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
`
export const PlayerCenter = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 20px;
`
export const PlayerRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 425px) {
    flex-direction: column-reverse;
  }
`

export const Title = styled.h1`
  font-size: 2em;
  margin-top: 0;
  font-family: Gellix;
  @media (max-width: 1200px) {
    font-size: 1.8em;
  }
  @media (max-width: 900px) {
    font-size: 1.2em;
  }
  @media (max-width: 425px) {
    text-align: center;
    font-size: 1em;
  }
`
export const Author = styled.h3`
  font-size: 27px;
  font-family: Fraunces;
  font-weight: normal;
  @media (max-width: 425px) {
    text-align: center;
    font-size: 1em;
  }
`
export const Date = styled.div`
  font-size: 15px;
`

export const PlayerTimerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  min-height: 55px;
  .MuiSlider-root {
    color: ${Colors.MILK_WHITE};
    .MuiSlider-thumb {
    }
    .MuiSlider-thumb::after {
    }
  }
`

export const SkipContainer = styled.div`
  margin: 0;
  .MuiButtonBase-root {
    &:first-child {
      padding: 0 !important;
    }
  }
`

export const SliderContainer = styled.div`
  flex: 1;
  padding: 0 15px;
`

export const Row = styled.div<{
  align?:
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ align }) => align || 'space-between'};
  min-height: 55px;
  @media (min-width: 415px) {
    margin-top: 10px;
  }
`
export const Logo = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  img {
    object-fit: contain;
  }
`
export const UserAvatar = styled.div`
  position: relative;
  .MuiButton-root {
    background-color: white;
    border-radius: 33px;
    width: 75px;
    height: 33px;
    position: relative;
  }

  .MuiAvatar-root {
    height: 57px;
    width: 57px;
    border: 2px solid white;
  }
`
export const UserMenuItem = styled(MenuItem)`
  &.MuiListItem-button {
    font-weight: bold;
  }
`
export const UserMenu = styled(Menu)`
  .MuiMenu-paper {
    margin-top: 60px;
  }
`

export const Time = styled.div`
  font-size: 16px;
  margin: 2px 12px;
  width: 90px;
  text-align: right;
`

export const TimeRate = styled.div`
  margin: 2px 12px;
`
export const LoginButtons = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  .MuiButton-root {
    margin: 10px 2.5px;
  }
`
export const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const PlayController = styled.div`
  margin: 2px 12px;
  height: 57px;
  width: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 28.5px;
  .MuiButtonBase-root {
    width: 100%;
    height: 100%;
  }
  .MuiSvgIcon-root {
    color: black;
    font-size: 32px;
  }
`
export const RateSelect = styled(Select)`
  &.MuiInputBase-root {
    color: #ccc;
    padding-left: 14px;
  }
`
export const IconImage = styled(Icon)`
  transition: color 300ms ease-in-out;
  color: ${Colors.LIGHT_WHITE};
  &:hover {
    color: ${Colors.MILK_WHITE};
    cursor: pointer;
  }
`
export const IconButton = styled(ButtonIcon)`
  .MuiIconButton-label {
    width: 100%;
    display: flex;
    align-items: inherit;
    justify-content: inherit;
    height: 50px;
    width: 31px;
    font-size: 16px;
  }
`

export const SkipBack = styled.div.attrs((props) => ({
  ...props,
  children: (
    <IconButton>
      <IconImage icon="skip-back" />
    </IconButton>
  ),
}))``

export const SkipForward = styled.div.attrs((props) => ({
  ...props,
  children: (
    <IconButton>
      <IconImage icon="skip-forward" />
    </IconButton>
  ),
}))``

export const RateSelectItem = styled(MenuItem)``

export const BasePlayer = styled(ReactPlayer)``
