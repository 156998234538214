import {
  CardContent,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup
} from '@material-ui/core'
import styled from 'styled-components'
import {Card, InputLabel} from '@material-ui/core'
import {Colors} from "../../styles";
import Button from "../../components/Button/Button";

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  margin: auto;
  width: 60%;
  color: #e4e4e4;
`

export const MessageWrapper = styled.div`
  margin-top: 50px;
  margin-bottom: 20px;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`

export const PageTitle = styled.div`
  font-size: 60px;
  font-weight: bold;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 60px;
  margin-bottom: 40px;
  text-align: center;
`

export const CardsWrapper = styled.div`
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
`

export const CardsLine = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`

export const Anchor = styled.a`
  color: white;
  margin-top: 20px;
  text-decoration: underline;

  &:focus,
  &:visited,
  &:active {
    text-decoration: none;
  }
`

export const Text = styled.div`
  margin: 24px 0;

`

export const CardItem = styled(Card)`
  width: 100%;

  &.MuiPaper-outlined {
    background-color: ${Colors.TEXT_DARK};
    color: #fff;
    .MuiCardContent-root {
      input, .MuiInputBase-multiline {
        background-color: ${Colors.TEXT_DARK};
        margin-top: 7px;
        border: 1px solid ${Colors.TEXT_LIGHT};
        color: ${Colors.TEXT_LIGHT};
      }
      textarea {
        color: ${Colors.TEXT_LIGHT};
      }
    }
  }

  &.MuiPaper-rounded {
    border-radius: 12px;
  }
`

export const CardTitleAndValue = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
  b {
    color: #fff;
  }
`
export const FormControlWrapper = styled(FormControl)`
  flex: 1;
  margin-left: 24px !important;
`

export const StyledLabel = styled.label`
  font-weight: bold;
  font-size: 22px;

  &.MuiFormLabel-root {
    font-family: 'Gellix';
  }
`

export const TitleWrapper = styled.div`
  width: 40%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media (max-width: 600px) {
    align-items: center;
    width: 95%;
  }
`

export const PageSubTitle = styled.div`
  font-size: 32px;
  font-weight: bold;
  width: 100%;
  margin: 24px 0;
  text-align: center;

  @media (max-width: 600px) {
    text-align: center;
  }
`

export const LargeButton = styled(Button)`
  width: 250px;
  height: 32px;
  align-self: center;
  &.MuiButtonBase-root {
    margin: 24px 0;
  }
`

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LoadingSpinner = styled(CircularProgress)`
  margin-right: 15px;
`