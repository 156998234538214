import React from 'react'
import * as Styled from './HomeFooter.styled'
import logo_dark from '../../../../assets/images/home/logo_dark.png'
import logo_name_dark from '../../../../assets/images/home/logo_name_dark.png'

const HomeFooter: React.FC<{}> = () => {
  return (
    <Styled.SectionWrapper>
      <Styled.BrandRow>
        <Styled.LogoImg src={logo_dark} alt="versão dark da logo da Orelo" />
        <Styled.BrandImg
          src={logo_name_dark}
          alt="versão dark da logo da Orelo"
        />
      </Styled.BrandRow>
      <Styled.SloganRow>
        <Styled.UppercaseText>
          LEIA ASSISTA OUÇA VEJA APOIE
        </Styled.UppercaseText>
        <Styled.Text style={{ fontFamily: 'Inter' }}>
          Assine o conteúdo dos seus Creators preferidos e fortaleça a
          comunidade criativa
        </Styled.Text>
      </Styled.SloganRow>
    </Styled.SectionWrapper>
  )
}

export default HomeFooter
