import styled from 'styled-components'
import { Button } from '@material-ui/core'

export const StyledButton = styled(Button) <{
  buttonColor?: string
  buttonColorOnHover?: string
  fontColor?: string
  fontSize?: 'small' | 'large'
  rounderEdges?: boolean
  borderColor?: string
  fontFamily?: string
  size?: 'small' | 'large' | 'wider'
}>`  
  &.MuiButton-root {
    text-transform: none;
    font-size: ${(p) => {
    if (p.fontSize && p.fontSize === 'large') return '1.3rem'
    if (p.fontSize && p.fontSize === 'small') return '0.8rem'

    return '16px'
  }};
    border-radius: ${(p) => (p.rounderEdges ? '60px' : '20px')};
    border-width: ${({ borderColor, size }) => {
    if (borderColor) {
      return size === 'small' ? '2px' : '1px'
    }
    return '0'
  }};
    border-style: ${({ borderColor }) => (borderColor ? 'solid' : '0')};
    border-color: ${({ borderColor }) =>
    borderColor ? borderColor : 'inherit'};
    font-family: ${({ fontFamily }) => fontFamily ? fontFamily : 'PP-Neue-Machina'};
    font-weight: 500;
    line-height: ${({ size }) => size === 'large' ? '2.5em' : '2em'};
    padding: ${({ size }) => size === 'small' ? '2px 24px' : '4px 42px'};
  }
;

  @media (max-width: 600px) {
    font-size: ${(p) => {
    if (p.fontSize && p.fontSize === 'large') return '24px'

    return '12px'
  }};
    padding: 6px 6px;
  }
}

&.MuiButton-contained {
  background-color: ${(p) => p.buttonColor};
  color: ${(p) => (p.fontColor ? p.fontColor : '#ffffff')};

  &:hover {
    background-color: ${(p) => p.buttonColorOnHover};
  }
}

&.MuiButton-outlined {
  &:hover {
    background-color: ${(p) => p.buttonColorOnHover};
  }
}

&.MuiButtonBase-root.MuiButton-outlined {
  color: ${(p) => (p.fontColor ? p.fontColor : '#ffffff')};
}

&.MuiButtonBase-root {
  background-color: ${(p) => p.buttonColor};
  opacity: ${props => props.disabled ? '0.21' : '1'};
  
  .MuiButton-label {
    color: ${(p) => (p.fontColor ? p.fontColor : '#ffffff')};
  }

  &:hover {
    background-color: ${(p) => p.buttonColorOnHover};
  }
}
`
