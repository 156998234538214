import styled from 'styled-components'
import { Colors } from 'styles'

interface ButtonProps {
  backgroundColor?: string
  shadowColor?: string
  fontColor?: string
  size?: string
  leftIcon?: boolean
  hoverColor?: string
  disabled?: boolean
  noBorder?: boolean
}

export const ButtonShadowWrapper = styled.div<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 2rem;
  margin: 8px 0px 8px 0px;
  cursor: pointer;
  ${({ disabled }) => disabled && 'opacity: 0.8;'}
  ${({ noBorder, shadowColor }) =>
    !noBorder
      ? ` 
    border: 1px solid ${Colors.GREY[400]};
    box-shadow: 3px 3px 1px 0px ${shadowColor},
    5px 5px 0px 1px ${Colors.GREY[400]};
    padding: 0.8rem 1rem;
  `
      : `
    text-decoration: underline;
    padding: 0.8rem 0rem;
  `}

  &:hover {
    background-color: ${(p) => p.hoverColor};
  }
`

export const ButtonShadowLabel = styled.span<ButtonProps>`
  font-family: PP-Neue-Machina;
  text-transform: uppercase;
  text-align: center;
  color: ${({ fontColor }) => fontColor};
  font-size: ${({ size }) => (size === 'small' ? 12 : 16)}px;
  font-weight: 800;
  ${({ leftIcon }) => leftIcon && 'margin-left: 8px;'}
`
