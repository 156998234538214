import React, { useState } from 'react'
import styled from 'styled-components'
import { CircularProgress, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { Topic as TopicType } from "../../types";
import { createTopicComment } from "../../services/api";
import { Colors, Typography } from 'styles'
import ButtonShadow from 'components/ButtonShadow/ButtonShadow'

const ModalWrapper = styled.div`
  display: flex;
  align-self: center;
  justify-content: space-around;
  width: 80%;
  height: 80%;
  margin: auto;
  margin-top: 5%;
  flex-direction: column;
  align-items: center;
  padding-horizontal: 1rem;
  background-color: ${Colors.GREY[50]};
  color: ${Colors.GREY[400]};
  border-radius: 1rem;

  @media (max-width: 600px) {
    width: 100%;
    height: 100%;
    margin-top: 0;
    border-radius: 0;
  }
`

const IconButtonWrapper = styled.div`
  width: 100%;
  padding-top: 0.5rem;
  padding-right: 1rem;
  display: flex;
  justify-content: flex-end;
`

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 50%;
  justify-content: center;
  align-items: center;
  color: #e4e4e4;
`

const UserMembershipTitle = styled.div`
  font-size: 1.6rem;
  color: black;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;
  font-family: SpaceGrotesk;
  letter-spacing: -${Typography.LETTER_SPACING_2}px;
  color: ${Colors.GREY[400]};
`

const PodcastImage = styled.img`
  width: 8rem;
  height: 8rem;
  border-radius: 10px;
  margin-bottom: 1rem;
`

const PodcastTitle = styled.div`
  font-size: 1.5rem;
  color: black;
  margin-bottom: 1rem;
  text-align: center;
  font-family: Inter;
  @media (max-width: 600px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`

const CircularButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  align-self: center;
  justify-content: center;
  gap: 24px;
`

const TextInput = styled.textarea`
  height: 20%;
  width: 100%;
`

type FollowerImagesProps = {
  topic: TopicType
  closeModal: () => void
}

const CommentModal = ({
  topic,
  closeModal,
}: FollowerImagesProps): JSX.Element => {

  const [commentBody, setCommentBody] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)

  const onSend = async () => {
    setIsLoading(true);

    const req = await createTopicComment(topic.id, commentBody)

    if (req.hasFailed()) {
      alert('Não foi possível salvar seu comentário! Por favor tente novamente mais tarde.')
    } else {
      setCommentBody('')
      closeModal()
    }

    setIsLoading(false);
  }

  if (isLoading) {
    return (
      <ModalWrapper>
        <CircularProgress />
      </ModalWrapper>
    )
  }

  return (
    <ModalWrapper>
      <IconButtonWrapper>
        <IconButton onClick={closeModal} size="small">
          <CloseIcon />
        </IconButton>
      </IconButtonWrapper>

      <ModalContentWrapper>
        <UserMembershipTitle>Adicionar comentário</UserMembershipTitle>
        <PodcastTitle>
          {topic.title}
        </PodcastTitle>

        <TextInput onChange={(e) => setCommentBody(e.target.value)} />

        <CircularButtonsWrapper>
          <ButtonShadow
            onPress={onSend}
            label={'enviar'}
            size="regular"
          />
          <ButtonShadow
            variant='secondary'
            onPress={closeModal}
            label="voltar"
            size="regular"
          />
        </CircularButtonsWrapper>
      </ModalContentWrapper>
    </ModalWrapper>
  )
}

export default CommentModal
