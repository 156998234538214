import styled from 'styled-components'
import { ReactComponent as Site1 } from '../../../../assets/images/home/site1.svg'
import HeroBg from '../../../../assets/images/home/hero_bg.svg'
import { Colors } from 'styles'

export const SectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px solid #212427;
  margin-top: 35px;
  margin-right: -1px;

  @media (max-width: 1023px) {
    background-image: url(${HeroBg});
    background-size: cover;
    margin-top: 30px;
  }
`
export const TextWrapper = styled.div`
  margin-left: 80px;
  width: 30%;
  align-self: center;

  @media (max-width: 1023px) {
    background-color: ${Colors.WHITE};
    border: 2px solid ${Colors.GREY[400]};
    box-shadow: 6px 6px 0px ${Colors.GREY[400]};
    justif-content: center;
    width: 80%;
    margin: 30px auto;
    padding: 32px;
    border-radius: 8px;
  }
`
export const MainTitle = styled.h1`
  text-transform: uppercase;
  margin-bottom: 0px;
  font-size: 4rem;
  color: #212427;

  @media (max-width: 767px) {
    font-size: 2.75rem;
  }
`
export const MainTitleStroke = styled(MainTitle)`
  text-shadow: -1px 0 #212427, 0 1px #212427, 1px 0 black, 0 -1px #212427;
  color: white;
  margin: 0px auto;
`

export const Description = styled.p`
  text-transform: uppercase;
  font-size: 1.25rem;
  line-height: 125%;
  padding-bottom: 25px;
  color: #212427;

  @media (max-width: 767px) {
    font-size: 0.8rem;
    line-height: 100%;
    padding-bottom: 10px;
  }
`

export const ImageWrapper = styled.div`
  max-width: 60%;
  object-fit: contain;
  margin-bottom: -4px;

  @media (max-width: 1023px) {
    display: none;
  }
`

export const Image = styled(Site1)`
  max-height: 600px;
`

