import React, { forwardRef, SyntheticEvent } from 'react'
import styled from 'styled-components'

interface BaseProps {
  children:
    | React.ReactChildren
    | React.ReactChildren[]
    | React.ReactChild
    | React.ReactChild[]
}

interface ButtonProps extends BaseProps {
  active: boolean
  reversed?: boolean
  onMouseDown: (event: SyntheticEvent) => void
}

const StyledButton = styled.span<ButtonProps>`
  cursor: pointer;
  color: ${(props) =>
    props.reversed
      ? props.active
        ? 'white'
        : '#aaa'
      : props.active
      ? 'black'
      : '#ccc'};
`

export const Button = forwardRef<HTMLSpanElement, ButtonProps>((props, ref) => (
  <StyledButton {...props} ref={ref} />
))

const StyledIcon = styled.span`
  font-size: 18px;
  vertical-align: text-bottom;
`

export const Icon = forwardRef<HTMLSpanElement, BaseProps>((props, ref) => (
  <StyledIcon {...props} ref={ref} />
))

const StyledMenu = styled.div`
  & > * {
    display: inline-block;
  }
  & > * + * {
    margin-left: 15px;
  }
`

export const Menu = forwardRef<HTMLDivElement, BaseProps>((props, ref) => (
  <StyledMenu {...props} ref={ref} />
))

const StyledToolbar = styled(Menu)`
  position: relative;
  padding: 1px 18px 17px;
  margin: 0 -20px;
  border-bottom: 2px solid #eee;
  margin-bottom: 20px;
`

export const Toolbar = forwardRef<HTMLDivElement, BaseProps>((props, ref) => (
  <StyledToolbar {...props} ref={ref} />
))
