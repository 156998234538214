import React from 'react'
import { ButtonProps as MUIButtonProps } from '@material-ui/core'

import { StyledButton } from './Button.styled'

interface ButtonProps extends MUIButtonProps {
  buttonColor?: string
  buttonColorOnHover?: string
  borderColor?: string
  fontColor?: string
  fontSize?: 'small' | 'large'
  rounderEdges?: boolean,
  fontFamily?: string,
  size?: 'large' | 'small',
}

const Button: React.FC<ButtonProps> = ({ children, ...rest }) => {
  return <StyledButton {...rest}>{children}</StyledButton>
}

export default Button
