import { MenuItem, Select, Slider } from '@material-ui/core'
import ButtonIcon from '@material-ui/core/IconButton'
import CloseIconSvg from '@material-ui/icons/Close'

import Icon from 'components/Icon'
import React from 'react'
import ReactPlayer from 'react-player'
import styled from 'styled-components'
import { Colors, Typography } from 'styles'

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  background-color: ${Colors.PURPLE[400]};
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 9;
  box-shadow: 0px -7px 43px -13px ${Colors.SHADE_DARKEST};
`

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 20px 0;
  color: ${Colors.WHITE};
  font-family: SpaceGrotesk;
  letter-spacing: -${Typography.LETTER_SPACING_1}px;

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 20px 10px;
  }
`

export const Column = styled.div<{ flex?: number }>`
  display: flex;
  flex-direction: column;
  flex: ${(props) => props.flex || 'inherit'};
`
export const Row = styled.div<{ align?: string }>`
  display: flex;
  flex-direction: row;
  align-items: ${({ align }) => align || 'inherit'};
`
export const ButtonContainer = styled.div``
export const ImageWrapper = styled.div`
  width: 100px;
  height: 80px;
  margin: 0 20px 0 20px;
  overflow: hidden;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 12px;
  }
`
export const Title = styled.div`
  color: ${Colors.MILK_WHITE};
  letter-spacing: 0.01785714em;
  font-size: 1.2rem;
  font-weight: 800;
  line-height: 1.25rem;
  display: inline-block;
  overflow: visible;
  white-space: nowrap;
  @media (max-width: 1024px) {
    white-space: break-spaces;
  }
`

export const PodcastTitle = styled.div`
  color: ${Colors.MILK_WHITE};
  letter-spacing: 0.01785714em;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  display: inline-block;
  overflow: visible;
  white-space: nowrap;
`

export const TextInfo = styled.div`
  color: ${Colors.WHITE};
  font-size: 16px;
  margin-top: 16px;
  font-family: Inter;

  a {
    color: ${Colors.WHITE};
  }
`

export const Controllers = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
`

export const IconImage = styled(Icon)`
  transition: color 300ms ease-in-out;
  color: ${Colors.WHITE};
  &:hover {
    color: ${Colors.LIGHT_WHITE};
    cursor: pointer;
  }
`
export const IconButton = styled(ButtonIcon)`
  /* &.MuiButton-root {
    max-width: 50px;
    max-height: 50px;
  } */
  .MuiIconButton-label {
    width: 100%;
    display: flex;
    align-items: inherit;
    justify-content: inherit;
    height: 50px;
    width: 31px;
    font-size: 16px;
  }
`

export const CloseIcon = styled(CloseIconSvg)`
  cursor: pointer;
  color: ${Colors.WHITE};
  align-self: flex-end;
  margin-right: 16px;
`

export const SkipBack = styled.div.attrs((props) => ({
  ...props,
  children: (
    <IconButton>
      <IconImage icon="skip-back" />
    </IconButton>
  ),
}))``
export const Play = styled.div.attrs((props) => ({
  ...props,
  children: (
    <IconButton>
      <IconImage icon="play" />
    </IconButton>
  ),
}))`
  margin: 0 10px;
`
export const Pause = styled.div.attrs((props) => ({
  ...props,
  children: (
    <IconButton>
      <IconImage icon="pause" />
    </IconButton>
  ),
}))`
  margin: 0 10px;
`
export const SkipForward = styled.div.attrs((props) => ({
  ...props,
  children: (
    <IconButton>
      <IconImage icon="skip-forward" />
    </IconButton>
  ),
}))``

export const TimeSlider = styled(Slider)`
  &.MuiSlider-root {
    color: ${Colors.WHITE};
    position: absolute;
    top: -13px;
    margin: 5px 10px;
    margin-top: 0;
    height: 8px;
    .MuiSlider-thumb {
    }
    .MuiSlider-thumb::after {
    }
  }
`
export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 18px;
  max-width: 350px;
  width: -webkit-fill-available;
  overflow: visible;
  text-overflow: ellipsis;
  -webkit-mask-image: linear-gradient(
    to right,
    transparent,
    #202124 5%,
    #202124 95%,
    transparent
  );
  > div {
    animation: marquee 10s linear infinite;
    padding-right: 40px;
  }

  @keyframes marquee {
    to {
      transform: translateX(-150px);
    }
  }
`
export const BasePlayer = styled(ReactPlayer)``

export const RateSelect = styled(Select)`
  &.MuiInputBase-root {
    color: ${Colors.WHITE};
    padding-left: 14px;
    &::before{
      border: none;
    }
  }

.MuiSelect-icon {
    color: ${Colors.WHITE};

}
`

export const RateSelectItem = styled(MenuItem)``
